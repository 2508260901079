import styled from 'styled-components';

import Grid from '../../design_v2/ui/grid';
import Container from '../../design_v2/ui/container';

export const BookingContainer = styled(Container)(({ theme }) => ({
    ...theme.marginsY(24, 0),
}));

export const BlockLeft = styled(Grid)(({ theme }) => ({
    order: 1,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 2,
    },
}));

export const BlockRight = styled(Grid)(({ theme }) => ({
    order: 2,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 1,
    },
}));

export const BlockPassengers = styled(Grid)(({ theme }) => ({
    paddingTop: '0 !important',
    paddingBottom: '0 !important',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        padding: '0 !important',
    },
}));