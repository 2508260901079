import React from 'react';

import useLocalization from '../../../../../contexts/localization/hook';

import Container from '../../../ui/container';
import Carousel from './carousel';
import TitleUI from '../../../ui/titleUI';
import toursIcon from "../../../../../assets/icons/iconsForTitle/discount.svg"

const ToursAndDiscounts = ({ tours }) => {
    const { t, language } = useLocalization();
    const toursByLanguage = tours.filter(tour => tour.tourContent[language]);

    if (!toursByLanguage.length) return null;

    return (
        <Container fixed style={{ marginBottom: '6rem' }}>
            <TitleUI as="h2" position="center" textTransform="uppercase" icon={toursIcon}>{t.tours_discount}</TitleUI>
            <Carousel tours={toursByLanguage} />
        </Container>
    )
};

export default ToursAndDiscounts;
