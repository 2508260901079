import {
    ONLY_A_Z,
    ONLY_A_Z_SPACE,
    INVALID_PHONE,
    MIN_2_SYMBOL,
    CHECK_DATE,
    MIN_3_SYMBOL,
    INCORRECT_NUMBER,
    INCORRECT_CVC,
    INCORRECT_CARD_HOLDER,
    INCORRECT_CARD_MONTH,
    INCORRECT_CARD_YEAR,
    ONLY_INTEGER,
    SELECT_VALUE_FROM_LIST,
    CODES_MATCH,
    INVALID_EMAIL,
    INVALID_PASSWORD,
    REQUIRED,
    INCORRECT_DATE_FROM_ADT,
    INCORRECT_DATE_FROM_CHD,
    INCORRECT_DATE_FROM_INF,
    EXPIRED_DATE,
    CHECK_DATE_AND_CHANGE_DOC,
    WARNING_INF_DATE,
    PS_SHOULD_BE_10_DIGITS,
    PSP_SHOULD_BE_9_DIGITS,
    INCORRECT_DOC_NUMBER,
    INCORRECT_DATE,
    INVALID_VALUE,
    IMAGE_TOO_LARGE,
    IMAGE_RESOLUTION_TOO_HIGH,
    IMAGE_RESOLUTION_TOO_SMALL,
    PASSWORD_CONFIRM_ERROR,
} from '../containers/common/constants';
import { SR, PS, PSP } from '../containers/booking/constants';
import { ADT, CHD, INS, INF } from '../containers/search/constants';
import moment from 'moment';
import _ from 'lodash';
import { formValues } from 'redux-form';

const MAX_IMAGE_SIZE = 1024 * 1024 * 4;
const MIN_WIDTH = 300;
const MAX_WIDTH = 2000;
const MIN_HEIGHT = 100;
const MAX_HEIGHT = 2000;

export const imgSize = size => {
    return size > MAX_IMAGE_SIZE ? IMAGE_TOO_LARGE : undefined;
};

export const imgMinMaxSize = (width, height) => {
    if (width > MAX_WIDTH || height > MAX_HEIGHT) {
        return IMAGE_RESOLUTION_TOO_HIGH;
    }

    if (width < MIN_WIDTH || height < MIN_HEIGHT) {
        return IMAGE_RESOLUTION_TOO_SMALL;
    }

    return undefined;
};

export const required = value => {
    return value ? undefined : REQUIRED;
};

export const requiredHash = value => {
    return value.code && (value.name || value.value) ? undefined : SELECT_VALUE_FROM_LIST;
};

export const email = value => {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? INVALID_EMAIL : undefined;
};

const minLength = (min, mess) => value => value && value.length < min ? mess : undefined;

export const minLength6 = value => {
    return value && value.length < 6 ? INVALID_PASSWORD : undefined;
};

export const minLength2 = minLength(2, MIN_2_SYMBOL);
export const minLength3 = minLength(3, MIN_3_SYMBOL);

export const notSameRoutes = (value, formValues, props, fieldName) => {
    const index = fieldName.replace(/[^\d]/g, '');
    const actualRoute = formValues.routes[index];
    const routeFrom = actualRoute.from;
    const routeTo = actualRoute.to;

    if (_.isEmpty(routeFrom) || _.isEmpty(routeTo)) {
        return undefined;
    }

    const codeFrom = routeFrom.code;
    const codeTo = routeTo.code;

    return codeFrom === codeTo ? CODES_MATCH : undefined;
};

const cardNumber = value => {
    const data = value.replace(/[^\d]/g, '');

    if (data.length !== 16) {
        return INCORRECT_NUMBER;
    }

    return !/^[0-9\s]*$/i.test(value) ? INCORRECT_NUMBER : undefined;
};

const onlyInteger = value => (isNaN(Number(value)) ? ONLY_INTEGER : undefined);

export const cardCvc = value => {
    const data = value.replace(/[^\d]/g, '');

    if (data.length !== 3) {
        return INCORRECT_CVC;
    }

    return !/^[0-9\s]*$/i.test(value) ? INCORRECT_NUMBER : undefined;
};

const cardNames = value => {
    if (value.length < 8) {
        return INCORRECT_CARD_HOLDER;
    }
};

const cardDateMonth = value => {
    if (value.length < 2 || value > 12) {
        return INCORRECT_CARD_MONTH;
    }
};

const cardDateYear = value => {
    if (value.length < 2) {
        return INCORRECT_CARD_YEAR;
    }
};

export const passengerFirstName = value => (value && !/^[A-Z\s]*$/i.test(value) ? ONLY_A_Z_SPACE : undefined);

export const passengerLastName = value => (value && !/^[A-Z\s]*$/i.test(value) ? ONLY_A_Z_SPACE : undefined);

export const passengerMiddleName = value => {
    return value && !/^[A-Z]*$/i.test(value) ? ONLY_A_Z : undefined;
};

export const requiredPassengerMiddleName = (value, formValues, props, fieldName) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const index = fieldName.replace(/[^\d]/g, '');
    const item = formValues.passengers[index];
    const documentType = item.document_type;

    return checkRequiredPassengerMiddleName(value, documentType);
};

export const requiredPassengerMiddleNameSingle = (value, formValues) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const documentType = formValues.document_type;

    return checkRequiredPassengerMiddleName(value, documentType);
};

const checkRequiredPassengerMiddleName = (value, documentType) => {
    if (documentType !== PS && documentType !== SR) {
        return false;
    }

    return value ? undefined : REQUIRED;
};

export const requiredContactInfo = (value, formValues, props, fieldName) => {
    const index = fieldName.replace(/[^\d]/g, '');

    if (index !== '0') {
        return false;
    }

    return value ? undefined : REQUIRED;
};

export const phone = value => {
    if (value) {
        const length = value.replace(/[^\d]/g, '').length;

        return length < 12 ? INVALID_PHONE : undefined;
    }
};

export const percentValue = value => {
    if (value) {
        return value > 100 ? INVALID_VALUE : undefined;
    }
};

export const requiredExpirationDate = (value, formValues, props, fieldName) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const index = fieldName.replace(/[^\d]/g, '');
    const item = formValues.passengers[index];
    const documentType = item.document_type;

    return checkRequiredExpirationDate(documentType, value);
};

export const requiredExpirationDateSingle = (value, formValues) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const documentType = formValues.document_type;

    return checkRequiredExpirationDate(documentType, value);
};

const checkRequiredExpirationDate = (documentType, value) => {
    if (documentType === PS && documentType === SR) {
        return false;
    }

    return value ? undefined : REQUIRED;
};

export const validDate = value => {
    return moment(value, 'DD.MM.YYYY', true).isValid() ? undefined : CHECK_DATE;
};

export const validDateWithoutEmpty = value => {
    if (_.isEmpty(value)) {
        return undefined;
    }

    return moment(value, 'DD.MM.YYYY', true).isValid() ? undefined : CHECK_DATE;
};

export const adtDateOfBirth = (value, formValues, props, fieldName) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const index = fieldName.replace(/[^\d]/g, '');
    const item = formValues.passengers[index];
    const documentType = item.document_type;
    const passengerType = item.type;
    const { deadlineDate } = props;

    return checkAdtDateOfBirth(value, documentType, passengerType, deadlineDate);
};

export const adtDateOfBirthSingle = (value, formValues, props) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const documentType = formValues.document_type;
    const { deadlineDate } = props;

    return checkAdtDateOfBirthForAllTypePassenger(value, documentType, deadlineDate);
};

const checkAdtDateOfBirthForAllTypePassenger = (value, documentType, deadlineDate) => {
    const inspect = moment(value, 'DD.MM.YYYY HH:mm');
    const deadline = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'years');

    if (documentType === SR) {
        const statusValid = deadline.isBefore(inspect);

        return statusValid ? undefined : CHECK_DATE_AND_CHANGE_DOC;
    } else if (documentType === PS) {
        const statusValid = deadline.isAfter(inspect) || deadline.isSame(inspect);

        return statusValid ? undefined : CHECK_DATE_AND_CHANGE_DOC;
    } else {
        return undefined;
    }
};

const checkAdtDateOfBirth = (value, documentType, passengerType, deadlineDate) => {
    if (passengerType === ADT && documentType === SR) {
        const inspect = moment(value, 'DD.MM.YYYY HH:mm');
        const deadline = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'years');
        const statusValid = deadline.isBefore(inspect);

        return statusValid ? undefined : CHECK_DATE_AND_CHANGE_DOC;
    } else if (passengerType === ADT && documentType === PS) {
        const inspect = moment(value, 'DD.MM.YYYY HH:mm');
        const deadline = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'years');
        const statusValid = deadline.isAfter(inspect) || deadline.isSame(inspect);

        return statusValid ? undefined : CHECK_DATE_AND_CHANGE_DOC;
    } else {
        return undefined;
    }
};

export const infDateOfBirth = (passengerType, deadlineDate, documentType, value) => {
    if ((passengerType === INF || passengerType === INS) && documentType === SR) {
        const inspect = moment(value, 'DD.MM.YYYY HH:mm');
        const max = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'days');
        const min = moment(deadlineDate, 'DD.MM.YYYY').add(-1, 'months');
        const statusValid =
            (min.isBefore(inspect) || min.isSame(inspect)) && (max.isAfter(inspect) || max.isSame(inspect));

        return statusValid ? WARNING_INF_DATE : undefined;
    } else {
        return undefined;
    }
};

export const dateOfBirth = (value, formValues, props, fieldName) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const index = fieldName.replace(/[^\d]/g, '');
    const item = formValues.passengers[index];
    const passengerType = item.type;
    const { deadlineDate } = props;

    return checkDateOfBirth(value, passengerType, deadlineDate);
};

export const dateOfBirthSingle = (value, formValues, props) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const { deadlineDate } = props;

    return checkDateOfBirthForAllPassengerType(value, deadlineDate);
};

const checkDateOfBirthForAllPassengerType = (value, deadlineDate) => {
    const inspect = moment(value, 'DD.MM.YYYY HH:mm');
    const min = moment('01.01.1900', 'DD.MM.YYYY');
    const max = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'days');

    const statusValid = (min.isBefore(inspect) || min.isSame(inspect)) && max.isAfter(inspect);

    return statusValid ? undefined : INCORRECT_DATE;
};

const checkDateOfBirth = (value, passengerType, deadlineDate) => {
    const inspect = moment(value, 'DD.MM.YYYY HH:mm');

    if (passengerType === ADT) {
        const min = moment('01.01.1900', 'DD.MM.YYYY');
        const max = moment(deadlineDate, 'DD.MM.YYYY').add(-12, 'years');
        const statusValid =
            (min.isBefore(inspect) || min.isSame(inspect)) && (max.isAfter(inspect) || max.isSame(inspect));

        return statusValid ? undefined : INCORRECT_DATE_FROM_ADT;
    } else if (passengerType === CHD) {
        const min = moment(deadlineDate, 'DD.MM.YYYY').add(-12, 'years');
        const max = moment(deadlineDate, 'DD.MM.YYYY').add(-2, 'years');
        const statusValid = min.isBefore(inspect) && (max.isAfter(inspect) || max.isSame(inspect));

        return statusValid ? undefined : INCORRECT_DATE_FROM_CHD;
    } else {
        const dateNow = moment().format('DD.MM.YYYY');
        const inspectDateNow = moment(dateNow, 'DD.MM.YYYY');

        if (inspectDateNow.isBefore(inspect) || inspectDateNow.isSame(inspect)) {
            return INCORRECT_DATE_FROM_INF;
        }

        const min = moment(deadlineDate, 'DD.MM.YYYY').add(-2, 'years');
        const max = moment(deadlineDate, 'DD.MM.YYYY').add(-14, 'days');
        const statusValid = min.isBefore(inspect) && max.isAfter(inspect);

        return statusValid ? undefined : INCORRECT_DATE_FROM_INF;
    }
};

export const expirationDate = (value, formValues, props) => {
    const { deadlineDate } = props;
    const inspect = moment(value, 'DD.MM.YYYY HH:mm');
    const deadline = moment(deadlineDate, 'DD.MM.YYYY').add(3, 'months');

    const statusValid = deadline.isBefore(inspect);

    return statusValid ? undefined : EXPIRED_DATE;
};

export const documentNumber = (value, formValues, props, fieldName) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const { typeArrayForm } = props;
    const index = fieldName.replace(/[^\d]/g, '');
    const item = formValues[typeArrayForm][index];
    const documentType = item.document_type;

    return checkDocumentNumber(documentType, value);
};

export const documentNumberSingle = (value, formValues) => {
    if (_.isEmpty(formValues)) {
        return undefined;
    }

    const documentType = formValues.document_type;

    return checkDocumentNumber(documentType, value);
};

const checkDocumentNumber = (documentType, value) => {
    if (documentType === PS) {
        return value.length !== 10 || isNaN(Number(value)) ? PS_SHOULD_BE_10_DIGITS : undefined;
    } else if (documentType === PSP) {
        return value.length !== 9 || isNaN(Number(value)) ? PSP_SHOULD_BE_9_DIGITS : undefined;
    } else if (documentType === SR) {
        let count = value.length;

        if (count < 9 || count > 12) {
            return INCORRECT_DOC_NUMBER;
        } else {
            if (isNaN(Number(value.substr(-6)))) {
                return INCORRECT_DOC_NUMBER;
            }

            count = count - 6;
            const lettersCode = value.substr(0, count);

            if (!/^[А-Я]*$/i.test(lettersCode.substr(-2))) {
                return INCORRECT_DOC_NUMBER;
            }

            count = count - 2;
            const latinLetters = lettersCode.substr(0, count);

            if (!/^[IVX]*$/i.test(latinLetters)) {
                return INCORRECT_DOC_NUMBER;
            }
        }

        return undefined;
    } else {
        return undefined;
    }
};

export const passwordConfirm = (value, formValues) => {
    return formValues.password !== formValues.password_confirm ? PASSWORD_CONFIRM_ERROR : undefined;
};
