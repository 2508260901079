import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RulesModal from '../rulesModal/rulesModal';
import moment from 'moment';
import {
    FormattedDateTime,
    getBaggageCountString,
    getRouteCity,
    GetTime,
    GetTransferTime,
    RenderAirlineLogo,
    RenderDurationTime,
    renderServiceClass,
} from '../../result/common';
import { COLOR_THEME } from '../../../containers/result/constants';
import useLocalization from '../../../contexts/localization/hook';
import {
    AirCraft,
    ArrivalData,
    ArrowBlock, BaggageBlock,
    BolderLeftLine,
    ClassBlock,
    FlightCode,
    DepartAirport,
    DepartCity,
    DepartureData,
    DepartureRouteBlock,
    DepartureTime,
    DepartureTimeBlock,
    DepartureTimeOnTop,
    Divider1,
    Divider2,
    FlightData,
    FlightNumber,
    FlightNumberAndTime,
    FlightTimes,
    LogoBlock,
    PointBlock,
    RootRoute,
    RouteTitleFirst,
    RouteTitleSecond,
    SegmentBlock,
    SegmentsContainer,
    SegmentTime,
    TechStopBlock,
    TechStopContainer,
    TehStopTitle,
    Terminal,
    TransferBlock,
    TransferContainer,
    TransferData,
    TravelTime,
    TravelTimeLabel,
    FlightInfo
} from './components';
import ThemeContext from '../../../contexts/theme/context';
import BaggageAndHandLuggageUI from '../../design_v2/ui/baggageAndHandLuggageUI';


export default function RoutesData(props) {

    const { routes, included, fareRules } = props;

    return (
        <div>
            {routes.map((route, index) => (
                <RenderRoute
                    route={route}
                    fareRules={fareRules}
                    key={index}
                    index={index}
                    included={included}
                    routes={routes}
                />
            ))}
        </div>
    );
}

function RenderRoute(data) {
    const { t, language } = useLocalization();
    const { route, index, included, fareRules, routes } = data;
    const themeColor = COLOR_THEME[index];
    const [departureCity, arrivalCity, departureTime] = getRouteCity(route, included, language);

    return (
        <RootRoute container>
            <BolderLeftLine />

            <Grid item xs={12}>
                <Grid container>
                    <RouteTitleFirst item xs={12} sm={8} lg={9}>
                        <DepartureTimeBlock>
                            {t.departure_title} <FormattedDateTime time={departureTime} />
                        </DepartureTimeBlock>
                        <DepartureRouteBlock>
                            {departureCity} — {arrivalCity}
                        </DepartureRouteBlock>
                    </RouteTitleFirst>

                    <Divider1 />

                    <RouteTitleSecond item xs={12} sm={4} lg={3}>
                        <TravelTimeLabel>{t.travel_time}</TravelTimeLabel>
                        <TravelTime>
                            <RenderDurationTime duration={route.duration} />
                        </TravelTime>
                    </RouteTitleSecond>
                </Grid>

                <Divider2 />
            </Grid>

            <SegmentsContainer item xs={12}>
                {route.segments.map((segment, index) => (
                    <RenderSegment
                        segment={segment}
                        route={route}
                        key={index}
                        index={index}
                        included={included}
                        fareRules={fareRules}
                        routes={routes}
                    />
                ))}
            </SegmentsContainer>
        </RootRoute>
    );
}

function RenderSegment(data) {
    const { t, language } = useLocalization();
    const [openRules, setOpenRules] = useState(false);

    const changeTheme = useContext(ThemeContext);

    const handleCloseRules = () => {
        setOpenRules(false);
    };

    const { segment, route, index, included, fareRules, routes } = data;
    const {
        aircraft,
        operation_supplier,
        carrier_number,
        duration,
        flight_number,
        departure,
        arrival,
        service_class,
        baggage,
        hand_luggage,
        tech_stops,
    } = segment;
    const isTransfer = index > 0;
    let transferTime = '';
    const isTechStops = segment.tech_stops && segment.tech_stops.length > 0;

    let aircraftName = aircraft;

    if (included.aircraft[aircraft]) {
        aircraftName = included.aircraft[aircraft].name.en;
    } else {
        aircraftName = '';
    }

    const departureCode = departure.city;
    const arrivalCode = arrival.city;
    const departureCity = included.city[departureCode].name[language];
    const arrivalCity = included.city[arrivalCode].name[language];
    const departureAirportCode = departure.airport;
    const arrivalAirportCode = arrival.airport;
    const departureAirport = included.airport[departureAirportCode].name[language];
    const arrivalAirport = included.airport[arrivalAirportCode].name[language];

    const departureCountryCode = included.airport[departureAirportCode].country;
    const departureCountry = included.country[departureCountryCode].name[language];

    if (isTransfer) {
        transferTime = GetTransferTime(route, index, t);
    }

    return (
        <SegmentBlock container>
            {isTransfer && (
                <TransferContainer item>
                    <TransferBlock changeTheme={changeTheme.theme}></TransferBlock>
                    <TransferData>
                        {t.transfers} {transferTime} • {departureCity}, {departureCountry}
                    </TransferData>
                </TransferContainer>
            )}
            <FlightData item>
                <LogoBlock>
                    <RenderAirlineLogo
                        supplier={operation_supplier}
                        style={{ backgroundPosition: 'left center' }}
                        horizontally='left'
                    />
                </LogoBlock>
                <FlightNumber>
                    {t.flight} {carrier_number || flight_number}
                </FlightNumber>
                <AirCraft>{aircraftName}</AirCraft>
                <FlightNumberAndTime>
                    <RenderDurationTime duration={duration} /> {t.on_way.toLowerCase()}
                </FlightNumberAndTime>
            </FlightData>
            <FlightTimes item xs={12} lg={6}>
                <Grid container>
                    <DepartureData item xs={5}>
                        <DepartureTimeOnTop>
                            <FormattedDateTime time={departure.time} />
                        </DepartureTimeOnTop>
                        <SegmentTime>
                            <GetTime time={departure.time} />
                        </SegmentTime>
                        <PointBlock>
                            <DepartureTime>
                                <FormattedDateTime time={departure.time} />
                            </DepartureTime>
                            <DepartCity>{departureCity}</DepartCity>
                            <DepartAirport>
                                {departureAirport}
                                <FlightCode component='span'>
                                    {departure.airport}
                                </FlightCode>
                            </DepartAirport>
                            {departure.terminal && (
                                <div>
                                    {t.terminal} {departure.terminal}
                                </div>
                            )}
                        </PointBlock>
                    </DepartureData>
                    <ArrowBlock item xs={2}></ArrowBlock>
                    <ArrivalData item xs={5}>
                        <DepartureTimeOnTop>
                            <FormattedDateTime time={departure.time} />
                        </DepartureTimeOnTop>
                        <SegmentTime>
                            <GetTime time={arrival.time} />
                        </SegmentTime>
                        <PointBlock>
                            <DepartureTime>
                                <FormattedDateTime time={arrival.time} />
                            </DepartureTime>
                            <DepartCity>{arrivalCity}</DepartCity>
                            <DepartAirport>
                                {arrivalAirport}
                                <FlightCode component='span'>
                                    {arrival.airport}
                                </FlightCode>
                            </DepartAirport>
                            {arrival.terminal && (
                                <Terminal>
                                    {t.terminal} {arrival.terminal}
                                </Terminal>
                            )}
                        </PointBlock>
                    </ArrivalData>
                </Grid>
            </FlightTimes>
            <FlightInfo item>
                <ClassBlock>{renderServiceClass(service_class, t)}</ClassBlock>
                <>
                    <BaggageAndHandLuggageUI baggage={baggage} handLuggage={hand_luggage} />
                </>
            </FlightInfo>
            <div>
                <RulesModal
                    openRules={openRules}
                    handleCloseRules={handleCloseRules}
                    fareRules={fareRules}
                    routes={routes}
                    actualSegment={segment}
                    included={included}
                />
            </div>
            {isTechStops && (
                <TechStopContainer item>
                    <TechStopBlock>
                        <RenderSegmentTechStops items={tech_stops} included={included} />
                    </TechStopBlock>
                </TechStopContainer>
            )}
        </SegmentBlock>
    );
}

const RenderSegmentTechStops = data => {
    const { t, language } = useLocalization();
    const { items, included } = data;

    const tehStops = items
        .map(item => {
            let time = '';

            if (item.duration.hour > 0) {
                time = item.duration.hour + ' ' + t.hour_short + ' ';
            }

            time += item.duration.minute + ' ' + t.minutes_short_3;

            const startTime = moment(item.arrival.time, 'h:mm a').format('HH:mm');
            const cityCode = item.city.code;
            const countryCode = item.country.code;

            return (
                included.city[cityCode].name[language] +
                ', ' +
                included.country[countryCode].name[language] +
                ', ' +
                t.in +
                ' ' +
                startTime +
                ', ' +
                t.duration +
                ' — ' +
                time
            );
        })
        .join(', ');

    return (
        <div>
            <TehStopTitle>{t.teh_stop}</TehStopTitle>
            <span>{tehStops}</span>
        </div>
    );
};
