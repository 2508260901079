import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBrandFareRecId } from '../../../../../../store/resultData/actions';

import TariffItem from '../tariffItem';

const Tariffs = ({ brandRecId, recId, getFareRules, brandFares, brandName, currency }) => {
    const dispatch = useDispatch();
    const { brandRules } = useSelector(state => state.resultData);

    const handleCheckTariffs = value => {
        dispatch(setBrandFareRecId({ recId, brandRecId: value }));
    };

    const handleGetFareRules = recId => {
        getFareRules(recId);
    };

    return brandFares.map((brandFare, index) => {
        let isChecked = false;

        if (brandRecId) {
            isChecked = brandFare.rec_id === brandRecId;
        } else {
            isChecked = brandFare.brand_name === brandName;
        }

        return (
            <TariffItem
                key={index}
                brandFare={brandFare}
                isChecked={isChecked}
                checkTariffs={handleCheckTariffs}
                handleGetFareRules={handleGetFareRules}
                brandRules={brandRules}
                currency={currency}
            />
        );
    });
};

export default Tariffs;
