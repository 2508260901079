import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterSpaceBetween,
    ...theme.marginsY(0, 12),

    '&:nth-last-of-type(1)': {
        ...theme.marginsY(0, 0),
    },
}));

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
`;

export const TextDescription = styled('div')(({ theme }) => ({
    fontSize: '0.6rem',
    color: theme.palette.button.checkboxClass,
    width: 96,
    lineHeight: 1.2,
}));

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled('div')(({ theme, disabled }) => ({
    ...theme.mixins.flexCenterCenter,
    border: 'none',
    backgroundColor: disabled ? theme.palette.button.deactivatedButtonPassengers : theme.palette.button.searchButtonBg,
    borderRadius: theme.spacing(0.75),
    width: 24,
    height: 24,
    cursor: 'pointer',

    '&:focus': {
        outline: 'none',
    },
}));

export const Count = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    fontWeight: 600,
    ...theme.margins(0, 12),
}));

export const TextPlain = styled('div')(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.main,
    ...theme.marginsY(0, 12),
    textAlign: 'center',
    width: '100%',
}));
