import React from "react";
import telegramIcon from "../../../assets/icons/newDesign/telegram-icon-connect.svg";
import { TelegramChatWrapper } from "./components";

const TelegramChat = () => {
  return (
    <a href="https://t.me/flytripuz_manager4" rel="noopener noreferrer" target="_blank" >
      <TelegramChatWrapper>
        <img src={telegramIcon} alt="" />
        <p>Связатся с менеджером telegram</p>
      </TelegramChatWrapper>
    </a>
  );
};

export default TelegramChat;
