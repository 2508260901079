import React from 'react';
import RoutesData from '../routesData/routesData';
import PriceForm from '../priceForm/priceForm';
import PassengerInfoForm from '../passengersInfoForm/passengerInfoForm';
import LocalTimeText from '../../ui/components/LocalTimeText/LocalTimeText';
import _ from 'lodash';
import Grid from '../../design_v2/ui/grid';
import { BlockLeft, BlockPassengers, BlockRight, BookingContainer } from './components';

export default function BookingForm(props) {
    const {
        totalPrice,
        passengers,
        onUpdateCitizenship,
        getCountries,
        resetCountries,
        onSendBooking,
        currency,
        countries,
        setPassengerType,
        formValues,
        routes,
        clearDocumentData,
        formErrors,
        clientFees,
        setPayerEmailToPassenger,
        setPayerPhoneToPassenger,
        onSelectAvailableDocumentTypes,
        sessionId,
        cashback
    } = props;

    const lastRoute = _.last(routes);
    const deadlineDate = _.last(lastRoute.segments).arrival.time;

    const partnerFees = _.isUndefined(formValues) ? 0 : formValues.partnerFees;

    return (
        <BookingContainer fixed>
            <Grid container spacing={4}>
                <BlockLeft item xs={12} lg={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <RoutesData {...props} />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalTimeText />
                        </Grid>
                    </Grid>
                </BlockLeft>

                <BlockRight item xs={12} lg={3}>
                    <PriceForm
                        currency={currency}
                        totalPrice={totalPrice}
                        partnerFees={partnerFees}
                        clientFees={clientFees}
                        cashback={cashback}
                    />
                </BlockRight>
            </Grid>

            <Grid container spacing={4}>
                <BlockPassengers item xs={12} xl={9}>
                    <PassengerInfoForm
                        typeArrayForm="passengers"
                        formErrors={formErrors}
                        onUpdateCitizenship={onUpdateCitizenship}
                        passengers={passengers}
                        getCountries={getCountries}
                        resetCountries={resetCountries}
                        countries={countries}
                        onSendBooking={onSendBooking}
                        setPassengerType={setPassengerType}
                        formValues={formValues}
                        clearDocumentData={clearDocumentData}
                        deadlineDate={deadlineDate}
                        setPayerEmailToPassenger={setPayerEmailToPassenger}
                        setPayerPhoneToPassenger={setPayerPhoneToPassenger}
                        routes={routes}
                        fareRules={props.fareRules}
                        included={props.included}
                        onSelectAvailableDocumentTypes={onSelectAvailableDocumentTypes}
                        sessionId={sessionId}
                    />
                </BlockPassengers>
            </Grid>
        </BookingContainer>
    );
}
