import styled from 'styled-components';

export const HeaderBlock = styled('header')(({theme}) => ({
    width: '100%',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100,
    // backgroundColor: `${theme.palette.bodyBg}`,
    height: theme.spacing(7),
    maxHeight: theme.spacing(7),
    color: `${theme.palette.white}`,
    backdropFilter: "blur(10px)",
    boxShadow: `0px 0px 3px 0px ${theme.palette.pricesTableBoxShadow}`,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        maxHeight: theme.spacing(10),
    }
}))

export const HeaderContainer = styled("div")(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 1280,
    margin: '0 auto',
    padding: '0 25px',
}));

