import styled from 'styled-components';
import { Divider, Grid } from '@material-ui/core';

export const PaymentContainer = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(2.75),
    backgroundColor: theme.palette.inputBackground,
    boxShadow: '0 10px 10px 1px rgba(0, 59, 110, 0.1)',
}));

export const PaymentBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16),
}));

export const PaymentTitle = styled(Grid)(({ theme }) => ({
    width: '70%',
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.mainText,
}));

export const PaymentMethodItem = styled(Grid)(({ theme }) => ({
    ...theme.paddingsY(16, 0),
    width: '100%',
    color: theme.palette.mainText,
    fontSize: 18,
}));

export const LoadingBlock = styled("div")(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    color: theme.palette.main,
}));

export const PaymentTimeLimitBlock = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        justifyContent: 'center',
    },
}));

export const PaymentButtonBlock = styled('div')(({ theme }) => ({
    ...theme.marginsX(16, 0),
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [theme.breakpoints.down(theme.breakpoints.laptop)]:  {
        justifyContent: 'center',
    },
}));

export const CardBlock = styled('div')(({ theme }) => ({
    ...theme.paddingsY(16, 0),
}));

export const LineBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 0),
}));

export const Line = styled(Divider)(({ theme }) => ({
    '&.MuiDivider-root': {
        height: 2,
        backgroundColor: theme.palette.lineDivider,
    }
}));

export const TimelimitLabel = styled('div')(({ theme }) => ({
    color: theme.palette.mainText
}));
