import React, { useContext } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';

import envConfigs from '../../../../../../../../config/envConfigs';

import { PhoneNumber, Items, Item } from './components';
import Dropdown from '../../../../../../ui/dropdown';
import { useStyles } from '../../../styles';
import { useState } from 'react';
import { Button, Component } from '../languages/components';
import { ReactComponent as CaretIcon } from '../../../../../../../../assets/icons/newDesign/caretIcon.svg';
import { ReactComponent as CaretIconWhite } from '../../../../../../../../assets/icons/newDesign/caretIcon-white.svg';
import { Divider } from '@material-ui/core';
import ThemeContext from '../../../../../../../../contexts/theme/context';
import { LIGHT_THEME } from '../../../../../../../../theme/constants';

const Contacts = () => {
    const [isActive, setIsActive] = useState(false);
    const styles = useStyles();
    const { theme } = useContext(ThemeContext);


    return (<Component>
        <Button onClick={() => setIsActive(true)}>
            <PhoneIcon style={{ marginLeft: '-5px', marginRight: '5px' }} />
            <span className={isActive ? styles.caretIcon : null}>
                    {theme === LIGHT_THEME ? <CaretIcon /> : <CaretIconWhite />}
                </span>
        </Button>

        <Dropdown isActive={isActive} onClickOutside={() => setIsActive(false)} width={'190px'}>
            <Items>
                <Item>
                    <PhoneNumber href={`tel: ${envConfigs.supportSecondPhone}`}>
                        {envConfigs.supportSecondPhone}
                    </PhoneNumber>
                </Item>
                <Divider />
                <Item>
                    <PhoneNumber href={`tel: ${envConfigs.supportPhone}`}>{envConfigs.supportPhone}</PhoneNumber>
                </Item>
            </Items>
        </Dropdown>
    </Component>);
};

export default Contacts;
