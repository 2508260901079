import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Wrapper = styled('div')(({ theme }) => ({
    opacity: 0.8,
    borderRadius: theme.spacing(0.5),
    boxShadow: '1px -17px 16px 0 rgba(11,-1,0,0.12), 0 -1px 4px 0 rgb(155 155 155 / 51%)',
    backgroundColor: theme.palette.blocksBackground,
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative',
}));

export const FareGrid = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 20, 20, 20),
    '&.MuiGrid-root': {
        textAlign: 'start',
    },
}));

export const BrandNameGrid = styled(Grid)(({ theme }) => ({
    width: '80%',
    display: 'inline-block',
    fontSize: 20,
    fontWeight: 500,
    color: '#212121',
}));

export const BrandCheckedIconGrid = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    right: 16,
    top: 16,
    color: theme.palette.main,
    textAlign: 'right',
    cursor: 'pointer',
}));

export const BrandTotalPriceGrid = styled(Grid)(({ theme }) => ({
    width: '100%',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.mainText,
}));

export const BrandFareInfoGrid = styled(Grid)({
    width: '100%',
});

export const BrandTariffsRulesGrid = styled(Grid)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 500,
    paddingTop: theme.spacing(1.5),
}));

export const BrandTariffsRulesLabel = styled('div')(({ theme, loading }) => ({
    ...theme.mixins.flexAlignCenter,
    cursor: 'pointer',
    color: loading ? '#9da8ae' : theme.palette.main,
}));
