import { Divider } from '@material-ui/core';
import styled from 'styled-components';

import Grid from '../../ui/grid';

export const ContainerGrid = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.transparent,
}));

export const Separator = styled('div')(({ theme }) => ({
    height: 16,
    backgroundColor: theme.palette.transparent,
}));

export const FlightDetailsBlock = styled('div')(({ theme, showDetailsBlock }) => ({
    ...theme.paddings(0, 16),
    textAlign: 'center',
    border: 'none',
    cursor: 'pointer',
    color: showDetailsBlock ? theme.palette.main : '#9da8ae',
    marginBottom: theme.spacing(0.5),
}));

export const TimeMessage = styled('div')(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.recommendation.classInfo,
    paddingBottom: theme.spacing(2),
    
    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        paddingLeft: 0,
        textAlign: 'center',
    },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: `${theme.palette.main} !important`,
}));
