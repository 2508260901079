import styled from 'styled-components';
import { Slider } from '@material-ui/core';

export const RangeTitle = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    fontSize: 14,
}));

export const RootSlider = styled('div')(({ theme }) => ({
    ...theme.paddingsX(13, 8),
    width: '100%',
}));

export const Circle = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 8,
    height: 8,
    backgroundColor: theme.palette.rangeSlider,
}));

export const RangeSliderItem = styled(Slider)(({ theme }) => ({
    '.MuiSlider-root': {
        color: theme.palette.main,
    },
    '.MuiSlider-thumb': {
        ...theme.margins(-9, 0, 0, -13),
        height: 20,
        width: 20,
        backgroundColor: theme.palette.rail,
        border: `5px solid ${theme.palette.main}`,
        boxShadow: '#ebebeb 0px',
        '&:focus,&:hover,&.Mui-active': {
            boxShadow: '#ccc 0px',
        },
    },
    '.MuiSlider-valueLabel': {
        left: 'calc(-50% + 4px)',
    },
    '.MuiSlider-track': {
        height: 4,
        color: theme.palette.main,
    },
    '.MuiSlider-rail': {
        color: theme.palette.rail,
        opacity: 1,
        height: 4,
    },
}));