import axios, { create as axiosCreate, CancelToken as axiosCancelToken } from 'axios';
import configs from '../config';
import qs from 'qs';

import { formatOptions, formatResult } from './resultFormatter';

const { envConfigs, mainConfigs } = configs;

const companyRequisitId = envConfigs.companyRequisitesId;
const baseURL = envConfigs.apiHost;
const hostURL = envConfigs.hostURL;
const countSearchCity = mainConfigs.countSearchCity;
const countOrdersLimit = mainConfigs.countOrdersLimit;
const tokenType = mainConfigs.tokenType;

let https = require('https');

const baseParams = {
    company_req_id: companyRequisitId,
    language: mainConfigs.language,
};

const сancelToken = axiosCancelToken;
let cancel;

const accessToken = () => {
    return localStorage.getItem('token');
};

const basicInstance = () =>
    axiosCreate({
        baseURL,
        timeout: 500000,
        withCredentials: false,
        httpsAgent: new https.Agent({ keepAlive: true }),
    });

const apiInstance = () =>
    axiosCreate({
        baseURL: baseURL,
        timeout: 100000,
        withCredentials: false,
        headers: {
            Authorization: accessToken() && tokenType + ' ' + accessToken(),
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { indices: true });
        },
        cancelToken: new сancelToken(function (c) {
            cancel = c;
        }),
        httpsAgent: new https.Agent({ keepAlive: true }),
    });

const API = {
    getRequisites() {
        return basicInstance()
            .get(`v2/requisits/${companyRequisitId}`)
            .then(response => {
                return response.data;
            });
    },

    login(data) {
        let params = {
            ...data,
            ...baseParams,
        };

        return basicInstance()
            .post('login', params)
            .then(response => {
                return response.data;
            });
    },

    register(data) {
        let params = {
            ...data,
            ...baseParams,
        };

        return basicInstance()
            .post('create', params)
            .then(response => {
                return response.data;
            });
    },

    activation(data) {
        let params = {
            ...data,
            ...baseParams,
        };

        return basicInstance()
            .post('activate', params)
            .then(response => {
                return response.data;
            });
    },

    getCities(data, limit = null) {
        let params = {
            ...baseParams,
            value: data,
            limit: limit || countSearchCity,
        };

        return apiInstance()
            .post('cities', params)
            .then(response => {
                return formatResult.cities(response.data.data);
            })
            .catch(() => {
                return [];
            });
    },

    getCountries(data) {
        let params = {
            ...baseParams,
            value: data,
            limit: countSearchCity,
        };

        return apiInstance()
            .post('countries', params)
            .then(response => {
                return response.data.data;
            })
            .catch(() => {
                return [];
            });
    },

    getClient() {
        let params = {
            ...baseParams,
        };

        return apiInstance()
            .get('auth/clients', params)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error.response.data;
            });
    },

    search(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('search', { params })
            .then(response => {
                if (response.data.data?.flights?.length > 0) {
                    return formatResult.searchResult(response.data.data);
                } else {
                    const nonPriceFlights = [];
                    let i = 0, stop = false;

                    while (!stop) {
                        if (!response.data?.data[i]) {
                            stop = true;
                            continue;
                        }

                        if (response.data?.data[i]?.included) nonPriceFlights.push(response.data?.data[i]);
                        i++;
                    }

                    return formatResult.nonPriceSearchResult({ ...response.data.data, nonPriceFlights });
                }
            })
            .catch(error => {
                return error.response.data;
            });
    },

    brandFares(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('brand_fares', params)
            .then(response => {
                return formatResult.searchBrandFares(response.data.data);
            })
            .catch(error => {
                return error.response.data;
            });
    },

    apiPrebook(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('prebook', params)
            .then(response => {
                return formatResult.getChangeBaggage(response.data.data);
            });
    },

    apiBook(data) {
        let params = {
            ...baseParams,
            ...data,
            url: hostURL,
        };

        return apiInstance()
            .post('book', params)
            .then(response => {
                return response.data;
            });
    },

    apiOrderData(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('order', params)
            .then(response => {
                return formatResult.orderData(response.data.data);
            });
    },

    apiGetOrders(data) {
        let params = {
            ...baseParams,
            ...data,
            limit: countOrdersLimit,
        };

        return apiInstance()
            .post('order-list', params)
            .then(response => {
                return formatResult.orders(response.data);
            });
    },

    billingGetPaymentSystems() {
        const params = {
            ...baseParams,
        };

        return apiInstance()
            .get('paymentSystems', { params })
            .then(response => {
                return formatResult.paymentSystems(response.data.data);
            });
    },

    billingPayment(data) {
        let params = {
            ...data,
            ...baseParams,
        };

        return apiInstance()
            .post('ticketing', params)
            .then(response => {
                return response.data.data;
            });
    },

    getTransactions(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('transactions', { params })
            .then(response => {
                return response.data;
            });
    },

    apiGetDocument(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('document', params)
            .then(response => {
                return response.data.data;
            });
    },

    getNewsList(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('news-list', { params })
            .then(response => {
                return response.data;
            });
    },

    apiGetOneNews(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('news', { params })
            .then(response => {
                return response.data;
            });
    },

    apiGetNewsBySlug(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('news/get-by-slug', { params })
            .then(response => {
                return response.data;
            });
    },

    getTourList(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('tour-list', { params })
            .then(response => {
                return response.data;
            });
    },

    getTourPage(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('tour', { params })
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    getTourPageBySlug(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('tour/get-by-slug', { params })
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    getStaticDiscriptions(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('article-list', { params })
            .then(response => {
                return response.data;
            });
    },

    getStaticPages(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('article', { params })
            .then(response => {
                return response.data;
            });
    },

    getStaticPageBySlug(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('article/get-by-slug', { params })
            .then(response => {
                return response.data;
            });
    },

    postAddingBalance(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('add-balance', params)
            .then(response => {
                return response.data;
            });
    },

    getPartnerList(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('partner-list', { params })
            .then(response => {
                return response.data;
            });
    },

    getPartnersPage(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .get('partner-by-slug', { params })
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    getPopularDestinations() {
        let params = {
            ...baseParams,
        };

        return apiInstance()
            .get('destination-list', { params })
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    postProfileEdit(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('profile/edit', params)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    getReviews() {
        let params = {
            ...baseParams,
            limit: 12,
        };

        return apiInstance()
            .get('review-list', { params })
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    postForgotPassword(data) {
        let params = {
            ...baseParams,
            ...data,
            url: hostURL,
        };

        return apiInstance()
            .post('reset/send', params)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    postResetPassword(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('/reset', params)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    postSubscribe(data) {
        let params = {
            ...baseParams,
            ...data,
            url: hostURL,
        };

        return apiInstance()
            .post('subscribe', params)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return err.response.data;
            });
    },

    getQuestionsCategories() {
        let params = {
            ...baseParams,
        };

        return basicInstance()
            .get('question-categories', { params })
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    getQuestionsByCategoryId(id) {
        const params = {
            ...baseParams,
            id,
        };

        return basicInstance()
            .get('question-by-category', { params })
            .then(response => response.data.data)
            .catch(err => err.response.data);
    },

    getQuestionById(id) {
        const params = {
            ...baseParams,
            id,
        };

        return basicInstance()
            .get('question', { params })
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    getPassengersList() {
        const params = {
            ...baseParams,
        };

        return apiInstance()
            .get('passengers', { params })
            .then(response => response.data.data)
            .catch(err => err.response.data);
    },

    storePassenger(data) {
        const params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('passengers/store', params)
            .then(response => response.data)
            .catch(error => error.response.data);
    },

    deletePassenger(id) {
        const params = { ...baseParams };

        return apiInstance()
            .post(`passengers/delete?id=${id}`, params)
            .then(response => response.data)
            .catch(error => error.response.data);
    },

    getMainData() {
        const params = { ...baseParams };

        return basicInstance()
            .get('main-data', { params })
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    async getMyLocation() {
        return axios
            .get(`${envConfigs.getIpLocationService}?key=${envConfigs.getIpLocationServiceToken}&lang=ru`)
            .then(response => {
                return response.data;
            });
    },

    searchOrder(data) {
        const params = {
            ...baseParams,
            ...data,
        };

        return basicInstance()
            .post('search-book', params)
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    apiOrderCancel(data) {
        let params = {
            ...baseParams,
            ...data,
        };

        return apiInstance()
            .post('cancel-book', params)
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    unsubscribe(data) {
        let params = {
            ...baseParams,
            ...data
        };

        return apiInstance().post('unsubscribe', params)
            .then(response => response)
            .catch(err => err.response);
    },

    refundOrder(data) {
        return basicInstance()
            .post('refund-order', data, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            })
            .then(response => response.data)
            .catch(err => err.response);
    },

    authWiaSocials(data) {
        const params = {
            ...baseParams,
            ...data,
        };

        return basicInstance()
            .post('social/auth', params)
            .then(response => response.data);
    },

    calendarPrices(data) {
        const params = { ...data, ...baseParams };

        return basicInstance()
            .get('calendar', { params })
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    logout() {
        const params = baseParams;

        return apiInstance()
            .post('logout', params)
            .then(response => response.data);
    },

    getOptions() {
        const params = {
            ...baseParams,
        };
        return basicInstance()
            .get('get-options', { params })
            .then(response => formatOptions(response.data.data))
            .catch(err => err.response.data);
    },

    addOptions(data) {
        const params = {
            ...baseParams,
            ...data,
        };
        return basicInstance()
            .post('post-policy', params)
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    calculatePrice(data) {
        const params = {
            ...baseParams,
            ...data,
        };
        return basicInstance()
            .post('calculate-price', params)
            .then(response => response.data)
            .catch(err => err.response.data);
    },

    getRecommendation(recId) {
        const params = {
            ...baseParams,
            rec_id: recId,
        }

        return basicInstance().get('get-recommendation', { params })
            .then(response => response.data);
    },
};

export default API;
