import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TransferIcon } from '../../../../assets/icons/newDesign/transfer.svg';

export const SegmentsWrapper = styled('div')(({ theme }) => ({
    ...theme.paddings(24, 16, 16),
}));

export const TransferBetweenAirportWarning = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    color: '#ff531d',
}));

export const SegmentBlock = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const TransferBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(8, 0),
    ...theme.margins(16, 0),
    ...theme.mixins.flexAlignCenter,
}));

export const StyledTransferIcon = styled((props) => (
    <span {...props}>
        <TransferIcon />
    </span>
))(({ theme }) => ({ color: theme.palette.recommendation.classInfo }));

export const TransferTime = styled('div')(({ theme }) => ({
    color: theme.palette.recommendation.classInfo,
    marginLeft: theme.spacing(1),
    lineHeight: 1,
}));

export const SegmentInfo = styled('div')(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    marginBottom: theme.spacing(2),
}));

export const AirlineLogo = styled('div')(({ imgUrl }) => ({
    height: 30,
    width: 90,
    minWidth: 90,
    backgroundImage: `url(${imgUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
}));

export const SegmentInfoText = styled('div')(({ theme }) => ({
    fontSize: 14,
    lineHeight: 1.2,
    marginLeft: theme.spacing(1.5),
    color: theme.palette.recommendation.classInfo,
}));

export const SegmentDate = styled('div')(({ theme }) => ({
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1,
    marginBottom: theme.spacing(2),
    color: theme.palette.recommendation.classInfo,
}));

export const SegmentTimes = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const SegmentDepartureBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
}));

export const SegmentDepartureTime = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    flex: '0 0 120px',
    paddingLeft: theme.spacing(5),
    position: 'relative',

    '& > .circle': {
        backgroundColor: theme.palette.calendarWeeks,
        border: `2px solid ${theme.palette.main}`,
        borderRadius: '50%',
        height: 8,
        width: 8,
        position: 'absolute',
        left: 8,
        top: 8,
        zIndex: 2,
    },

    '& > .line': {
        backgroundColor: theme.palette.main,
        width: 2,
        position: 'absolute',
        bottom: 0,
        left: 11,
        top: 10,
    },

    '& > .text': {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.recommendation.classInfo,
    },
}));

export const SegmentDepartureInfo = styled('div')(({ theme }) => ({
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.recommendation.classInfo,

    '& > span': {
        color: '#9da8ae',
        fontWeight: 600,
    }
}));

export const SegmentDuration = styled('div')(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    height: 30,
    lineHeight: 1,
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(15),
    position: 'relative',
    fontSize: 14,
    textTransform: 'uppercase',

    '& > .line': {
        width: 2,
        backgroundColor: '#0085ff',
        position: 'absolute',
        bottom: 0,
        left: 11,
        top: 0,
    },

    '& > .onWay': {
        color: '#9da8ae',
    },

    '& > .time': {
        color: '#000',
    },
}));

export const SegmentArrivalBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
}));

export const SegmentArrivalTime = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 120px',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(5),
    position: 'relative',

    '& > .circle': {
        width: 8,
        height: 8,
        position: 'absolute',
        left: 8,
        bottom: 8,
        zIndex: 2,
        backgroundColor: theme.palette.calendarWeeks,
        border: `2px solid ${theme.palette.main}`,
        borderRadius: '50%',
    },

    '& > .line': {
        width: 2,
        position: 'absolute',
        top: 0,
        bottom: 10,
        left: 11,
        backgroundColor: theme.palette.main,
    },

    '& > .otherDate': {
        fontSize: 14,
        lineHeight: 1,
        color: theme.palette.recommendation.classInfo,
    },

    '& > .text': {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.recommendation.classInfo,
    },
}));

export const SegmentArrivalInfo = styled('div')(({ theme, hasMarginTop }) => ({
    lineHeight: 1,
    fontSize: 14,
    marginTop: hasMarginTop ? theme.spacing(1.75) : 0,
    color: theme.palette.recommendation.classInfo,

    '& > span': {
        color: '#9da8ae',
        fontWeight: 600,
    },
}));
