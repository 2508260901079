import styled from 'styled-components';

export const TitleContainer = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    alignItems: 'baseline',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 18,
    },
}));

export const ButtonContainer = styled('div')(() => ({
    width: '100%',
}));

export const ID = styled('h3')(({ theme }) => ({
    ...theme.marginsX(8, 0),
    color: theme.palette.main,
}));
