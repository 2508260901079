import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { ExpandMore } from '@material-ui/icons';
import { Field } from 'redux-form';

import useLocalization from '../../../contexts/localization/hook';
import { normalizeDocumentNumber } from '../../../lib/normalize';
import { ADT, CHD, INS, INF } from '../../../containers/search/constants';

import Grid from '../../design_v2/ui/grid';
import {
    infDateOfBirth,
    required,
    passengerLastName,
    minLength2,
    passengerFirstName,
    requiredPassengerMiddleName,
    passengerMiddleName,
    requiredHash,
    validDate,
    dateOfBirth,
    adtDateOfBirth,
    documentNumber,
    requiredExpirationDate,
    expirationDate,
    requiredContactInfo,
    email,
    phone,
} from '../../../lib/validate';
import { normalizeClientNames } from '../../../lib/normalize';
import { withExpirationDate, hintForDocNumber } from '../common';

import {
    AccordionBody,
    AccordionComponent,
    AccordionHeader,
    CheckIcon,
    HeaderButtons,
    HeaderButton,
} from '../components';
import ResponsiveFormField from '../../ui/responsive/FormField';
import CitizenshipForm from '../citizenshipForm/citizenshipForm';
import GenderField from '../genderField';
import DateField from '../dateField';
import DocumentTypeField from '../documentTypeField';
import PhoneField from '../../ui/components/PhoneField/PhoneField';
import { AirlineBonusCard } from '.';
import Snackbar from '../../design_v2/ui/snackbar';
import SavedPassengers from './savedPassengers';
import API from '../../../lib/api';
import Options from '../options';
import { BonusCard, EmptyInputBlock } from './components';

export const PassengerItem = props => {
    const { t } = useLocalization();
    const { isAuth } = useSelector(state => state.appConfigs);
    const {
        passengerType,
        index,
        onUpdateCitizenship,
        getCountries,
        resetCountries,
        countries,
        formValues,
        deadlineDate,
        clearDocumentData,
        formErrors,
        onSelectAvailableDocumentTypes,
        sessionId,
    } = props;

    const [modalShow, setModalShow] = useState(false);
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    let documentType;
    let isShowMiddleName;
    let availableDocumentTypes;

    if (_.isObject(formValues)) {
        documentType = formValues.passengers[index].document_type;
        isShowMiddleName = formValues.passengers[index].isShowMiddleName;
        availableDocumentTypes = formValues.passengers[index].availableDocumentTypes;
    }

    const handleOnBlur = event => {
        event.preventDefault();
    };

    const handleChangeCitizenship = value => {
        getCountries(value);
    };

    const [isWarning, setIsWarning] = useState(null);

    let checkWarning = value => {
        const status = infDateOfBirth(passengerType, deadlineDate, documentType, value.target.value);

        setIsWarning(status);
    };

    const normalizeDocNumber = normalizeDocumentNumber(documentType);

    const passengers_items = {
        [ADT]: { mainLabel: t.adt_main_label },
        [CHD]: { mainLabel: t.chd_main_label },
        [INS]: { mainLabel: `${t.inf_main_label} (${t.inf_with_seat_second_label})` },
        [INF]: { mainLabel: t.inf_main_label },
    };

    const handleOnChangeDocumentType = (value, props) => {
        const { passengerIndex } = props;

        clearDocumentData(passengerIndex);
    };

    const handleSavePassenger = async () => {
        setIsSaving(true);
        const passenger = formValues.passengers[index];

        API.storePassenger({
            name: passenger?.name,
            surname: passenger?.surname,
            middle_name: passenger?.middle_name,
            citizenship: JSON.stringify(passenger?.citizenship),
            gender: passenger?.gender,
            date_of_birth: passenger?.date_of_birth,
            document_type: passenger?.document_type,
            document_number: passenger?.document_number,
            expiration_date: passenger?.expiration_date,
            email: passenger?.email,
            phone: passenger?.phone,
            passenger_type: passenger?.type,
        }).then(response => {
            setSnackbarShow(true);
            setIsSaving(false);
        });
    };

    return (
        <>
            <AccordionComponent defaultExpanded disabled={formErrors.passengers && !!formErrors.passengers[index]}>
                <AccordionHeader
                    expandIcon={formErrors.passengers && !!formErrors.passengers[index] ? null : <ExpandMore />}
                >
                    {passengers_items[passengerType].mainLabel}
                    {formErrors.passengers && !!formErrors.passengers[index] ? null : <CheckIcon />}
                </AccordionHeader>

                <AccordionBody>
                    <Grid container spacing={2}>
                        {isAuth && (
                            <Grid item xs={12}>
                                <HeaderButtons>
                                    <HeaderButton type="button" onClick={() => setModalShow(true)}>
                                        {t.select_from_list}
                                    </HeaderButton>
                                    /
                                    <HeaderButton
                                        disabled={(formErrors.passengers && !!formErrors.passengers[index]) || isSaving}
                                        onClick={handleSavePassenger}
                                        type="button"
                                    >
                                        {t.save}
                                    </HeaderButton>
                                </HeaderButtons>
                            </Grid>
                        )}

                        <Grid item xs={12} lg={4}>
                            <Field
                                component={ResponsiveFormField}
                                normalize={normalizeClientNames}
                                name={`passengers[${index}].surname`}
                                label={t.surname_as_in_document}
                                widthSize="100%"
                                isPadding
                                validate={[required, passengerLastName, minLength2]}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Field
                                component={ResponsiveFormField}
                                normalize={normalizeClientNames}
                                name={`passengers[${index}].name`}
                                label={t.name_as_in_document}
                                widthSize="100%"
                                isPadding
                                validate={[required, passengerFirstName, minLength2]}
                            />
                        </Grid>

                        {isShowMiddleName ? (
                            <Grid item xs={12} lg={4}>
                                <Field
                                    component={ResponsiveFormField}
                                    normalize={normalizeClientNames}
                                    name={`passengers[${index}].middle_name`}
                                    label={t.middlename_as_in_document}
                                    widthSize="100%"
                                    isPadding
                                    validate={[requiredPassengerMiddleName, passengerMiddleName, minLength2]}
                                />
                            </Grid>
                        ) : (
                            <EmptyInputBlock item lg={4} />
                        )}

                        <Grid item xs={12} lg={4}>
                            <Field
                                component={CitizenshipForm}
                                name={`passengers[${index}].citizenship`}
                                onUpdate={value => onUpdateCitizenship(value, index)}
                                onChange={handleChangeCitizenship}
                                onBlur={handleOnBlur}
                                countries={countries}
                                resetCountries={resetCountries}
                                validate={[required, requiredHash]}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Field name={`passengers[${index}].gender`} component={GenderField} validate={[required]} />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Field
                                component={DateField}
                                name={`passengers[${index}].date_of_birth`}
                                label={t.date_of_birth}
                                warning={isWarning}
                                onChange={checkWarning}
                                validate={[required, validDate, dateOfBirth, adtDateOfBirth]}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Field
                                component={DocumentTypeField}
                                name={`passengers[${index}].document_type`}
                                availableDocumentTypes={availableDocumentTypes}
                                passengerIndex={index}
                                clearDocumentData={clearDocumentData}
                                onChangeValue={handleOnChangeDocumentType}
                                validate={[required]}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Field
                                component={ResponsiveFormField}
                                normalize={normalizeDocNumber}
                                name={`passengers[${index}].document_number`}
                                label={t.series_and_number}
                                tooltipTitle={hintForDocNumber(documentType, t)}
                                widthSize="100%"
                                isPadding
                                validate={[required, documentNumber]}
                            />
                        </Grid>

                        {withExpirationDate(documentType) ? (
                            <Grid item xs={12} lg={4}>
                                <Field
                                    component={DateField}
                                    name={`passengers[${index}].expiration_date`}
                                    label={t.valid_until}
                                    validate={[requiredExpirationDate, validDate, expirationDate]}
                                />
                            </Grid>
                        ) : (
                            <EmptyInputBlock item lg={4} />
                        )}

                        {passengerType === ADT && (
                            <Grid item xs={12} lg={4}>
                                <Field
                                    component={ResponsiveFormField}
                                    name={`passengers[${index}].email`}
                                    label={t.email}
                                    widthSize="100%"
                                    isPadding
                                    placeholder="example@mail.com"
                                    validate={[requiredContactInfo, email]}
                                />
                            </Grid>
                        )}

                        {passengerType === ADT && (
                            <Grid item xs={12} lg={4}>
                                <Field
                                    component={PhoneField}
                                    name={`passengers[${index}].phone`}
                                    label={t.phone_number}
                                    width="100%"
                                    height="auto"
                                    isPadding
                                    validate={[requiredContactInfo, phone]}
                                />
                            </Grid>
                        )}

                        {passengerType === ADT &&
                            false && ( // TODO не отображаем бонусные карты, пока не будет реализован АПИ
                                <BonusCard item>
                                    <AirlineBonusCard />
                                </BonusCard>
                            )}
                    </Grid>

                    <Options
                        passenger={formValues?.passengers[index]}
                        passengerIndex={index}
                        passengerType={passengerType}
                        passengerError={formErrors.passengers && !!formErrors.passengers[index]}
                        sessionId={sessionId}
                    />
                </AccordionBody>
            </AccordionComponent>

            <SavedPassengers
                isActive={modalShow}
                onClose={() => setModalShow(false)}
                passengerIndex={index}
                onSelectAvailableDocumentTypes={onSelectAvailableDocumentTypes}
                onUpdateCitizenship={onUpdateCitizenship}
            />

            <Snackbar
                show={snackbarShow}
                duration={5000}
                onClose={() => setSnackbarShow(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                alertVariant="filled"
                alertSeverity="success"
                alertText={t.passenger_success_save}
            />
        </>
    );
};
