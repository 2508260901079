import styled from 'styled-components';

export const TimeLimitExpired = styled('div')(({ theme }) => ({
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.button.clearRoute,
}));

export const ValuesContainer = styled('div')(({ theme }) => ({
    display: 'flex',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsY(0, 8)
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.marginsY(0, 16)
    },
}));

export const TimeLimitValueBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.marginsX(0, 16),
    width: 70,
    height: 70,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.timeCounterBg,
    flexDirection: 'column',
    textAlign: 'center',
}));

export const TimeLimitValueItem = styled('div')(({ theme }) => ({
    ...theme.paddingsY(3, 0),
    color: theme.palette.timeCounterColor,
    fontSize: 30,
    fontWeight: 600,
}));

export const TimeLimitLabelItem = styled('div')(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.timeCounterColor,
}));