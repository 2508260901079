import React, { useCallback, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props' 

import {
    Navigation,
    NavigationLink,
    Form,
    FormTitle,
    FormSubtitle,
    LineWithText,
    SocialsButtons,
    SocialButton,
} from '../../components/design_v2/authPages/components';

import { nonAuthRoutes } from '../../config/routes';
import API from '../../lib/api';
import { setClientData } from '../../store/clientData/actions';
import { setAppConfigs } from '../../store/appConfigs/actions';
import useLocalization from '../../contexts/localization/hook';
import RegistrationForm from './parts/registrationForm';
import ActivationForm from './parts/activationForm';
import envConfigs from '../../config/envConfigs';
import { getPasswordWithPrefixAndPostfix } from '../../lib/password';
import { ReactComponent as GoogleIcon } from '../../assets/icons/googleIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebookIcon.svg';

const NewRegistrationPage = () => {
    const { activeForm } = useSelector(store => store.registration);
    const { isFullScreenSearchBlock } = useSelector(state => state.appConfigs);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useLocalization();
    const containerRef = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const responseFacebook = (response) => {
        const name = response.name.split(" ")
        
        API.authWiaSocials({
            firstName: name[0],
            lastName: name[1],
            email: response.id,
            password: `facebook-${response.id}` ,
        })
        .then(res => {
            dispatch(
                setClientData({
                    id: res.idp,
                    firstName: res.first_name,
                    lastName: res.last_name,
                    login: res.login,
                    balance: res.balance,
                    email: res.email,
                    phone: res.phone,
                })
            );

            dispatch(
                setAppConfigs({
                    isAuth: true,
                })
            );

            window.localStorage.setItem('token', res.token);

            history.push('/');
        })
        .catch(error => {
            console.log({ error });
        });
        
      }

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, [isFullScreenSearchBlock]);

    const validationSchema = Yup.object().shape({
        login: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
        first_name: Yup.string().trim().required(t.required),
        last_name: Yup.string().trim().required(t.required),
    });

    const handleFormSubmit = useCallback(
        values => {
            if (isLoading) {
                return;
            }

            setIsLoading(true);

            API.login(values)
                .then(response => {
                    dispatch(
                        setClientData({
                            firstName: response.data.first_name,
                            lastName: response.data.last_name,
                            login: response.data.login,
                        })
                    );

                    dispatch(
                        setAppConfigs({
                            isAuth: true,
                        })
                    );

                    window.localStorage.setItem('token', response.data.token);

                    history.push('/');
                })
                .catch(error => {
                    setFieldError('login', error.response.data.message);
                    setIsLoading(false);
                });
        },
        [isLoading]
    );

    const { setFieldError } = useFormik({
        initialValues: {
            login: '',
            first_name: '',
            last_name: '',
        },
        validationSchema,
        onSubmit: handleFormSubmit,
        validateOnBlur: true,
    });

    const onSuccess = res => {
        const { profileObj } = res;

        API.authWiaSocials({
            firstName: profileObj.givenName,
            lastName: profileObj.familyName,
            email: profileObj.email,
            password: getPasswordWithPrefixAndPostfix(profileObj.googleId),
        })
            .then(response => {
                dispatch(
                    setClientData({
                        id: response.idp,
                        firstName: response.first_name,
                        lastName: response.last_name,
                        login: response.login,
                        balance: response.balance,
                        email: response.email,
                        phone: response.phone,
                    })
                );

                dispatch(
                    setAppConfigs({
                        isAuth: true,
                    })
                );

                window.localStorage.setItem('token', response.token);

                history.push('/');
            })
            .catch(error => {
                console.log({ error });
            });
    };

    const onFailure = err => {
        console.log('failed:', err);
    };

    return (
        <Container fixed ref={containerRef}>
            <Navigation>
                <NavigationLink to={nonAuthRoutes.login}>{t.log_in}</NavigationLink>

                <NavigationLink active={true} to={nonAuthRoutes.registration}>
                    {t.registration}
                </NavigationLink>
            </Navigation>

            <Form>
                <FormTitle>{activeForm == 'registration' ? t.registration : t.activation_title}</FormTitle>

                <FormSubtitle>{activeForm == 'registration' ? t.register_to_save_data : t.activation_code_send_to_email}</FormSubtitle>

                {activeForm === 'registration' && <RegistrationForm />}

                {activeForm === 'activation' && <ActivationForm />}

                <LineWithText>{t.or_use_socials || 'или используйте соц. сети'}</LineWithText>

                <SocialsButtons>
                    <GoogleLogin
                        clientId={envConfigs.googleAuthClientId}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        buttonText="Sign in with Google"
                        cookiePolicy={'single_host_origin'}
                        render={props => (
                            <SocialButton {...props}>
                                <GoogleIcon />
                            </SocialButton>
                        )}
                    />
                    <FacebookLogin
                        appId={envConfigs.facebookID}
                        fields="name,email,picture"
                        scope="public_profile"
                        callback={responseFacebook}
                        icon="fa-facebook"
                        render={props => (
                            <SocialButton {...props}>
                                <FacebookIcon />
                            </SocialButton>
                        )}
                    />
                </SocialsButtons>
            </Form>
        </Container>
    );
};

export default withRouter(NewRegistrationPage);
