import React from 'react';
import Slider from 'react-slick';
import { NavLink } from 'react-router-dom';

import useLocalization from '../../../../../contexts/localization/hook';
import config from '../../../../../config';

import { CarouselBlock, Header, Icon, SliderImage, SliderItem } from './components';
import partnerLogos from "./partnerLogos";
import TitleUI from '../../../ui/titleUI';
import partnersIcon from "../../../../../assets/icons/iconsForTitle/partner.svg"

const OurPartners = ({ partners }) => {
    const { t } = useLocalization();
    const { envConfigs } = config;

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: partnerLogos.length > 6,
        pauseOnHover: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        draggable: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1299,
                settings: {
                    slidesToShow: 4,
                    infinite: partnerLogos.length > 4,
                },
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 3,
                    infinite: partnerLogos.length > 3,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 2,
                    infinite: partnerLogos.length > 2,
                },
            },
        ],
    };

    if (partnerLogos.length === 0) return null;

    return (
        <CarouselBlock>
            <TitleUI as="h2" textTransform="uppercase" position="center" icon={partnersIcon}>{t.our_partners}</TitleUI>
            <div>
                <Slider {...settings}>
                    {partnerLogos.map((item, index) => {
                        return (
                            <SliderItem key={index}>
                                <NavLink to={`/ourPartners/${item.slug}`}>
                                    <SliderImage
                                        loading="lazy"
                                        src={item.logo}
                                    />
                                </NavLink>
                            </SliderItem>
                        );
                    })}
                </Slider>
            </div>
        </CarouselBlock>
    );
};

export default OurPartners;
