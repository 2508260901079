import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalization from '../../../../../contexts/localization/hook';
import { resetFilters } from '../../../../../lib/filters';
import { setAllFilterParams } from '../../../../../store/filters/actions';
import AirlinesFilter from '../parts/airlines';
import AirportsFilter from '../parts/airports';
import ArrivalTimeFilter from '../parts/arrivalTime';
import BaggageFilter from '../parts/baggage';
import DepartureTimeFilter from '../parts/departureTime';
import DurationFilter from '../parts/duration';
import FlightNumbersFilter from '../parts/flightNumbers';
import PriceFilter from '../parts/price';
import SortingFilter from '../parts/sorting';
import TransfersFilter from '../parts/transfers';
import { Button, ButtonBlock, Icon } from './components';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const FiltersDesktop = () => {
    const { t } = useLocalization();
    const dispatch = useDispatch();
    const { filterParams, filtersData } = useSelector(state => state.filters);

    const checkIsActive = () => {
        const {
            baggage,
            transfers,
            sorting,
            airlines,
            flightNumbers,
            price,
            airports,
            duration,
            departureTime,
            arrivalTime,
        } = filterParams;

        return (
            baggage.isActive ||
            transfers.isActive ||
            sorting.isActive ||
            airlines.isActive ||
            flightNumbers.isActive ||
            price.isActive ||
            airports.isActive ||
            duration.isActive ||
            departureTime.isActive ||
            arrivalTime.isActive
        );
    };

    const handleReset = () => {
        const reset = resetFilters(filtersData);

        dispatch(setAllFilterParams(reset));
        window.history.pushState(null, null, window.location.pathname);
    };

    return (
        <div>
            <SortingFilter />
            <PriceFilter />
            <BaggageFilter />
            <TransfersFilter />
            <FlightNumbersFilter />
            <AirlinesFilter />
            <AirportsFilter />
            <DurationFilter />
            <DepartureTimeFilter />
            <ArrivalTimeFilter />
            {checkIsActive() && (
                <ButtonBlock>
                    <Button onClick={handleReset}>
                        <Icon /> {t.reset}
                    </Button>
                </ButtonBlock>
            )}
        </div>
    );
};

export default FiltersDesktop;
