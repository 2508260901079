import styled from 'styled-components';

export const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
}));

export const From = styled('div')(({ theme, notFilled }) => ({
    ...theme.marginsX(0, 4),
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 250,
    borderRadius: theme.spacing(1.5),
    position: 'relative',
    boxShadow: notFilled ? `0px 0px 4px 2px ${theme.palette.error}` : 'none',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        ...theme.marginsY(0, 8),
        width: 452,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsX(0, 0),
        width: '100%',
    },
}));

export const ReplaceRouteButton = styled('button')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    position: 'absolute',
    top: '50%',
    left: 238,
    transform: 'translateY(-50%)',
    width: 28,
    height: 28,
    backgroundColor: theme.palette.searchTextForm,
    borderRadius: theme.spacing(1.375),
    border: 'none',
    flexDirection: 'column',
    boxShadow: '0 2px 6px rgb(0 0 0 / 20%)',
    zIndex: 11,
    color: theme.palette.clientName,
    cursor: 'pointer',

    '&:hover': {
        boxShadow: '0 2px 6px rgb(0 0 0 / 40%)',
    },

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        left: 440,
        transform: 'none',
        top: 14,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        left: 'auto',
        right: 10,
        top: 48,
        transform: 'rotate(90deg)',
    },
}));

export const ReplaceRouteButtonLeftIcon = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 2),
    transform: 'rotate(0.5turn) translateX(0)',

    '& > svg': {
        display: 'block',
    },
}));

export const ReplaceRouteButtonRightIcon = styled('div')(({ theme }) => ({
    '& > svg': {
        display: 'block',
    },
}));

export const To = styled('div')(({ theme, notFilled }) => ({
    ...theme.marginsX(0, 4),
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 250,
    position: 'relative',
    borderRadius: theme.spacing(1.5),
    boxShadow: notFilled ? `0px 0px 4px 2px ${theme.palette.error}` : 'none',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        ...theme.margins(0, 0, 8, 0),
        width: 452,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
        borderRadius: theme.spacing(1.5),
    },
}));

export const DateFromTo = styled('div')(({ theme, notFilled }) => ({
    ...theme.marginsX(0, 4),
    display: 'flex',
    position: 'relative',
    height: 60,
    width: 360,
    borderRadius: theme.spacing(1.5),
    boxShadow: notFilled ? `0px 0px 4px 2px ${theme.palette.error}` : 'none',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 452,
        borderRadius: theme.spacing(1.5),
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.margins(0, 0, 8, 0),
        width: '100%',
    },
}));

export const PassengersAndClass = styled('div')(({ theme }) => ({
    ...theme.marginsX(0, 4),
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 200,
    borderRadius: theme.spacing(1.5),
    position: 'relative',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 452,
        borderRadius: theme.spacing(1.5),
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const SubmitButton = styled('button')(({ theme }) => ({
    height: 60,
    backgroundColor: theme.palette.button.searchButtonBg,
    color: theme.palette.searchTextForm,
    border: `2px outset ${theme.palette.button.buttonBorder}`,
    borderRadius: theme.spacing(1.25),
    width: 156,
    fontSize: '1.2rem',
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        display: 'none',
    },

    '&:hover': {
        cursor: 'pointer',
    },
}));
