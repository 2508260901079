import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';

export const AccordionComponent = styled(Accordion)(({ theme }) => ({
    boxShadow: 'none !important',
    backgroundColor: `${theme.palette.transparent} !important`,
    marginTop: '0px !important',

    '&::before': {
        content: 'none !important',
    },
}));

export const AccordionHeader = styled(AccordionSummary)(({ theme }) => ({
    padding: '8px 0 !important',
    fontSize: 20,
    fontWeight: 600,
    color: `${theme.palette.mainText} !important`,
    minHeight: 'auto !important',
    opacity: '1 !important',

    '&>.MuiAccordionSummary-content': {
        margin: '12px 0 !important',
    },
}));

export const HeaderButtons = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterEnd,
}));

export const HeaderButton = styled('button')(({ theme }) => ({
    padding: 0,
    backgroundColor: theme.palette.transparent,
    border: 'none',
    boxShadow: 'none',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.main,

    '&:hover': {
        cursor: 'pointer',
    },

    '&:disabled': {
        color: theme.palette.departureCountryAndAirCompany,

        '&:hover': {
            cursor: 'default',
        },
    },

    '&:nth-child(1)': {
        marginRight: 8,
    },

    '&:nth-child(2)': {
        marginLeft: 8,
    },
}));

export const AccordionBody = styled(AccordionDetails)(() => ({
    flexDirection: 'column',
    gap: 32,
    width: '100%',
    padding: '0 0 16px !important',
}));

export const CheckIcon = styled(CheckCircleOutline)(({ theme }) => ({
    marginLeft: 8,
    color: `${theme.palette.checkIcon} !important`,
}));
