import styled from "styled-components";

import bgTravel from "../../../../images/flyCash/bgTravel.jpg";

export const Wrapper = styled("section")(({ theme }) => ({
    background: `url(${bgTravel})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}));

export const InfoBlock = styled("div")(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    ...theme.paddings(60, 25),
    margin: "0 auto",
    maxWidth: 1280,
    height: "100%",
    position: "relative",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        flexDirection: "column",
        gap: 20,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.paddings(30, 15),
    }
}));

export const TextBlock = styled("div")(({ theme }) => ({
    width: "60%",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: "100%",
    },
}));

export const TitleBlock = styled("div")(({ theme }) => ({
    fontSize: 48,
    color: "#004aad",
    fontWeight: 600,
    lineHeight: 1.3,
    marginBottom: 30,

    "&.mobile": {
        display: "none",

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            display: "block",
        },
    },

    "&.desktop": {
        display: "block",

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            display: "none",
        },
    },

    "& > img": {
        marginTop: -24,
        marginBottom: -12,
    },

    "& > .cash2": {
        display: "block",
        marginTop: -12,
        marginBottom: 0,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 24,
        lineHeight: 1.6,
        marginBottom: 10,
    },
}));

export const DescriptionBlock = styled("div")(({ theme }) => ({
    color: "#7d7c8e",
    fontWeight: 500,
    fontSize: 20,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 16,
        lineHeight: 1.6,
    },
}));

export const ImageBlock = styled("div")(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    width: "40%",
    position: "relative",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: "100%",
    },

    "& > img": {
        maxWidth: "100%",

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            maxWidth: 200,
        },
    }
}));