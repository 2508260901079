import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
    Circle,
    RangeSliderItem,
    RangeTitle,
    RootSlider,
} from './components';

function ThumbComponent(props) {
    return (
        <span {...props}>
            <Circle />
        </span>
    );
}

export default function RangeSlider(props) {
    const { range, handleChange, filter, titleComponent, step } = props;
    const [value, setValue] = useState([filter.min || range.min, filter.max || range.max]);
    let disabledSlider = false;

    useEffect(() => {
        setValue([filter.min || range.min, filter.max || range.max]);
    }, [filter, range]);

    if (_.first(value) === _.last(value)) {
        disabledSlider = true;
    }

    const handleOnChange = (event, newValue) => {
        setValue(newValue);
    };

    const onChangeCommitted = (event, newValue) => {
        handleChange({
            min: _.first(newValue),
            max: _.last(newValue),
        });
    };

    return (
        <div>
            <RangeTitle>
                {titleComponent ? (
                    titleComponent(_.first(value), _.last(value))
                ) : (
                    <DefaultTitleComponent min={_.first(value)} max={_.last(value)} />
                )}
            </RangeTitle>
            <RootSlider>
                <RangeSliderItem
                    disabled={disabledSlider}
                    ThumbComponent={ThumbComponent}
                    onChange={handleOnChange}
                    onChangeCommitted={onChangeCommitted}
                    step={step}
                    min={range.min}
                    max={range.max}
                    value={value}
                />
            </RootSlider>
        </div>
    );
}

const DefaultTitleComponent = props => {
    const { min, max } = props;

    return (
        <div>
            {min} — {max}
        </div>
    );
};

RangeSlider.defaultProps = {
    range: {
        min: 1,
        max: 100,
        minPercent: 1,
        maxPercent: 100,
        step: 1,
    },
};
