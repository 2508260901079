import styled from 'styled-components';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog } from '@material-ui/core';
import { ArrowRight, Close } from '@material-ui/icons';


export const DesktopHistorySearch = styled('button')(({ theme }) => ({
    ...theme.marginsX(8, 0),
    border: 'none',
    backgroundColor: theme.palette.transparent,
    cursor: 'pointer',
    fontSize: 14,
    color: theme.palette.button.advancedRouteAndHistory,

    '&:hover': {
        color: theme.palette.buttonHover.advancedRouteAndHistorySearch,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const DialogModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: theme.palette.blocksBackground,
    },
}));

export const ModalTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.mixins.flexCenterSpaceBetween,
    ...theme.paddings(16, 32),
    fontSize: 16,
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.searchBlockBorder}`,
    color: theme.palette.mainText,
}));

export const MobileHistorySearch = styled('button')(({ theme }) => ({
    ...theme.marginsX(8),
    display: 'none',
    border: 'none',
    backgroundColor: theme.palette.transparent,
    cursor: 'pointer',
    fontSize: 14,
    color: theme.palette.button.searchHistory,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'block',
    },
}));

export const Routes = styled('div')(({ theme }) => ({
    fontWeight: 600,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 18,
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 14,
    },
}));

export const LoadingBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    width: '100%',
    height: '100%',
}));

export const Footer = styled('div')(({ theme }) => ({
    ...theme.margins(16, 16),
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 4,
    width: '88%',
}));

export const ClearAllHistoryButton = styled('button')(({ theme }) => ({
    backgroundColor: theme.palette.button.clearRouteBg,
    fontSize: 14,
    color: theme.palette.button.clearRoute,
    height: 40,
    width: '35%',
    textTransform: 'uppercase',
    fontWeight: 600,
    border: 'none',
    borderRadius: theme.spacing(1),

    '&:hover': {
        cursor: 'pointer',
    }
}));

export const NoResultText = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    width: '100%',
    height: '100%',
}));

export const SearchBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 16),
    ...theme.marginsY(0, 16),
    ...theme.mixins.flexCenterSpaceBetween,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.blocksBackground,
    border: `1px solid ${theme.palette.searchBlockBorder}`,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.paddings(8, 8),
    },
}));

export const Passengers = styled('div')(({ theme }) => ({
    ...theme.paddingsX(0, 8),
    color: theme.palette.mainText,
}));

export const FlightClass = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
}));

export const ArrowRightIcon = styled(ArrowRight)(({ theme }) => ({
    color: theme.palette.mainText,
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
    color: theme.palette.mainText,
}));

export const RoutesBlock = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
