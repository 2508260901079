import { IconButton } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    
    to {
        transform: translateY(100%); 
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    display: ${props => props.isShow ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 250;
    background-color: rgba(0, 0, 0, .7);
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 251;
    animation-name: ${props => props.isShow ? fadeIn : fadeOut};
    animation-duration: .3s;
    animation-fill-mode: forwards;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 599px) {
        align-items: flex-end;
    }
`;

export const Modal = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 360px;
    width: 480px;
    position: relative;
    border-radius: 8px;

    @media (max-width: 959px) {
        width: 100%;
        margin: 0 16px;
    }

    @media (max-width: 599px) {
        margin: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: auto;
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute !important;
    right: 12px;
    top: 10px;
`;

export const TextBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
`;

export const LogoBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 32px;
    
    @media (max-width: 599px) {
        padding: 32px 0;
    }
`;

export const Logo = styled.img`
    max-width: 200px;

    @media (max-width: 599px) {
        max-width: 160px;
    }
`;

export const EventText = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 599px) {
        font-size: 18px;
    }
`;

export const ButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 0;

    @media (max-width: 599px) {
        padding: 32px 0;
    }
`;

export const Button = styled.button`
    border-radius: 12px;
    background: linear-gradient(to right bottom,#f8d023,#e65c01);
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding: 12px 24px;
    text-decoration: none;

    @media (max-width: 599px) {
        font-size: 16px;
    }
`;
