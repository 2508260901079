import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";

import Grid from '../../design_v2/ui/grid';
import { normalizePrice } from '../../../lib/normalize';
import useLocalization from '../../../contexts/localization/hook';
import PassengersAmountBlock from '../passengersAmountFolder/PassengersAmountWrap';

import {
    AmountPassengersBlock, FlightBlock,
    FlightTicket,
    FlightTicketAmount,
    FlightTicketRow,
    PriceBlock,
    PriceContainer, PriceTicketRow,
    PriceTotalBlock, TicketsBlock,
    TotalPrice,
    TotalPriceText,
    TotalTitle,
} from './components';

export default function PriceForm(props) {
    const { totalPrice, optionsPrice, forOrder, currency, passengers, cashback } = props;

    const { t } = useLocalization();
    const { options, optionsDynamicPrices, routes, selectedOptions } = useSelector(store => store.setPreBookData);

    const flightTotal = forOrder ? +totalPrice : totalPrice[currency];

    const optionsAllPrice = useMemo(() => {
        let price = 0;

        options.forEach(option => {
            selectedOptions
                .filter(item => item.optionId === option.id)
                .forEach(selected => {
                    if (option.is_dynamic_price) {
                        const optionDynamicPrice = optionsDynamicPrices.find(dynamicPriceOption => dynamicPriceOption.id === selected.id);
                        if (optionDynamicPrice) {
                            if (option?.payment_for === 'segments') {
                                price += +optionDynamicPrice.price * routes[selected.routeIndex]?.segments.length;
                            } else {
                                price += +optionDynamicPrice.price;
                            }
                        }
                    } else {
                        if (option?.payment_for === 'segments') {
                            price += +option.price * routes[selected.routeIndex]?.segments.length;
                        } else {
                            price += +option.price;
                        }
                    }
                });
        });
        return price.toFixed(2);
    }, [options, selectedOptions, optionsDynamicPrices, routes]);

    if (forOrder) {
        return (
            <PriceContainer>
                <TicketsBlock container>
                    <Grid item xs={12}>
                        <FlightTicketRow container>
                            <FlightTicket item>
                                {t.flights_tickets}:
                            </FlightTicket>
                            <FlightTicketAmount item>
                                {normalizePrice(optionsPrice ? (flightTotal - optionsPrice).toFixed(2) : flightTotal.toFixed(2))} {currency}
                            </FlightTicketAmount>
                        </FlightTicketRow>
                    </Grid>

                    <AmountPassengersBlock item xs={12}>
                        <PassengersAmountBlock passengers={passengers} currency={currency} />
                    </AmountPassengersBlock>

                    {optionsPrice ? (
                        <Grid item xs={12}>
                            <FlightTicketRow container>
                                <FlightTicket item>
                                    {t.additional_options}:
                                </FlightTicket>
                                <FlightTicketAmount item>
                                    {normalizePrice(optionsPrice)} {currency}
                                </FlightTicketAmount>
                            </FlightTicketRow>
                        </Grid>
                    ) : null}

                    <PriceTotalBlock item xs={12}>
                        <FlightTicketRow container $isCashback={_.isObject(cashback) && cashback[currency]}>
                            <TotalTitle item>
                                {t.total}
                            </TotalTitle>
                            <TotalPrice item>
                                <TotalPriceText>
                                    {normalizePrice(totalPrice)} {currency}
                                </TotalPriceText>
                            </TotalPrice>
                        </FlightTicketRow>
                    </PriceTotalBlock>

                    {_.isObject(cashback) && cashback[currency] ? (
                        <PriceTotalBlock item xs={12} $isCashback>
                            <FlightTicketRow container alignItems="center" $isCashback>
                                <TotalTitle item $isCashback>
                                    {t.cashback}
                                </TotalTitle>
                                <TotalPrice item $isCashback>
                                    +{normalizePrice(cashback[currency])} {currency}
                                </TotalPrice>
                            </FlightTicketRow>
                        </PriceTotalBlock>
                    ) : null}
                </TicketsBlock>
            </PriceContainer>
        );
    }

    return (
        <PriceContainer>
            <PriceBlock container>
                <FlightBlock item xs={12}>
                    <PriceTicketRow container>
                        <FlightTicket item lg={12} xl={'auto'}>
                            {t.flights_tickets}:
                        </FlightTicket>
                        <FlightTicketAmount item lg={12} xl={'auto'}>
                            {normalizePrice(flightTotal)} {currency}
                        </FlightTicketAmount>
                    </PriceTicketRow>
                </FlightBlock>

                {selectedOptions ? (
                    <FlightBlock item xs={12}>
                        <PriceTicketRow container>
                            <FlightTicket item lg={12} xl={'auto'}>
                                {t.additional_options}:
                            </FlightTicket>
                            <FlightTicketAmount item lg={12} xl={'auto'}>
                                {normalizePrice(optionsAllPrice)} {currency}
                            </FlightTicketAmount>
                        </PriceTicketRow>
                    </FlightBlock>
                ) : null}

                <PriceTotalBlock item xs={12}>
                    <PriceTicketRow container>
                        <TotalTitle item lg={12} xl={'auto'}>
                            {t.total}:
                        </TotalTitle>
                        <TotalPrice item lg={12} xl={'auto'}>
                            {normalizePrice((+flightTotal + +optionsAllPrice).toFixed(2))} {currency}
                        </TotalPrice>
                    </PriceTicketRow>
                </PriceTotalBlock>

                {_.isObject(cashback) && cashback[currency] ? (
                    <PriceTotalBlock item xs={12} $isCashback>
                        <PriceTicketRow container alignItems="center">
                            <TotalTitle item lg={12} xl={'auto'} $isCashback>
                                {t.cashback}:
                            </TotalTitle>
                            <TotalPrice item lg={12} xl={'auto'} $isCashback>
                                +{normalizePrice(cashback[currency])} {currency}
                            </TotalPrice>
                        </PriceTicketRow>
                    </PriceTotalBlock>
                ) : null}
            </PriceBlock>
        </PriceContainer>
    );
}
