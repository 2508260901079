import styled from 'styled-components';
import { Divider } from '@material-ui/core';

import Grid from '../../design_v2/ui/grid';

export const BookDataBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16),
    backgroundColor: theme.palette.inputBackground,
    borderRadius: theme.spacing(2.75),
    boxShadow: '0 10px 10px 1px rgba(0, 59, 110, 0.1)',
}));

export const BookLabel = styled(Grid)(({ theme }) => ({
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.mainText,
    lineHeight: 1.2,
}));

export const BookDataTitle = styled('div')(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.mainText,
    display: 'flex',
    flexWrap: 'wrap',

    '& div:nth-child(1)': {
        ...theme.marginsX(0, 20),
    },
}));

export const LocatorItem = styled('div')(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.mainText,
}));

export const LineBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 0),
}));

export const Line = styled(Divider)(({ theme }) => ({
    '&.MuiDivider-root': {
        height: 2,
        backgroundColor: theme.palette.lineDivider,
    }
}));

export const TotalPriceText = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.2,
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        justifyContent: 'flex-end',
    },
}));

export const PriceIcon = styled('div')(({ theme }) => ({
    ...theme.paddingsX(8, 0),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.techStopBlockBorder,
    cursor: 'pointer',
    lineHeight: 1,
}));

export const RouteInformation = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.inputUiBorder,
    cursor: 'pointer',
    fontSize: 16,
}));

export const RouteInformationText = styled('div')(({ theme }) => ({
    ...theme.paddingsX(8, 0),
    position: 'relative',
}));

export const PassengerName = styled('div')(({ theme }) => ({
    ...theme.paddingsY(0, 4),
    color: theme.palette.passengerNameBookData,
    fontSize: 18,

}));

export const PassengerBlock = styled('div')(({ theme }) => ({
    ...theme.paddingsY(0, 16),
}));

export const PassengerParamTitle = styled(Grid)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.passengerParamTitle,
}));

export const PassengerParam = styled(Grid)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.passengerNameBookData,
}));

export const BoughtOptions = styled("div")(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
    gap: theme.spacing(1),
    color: theme.palette.mainText,
}));
