import React from "react";

import { Wrapper } from "./style";

const Container = ({ children, fixed, flex, ...rest }) => (
  <Wrapper fixed={fixed} flex={flex} {...rest}>
    {children}
  </Wrapper>
);

export default Container;
