import {
    SET_FLIGHT_TYPE,
    SET_CABIN,
    SET_PASSEGERS,
    SET_ROUTES,
    SET_SEARCH_PARAMS,
    SET_HAS_DATA,
    SET_NOT_FILLED_FIELDS,
    SET_CALENDAR_PRICES,
} from './constants';
import initialState from './initialState';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_PARAMS:
            return {
                ...state,
                ...action.payload,
            };
        case SET_FLIGHT_TYPE:
            return {
                ...state,
                flightType: action.payload,
            };
        case SET_CABIN:
            return {
                ...state,
                cabin: action.payload,
            };
        case SET_PASSEGERS:
            return {
                ...state,
                passengers: action.payload,
            };
        case SET_ROUTES:
            return {
                ...state,
                routes: action.payload,
                hasData: true,
            };
        case SET_HAS_DATA:
            return {
                ...state,
                hasData: action.payload,
            };
        case SET_NOT_FILLED_FIELDS:
            return {
                ...state,
                notFilledFields: action.payload,
            };
        case SET_CALENDAR_PRICES:
            return {
                ...state,
                calendarPrices: action.payload,
            }
        default:
            return state;
    }
};
