import styled from 'styled-components';

import Grid from '../../design_v2/ui/grid';

export const OrderNumberContainer = styled('div')(({ theme }) => ({
    fontSize: 34,
    fontWeight: 500,
    color: theme.palette.mainText,
}));

export const StatusContainer = styled('div')(({ theme }) => ({
    ...theme.paddings(8, 0, 12, 12),
    position: 'relative',
    lineHeight: 1,
}));


export const Circle = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 12,
    left: 0,
    width: 8,
    height: 8,
    borderRadius: theme.spacing(1),
}));

export const PaymentRoot = styled('div')(({ theme }) => ({
    ...theme.paddingsY(0, 24),
}));

export const ContentContainer = styled(Grid)(({ theme }) => ({
    order: 1,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 2,
    },
}));

export const SidebarContainer = styled(Grid)(({ theme }) => ({
    order: 2,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 1,
    },
}));

export const VoidInProcessBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(24, 0, 24, 32),
    color: theme.palette.bonusCard,
    fontSize: '16px',
}));

export const VoidButtonBlock = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));

export const HelpContainer = styled('div')(({ theme }) => ({
    ...theme.paddingsY(8, 16),
}));

export const LoadingSniperBlock = styled('span')(({ theme }) => ({
    ...theme.paddingsX(0, 8),
    color: theme.palette.inputUiBorder,
    position: 'relative',
    top: 2,
}));

export const VoidBlock = styled('div')(() => ({
    fontSize: 14,
    textAlign: 'center',
}));

export const VoidTextOrderForm = styled('div')(({ theme }) => ({
    ...theme.marginsY(8, 0),
    color: theme.palette.mainText,
    textAlign: 'center',
}));

export const CopyLinkBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(20, 0),
    textAlign: 'center',
}));

export const RoutesTitleOrderForm = styled('div')(({ theme }) => ({
    ...theme.paddings(24, 0, 10,  0),
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.mainText,
}));

export const UpButtonContainer = styled('div')(({ theme }) => ({
    ...theme.paddingsY(16, 64),
    display: 'flex',
    justifyContent: 'center',
}));

export const StatusBlock = styled('div')(() => ({
    fontSize: 16,
    display: 'inline-block',
}));

export const CreatedAtBlock = styled('div')(({theme}) => ({
    ...theme.paddingsX(8, 0),
    color: theme.palette.bonusCard,
    fontSize: 16,
    display: 'inline-block',
}));

export const TicketsOptionsButtons = styled('div')(({theme}) => ({
    ...theme.paddingsY(0, 16),
}));

export const GridContainer = styled(Grid)(({theme}) => ({
    ...theme.paddings(8, 16),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2.5),
    backgroundColor: theme.palette.white,
    boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
    position: 'relative',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));
