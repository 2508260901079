import React from 'react';

import useTheme from '../../../../../../../../contexts/theme/hook';

import { ThemeButton } from './components';

const ThemeBtn = () => {
    const { themeToggle } = useTheme();

    return <ThemeButton onClick={themeToggle} />;
};

export default ThemeBtn;