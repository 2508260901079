import React from 'react';
import { LoaderBlock, LoadingContainer, StyledCircularProgress } from './components';

const LoadingBlock = ({ withoutIcon, style }) => {
    if (withoutIcon) {
        const styleBlock = {
            color: '#0085FF',
            position: 'relative',
        };

        return <StyledCircularProgress style={style || styleBlock} size={24} />;
    }

    return (
        <LoadingContainer>
            <LoaderBlock />
        </LoadingContainer>
    );
};

export default LoadingBlock;
