import {
    SET_RECOMMENDATIONS,
    SET_FILTERS,
    SET_BRAND_FARES,
    SET_ADDITIONALLY_OPTINS,
    SET_BRAND_FARE_REC_ID,
    SET_BRAND_RULES,
    RESET_ALL_FILTERS,
    BY_MIN_PRICE,
    SET_SEARCH_DATA,
    SET_ALL_FILTERS,
} from './constants';
import initialState from './initialState';
import { handleFilterParamsChange } from '../../lib/filters';

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_RECOMMENDATIONS:
            return {
                ...state,
                nonPriceFlights: action.payload.nonPriceFlights,
                recommendations: action.payload.flights,
                included: action.payload.included,
                session: action.payload.session,
                isLoad: action.payload.loadStatus,
            };
        case SET_BRAND_FARES:
            return {
                ...state,
                brandFares: { ...state.brandFares, [action.payload.recId]: action.payload.flights },
            };
        case SET_BRAND_RULES:
            return {
                ...state,
                brandRules: { ...state.brandRules, [action.payload.recId]: action.payload.data },
            };
        case SET_BRAND_FARE_REC_ID:
            return {
                ...state,
                recommendations: state.recommendations.map(recommendation => {
                    if (recommendation.rec_id === action.payload.recId) {
                        return {
                            ...recommendation,
                            brandRecId: action.payload.brandRecId,
                        };
                    }
                    return {
                        ...recommendation,
                    };
                }),
            };
        case SET_FILTERS:
            handleFilterParamsChange(action.payload);
            return {
                ...state,
                filterParams: { ...state.filterParams, [action.payload.type]: action.payload.status },
            };
        case SET_ALL_FILTERS:
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    ...action.payload,
                },
            };
        case RESET_ALL_FILTERS:
            return {
                ...state,
                filterParams: {
                    baggage: {
                        withBaggage: true,
                        withoutBaggage: true,
                    },
                    transfers: {
                        withoutTransfers: true,
                        oneTransfers: true,
                        manyTransfers: true,
                    },
                    allTransfers: true,
                    sortType: BY_MIN_PRICE,
                    airlines: [],
                    airports: false,
                    duration: false,
                    arrivalTime: false,
                    departureTime: false,
                    flightNumbers: false,
                    routesData: [],
                    priceRange: {},
                    flightNumbersList: null,
                },
            };
        case SET_ADDITIONALLY_OPTINS:
            return {
                ...state,
                recommendations: state.recommendations.map(recommendation => {
                    if (recommendation.rec_id === action.payload.recId) {
                        return {
                            ...recommendation,
                            routes: recommendation.routes.map(item => {
                                if (item.index === action.payload.routeIndex) {
                                    return {
                                        ...item,
                                        [action.payload.type]: action.payload.status,
                                    };
                                }
                                return {
                                    ...item,
                                };
                            }),
                        };
                    }
                    return {
                        ...recommendation,
                    };
                }),
            };
        case SET_SEARCH_DATA:
            return {
                ...state,
                searchData: action.payload,
            };
        default:
            return state;
    }
};
