import styled from 'styled-components';

export const DividerBooking = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.dividerBooking}`,
    width: '96%',
    height: 1,
    margin: '0 auto',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: '92%',
        borderBottom: `1px solid ${theme.palette.enterAndRegistrationBorderBottom}`,
    },
}));