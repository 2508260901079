import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { reduxForm } from 'redux-form';


import { ADT, CHD, INS, INF } from '../../../containers/search/constants';
import useLocalization from '../../../contexts/localization/hook';
import RulesModal from '../rulesModal/rulesModal';
import { PassengerItem, PayerBlock, Submit } from './index';
import { setPassengerOptions, setSelectedOptions } from '../../../containers/booking/actions';
import { getRouteCity } from '../../result/common';

import {
    AgreeCheckbox,
    AgreementBookLink,
    AgreementTheBook,
    PassengersBlock,
    PassengersContainer,
    PassengersTitle,
} from './components';

const PassengerInfoForm = props => {
    const { language, t } = useLocalization();
    const {
        passengers,
        onUpdateCitizenship,
        getCountries,
        resetCountries,
        onSendBooking,
        handleSubmit,
        setPassengerType,
        countries,
        formValues,
        clearDocumentData,
        deadlineDate,
        formErrors,
        setSelectedPassengerIndex,
        setOpenSuccessModal,
        setPayerEmailToPassenger,
        setPayerPhoneToPassenger,
        routes,
        fareRules,
        included,
        onSelectAvailableDocumentTypes,
        sessionId,
    } = props;

    const { login, phone } = useSelector(state => state.clientData);
    const { options } = useSelector(state => state.setPreBookData);
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);
    const [openRules, setOpenRules] = useState(false);

    useEffect(() => {
        _.forEach(passengersList, function (type, index) {
            setPassengerType(type, index);
        });

        setPayerEmailToPassenger(login);
        setPayerPhoneToPassenger(phone);

        setSelectedOptionsToPassengers();
    }, []);

    const checkIsRoundTrip = () => {
        if (routes.length !== 2) return false;
        const [departureCity] = getRouteCity(routes[0], included, language);
        const [_, arrivalCity] = getRouteCity(routes[1], included, language);
        return departureCity === arrivalCity;
    };

    const setSelectedOptionsToPassengers = () => {
        const passengersList = getPassengerList(passengers);
        const passengerOptionsArray = [];
        const selectedOptionsArray = [];
        options.forEach(option => {
            passengersList.forEach((passenger, index) => {
                if (option.passenger_type === 'all' || option.passenger_type === passenger) {
                    if (option.selected) {
                        routes.forEach((route, routeIndex) => {
                            const isRoundTrip = checkIsRoundTrip();
                            if (
                                (isRoundTrip && routeIndex === 1 && option.technical_name === 'bima-ITP1') ||
                                (!isRoundTrip && routeIndex > 0 && option.technical_name === 'bima-ITP1')
                            )
                                return null;
                            selectedOptionsArray.push({
                                id: `${option.id}${index}${route.index}`,
                                optionId: option.id,
                                passengerIndex: index,
                                routeIndex: route.index,
                                isDynamicPrice: option.is_dynamic_price,
                                dynamicPrice: 0,
                            });
                        });
                    }
                    passengerOptionsArray.push({
                        passengerIndex: index,
                        currency: option.currency,
                        description: option.description,
                        id: option.id,
                        isDynamicPrice: option.is_dynamic_price,
                        names: option.names,
                        optionType: {
                            id: option.option_type.id,
                            names: option.option_type.names,
                            technicalName: option.option_type.technical_name,
                        },
                        passengerType: option.passenger_type,
                        paymentFor: option.payment_for,
                        price: option.price,
                        selectForAll: option.selectForAll,
                        selected: option.selected,
                        technicalName: option.technical_name
                    });
                }
            });
        });

        dispatch(setPassengerOptions(passengerOptionsArray));
        dispatch(setSelectedOptions(selectedOptionsArray));
    };

    const getPassengerList = items => {
        let passengersArray = [];
        let chdArray = [];
        let insArray = [];

        _.forEach(items, function (count, passengerType) {
            if (passengerType === ADT) {
                _.times(count, () => {
                    passengersArray.push([passengerType]);
                });
            }

            if (passengerType === CHD) {
                _.times(count, () => {
                    chdArray.push(passengerType);
                });
            }

            if (passengerType === INS) {
                _.times(count, () => {
                    insArray.push(passengerType);
                });
            }

            if (passengerType === INF) {
                _.times(count, index => {
                    passengersArray[index] = [...passengersArray[index], passengerType];
                });
            }
        });

        return [..._.flatten(passengersArray), ...chdArray, ...insArray];
    };

    const passengersList = getPassengerList(passengers);

    const handleCloseRules = () => {
        setOpenRules(false);
    };

    const renderPassengers = () =>
        _.map(passengersList, (type, index) => {
            return (
                <PassengerItem
                    key={index}
                    passengerType={type}
                    index={index}
                    setOpenSuccessModal={setOpenSuccessModal}
                    formErrors={formErrors}
                    onUpdateCitizenship={onUpdateCitizenship}
                    getCountries={getCountries}
                    resetCountries={resetCountries}
                    countries={countries}
                    passengersData={passengersList}
                    formValues={formValues}
                    deadlineDate={deadlineDate}
                    clearDocumentData={clearDocumentData}
                    setSelectedPassengerIndex={setSelectedPassengerIndex}
                    onSelectAvailableDocumentTypes={onSelectAvailableDocumentTypes}
                    sessionId={sessionId}
                />
            );
        });

    return (
        <form onSubmit={handleSubmit(data => onSendBooking(data))}>
            <PassengersContainer container spacing={0}>
                <PassengersBlock>
                    <PayerBlock
                        setPayerEmailToPassenger={setPayerEmailToPassenger}
                        setPayerPhoneToPassenger={setPayerPhoneToPassenger}
                    />

                    <PassengersTitle item>
                        {passengersList.length === 1 ? t.passenger_data : t.passengers}
                    </PassengersTitle>

                    {renderPassengers()}
                </PassengersBlock>

                <RulesModal
                    openRules={openRules}
                    handleCloseRules={handleCloseRules}
                    fareRules={fareRules}
                    routes={routes}
                    included={included}
                />

                <AgreementTheBook>
                    <AgreeCheckbox
                        color="primary"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        disableRipple
                        style={{ padding: '0 8px 0 0' }}
                    />
                    <div>
                        {t.i_read_fares}
                        <AgreementBookLink onClick={() => setOpenRules(true)}>
                            {t.agreement_book_link}
                        </AgreementBookLink>
                        {t.i_accept_fares}
                    </div>
                </AgreementTheBook>

                <Submit routes={routes} fareRules={fareRules} included={included} disabled={checked} />
            </PassengersContainer>
        </form>
    );
};

let form = reduxForm({ form: 'booking' })(PassengerInfoForm);

form = connect(state => ({
    initialValues: {
        payerEmail: state.clientData.login,
        payerPhone: state.clientData.phone,
        payerWhatsApp: "",
    },
}))(form);

export default form;
