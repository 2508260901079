import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import API from '../../lib/api';
import { setAppConfigs } from '../../store/appConfigs/actions';
import config from '../../config';
import useLocalization from '../../contexts/localization/hook';

import { Title, Body, Date } from '../singleNews/components';
import Loading from '../../components/design_v2/ui/loading';
import { NewsImage } from '../../components/design_v2/main/parts/lastNews/components';

const useStyles = makeStyles({
    notFoundHeader: {
        textAlign: 'center',
        color: '#404B5A',
    },
    notFoundText: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#404B5A',
    },
    notFoundBlock: {
        margin: '96px 0',

        '@media (max-width: 959px)': {
            margin: '32px 0 64px',
        },
    },
    buyButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 32,
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 16px',
        borderRadius: 12,
        backgroundColor: '#52BE00',
        color: '#fff',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
});

const ToursAndDiscountPage = props => {
    const {
        match: { params },
    } = props;
    const { slug } = params;

    const { envConfigs } = config;
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t, language } = useLocalization();

    const [getInform, setGetInform] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(true);

    useEffect(() => {
        document.title = `${t.page_home} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    useEffect(() => {
        document.title = `${t.page_home} | ${envConfigs.appTitle}`;
    }, [t, envConfigs]);

    useEffect(() => {
        getTours();
    }, [language]);

    const getTours = async () => {
        setIsLoading(true);
        setNotFound(false);
        await API.getTourPageBySlug({ slug, language }).then(response => {
            if (response.data.tour_content.find(content => content.language === language)) {
                setGetInform(response.data);
                document.title = `${
                    response.data.tour_content.find(content => content.language === language)?.tour_name
                } | ${envConfigs.appTitle}`;
            } else {
                setNotFound(true);
            }
            setIsLoading(false);
        });
    };

    const showTextNotFound = () => {
        return (
            <div className={classes.notFoundBlock}>
                <h1 className={classes.notFoundHeader}>{t.not_found_content}</h1>
                <p className={classes.notFoundText}>{t.not_found_content_text}</p>
            </div>
        );
    };

    if (isLoading) return <Loading />;

    return (
        <Container fixed>
            {notFound ? (
                showTextNotFound()
            ) : (
                <>
                    <Title>{getInform.tour_content.find(content => content.language === language)?.tour_name}</Title>
                    <NewsImage
                        noneBorderRadius
                        minHeight
                        backgroundImage={getInform.photo ? `${envConfigs.uploadHost}${getInform.photo}` : null}
                    />
                    <Date>{moment(getInform.created_at).format('DD.MM.YYYY')}</Date>
                    <Body
                        dangerouslySetInnerHTML={{
                            __html: getInform.tour_content.find(content => content.language === language)?.body,
                        }}
                    />
                    <div className={classes.buyButtonContainer}>
                        {slug === "turi_v_Jordan" ? (
                            <NavLink to="/result/RT-DYU0606AMM0706-A1" className={classes.link}>Купить</NavLink>
                        ) : (
                            <a href="https://wa.me/992888884505" className={classes.link} target="_blank">
                                {t.buy_tour}
                            </a>
                        )}
                    </div>
                </>
            )}
        </Container>
    );
};

export default ToursAndDiscountPage;
