import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import _ from 'lodash';
import { getSegmentCity } from '../../result/common';
import SelectUI from '../../ui/components/SelectUI/SelectUI';
import useLocalization from '../../../contexts/localization/hook';
import { CloseButton, DialogTitleRoot, RulesBlock, SelectBlock } from './components';

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitleRoot disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <CloseButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </CloseButton>
            ) : null}
        </DialogTitleRoot>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function RulesModal(props) {
    const { language, t } = useLocalization();
    const { handleCloseRules, openRules, fareRules, included, routes, actualSegment } = props;

    let segment = actualSegment;

    if (!actualSegment) {
        segment = routes[0].segments[0];
    }

    const { fare_code, departure } = segment;
    const departureDate = departure.time.split(' ');
    const fareCodeDefaultKey = fare_code + '|' + _.first(departureDate) + '|' + segment.index;

    const [fareCodeKey, setFareCodeKey] = useState(fareCodeDefaultKey);
    const segmentsList = getSegmentsCityList(routes, included, language);

    const closeRules = () => {
        handleCloseRules();
        setFareCodeKey(fareCodeDefaultKey);
    };

    const textWithTags = getRulesContent(fareCodeKey, fareRules, t);
    const textWithoutTags = textWithTags.replace(/<b>/g, '').replace(/<\/b>/g, '').replace(/<i>/g, '').replace(/<\/i>/g, '');

    return (
        <Dialog onClose={closeRules} open={openRules} maxWidth="md" fullWidth scroll="body">
            <DialogTitle id="customized-dialog-title" onClose={handleCloseRules}>
                {t.tariff_rules}
            </DialogTitle>
            <DialogContent>
                <RulesBlock>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="how-to-read-rules-header"
                    >
                        <Typography>{t.how_to_read_rules}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RenderRulesDescription />
                    </AccordionDetails>
                </RulesBlock>
                <SelectBlock>
                    <SegmentsListForSelect
                        segmentsList={segmentsList}
                        fareCodeKey={fareCodeKey}
                        setFareCodeKey={setFareCodeKey}
                    />
                </SelectBlock>

                <Paper
                    style={{
                        maxHeight: 600,
                        overflow: 'auto',
                        boxShadow: 'none',
                    }}
                >
                    <pre>{textWithoutTags}</pre>
                </Paper>
            </DialogContent>
        </Dialog>
    );
}

const getRulesContent = (fareCodeKey, fareRules, t) => {
    let content = t.no_data_contact_technical_support;
    const fareCode = _.first(fareCodeKey.split('|'));

    if (fareRules.length > 0 && _.isString(fareCode)) {
        const fareRule = _.find(fareRules, { fare_code: fareCode });

        if (fareRule !== undefined) {
            content = fareRule.penalties;
        }
    }

    return content;
};

const handelOnChange = (value, props) => {
    const { setFareCodeKey } = props;
    setFareCodeKey(value);
};

const SegmentsListForSelect = props => {
    const { t } = useLocalization();
    const { segmentsList, fareCodeKey } = props;
    const selectedValue = getSelectValue(segmentsList, fareCodeKey);

    return (
        <SelectUI
            items={segmentsList}
            selectedValue={selectedValue}
            label={t.for_route}
            widthSelect="100%"
            widthDropdown="100%"
            onChange={value => handelOnChange(value, props)}
        />
    );
};

const getSelectValue = (segmentsList, fareCodeKey) => {
    return _.find(segmentsList, { value: fareCodeKey });
};

const getSegmentsCityList = (routes, included, language) => {
    let data = [];

    _.forEach(routes, function (route) {
        _.forEach(route.segments, function (segment) {
            const [departureCity, arrivalCity, departureTime] = getSegmentCity(segment, included, language);
            const departureDateArray = departureTime.split(' ');
            const label = departureCity + ' — ' + arrivalCity + ', ' + departureDateArray[0];
            const fareCode = segment.fare_code;

            data = [
                ...data,
                {
                    value: fareCode + '|' + departureDateArray[0] + '|' + segment.index,
                    label,
                },
            ];
        });
    });

    return data;
};

const RenderRulesDescription = () => {
    const { t } = useLocalization();

    return (
        <div>
            <p>
                {t.rules_text_1} <b>CANCELLATIONS ({t.cancel}):</b>
            </p>
            <p>
                <b>TICKET IS NON-REFUNDABLE</b> — {t.rules_text_2};<br />
                <b>TICKET IS NON-REFUNDABLE FOR CANCEL/REFUND</b> — {t.rules_text_2};<br />
                <b>REFUND IS NOT PERMITTED</b> — {t.rules_text_2};<br />
                <b>ANY TIME TICKET IS NON-REFUNDABLE</b> — {t.rules_text_2};<br />
                <b>TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW</b> — {t.rules_text_3}.<br />
            </p>
            <p>
                {t.rules_text_4} <b>CHANGES.</b>
            </p>
            <p>
                {t.line} <b>CHANGES ARE NOT PERMITTED</b> {t.rules_text_5}.
            </p>
        </div>
    );
};
