import React, { useState } from 'react';
import BookData from '../bookData/bookData';
import PaymentForm from '../paymentForm/paymentForm';
import { CircularProgress, Divider } from '@material-ui/core';
import RoutesData from '../routesData/routesData';
import { TICKETING_DONE_CODE } from '../../../containers/order/constants';
import { StyledButton, StyledVoidButton } from '../../../styles/common';
import ScrollUpButton from 'react-scroll-up-button';
import LocalTimeText from '../../ui/components/LocalTimeText/LocalTimeText';
import {
    getColorStatus,
    getCreatedTime,
    getBookStatus,
    checkIsAllowedCancel,
    checkInAccessToPaymentStatus,
} from '../../result/common';
import { Redirect } from 'react-router-dom';
import HelpBlock from '../../ui/components/HelpBlock/HelpBlock';
import configs from '../../../config';
import ButtonUI from '../../ui/components/ButtonUI/ButtonUI';
import SnackbarAlert from '../../ui/components/SnackbarAlert/SnackbarAlert';
import Snackbar from '../../design_v2/ui/snackbar';
import AlertModal from '../../ui/components/AlertModal/AlertModal';
import useLocalization from '../../../contexts/localization/hook';
import PriceForm from '../../booking/priceForm/priceForm';
import {
    Circle,
    ContentContainer,
    CopyLinkBlock,
    CreatedAtBlock,
    HelpContainer,
    LoadingSniperBlock,
    OrderNumberContainer,
    PaymentRoot,
    RoutesTitleOrderForm,
    SidebarContainer,
    StatusBlock,
    StatusContainer,
    TicketsOptionsButtons,
    UpButtonContainer,
    VoidBlock,
    VoidButtonBlock,
    VoidInProcessBlock,
    VoidTextOrderForm,
} from './components';

import Container from '../../design_v2/ui/container';
import Grid from '../../design_v2/ui/grid';

const authRoutes = configs.authRoutes;

export default function OrderForm(props) {
    const { t } = useLocalization();
    const {
        sendPayment,
        orderData,
        orderCancel,
        refundOrder,
        partnerCode,
        isChangedStatus,
        setIsChangedStatus,
        redirectToPayment,
    } = props;
    const {
        order_id,
        timestamp,
        booking_number,
        passengers_data,
        payer_email,
        payer_phone,
        included,
        routes,
        fare_rules,
        timelimit,
        session_id,
        currency,
        booking_status,
        airline_booking_number,
        order_status,
        status_at,
        partner_fees,
        total_price,
        options,
    } = orderData;

    const [redirect, setRedirect] = useState(false);
    const [voidStatus, setVoidStatus] = useState(false);
    const [isLoadingRefund, setIsLoadingRefund] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        severity: 'success',
        message: t.application_refund,
    });

    const handelSetRefundAlert = status => {
        if (status) {
            setAlertData({
                severity: 'success',
                message: t.application_refund,
            });
        } else {
            setAlertData({
                severity: 'error',
                message: t.error_creating_refund_request,
            });
        }

        setOpenAlert(true);
    };

    const handelOrderRefund = () => {
        setIsLoadingRefund(true);
        refundOrder(setIsLoadingRefund, handelSetRefundAlert);
    };

    const handelPaymentRequest = method => {
        sendPayment(() => {});
    };

    if (redirect) {
        return (
            <Redirect
                push
                to={{
                    pathname: authRoutes.orders,
                }}
            />
        );
    }

    const changedStatusMessage = () => {
        return (
            <div>
                {t.order_status_label} <b>{order_id}</b> {t.was_changed_to}:{' '}
                <b>{getBookStatus(booking_status, order_status, t)}</b>
            </div>
        );
    };

    const isAccessToPayment = checkInAccessToPaymentStatus(booking_status, order_status);

    return (
        <Container fixed>
            <Grid container>
                <Grid item xs={12}>
                    <OrderNumber orderId={order_id} />
                </Grid>
                <Grid item xs={12}>
                    <BookStatus
                        timestamp={timestamp}
                        createdAt={status_at}
                        bookStatus={booking_status}
                        orderStatus={order_status}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <ContentContainer item xs={12} lg={9}>
                    {isAccessToPayment && !voidStatus && (
                        <PaymentRoot>
                            <PaymentForm
                                timelimit={timelimit}
                                setPaymentRequest={handelPaymentRequest}
                                redirectToPayment={redirectToPayment}
                            />
                        </PaymentRoot>
                    )}
                    <BookData
                        bookingNumber={booking_number}
                        airlineBookingNumber={airline_booking_number}
                        payerPhone={payer_phone}
                        payerEmail={payer_email}
                        passengers={passengers_data}
                        orderId={order_id}
                        bookStatus={booking_status}
                        sessionId={session_id}
                        currency={currency}
                        totalPrice={total_price}
                        partnerFees={partner_fees}
                        options={options}
                    />
                    <RoutesTitle />
                    <RoutesData routes={routes} fareRules={fare_rules} included={included} />
                    <LocalTimeText />
                    <RenderUpButton />
                </ContentContainer>
                <SidebarContainer item xs={12} lg={3}>
                    <FixedRightBlock
                        orderData={orderData}
                        setVoidStatus={setVoidStatus}
                        voidStatus={voidStatus}
                        orderCancel={orderCancel}
                        refundOrder={handelOrderRefund}
                        isLoadingRefund={isLoadingRefund}
                        partnerCode={partnerCode}
                    />
                </SidebarContainer>
            </Grid>
            <SnackbarAlert
                severity={alertData.severity}
                message={alertData.message}
                isOpen={openAlert}
                setIsOpen={setOpenAlert}
            />
            <AlertModal message={changedStatusMessage()} isOpen={isChangedStatus} setOpen={setIsChangedStatus} />
        </Container>
    );
}

const handelOrderCancel = (setVoidStatus, orderCancel) => {
    setVoidStatus(true);
    orderCancel();
};

const FixedRightBlock = props => {
    const { t } = useLocalization();

    const { orderData, setVoidStatus, voidStatus, orderCancel, refundOrder, isLoadingRefund } = props;
    const {
        currency,
        passengers_data,
        booking_status,
        order_status,
        partner_fees,
        total_price,
        purchased_options_total_price,
        cashback,
    } = orderData;
    const isAllowedCancel = checkIsAllowedCancel(booking_status, order_status);

    const getTotalPrice = () => {
        if (purchased_options_total_price) {
            return +total_price + +purchased_options_total_price;
        }
        return total_price;
    }

    if (isAllowedCancel) {
        return (
            <div>
                <PriceForm
                    totalPrice={getTotalPrice()}
                    optionsPrice={purchased_options_total_price}
                    partnerFees={partner_fees}
                    currency={currency}
                    passengers={passengers_data}
                    forOrder={true}
                    cashback={cashback}
                />
                {voidStatus ? (
                    <>
                        <VoidInProcessBlock>
                            <LoadingSniper />
                            <span>{t.void_in_process}</span>
                        </VoidInProcessBlock>

                        <Divider />
                    </>
                ) : (
                    <>
                        <VoidButtonBlock>
                            <VoidButton setVoidStatus={setVoidStatus} orderCancel={orderCancel} />
                            <VoidText />
                        </VoidButtonBlock>

                        <Divider />
                    </>
                )}

                <CopyLinkButton orderData={orderData} />
            </div>
        );
    }

    return (
        <div>
            {false && (
                <OrderTicketsOptions
                    bookingStatus={booking_status}
                    isLoadingRefund={isLoadingRefund}
                    refundOrder={refundOrder}
                />
            )}
            <HelpContainer>
                <HelpBlock />
            </HelpContainer>
        </div>
    );
};

const LoadingSniper = () => {

    return (
        <LoadingSniperBlock>
            <CircularProgress color="inherit" size="1rem" />
        </LoadingSniperBlock>
    );
};

const VoidText = () => {

    const { t } = useLocalization();

    return (
        <VoidBlock>
            <VoidTextOrderForm>{t.void_text}</VoidTextOrderForm>
        </VoidBlock>
    );
};

const VoidButton = props => {

    const { t } = useLocalization();
    const { setVoidStatus, orderCancel } = props;

    return (
        <VoidButtonBlock>
            <StyledVoidButton onClick={() => handelOrderCancel(setVoidStatus, orderCancel)} heightSize="40px">
                {t.void_order}
            </StyledVoidButton>
        </VoidButtonBlock>
    );
};

const CopyLinkButton = ({ orderData }) => {

    const { t } = useLocalization();
    const [showSnackbar, setSnackbarShow] = useState(false);

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(`${window.location.host}/order/${orderData.order_id}?key=${orderData?.key}`);
        setSnackbarShow(true);
    };

    return (
        <CopyLinkBlock>
            <ButtonUI title={t.copy_link} onClick={copyLinkToClipboard} />

            <VoidBlock>
                <VoidTextOrderForm>{t.copy_order_link_text}</VoidTextOrderForm>
            </VoidBlock>

            <Snackbar
                show={showSnackbar}
                duration={5000}
                onClose={() => setSnackbarShow(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                alertVariant="filled"
                alertSeverity="success"
                alertText={t.link_copied_to_clipboard}
            />
        </CopyLinkBlock>
    );
};

const RoutesTitle = () => {

    const { t } = useLocalization();

    return <RoutesTitleOrderForm>{t.flight_overview}</RoutesTitleOrderForm>;
};

const RenderUpButton = () => {

    const { t } = useLocalization();

    return (
        <UpButtonContainer>
            <ScrollUpButton>
                <StyledButton type="button" widthSize="128px">
                    {t.to_top}
                </StyledButton>
            </ScrollUpButton>
        </UpButtonContainer>
    );
};

export const OrderNumber = props => {

    const { t } = useLocalization();
    const { orderId } = props;

    return (
        <OrderNumberContainer>
            {t.order} {orderId}
        </OrderNumberContainer>
    );
};

export const BookStatus = props => {

    const { t, language } = useLocalization();
    const { createdAt, timestamp, bookStatus, orderStatus } = props;
    const isTicketingDoneStatus = bookStatus === TICKETING_DONE_CODE;

    const statusColor = getColorStatus(bookStatus);

    return (
        <StatusContainer>
            <Circle style={{ backgroundColor: statusColor }} />
            <StatusBlock style={{ color: statusColor }}>
                {getBookStatus(bookStatus, orderStatus, t)}{' '}
                {isTicketingDoneStatus && getCreatedTime(createdAt, language)}
            </StatusBlock>
            <CreatedAtBlock>
                • {t.order_created} {getCreatedTime(timestamp, language)}
            </CreatedAtBlock>
        </StatusContainer>
    );
};

const OrderTicketsOptions = props => {

    const { t } = useLocalization();
    const { isLoadingRefund, refundOrder } = props;

    return (
        <TicketsOptionsButtons>
            <ButtonUI
                fontSize="16px"
                height="38px"
                title={t.return_ticket}
                onClick={refundOrder}
                isLoading={isLoadingRefund}
                disabled={isLoadingRefund}
            />
        </TicketsOptionsButtons>
    );
};
