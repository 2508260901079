import styled from 'styled-components';
import { Accordion, Paper } from '@material-ui/core';

export const RulesBlockAccordion = styled(Accordion)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    color: theme.palette.main,
    fontSize: 14,
    cursor: 'pointer',
}));

export const SelectBlock = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
    ...theme.paddings(0, 16),
    maxHeight: 600,
    overflow: 'auto',
    boxShadow: 'none',
}));
