import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRoutes } from "../../../../../../store/searchParams/actions";
import useLocalization from "../../../../../../contexts/localization/hook";
import moment from "moment";
import API from "../../../../../../lib/api";

import {
  Destinations,
  Figcaption,
  Figure,
  ItemWrapper,
  SpinerLoader,
  ShowMore,
} from "./components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CircularProgress } from "@material-ui/core";
import TitleUI from "../../../../ui/titleUI";

const PopularDestinations = ({
  destination,
  cities,
  airports,
  airlines,
  rtl,
}) => {
  const [showMoreDestinations, setShowMoreDestinations] = useState(6);
  const { t, language } = useLocalization();
  const { currency } = useSelector((state) => state.appConfigs);
  const { flights, from } = destination || {};
  const { routes } = useSelector((state) => state.searchParams);

  const flightsLimit = flights.slice(0, showMoreDestinations);

  const [spinerLoader, setSpinerLoader] = useState(0);

  const dispatch = useDispatch();

  const price = (flight) => {
    return flight[`pop_price_${currency.toLowerCase()}`];
  };

  const daysIndexes = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const dayOfTheWeeks = (weekDays) => {
    const parsedweekDays = JSON.parse(weekDays);

    const translationWeekDays = parsedweekDays.map((day) => {
      switch (day) {
        case daysIndexes[0]:
          return t.mon;

        case daysIndexes[1]:
          return t.tues;

        case daysIndexes[2]:
          return t.wed;

        case daysIndexes[3]:
          return t.thur;

        case daysIndexes[4]:
          return t.fri;

        case daysIndexes[5]:
          return t.sat;

        case daysIndexes[6]:
          return t.sun;

        default:
          return t.daily;
      }
    });

    return translationWeekDays.length === 7
      ? t.daily
      : translationWeekDays.join(", ");
  };

  const handlePushRoute = async (flight) => {
    setSpinerLoader(flight.id);

    const departure = await API.getCities(
      airports[flight.dep_airport].code_iata
    ).then((result) => result[0]);
    const arrival = await API.getCities(
      airports[flight.arr_airport].code_iata
    ).then((result) => result[0]);

    const getDaysIndex = (days) => {
      return days.map((day) =>
        daysIndexes.findIndex((dayIndex) => dayIndex === day)
      );
    };

    const flightDays = getDaysIndex(JSON.parse(flight.days_of_week));

    const date = parseInt(
      flightDays.find((day) => day + 1 > moment().isoWeekday()) + 1 ||
        flightDays[0] + 1
    );
    const flightDate =
      date <= moment().isoWeekday()
        ? moment().add(1, "weeks").isoWeekday(date)
        : moment().isoWeekday(date);

    const currentRoutes = [...routes];
    currentRoutes[0] = {
      from: departure,
      to: arrival,
      date_from: flightDate.format("YYYY-MM-DD"),
    };

    currentRoutes[1].from = arrival;
    currentRoutes[1].to = departure;

    dispatch(setRoutes(currentRoutes));

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setSpinerLoader(0);
  };

  const handleShowMore = () => {
    setShowMoreDestinations(flights.length);
  };

  return (
    <Destinations>
      <TitleUI as="h4" position="center">{t.from_airport} {airports[from][`name_${language}`]}</TitleUI>
      <ItemWrapper>
        {flightsLimit.map((flight, index) => (
          <Figure key={flight.id} onClick={() => handlePushRoute(flight)}>
            <img
              src={`./citiesImages/${flight.arr_airport}.webp`}
              alt={flight.arr_airport}
            />
            <Figcaption>
              <div>
                <span>
                  {
                    cities[airports[flight.arr_airport].city_code][
                      `name_${language}`
                    ]
                  }
                </span>
                <span>
                  {price(flight)} {price(flight) && currency}
                </span>
                <small>{dayOfTheWeeks(flight.days_of_week)}</small>
              </div>
            </Figcaption>
            {spinerLoader === flight.id && (
              <SpinerLoader>
                <CircularProgress size={40} color="#fff" />
              </SpinerLoader>
            )}
          </Figure>
        ))}
      </ItemWrapper>
      {flights.length > 6 && showMoreDestinations === 6 && (
        <ShowMore onClick={handleShowMore}>{t.show_more}</ShowMore>
      )}
    </Destinations>
  );
};

export default PopularDestinations;
