import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { Container } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { setAppConfigs } from '../../store/appConfigs/actions';
import useLocalization from '../../contexts/localization/hook';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import API from '../../lib/api';

import Loading from '../../components/design_v2/ui/loading';
import NotFoundContent from '../../components/design_v2/ui/notFoundContent';
import DesktopModal from '../../components/design_v2/ui/desktopModal';
import ModalComponent from '../../components/design_v2/ui/modal';
import envConfigs from '../../config/envConfigs';

import {
    Title,
    Block,
    AccordionHeader,
    Link,
    LinksBlock,
    AccordionBody,
    HtmlView,
    MobileModalTitle,
    AccordionFaqBlock,
} from './components';

const FaqPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t, language } = useLocalization();
    const { width } = useWindowDimensions();

    const containerRef = useRef(null);

    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [questionsLoading, setQuestionsLoading] = useState(false);
    const [notFound, setNotFound] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [expanded, setExpanded] = useState(null);
    const [selectedContent, setSelectedContent] = useState(null);

    useEffect(() => {
        document.title = `${t.page_faq} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );

        getCategories();
    }, []);

    useEffect(() => {
        document.title = `${t.page_faq} | ${envConfigs.appTitle}`;
    }, [t, envConfigs]);

    useEffect(() => {
        let check = true;

        categories.forEach(category => {
            if (category.contents.length > 0 && category.contents.find(x => x.language === language)) {
                check = false;
            }
        });

        setNotFound(check);
    }, [language, categories]);

    useEffect(() => {
        if (location.pathname !== currentLocation) {
            getCategories();
            setCurrentLocation(location.pathname);
        }
    }, [location, currentLocation]);

    useEffect(() => {
        if (!!selectedContent) {
            document.body.classList.add('hidden');
        } else {
            document.body.classList.remove('hidden');
        }
    }, [selectedContent]);

    const executeScroll = () => {
        const y = containerRef?.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const getCategories = async () => {
        setIsLoading(true);
        setNotFound(false);

        await API.getQuestionsCategories().then(response => {
            if (response.data) {
                setCategories(response.data);
            } else {
                setNotFound(true);
            }
        });

        setIsLoading(false);
        executeScroll();
    };

    const getQuestionsByCategory = async id => {
        setQuestionsLoading(true);

        await API.getQuestionsByCategoryId(id).then(response => {
            setQuestions([...questions, response]);
        });

        setQuestionsLoading(false);
    };

    const handleChangeAccordion = id => {
        setExpanded(prev => (prev === id ? null : id));

        if (!questions.find(x => x.id === id)) {
            getQuestionsByCategory(id);
        }
    };

    const handleLinkClick = content => {
        setSelectedContent(content);
    };

    const renderCategoryQuestions = id => {
        const category = questions.find(x => x.id === id);

        if (category) {
            return category.questions.map(question => {
                const content = question.contents.find(x => x.language === language);

                if (content) {
                    return (
                        <Link onClick={() => handleLinkClick(content)} key={`question-${question.id}`}>
                            {content.description}
                        </Link>
                    );
                }

                return null;
            });
        }

        return null;
    };

    const renderCategories = () => {
        return categories.map(category => {
            if (category.contents.length > 0 && category.contents.find(x => x.language === language)) {
                return (
                    <AccordionFaqBlock
                        expanded={expanded === category.id}
                        onChange={() => handleChangeAccordion(category.id)}
                        key={`category-${category.id}`}
                    >
                        <AccordionHeader expandIcon={<ExpandMore fontSize="large" />}>
                            {category.contents.find(x => x.language === language)?.title}
                        </AccordionHeader>

                        <AccordionBody>
                            {questionsLoading ? (
                                <Loading />
                            ) : (
                                <LinksBlock>{renderCategoryQuestions(category.id)}</LinksBlock>
                            )}
                        </AccordionBody>
                    </AccordionFaqBlock>
                );
            }

            return null;
        });
    };

    return (
        <Container fixed ref={containerRef}>
            <Title>{t.useful_info || 'Полезная информация'}</Title>

            {isLoading && <Loading />}

            {!isLoading && notFound && <NotFoundContent />}

            {!isLoading && !notFound && <Block>{renderCategories()}</Block>}

            <DesktopModal
                active={!!selectedContent && width >= 960}
                title={selectedContent?.description}
                onClose={() => setSelectedContent(null)}
                maxWidth={960}
                fixedWidth
            >
                <HtmlView dangerouslySetInnerHTML={{ __html: selectedContent?.answer }} />
            </DesktopModal>

            <ModalComponent
                active={!!selectedContent && width < 960}
                onClose={() => setSelectedContent(null)}
                title={t.useful_info || 'Полезная информация'}
            >
                <MobileModalTitle>{selectedContent?.description}</MobileModalTitle>
                <HtmlView dangerouslySetInnerHTML={{ __html: selectedContent?.answer }} />
            </ModalComponent>
        </Container>
    );
};

export default FaqPage;
