import styled from 'styled-components';
import bg from "../../../assets/images/header-bg-light.png"

export const SearchContainer = styled('div')(
    ({ theme, isFullScreenSearchBlock, isStickySearchBlock, isSmallSearchBlock }) => ({
        ...theme.mixins.flexCenterCenter,
        flexDirection: 'column',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.bodyBg,
        backgroundImage: `url(${bg})`,
        backgroundSize: 400,
        backgroundRepeat: "repeat",
        backgroundAttachment: "fixed",
        position: isStickySearchBlock ? 'sticky' : 'relative',
        top: isStickySearchBlock ? theme.spacing(12.5) : 0,
        zIndex: isStickySearchBlock ? 250 : 1,
        boxShadow: `inset 0 0 9px 0 ${theme.palette.pricesTableBoxShadow}`,
        marginBottom: 50,

        padding: isSmallSearchBlock
            ? `${theme.spacing(1)} 0`
            : isFullScreenSearchBlock
                ? '6vh 0 13vh 0'
                : `${theme.spacing(4)} 0`,

        [theme.breakpoints.down(theme.breakpoints.tablet)]: {
            padding: isSmallSearchBlock ? `${theme.spacing(1)} 0` : `${theme.spacing(4)} 0`,
            top: isStickySearchBlock ? theme.spacing(10) : 0,
            minHeight: isFullScreenSearchBlock ? `calc(100vh - ${theme.spacing(10)})` : 'auto',
        },
        
    }),
);

export const SearchContainerWithoutBg = styled('div')(
    ({ theme, isFullScreenSearchBlock, isStickySearchBlock, isSmallSearchBlock }) => ({
        ...theme.mixins.flexCenterCenter,
        flexDirection: 'column',
        backgroundColor: theme.palette.bodyBg,
        minHeight: isFullScreenSearchBlock ? 'calc(100vh - 100px)' : 'auto',
        position: isStickySearchBlock ? 'sticky' : 'relative',
        top: isStickySearchBlock ? theme.spacing(12.5) : 0,
        zIndex: isStickySearchBlock ? 250 : 1,
        gap: theme.spacing(2),

        padding: isSmallSearchBlock
            ? `${theme.spacing(1)} 0`
            : isFullScreenSearchBlock
                ? '0 0 15vh'
                : `${theme.spacing(4)} 0`,

        [theme.breakpoints.down(theme.breakpoints.tablet)]: {
            padding: isSmallSearchBlock ? `${theme.spacing(1)} 0` : `${theme.spacing(4)} 0`,
            top: isStickySearchBlock ? theme.spacing(10) : 0,
            minHeight: isFullScreenSearchBlock ? `calc(100vh - ${theme.spacing(10)})` : 'auto',
        },
    }),
);

export const SearchSubtitle = styled('div')(({ theme }) => ({
    fontSize: '1.2rem',
    color: theme.palette.mainText,
    textAlign: 'center',
    marginBottom: 48,
    fontWeight: 'normal',

    [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
        display: 'none',
    },
}));
