import styled from 'styled-components';

export const Component = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const InputBlock = styled('div')(({ theme, error }) => ({
    ...theme.paddings(0, 10),
    display: 'flex',
    alignItems: 'center',
    height: 60,
    width: '100%',
    border: `1px solid ${error ? '#ff0000' : '#c3d6e8'}`,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.inputBackground,
}));

export const Label = styled('div')(({ theme, marginBottom }) => ({
    fontSize: 16,
    color: theme.palette.mainText,
    marginBottom: marginBottom ? '4px' : 0,
    marginRight: marginBottom ? 0 : '8px',
    fontWeight: 600,

    '& > span': {
        color: theme.palette.error,
    },
}));

export const Input = styled('input')(({ theme }) => ({
    backgroundColor: theme.palette.transparent,
    color: theme.palette.inputPlaceholder,
    border: 'none',
    outline: 'none',
    fontSize: 16,
    flexGrow: 1,
}));

export const ErrorText = styled('div')(({ theme }) => ({
    ...theme.marginsY(4, 0),
    color: theme.palette.error,
    fontSize: 14,
}));
