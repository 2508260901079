import styled from "styled-components";

export const TelegramChatWrapper = styled("div")`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #1696d6;
  border-radius: 100px;
  width: 34px;
  transition: 1s ease;
  cursor: pointer;

  img {
    width: 30px;
    heigth: 30px;
    transform: rotate(359deg);
    transition: 1s ease;
  }

  p {
    margin: 0 10px 0 4px;
    color: #4d4d4d;
    font-size: 14px;
    text-align: center;
    opacity: 0;
    text-wrap: nowrap;
    transition: 1s 0 ease;
  }

  :hover {
    width: 270px;

    img {
      transform: rotate(-359deg);
      transition: 1s ease;
    }

    p {
      transition: 1s 500ms ease;
      opacity: 1;
    }
  }
`;
