import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { setRoutes } from "../../../../../store/searchParams/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Chart,
  ChartItem,
  PriceSlider,
  PriceSliderItem,
  Price,
} from "./components";
import Arrow from "../../../../../assets/icons/chevronRightIcon.svg";
import Arrow_Dark from "../../../../../assets/icons/chevronRightIcon_dark.png";
import moment from "moment";
import { appendToLocalStorage } from "../../../../../lib/searchHistory";
import { encodeSearchId } from "../../../../../lib/searchId";
import { setNotFilledFields } from "../../../../../store/searchParams/actions";
import { setAllLoadngData } from "../../../../../store/loading/actions";
import { setFilterParams } from "../../../../../store/filters/actions";
import "moment/locale/ru";
import "moment/locale/tg";
import useTheme from '../../../../../contexts/theme/hook';

const PriceChart = ({ days }) => {
  const { currency } = useSelector((state) => state.appConfigs);
  const [averagePrice, setAveragePrice] = useState("");
  const { routes, flightType, cabin, passengers } = useSelector(
    (state) => state.searchParams
  );
  const newRoutes = [...routes];
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme()

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={theme.theme === 'DARK_THEME' ? Arrow_Dark : Arrow} alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={theme.theme === 'DARK_THEME' ? Arrow_Dark : Arrow} alt="" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [

      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ],
  };

  function averageNum(arr, keyName) {
    return (
      arr.reduce(
        (sum, a) => sum + (keyName === undefined ? a : +a[keyName]),
        0
      ) / arr.length
    );
  }

  function minNum(arr) {
    return Math.min(...arr.map((item) => item.price));
  }

  function maxNum(arr) {
    return Math.max(...arr.map((item) => item.price));
  }

  const handleClick = (event, date) => {
    let currentRoutes = [...routes];
    let currentFlightType = flightType;

    currentRoutes[0].date_from = moment(date).format("YYYY-MM-DD");
    dispatch(setRoutes(currentRoutes));

    if (
      currentRoutes[0].from &&
      currentRoutes[0].to &&
      currentRoutes[0].date_from
    ) {
      const searchId = encodeSearchId(
        flightType,
        cabin,
        currentRoutes,
        passengers
      );
      appendToLocalStorage(searchId, currentRoutes[0].date_from);
      history.push(`/result/${searchId}`);

      dispatch(
        setFilterParams({
          type: "pricesTable",
          values: {
            isActive: false,
            values: {
              type: null,
              supplier: null,
              price: null,
            },
          },
        })
      );

      dispatch(
        setAllLoadngData({
          showLoading: true,
          searchLoading: true,
          searchData: {
            flightType: currentFlightType,
            cabin,
            passengers,
            routes: currentRoutes,
          },
        })
      );
    } else {
      dispatch(
        setNotFilledFields({
          from: !!!currentRoutes[0].from,
          to: !!!currentRoutes[0].to,
          date: !!!currentRoutes[0].date_from,
        })
      );

      setTimeout(() => {
        dispatch(
          setNotFilledFields({
            from: false,
            to: false,
            date: false,
          })
        );
      }, 3000);
    }

  };
  return (
    <>
      <PriceSlider {...settings}>
        {days.map((day, index) => {
          const date = new Date(day.date);
          const monthName = moment(date).format('MMMM');

          return (
            <PriceSliderItem key={index} background={averagePrice > day.price && "#35C768"}>
              <Chart onClick={(event) => handleClick(event, date)}>
                <ChartItem>
                  {date.getDate()} {monthName} - {date.getDate()} {monthName}
                </ChartItem>
                <Price>{day.price} {currency}</Price>
              </Chart>
            </PriceSliderItem>
          );
        })}
      </PriceSlider>
    </>
  );
};

export default PriceChart;
