import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import flyCash2 from "../../../../images/flyCash/flyCash2.png";
import flyCash5 from "../../../../images/flyCash/flyCash5.png";
import flyCash10 from "../../../../images/flyCash/flyCash10.png";
import present from "../../../../images/flyCash/present.png";

import { DescriptionBlock, ImageBlock, InfoBlock, TextBlock, TitleBlock, Wrapper } from "./components";

const TravelPart = () => {
  const { language } = useLocalization();

    if (language === "ru") {
        return (
            <Wrapper>
                <InfoBlock>
                    <TextBlock>
                        <TitleBlock className="desktop">Как насчет<br /> путешествий по<br /> всему миру вместе с<br /> кешбэками <img src={flyCash5} className="cash5" alt="fly-cash-text" /></TitleBlock>
                        <TitleBlock className="mobile">Как насчет<br /> путешествий по<br /> всему миру вместе с<br /> кешбэками <img src={flyCash2} className="cash2" alt="fly-cash-text" /></TitleBlock>
                        <DescriptionBlock>Наша программа рассчитана для людей, желающих наслаждаться привилегиями в небесах. Экономьте на перелетах, зарабатывая кешбэк с помощью FLY CASH. Стать участником программы просто. Любые авиабилеты позволяют приобрести кэшбек.</DescriptionBlock>
                    </TextBlock>
                    <ImageBlock>
                        <img src={present} alt="present" />
                    </ImageBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    if (language === "tj") {
        return (
            <Wrapper>
                <InfoBlock>
                    <TextBlock>
                        <TitleBlock className="desktop">Сайругашт кардан<br /> дар саросари ҷаҳон бо <img src={flyCash5} className="cash5" alt="fly-cash-text" /></TitleBlock>
                        <TitleBlock className="mobile">Сайругашт кардан<br /> дар саросари ҷаҳон бо <img src={flyCash2} className="cash2" alt="fly-cash-text" /></TitleBlock>
                        <DescriptionBlock>Барномаи мо барои одамоне аст, ки мехоҳанд аз имтиёзҳои осмон баҳра баранд. Бо гирифтани кэшбэк бо FLY CASH дар парвозҳо сарфа кунед. Узви барнома шудан осон аст. Кэшбек ба шумо имкон медиҳад, ки ҳар гуна чиптаҳои ҳавопаймоиро харед.</DescriptionBlock>
                    </TextBlock>
                    <ImageBlock>
                        <img src={present} alt="present" />
                    </ImageBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <InfoBlock>
                <TextBlock>
                    <TitleBlock className="desktop">How about<br /> traveling around the<br /> world with <img src={flyCash5} className="cash5" alt="fly-cash-text" /></TitleBlock>
                    <TitleBlock className="mobile">How about traveling<br /> around the world with <img src={flyCash10} className="cash2" alt="fly-cash-text" width={214} /></TitleBlock>
                    <DescriptionBlock>Our program is designed for people who want to enjoy their trip with privileges. Save on flights while earning cashback using FLY CASH. Becoming a program participant is easy. Cashback allows you to purchase any air ticket.</DescriptionBlock>
                </TextBlock>
                <ImageBlock>
                    <img src={present} alt="present" />
                </ImageBlock>
            </InfoBlock>
        </Wrapper>
    );
};

export default TravelPart;
