import React, { useState } from 'react';

import {
    ActionButtonBlock,
    ActionButtonContainer,
    ActionSubmitBlock,
    AmountBlock,
    AmountContainer,
    DeparturesBlock,
    OderIdBlock,
    OrderBlock,
    OrderContainer,
    OrderMainBlock,
    OrderSubmitBlock,
    RoutesBlock,
    StatusBlock,
    TimestampBlock,
} from './styles';
import { downloadMk } from '../../../../components/order/bookData/bookData';
import { normalizePrice } from '../../../../lib/normalize';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CancelIcon from '@material-ui/icons/Cancel';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {
    checkAllowedMK,
    checkIsAllowedRefund,
    checkInProgressStatus,
    FormattedDateTimeWithoutYear,
    checkIsAllowedCancel,
    getDayMonthYearFormat,
} from '../../../../components/result/common';
import Grid from '@material-ui/core/Grid';
import ButtonUI from '../../../../components/ui/components/ButtonUI/ButtonUI';
import RoutesInfo from '../routesInfo/index';
import { BookingStatus } from '../smallComponents/index';
import _ from 'lodash';
import useLocalization from '../../../../contexts/localization/hook';

const Order = props => {
    const { t, language } = useLocalization();
    const { order, setOrderRedirect, orderCancel, setAlert, setOpenAlert, orderRefund, setShowActionButtons } = props;
    const {
        order_id,
        routes,
        included,
        amount,
        currency,
        timestamp,
        booking_status,
        timelimit,
        order_status,
        session_id,
        showActionButtons,
        client_name,
        email,
        key,
    } = order;
    const [isLoadingMk, setIsLoadingMk] = useState(false);
    const [isLoadingCancel, setIsLoadingCancel] = useState(false);
    const [isLoadingReturn, setIsLoadingReturn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const setShowAction = () => {
        setShowActionButtons(order_id, !showActionButtons);
    };

    const getDepartureTimeArray = (routes, language) => {
        return _.map(routes, function(route) {
            const firstSegment = _.first(route.segments);

            if (firstSegment) {
                return getDayMonthYearFormat(firstSegment.departure.time, language);
            }
        });
    };

    const departureTimeArray = getDepartureTimeArray(routes, language);

    const handelDownloadMk = () => {
        setIsLoading(true);
        setIsLoadingMk(true);
        downloadMk(order_id, session_id, setSuccess);
    };

    const handelOrderCancel = () => {
        setIsLoading(true);
        setIsLoadingCancel(true);
        orderCancel(order_id, session_id, key, setSuccessCancel);
    };

    const handelOrderReturn = () => {
        setIsLoading(true);
        setIsLoadingReturn(true);
        orderRefund(order_id, setSuccessRefund);
    };

    const setSuccess = () => {
        setIsLoading(false);
        setIsLoadingMk(false);
        setIsLoadingCancel(false);
        setIsLoadingReturn(false);
    };

    const isAllowedMK = checkAllowedMK(booking_status);
    const isAllowedRefund = checkIsAllowedRefund(booking_status);
    const isAllowedPay = checkInProgressStatus(booking_status, order_status);
    const isAllowedCancel = checkIsAllowedCancel(booking_status, order_status);

    const setSuccessCancel = (status = true) => {
        setSuccess();

        setAlert({
            severity: status ? 'success' : 'error',
            message: status ? t.order_cancel_successfully_mess : t.order_cancel_error_mess,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setOpenAlert();
    };

    const setSuccessApprovedPaymentWithBalance = (status = true) => {
        setSuccess();

        setAlert({
            severity: status ? 'success' : 'error',
            message: status ? t.approved_balance_request_successfully : t.error_approved_balance_request_successfully,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setOpenAlert();
    };

    const setSuccessRefund = (status = true) => {
        setSuccess();

        setAlert({
            severity: status ? 'success' : 'error',
            message: status ? t.return_request_created : t.error_creating_refund_request,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        setOpenAlert();
    };

    const getSeparator = routes => {
        const count = routes.length;

        if (count === 0) return null;

        const departureSegment = _.first(_.first(routes).segments);
        const arrivalSegment = _.last(_.last(routes).segments);

        if (_.isUndefined(departureSegment) || _.isUndefined(arrivalSegment)) {
            return ', ';
        }

        const departureAirport = departureSegment.departure.airport;
        const arrivalAirport = arrivalSegment.arrival.airport;

        if (count === 2 && departureAirport === arrivalAirport) {
            return ' - ';
        }

        return ', ';
    };

    const separator = getSeparator(routes);

    return (
        <OrderContainer>
            <OrderBlock container>
                <OrderMainBlock item>
                    <Grid container onClick={() => setOrderRedirect(order_id)}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Grid container direction={'column'}>
                                <OderIdBlock item>
                                    {t.order} {order_id}
                                </OderIdBlock>
                                <TimestampBlock item>
                                    {t.created} {FormattedDateTimeWithoutYear(timestamp, language)}
                                </TimestampBlock>
                                <TimestampBlock item>
                                    {t.client}: {client_name}
                                </TimestampBlock>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={5}>
                            <Grid container direction={'column'}>
                                <RoutesBlock item>
                                    <RoutesInfo routes={routes} included={included} />
                                </RoutesBlock>
                                <DeparturesBlock item>
                                    {departureTimeArray.join(separator)}
                                </DeparturesBlock>
                                <TimestampBlock item>
                                    {email}
                                </TimestampBlock>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <AmountContainer
                                container
                            >
                                <AmountBlock item>
                                    {amount && `${normalizePrice(amount)} ${currency}`}
                                </AmountBlock>
                            </AmountContainer>
                        </Grid>
                    </Grid>
                </OrderMainBlock>
                <OrderMainBlock item>
                    <Grid container justifyContent={'space-between'}>
                        <StatusBlock
                            item
                            onClick={() => setOrderRedirect(order_id)}
                            xs={12}
                            sm={8}
                        >
                            <BookingStatus
                                bookingStatus={booking_status}
                                orderStatus={order_status}
                                timelimit={timelimit}
                            />
                        </StatusBlock>
                        <Grid item xs={12} sm={4}>
                            <OrderSubmitBlock>
                                <ButtonUI
                                    onClick={setShowAction}
                                    title={t.action}
                                    endIcon={showActionButtons ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    fontSize='12px'
                                    height='32px'
                                />
                            </OrderSubmitBlock>
                        </Grid>
                        {showActionButtons && (
                            <ActionButtonContainer item>
                                <ActionButtonBlock>
                                    <ActionSubmitBlock>
                                        <ButtonUI
                                            onClick={() => setOrderRedirect(order_id)}
                                            title={t.to_pay}
                                            endIcon={<AddShoppingCartIcon />}
                                            fontSize='12px'
                                            height='32px'
                                            isGreen={true}
                                            disabled={isLoading || !isAllowedPay || timelimit < 0}
                                        />
                                    </ActionSubmitBlock>
                                    <ActionSubmitBlock>
                                        <ButtonUI
                                            onClick={handelOrderCancel}
                                            title={t.cancel}
                                            endIcon={<CancelIcon />}
                                            fontSize='12px'
                                            height='32px'
                                            disabled={isLoading || !isAllowedCancel}
                                            isLoading={isLoadingCancel}
                                        />
                                    </ActionSubmitBlock>
                                    <ActionSubmitBlock>
                                        <ButtonUI
                                            onClick={handelDownloadMk}
                                            title={t.itinerary_receipt}
                                            endIcon={<ReceiptIcon />}
                                            fontSize='12px'
                                            height='32px'
                                            disabled={isLoading || !isAllowedMK}
                                            isLoading={isLoadingMk}
                                        />
                                    </ActionSubmitBlock>
                                </ActionButtonBlock>
                            </ActionButtonContainer>
                        )}
                    </Grid>
                </OrderMainBlock>
            </OrderBlock>
        </OrderContainer>
    );
};

export default Order;
