import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const LoadingWrapper = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    padding: theme.spacing(4),
}));

export const BrandsWrapper = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 0, 0),
    // textAlign: 'center',
}));

export const BrandsContainer = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 0, 0),
    textAlign: 'start'
}));

export const BrandsMessageBlock = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    fontSize: 16,

}));

export const BrandsMessageIcon = styled('div')({ display: 'inline-block' });

export const BrandsMessageTitle = styled('div')(({ theme }) => ({
    display: 'inline-block',
    position: 'relative',
    bottom: 4,
    marginLeft: theme.spacing(0.75),
}));

export const BrandsBlock = styled('div')(({ theme }) => ({
    // ...theme.paddings(16, 0, 24, 27),
}));

export const TariffsGridWrapper = styled("div")(() => ({
    display: 'grid',
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: 20,
}));
