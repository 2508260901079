import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import CopiedLinkNotification from "../../resultPage/recommendation/copiedLinkNotification/CopiedLinkNotification";

import {
  Wrapper,
  Backdrop,
  Modal,
  Header,
  CloseButton,
  CloseIcon,
  Body,
} from "./components";
import ShareIconWithText from "../../resultPage/recommendation/ui/shareIconWithText";

const TicketModalComponent = ({
  children,
  active,
  onClose,
  flexCenter,
  maxWidth = 1280,
  fixedWidth = false,
}) => {
  const backdropRef = useRef(null);

  const [snakBarShow, setSnakBarShow] = useState(false);

  useEffect(() => {
    if (active) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [active]);

  const handleClose = () => {
    onClose();
    setSnakBarShow(false);
  };

  const handleBackdropClick = (event) => {
    if (event.target === backdropRef.current) {
      handleClose();
    }
  };

  const handleShareLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setSnakBarShow(true);
      })
      .catch((error) => {
        console.error("Ошибка при копировании", error);
      });
  };

  if (!active) return null;

  return ReactDOM.createPortal(
    <Wrapper isShow={active}>
      <CopiedLinkNotification
        snakBarShow={snakBarShow}
        setSnakBarShow={setSnakBarShow}
      />
      <Backdrop isShow={active} onClick={handleBackdropClick} ref={backdropRef}>
        <Modal isShow={active} maxWidth={maxWidth} fixedWidth={fixedWidth}>
          <Header>
            <CloseButton onClick={handleClose} size="small">
              <CloseIcon />
            </CloseButton>
            <ShareIconWithText handleShareLink={handleShareLink} />
          </Header>

          <Body flexCenter={!!flexCenter}>{children}</Body>
        </Modal>
      </Backdrop>
    </Wrapper>,
    document.body
  );
};

export default TicketModalComponent;
