import React from 'react';
import { InputTextFieldUi } from './components';


function InputTextField(props) {
    const { InputProps, input } = props;

    return (
        <InputTextFieldUi
            {...props}
            {...input}
            autoComplete="off"
            InputProps={{
                ...InputProps,
                disableUnderline: true,
            }}
        />
    );
}

export default function InputUI(props) {
    const { onChange } = props;

    const handelChange = e => {
        onChange(e.target.value);
    };

    return (
        <InputTextField
            {...props}
            onChange={handelChange}
            variant="filled"
            fullWidth
            InputLabelProps={{
                shrink: true,
                color: 'primary',
            }}
        />
    );
}

InputUI.defaultProps = {
    onChange: () => {},
};
