import styled from 'styled-components';

const getItemWidth = (current, widths) => {
    const breakpoints = ['xl', 'lg', 'md', 'sm', 'xs' ];
    const currentIndex = breakpoints.findIndex(item => item === current);
    const nearestNotZero = breakpoints.find((item, index) => index >= currentIndex && widths[breakpoints[index]] !== 0);

    if (nearestNotZero) {
      if (widths[nearestNotZero] === 'auto') return {};

      return {
        flexGrow: 0,
        maxWidth: `${(widths[nearestNotZero] * 100) / 12}%`,
        flexBasis: `${(widths[nearestNotZero] * 100) / 12}%`,
      };
    }

    return {};
};

export const Wrapper = styled('div')(({ theme, $container, $alignItems, $justifyContent, $item, $spacing, $widths }) => {
  if ($container) {
    let styles = {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
      alignItems: $alignItems,
      justifyContent: $justifyContent,
    };

    if ($spacing) {
      styles = {
        ...styles,
        width: `calc(100% + ${theme.spacing($spacing)})`,
        margin: -($spacing * 8) / 2,

        '& > div': {
          padding: ($spacing * 8) / 2,
        },
      };
    }

    return styles;
  }

  if ($item) {
    let styles = {
      margin: 0,
      boxSizing: 'border-box',
      ...getItemWidth('xl', $widths),

      [theme.breakpoints.upDown(1000, 1300)]: {
        ...getItemWidth('lg', $widths),
      },

      [theme.breakpoints.upDown(800, 1000)]: {
        ...getItemWidth('md', $widths),
      },

      [theme.breakpoints.upDown(600, 800)]: {
        ...getItemWidth('sm', $widths),
      },

      [theme.breakpoints.down(599)]: {
        ...getItemWidth('xs', $widths),
      },
    }

    return styles;
  }

  return {};
});
