import React, { useEffect, useRef } from 'react';
import { Close } from '@material-ui/icons';

import useLocalization from '../../../contexts/localization/hook';

import {
    Wrapper,
    Backdrop,
    Modal,
    CloseButton,
    TextBlock,
    LogoBlock,
    Logo,
    EventText,
    ButtonBlock,
    Button,
} from './components';

import visaLogoImage from '../../../images/otherLogos/visa.png';

const VisaPaymentPopup = ({ showPopup, handleClose, data }) => {
    const backdropRef = useRef(null);
    const { t } = useLocalization();

    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            if (showPopup) {
                document.body.classList.add('hidden');
            } else {
                document.body.classList.remove('hidden');
            }
        };
    }, [showPopup]);

    const handleBackdropClick = event => {
        if (event.target === backdropRef.current) {
            handleClose();
        }
    };

    if (!showPopup) return null;

    return (
        <Wrapper isShow={showPopup}>
            <Backdrop isShow={showPopup} onClick={handleBackdropClick} ref={backdropRef}>
                <Modal isShow={showPopup}>
                    <CloseButton onClick={handleClose} size="small">
                        <Close />
                    </CloseButton>

                    <TextBlock>
                        <LogoBlock>
                            <Logo src={visaLogoImage} alt="visa-logo" />
                        </LogoBlock>

                        <EventText>{t.confirm_actions}</EventText>

                        <form action={data.payUrl} method="POST">
                            <input name="appendix" value={data.appendix} hidden />
                            <input name="PostLink" value={data.PostLink} hidden />
                            <input name="BackLink" value={data.BackLink} hidden />
                            <input name="email" value={data.email} hidden />
                            <input name="Signed_Order_B64" value={data.Signed_Order_B64} hidden />
                            <ButtonBlock>
                                <Button type="submit">{t.confirm}</Button>
                            </ButtonBlock>
                        </form>
                    </TextBlock>
                </Modal>
            </Backdrop>
        </Wrapper>
    );
};

export default VisaPaymentPopup;
