import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useLocalization from '../../../../contexts/localization/hook';
import * as Img from '../../../../lib/getImage';
import { normalizePrice } from '../../../../lib/normalize';
import { setFilterParams } from '../../../../store/filters/actions';

import {
    Wrapper,
    Tabs,
    TabsHeader,
    TabsButtons,
    TabsButton,
    TabsBody,
    TabsContent,
    Suppliers,
    SupplierItem,
    SupplierHeader,
    SupplierLogo,
    SupplierPrices,
    SupplierPrice,
    SupplierPriceButton,
    ActiveIndicator,
} from './components';
import AccordionComponent from '../../ui/accordion';

const CompaniesAndPricesTable = () => {
    const { recommendations, included } = useSelector(state => state.resultData);
    const { currency } = useSelector(state => state.appConfigs);
    const { filterParams } = useSelector(state => state.filters);

    const dispatch = useDispatch();
    const { t } = useLocalization();

    const [directFlights, setDirectFlights] = useState([]);
    const [transfersFlights, setTransfersFlights] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [activeTab, setActiveTab] = useState('direct');

    useEffect(() => {
        readyData();
    }, [currency]);

    const readyData = () => {
        const suppliers = [];
        for (const key of Object.keys(included.supplier)) {
            suppliers.push({
                code: key,
                flights: recommendations.filter(flight => flight.validating_supplier === key),
            });
        }

        const suppliersDirectPrices = [];
        const suppliersTransfersPrices = [];

        for (const supplier of suppliers) {
            let directPrices = [];
            let transferPrices = [];

            supplier.flights.forEach(flight => {
                if (checkFlightHasTransfers(flight)) {
                    if (transferPrices.indexOf(flight.total_price[currency]) === -1)
                        transferPrices.push(flight.total_price[currency]);
                } else {
                    if (directPrices.indexOf(flight.total_price[currency]) === -1)
                        directPrices.push(flight.total_price[currency]);
                }
            });

            directPrices = directPrices.sort((a, b) => {
                if (a > b) return 1;
                if (b > a) return -1;
                return 0;
            });

            transferPrices = transferPrices.sort((a, b) => {
                if (a > b) return 1;
                if (b > a) return -1;
                return 0;
            });

            if (directPrices.length) {
                suppliersDirectPrices.push({
                    code: supplier.code,
                    prices: directPrices,
                });
            }

            if (transferPrices.length) {
                suppliersTransfersPrices.push({
                    code: supplier.code,
                    prices: transferPrices,
                });
            }
        }

        if (!suppliersDirectPrices.length) setActiveTab('transfers');

        setDirectFlights(suppliersDirectPrices);
        setTransfersFlights(suppliersTransfersPrices);
    };

    const checkFlightHasTransfers = flight => {
        const { routes } = flight;

        for (const route of routes) {
            if (route.segments.length > 1) return true;
        }

        return false;
    };

    const handleClickButton = (type, supplier, price) => {
        const { values } = filterParams.pricesTable;

        if (values.type === type && values.supplier === supplier && values.price == price) {
            dispatch(
                setFilterParams({
                    type: 'pricesTable',
                    values: {
                        isActive: false,
                        values: { type: null, supplier: null, price: null },
                    },
                })
            );
        } else {
            dispatch(
                setFilterParams({
                    type: 'pricesTable',
                    values: {
                        isActive: true,
                        values: { type, supplier, price },
                    },
                })
            );
        }
    };

    const renderTabContent = type => {
        const suppliers = type === 'direct' ? directFlights.slice(0, 10) : transfersFlights.slice(0, 10);

        return (
            <TabsContent className={activeTab === type ? 'active' : null}>
                <Suppliers>
                    {suppliers.map(value => (
                        <SupplierItem key={`supplier-${type}-${value.code}`}>
                            <SupplierHeader>
                                <SupplierLogo src={Img.getSupplierIcon(value.code)} />
                            </SupplierHeader>

                            <SupplierPrices>
                                {value.prices.map((item, index) => {
                                    if (index < 3 || (showAll && index >= 3)) {
                                        const { values } = filterParams.pricesTable;

                                        return (
                                            <SupplierPrice key={`supplier-${type}-${value.code}-price-${index}`}>
                                                <SupplierPriceButton
                                                    onClick={() => handleClickButton(type, value.code, item)}
                                                    className={
                                                        values.type === type &&
                                                        values.supplier === value.code &&
                                                        values.price === item
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    <span>{normalizePrice(item)}</span> {currency}
                                                </SupplierPriceButton>
                                            </SupplierPrice>
                                        );
                                    }

                                    return null;
                                })}
                            </SupplierPrices>
                        </SupplierItem>
                    ))}
                </Suppliers>
            </TabsContent>
        );
    };

    const checkHasMoreOptions = () => {
        if (directFlights.length && transfersFlights.length) {
            for (let i = 0; i < directFlights.length; i++) {
                if (directFlights[i].prices.length > 3) {
                    return true;
                }
            }

            for (let i = 0; i < transfersFlights.length; i++) {
                if (transfersFlights[i].prices.length > 3) {
                    return true;
                }
            }

            return false;
        }

        if (directFlights.length > 0) {
            for (let i = 0; i < directFlights.length; i++) {
                if (directFlights[i].prices.length > 3) {
                    return true;
                }
            }

            return false;
        }

        if (transfersFlights.length > 0) {
            for (let i = 0; i < transfersFlights.length; i++) {
                if (transfersFlights[i].prices.length > 3) {
                    return true;
                }
            }

            return false;
        }
    };

    return (
        <Wrapper>
            <AccordionComponent
                isActive
                title={t.prices_table || 'Таблица цен'}
                footerButtonText={checkHasMoreOptions() ? t.more_options : null}
                footerButtonOnClick={() => setShowAll(prev => !prev)}
            >
                <Tabs>
                    <TabsHeader>
                        <TabsButtons>
                            {directFlights.length > 0 ? (
                                <TabsButton onClick={() => setActiveTab('direct')} active={activeTab === 'direct'}>
                                    {t.straight}
                                    {filterParams.pricesTable.isActive &&
                                    filterParams.pricesTable.values.type === 'direct' ? (
                                        <ActiveIndicator />
                                    ) : null}
                                </TabsButton>
                            ) : null}

                            {transfersFlights.length > 0 ? (
                                <TabsButton
                                    onClick={() => setActiveTab('transfers')}
                                    active={activeTab === 'transfers'}
                                >
                                    {t.with_transfer}
                                    {filterParams.pricesTable.isActive &&
                                    filterParams.pricesTable.values.type === 'transfers' ? (
                                        <ActiveIndicator />
                                    ) : null}
                                </TabsButton>
                            ) : null}
                        </TabsButtons>
                    </TabsHeader>

                    <TabsBody paddingBottom={checkHasMoreOptions() ? 0 : 1}>
                        {directFlights.length > 0 && renderTabContent('direct')}
                        {transfersFlights.length > 0 && renderTabContent('transfers')}
                    </TabsBody>
                </Tabs>
            </AccordionComponent>
        </Wrapper>
    );
};

export default CompaniesAndPricesTable;
