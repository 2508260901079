import React, { useState, useRef, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Divider } from '@material-ui/core';
import useLocalization from '../../../../../../contexts/localization/hook';
import config from '../../../../../../config';
import { setAppConfigs } from '../../../../../../store/appConfigs/actions';
import {
    Wrapper,
    Block,
    ExitToAppIcon,
    AccountCircleIcon,
    EnterBlock,
    Dropdown,
    StyledLink,
    LinkForAgents,
    Component,
    Button,
    ClientName,
    ClientLogin,
    ClientBalanceBlock,
    ClientBalanceTitle,
    ClientBalanceValue,
    ExitButton,
    FlyCashButton,
    UserBalance,
    UserBtnWrap,
} from './components';
import SelectCurrencies from './parts/currencies';
import SearchOrder from './parts/searchOrder';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import Contacts from './parts/contact';
import ThemeBtn from './parts/themeButton';
import API from '../../../../../../lib/api';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import ActiveOrdersIcon from './parts/activeOrdersIcon';

const DesktopNav = () => {
    const { isAuth, currency } = useSelector(store => store.appConfigs);
    const user = useSelector(state => state.clientData);
    const { width } = useWindowDimensions();

    const history = useHistory();

    const { t } = useLocalization();
    const clickOutside = useRef();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);


    const { agentsLink } = config.envConfigs;
    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleLogout = () => {
        dispatch(
            setAppConfigs({
                isAuth: false,
            }),
        );
        setIsActive(false);
        window.localStorage.removeItem('token');
        window.location.href = '/';

        API.logout().then(response => {
            dispatch(
                setAppConfigs({
                    isAuth: false,
                })
            );
            setIsActive(false);
            window.localStorage.removeItem('token');
            window.location.href = '/';
        })
    };

    const handleClick = e => {
        if (clickOutside.current?.contains(e.target)) {
            return;
        }

        setIsActive(false);
    };

    const handleGoToFlyCashPage = () => {
        history.push('/fly-cash');
    };

    const isShow = false;

    return (
        <Wrapper>
            {!isAuth && (
                <Block>
                    {/* <FlyCashButton onClick={handleGoToFlyCashPage}>
                        FLY CASH
                    </FlyCashButton> */}
                    {width >= 960 && <Contacts />}
                    {width >= 960 && <SearchOrder />}

                    <SelectCurrencies />

                    <EnterBlock onClick={() => setIsActive(true)}>
                        <ExitToAppIcon />
                    </EnterBlock>
                    <Dropdown active={isActive} ref={clickOutside} right>
                        <StyledLink to='/login' text={t.log_in} onClick={() => setIsActive(false)} />

                        <Divider />

                        <StyledLink
                            to='/useful-informations'
                            text={t.useful_info || 'Полезная информация'}
                            onClick={() => setIsActive(false)}
                        />
                        <Divider />
                        <StyledLink to='/registration' text={t.registration} onClick={() => setIsActive(false)} />

                        {width < 960 && width >= 600 && (
                            <>
                                <Divider />
                                <SearchOrder desktop={true} />
                            </>
                        )}
                        <Divider />
                        <LinkForAgents href={`${agentsLink}`}>{t.agents}</LinkForAgents>
                    </Dropdown>
                </Block>
            )}

            {isAuth && (
                <Component>
                    { isShow && <FlyCashButton onClick={handleGoToFlyCashPage}>
                        FLY CASH
                    </FlyCashButton>}
                    {width >= 960 && <Contacts />}
                    <SelectCurrencies />
                    <ActiveOrdersIcon />
                    <Button onClick={() => setIsActive(true)}>
                        <AccountCircleIcon />
                    </Button>
                    <UserBtnWrap>
                        <NavLink to="/rechargeBalance">
                            <UserBalance>
                                <span> { user.balance || 0 } </span> | <span> { user.currency || currency } </span>
                            </UserBalance>
                        </NavLink>
                    </UserBtnWrap>
                    <Dropdown active={isActive} ref={clickOutside}>
                        <ClientName>
                            {user.firstName} {user.lastName}
                        </ClientName>
                        <ClientLogin>{user.login}</ClientLogin>
                        <ClientBalanceBlock>
                            <ClientBalanceTitle>{t.your_id}:</ClientBalanceTitle>
                            <ClientBalanceValue>{user.id}</ClientBalanceValue>
                        </ClientBalanceBlock>
                        <Divider />

                        <ClientBalanceBlock>
                            <ClientBalanceTitle>{t.balance}:</ClientBalanceTitle>

                            <ClientBalanceValue>
                                {user.balance || 0}
                                <span>{user.currency || currency}</span>
                            </ClientBalanceValue>
                        </ClientBalanceBlock>

                        <Divider />

                        <StyledLink to='/orders' onClick={() => setIsActive(false)} text={t.orders} />

                        <Divider />

                        <StyledLink to='/transactions' onClick={() => setIsActive(false)} text={t.transactions} />

                        <Divider />

                        <StyledLink to='/userProfile' onClick={() => setIsActive(false)} text={t.my_profile} />

                        <Divider />

                        <StyledLink
                            to='/rechargeBalance'
                            onClick={() => setIsActive(false)}
                            text={t.recharging_balance}
                        />
                        <Divider />

                        <ExitButton onClick={() => handleLogout()}>{t.log_out}</ExitButton>
                    </Dropdown>
                </Component>
            )}
            <ThemeBtn />
        </Wrapper>
    );
};

export default DesktopNav;