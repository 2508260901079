import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { last } from "lodash";
import moment from 'moment';

import useLocalization from '../../../contexts/localization/hook';
import { setSelectedOptions } from '../../../containers/booking/actions';
import { getRouteCity } from '../../result/common';
import { removeBimaOptionsFromPassenger } from '../../../lib/removeBimaOptions';

import {
    CheckboxItem,
    NotFoundOptionBlock,
    OptionItem,
    OptionItemCategory,
    OptionItemName,
    OptionItemPrice,
    OptionsBlock,
    RouteBlock,
    RouteTitle,
    Title,
    Wrapper,
} from './components';

const Options = ({ passengerIndex, passengerType, passenger }) => {
    const { language, t } = useLocalization();
    const { included, passengerOptions, options, routes, selectedOptions, optionsDynamicPrices } = useSelector(
        state => state.setPreBookData
    );
    const dispatch = useDispatch();
    const lastRoute = last(routes);
    const deadlineDate = useRef(last(lastRoute.segments).arrival.time).current;

    const handleChangeDateOfBirth = () => {
        if (!moment(passenger?.date_of_birth, 'DD.MM.YYYY', true).isValid()) return;
        const minDate = moment(deadlineDate, 'DD.MM.YYYY').add(-80, 'years');
        const maxDate = moment(deadlineDate, 'DD.MM.YYYY').add(-18, 'years');
        const inspect = moment(passenger.date_of_birth, 'DD.MM.YYYY');
        if (minDate.isAfter(inspect) || maxDate.isBefore(inspect)) {
            dispatch(
                setSelectedOptions(removeBimaOptionsFromPassenger(passengerIndex, options, selectedOptions))
            );
        }
    };

    const checkCanShowBimaOption = (option) => {
        if (!moment(passenger?.date_of_birth, 'DD.MM.YYYY', true).isValid() || !option.technicalName.includes("bima")) return true;
        const minDate = moment(deadlineDate, 'DD.MM.YYYY').add(-80, 'years');
        const maxDate = moment(deadlineDate, 'DD.MM.YYYY').add(-18, 'years');
        const inspect = moment(passenger.date_of_birth, 'DD.MM.YYYY');
        if (minDate.isAfter(inspect) || maxDate.isBefore(inspect)) return false;
        return true;
    };

    useEffect(() => {
        if (passenger?.date_of_birth) handleChangeDateOfBirth();
    }, [passenger]);

    const checkIsRoundTrip = () => {
        if (routes.length !== 2) return false;
        const [departureCity] = getRouteCity(routes[0], included, language);
        const [_, arrivalCity] = getRouteCity(routes[1], included, language);
        return departureCity === arrivalCity;
    };

    const handleClickOption = (option, routeIndex) => {
        if (!option.selected) {
            if (selectedOptions.find(item => item.id === `${option.id}${[passengerIndex]}${routeIndex}`)) {
                dispatch(
                    setSelectedOptions(
                        selectedOptions.filter(item => item.id !== `${option.id}${[passengerIndex]}${routeIndex}`)
                    )
                );
            } else {
                dispatch(
                    setSelectedOptions([
                        ...selectedOptions,
                        {
                            id: `${option.id}${[passengerIndex]}${routeIndex}`,
                            optionId: option.id,
                            passengerIndex,
                            routeIndex,
                            isDynamicPrice: option.is_dynamic_price,
                            dynamicPrice: optionsDynamicPrices.find(item => item.id === `${passengerIndex}${routeIndex}${option.id}`)?.price || 0,
                        },
                    ])
                );
            }
        }
    };

    const renderPrice = (option, route) => {
        const isDynamicPrice = option.isDynamicPrice;
        const price = isDynamicPrice
            ? optionsDynamicPrices.find(
                  item =>
                      item.optionId === option.id &&
                      item.passengerIndex === passengerIndex &&
                      item.routeIndex === route.index
              )?.price
            : option.price;

        if (price) {
            return (
                <>
                    {price}{option.paymentFor === 'segments' ? ` (x${route.segments.length})` : ''} <span>{option.currency}</span>
                </>
            );
        }

        return '';
    };

    const renderOptions = (route, index) => {
        const [departureCity, arrivalCity] = getRouteCity(route, included, language);
        let isRoundTrip = checkIsRoundTrip();

        const showedOptions = [];
        passengerOptions.forEach((option) => {
          if (
              !checkCanShowBimaOption(option) ||
              option.passengerIndex !== passengerIndex ||
              (option.passengerType !== 'all' && option.passengerType !== passengerType) ||
              (isRoundTrip && index === 1 && option.technicalName === 'bima-ITP1') ||
              (!isRoundTrip && index > 0 && option.technicalName === 'bima-ITP1')
          ) {
              return null;
          }

          showedOptions.push(option);
        });

        return (
            <RouteBlock key={index}>
                <RouteTitle>
                    {departureCity} - {arrivalCity}
                </RouteTitle>

                {showedOptions.length ? (
                  <OptionsBlock>
                    {
                      showedOptions.map((option, optionIndex) => (
                          <OptionItem key={optionIndex} onClick={() => handleClickOption(option, route.index)}>
                              <OptionItemName>{option.names[language]}</OptionItemName>
                              <OptionItemCategory>{option.optionType.names[language]}</OptionItemCategory>
                              <OptionItemPrice>{renderPrice(option, route)}</OptionItemPrice>
                              <CheckboxItem
                                  isActive={selectedOptions.find(
                                      item => item.id === `${option.id}${passengerIndex}${route.index}`
                                  )}
                              />
                          </OptionItem>
                      ))
                    }
                  </OptionsBlock>
                ) : (
                  <NotFoundOptionBlock>
                    {t.additional_options_not_found}
                  </NotFoundOptionBlock>
                )}
            </RouteBlock>
        );
    };

    return (
        <Wrapper>
            <Title>{t.additional_options}</Title>
            {routes.map((route, index) => renderOptions(route, index))}
        </Wrapper>
    );
};

export default Options;
