import styled from 'styled-components';

export const Wrapper = styled('div')(({theme, active, topMargin, position, padding, width, boxShadow}) => ({
    display: active ? 'block' : 'none',
    position: 'absolute',
    top: `calc(100% + ${topMargin})`,
    right: position === 'right' ? -20 : 'auto',
    left: position === 'left' ? 0 : 'auto',
    backgroundColor: theme.palette.blocksBackground,
    padding: padding,
    borderRadius: theme.spacing(1.5),
    width: width,
    boxShadow: boxShadow,
    zIndex: 10,
}));
