import styled from 'styled-components';

export const CitizenshipBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(8, 0, 8, 16),
    fontSize: 14,
    position: 'relative',
    cursor: 'pointer',

    '&:hover': {
        background: '#fbe9e7',
    },

    '&:active': {
        background: '#ffccbc',
    },
}));

export const CitizenshipItem = styled('div')(({ theme }) => ({
    width: 180,
}));

export const CitizenshipItemCode = styled('div')(({ flag }) => ({
    width: 24,
    height: 20,
    position: 'absolute',
    top: 7,
    right: 10,
    backgroundImage: `url(${flag})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '20px 24px',
}));

export const FormControlCitizenship = styled('div')(() => ({
    position: 'relative',
}));

export const LabelCitizenship = styled('div')(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.departureCountryAndAirCompany,
    position: 'absolute',
    top: 8,
    left: 16,
}));


export const CitizenshipInputCode = styled('div')(({ flag }) => ({
    width: 24,
    height: 24,
    position: 'absolute',
    bottom: 10,
    right: 10,
    backgroundImage: `url(${flag})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '24px 24px',
}));