import React from "react";

import { Section, ImageBlock } from "./components";

const TopAndBottomSection = ({ data }) => {
	const { title, description, image } = data;

	return (
		<Section >
			<article>
				<h1>{title}</h1>
				<p>{description}</p>
			</article>
			<ImageBlock>
				<img src={image} alt="" />
			</ImageBlock>
		</Section>
	);
};

export default TopAndBottomSection;
