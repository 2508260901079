import React from 'react';

import FlightDetails from '../flightDetails';
import DesktopTariffsBlock from '../tariffsBlock/desktop';
import useLocalization from '../../../../contexts/localization/hook';

import Grid from '../../ui/grid';
import { ContainerGrid, FlightDetailsBlock, Separator, StyledDivider, TimeMessage } from './components';

const AdditionallyForm = props => {
    const {
        brandFares,
        route,
        showTariffs,
        showDetails,
        setShowRouteDetails,
        setShowTariffs,
        routeIndex,
        recId,
        brandRecId,
        getFareRules,
        brandName,
        recommendation,
        currency,
        included,
    } = props;
    const hasBrandedTariffs = recommendation.has_branded_tariffs;

    const { t } = useLocalization();

    return (
        <>
            <ContainerGrid container spacing={0}>
                <Grid item xs={12}>
                    <Separator />
                </Grid>
                <Grid>
                    <FlightDetailsBlock
                        showDetailsBlock={showDetails}
                        onClick={() => setShowRouteDetails(routeIndex)}
                    >
                        {t.flight_details}
                    </FlightDetailsBlock>
                    {showDetails && <StyledDivider />}
                </Grid>
                {hasBrandedTariffs && (
                    <Grid>
                        <FlightDetailsBlock
                            showDetailsBlock={showTariffs}
                            onClick={() => setShowTariffs(recId, routeIndex)}
                        >
                            {t.tariffs}
                        </FlightDetailsBlock>
                        {showTariffs && <StyledDivider />}
                    </Grid>
                )}
            </ContainerGrid>
            {showDetails && (
                <>
                    <FlightDetails
                        route={route}
                        recommendation={recommendation}
                        included={included}
                        routeIndex={routeIndex}
                    />
                    <TimeMessage>{t.local_time_message}</TimeMessage>
                </>
            )}
            {showTariffs && hasBrandedTariffs && (
                <DesktopTariffsBlock
                    recId={recId}
                    brandRecId={brandRecId}
                    brandFares={brandFares}
                    brandName={brandName}
                    getFareRules={getFareRules}
                    currency={currency}
                />
            )}
        </>
    );
};

export default AdditionallyForm;
