import styled from "styled-components";

export const Wrapper = styled("div")(({ theme }) => ({
    ...theme.mixins.flexCenterBetween,
    display: "none",
    gap: 12,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: "flex",
    },
}));

export const Button = styled('button')(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    height: 42,
    width: 42,
    border: `1px solid ${theme.palette.flyCash}`,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: theme.palette.transparent,
}));

export const CartIconBlock = styled("div")(({ theme }) => ({
    marginRight: "-16px"
}))
