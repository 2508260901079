import styled from 'styled-components';

export const Container = styled('div')(({theme, active}) => ({
    display: active ? 'flex' : 'none',
    flexDirection: 'column',
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: 0,
    width: 286,
    backgroundColor: theme.palette.inputBackground,
    color: theme.palette.mainText,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.25)',
    zIndex: 9,
    borderRadius: theme.spacing(1.5),

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 440,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const Item = styled('div')(({theme, isMain}) => ({
    ...theme.mixins.flexCenterSpaceBetween,
    ...(isMain ? theme.paddings(12, 16, 12, 12) :
        theme.paddings(12, 16, 12, 32)),
    cursor: 'pointer',
    fontSize: '0.875rem',

    '&:first-of-type': {
        borderTop: 'none',
        borderRadius: `${theme.spacing(1.25)} ${theme.spacing(1.25)} 0 0`,
    },

    '&:nth-last-of-type(1)': {
        borderBottom: 'none',
        borderRadius: `0 0 ${theme.spacing(1.25)} ${theme.spacing(1.25)}`,
    },

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.dropDownItem,
    },
}));

export const Name = styled('div')`
  font-weight: 600;
`;

export const Country = styled('span')(({theme}) => ({
    color: theme.palette.countryDropdown,
}));

export const Code = styled('div')(({}) => ({theme}) => ({
    textTransform: 'uppercase',
    lineHeight: 1,
    color: theme.palette.countryDropdown,
}));
