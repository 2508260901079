import { Button, Tab, Tabs } from "@material-ui/core";
import styled from "styled-components";

export const TabsComponent = styled(Tabs)`
  & .MuiTabs-indicator {
    display: none;
  }
`;

export const ShareBtnSection = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  paddingBottom: 16,
}));

export const TabContent = styled("div")(({ theme }) => ({
  ...theme.paddings(8, 16),
}));

export const BuyButton = styled(Button)(({ theme }) => ({
  padding: `12px !important`,
  backgroundColor: `${theme.palette.main} !important`,
  fontWeight: `600 !important`,
  fontSize: `18px !important`,
  color: `${theme.palette.searchTextForm} !important`,
  borderRadius: `12px !important`,

  "&:hover": {
    backgroundColor: `${theme.palette.modalBtnHover} !important`,
  },
}));

export const ActionsBlock = styled("div")(({ theme }) => ({
  ...theme.paddings(0, 16, 16),
}));

export const TransfersBetweenAirportsWarning = styled("div")(({ theme }) => ({
  ...theme.paddingsY(8, 0),
  color: theme.palette.transfersBetween,
}));

export const TariffsTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.mainText,
  fontSize: 14,

  "&.Mui-selected": {
    color: theme.palette.main,
  },
}));

export const RouteBlock = styled("div")(({ theme }) => ({
  ...theme.margins(16, 0, 32),
}));

export const DepartureBlock = styled("div")(() => ({
  textTransform: "uppercase",
  fontWeight: 500,
  fontSize: 14,
}));

export const RouteDuration = styled("div")(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: 12,
  color: theme.palette.languages,
}));

export const SegmentBlock = styled("div")(({ theme }) => ({
  ...theme.margins(15, 0),
  borderRadius: theme.spacing(1.5),
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.15)",
}));

export const SegmentHeader = styled("div")(({ theme }) => ({
  ...theme.paddings(8, 16),
  borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} 0 0`,
  display: "flex",
  justifyContent: "space-between",
  border: `1px solid ${theme.palette.main}`,
}));

export const SegmentHeaderTitle = styled("span")(() => ({
  textTransform: "uppercase",
  fontWeight: 500,
  fontSize: 14,
}));

export const SegmentHeaderDuration = styled("span")(() => ({
  fontSize: 14,
}));

export const SegmentBody = styled("div")(({ theme }) => ({
  ...theme.paddings(16, 16),
  overflow: "hidden",
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  borderLeft: `1px solid ${theme.palette.main}`,
  borderRight: `1px solid ${theme.palette.main}`,
}));

export const OperatingCompanyBlock = styled("div")(({ theme }) => ({
  ...theme.paddingsY(0, 3),
  fontSize: 12,
  color: theme.palette.languages,
}));

export const SegmentSupplierIconBlock = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

export const SegmentFooter = styled("div")(({ theme }) => ({
  ...theme.paddings(8, 16),
  borderRadius: `0 0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  display: "flex",
  justifyContent: "space-between",
  border: `1px solid ${theme.palette.main}`,
  borderTop: `1px solid ${theme.palette.enterAndRegistrationBorderBottom}`,
}));

export const SegmentFooterFlightClass = styled("div")(({ theme }) => ({
  fontSize: 12,
  display: "flex",
  alignItems: "center",
  color: theme.palette.mainText,
  fontWeight: 600,
}));

export const SegmentFooterFlightAdditionalInfo = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  lineHeight: 1,
  alignItems: "flex-end",
}));

export const SegmentFooterFlightAdditionalInfoItems = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const SegmentFooterFreeSits = styled("span")(({ theme }) => ({
  ...theme.paddingsX(8, 0),
  fontSize: 10,
  color: theme.palette.mainText,
}));

export const SegmentFooterFlightAdditionalInfoItem = styled("div")(
  ({ theme }) => ({
    ...theme.paddingsY(4, 0),
    fontSize: 10,
    color: theme.palette.mainText,
  })
);

export const PriceBlock = styled("div")(({ theme }) => ({
  ...theme.margins(16, 16, 32),
}));

export const ValidatingSupplier = styled("div")(({ theme }) => ({
  ...theme.paddingsY(0, 8),
  display: "flex",
  justifyContent: "center",
}));

export const Price = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  fontSize: 32,
  fontWeight: 500,
}));

export const PriceForTicket = styled("span")(({ theme }) => ({
  ...theme.marginsX(0, 12),
  color: theme.palette.main,
}));

export const LowcostBlock = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const Lowcost = styled("div")(({ theme }) => ({
  ...theme.paddings(2, 15),
  display: "inline-block",
  color: theme.palette.searchTextForm,
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.lowCost,
  textAlign: "center",
}));

export const Charter = styled(Lowcost)(({ theme }) => ({
  ...theme.marginsY(5, 0),
}));

export const SegmentFlightInfoBlock = styled("div")(({ theme }) => ({
  ...theme.margins(16, 0, 0),
  width: "100%",
}));

export const SegmentFlightDateTime = styled("div")(({ theme }) => ({
  ...theme.paddingsX(0, 32),
}));

export const SegmentFlightDate = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.mainText,
}));

export const SegmentFlightTime = styled("div")(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: 26,
  color: theme.palette.mainText,
}));

export const SegmentFlightAirport = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const SegmentFlightInfoSubTitle = styled("div")(({ theme }) => ({
  ...theme.marginsX(0, 30),
  fontSize: 12,
  color: theme.palette.languages,
}));

export const SegmentFlightInfoTitle = styled("div")(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: 14,
  color: theme.palette.languages,
}));
