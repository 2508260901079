import styled from 'styled-components';

export const PassBlock = styled('div')(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.departureTimeResultPage,
}));

export const PassItem = styled('div')(({ theme }) => ({
    ...theme.paddingsY(0, 8),
}));

export const PassAmountTitle = styled('span')(({ theme }) => ({
    color: theme.palette.mainText,
    width: '50%',
}));

export const PassAmountValue = styled('span')(({ theme }) => ({
    width: '50%',
    textAlign: 'right',
    float: 'right',
    color: theme.palette.mainText,
}));