import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';

export const useStyles = makeStyles({
    searchFieldBlock: {
        borderRadius: '8px',
        boxShadow: '0 4px 40px 0 rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff',
        order: 2,
        position: 'sticky',
        top: '116px',

        '@media (max-width:960px)': {
            order: 1,
        },
    },
    searchFields: {
        padding: '16px',
    },
    searchSingleItem: {
        width: '100%',
    },
    itemBlock: {
        minHeight: '68px',
    },
    orderStatusSearchBlock: {
        minHeight: '76px',
    },
    test1: {
        width: '100%',
    },
    searchDoubleItem: {
        width: '50%',
    },
    dateFromBlock: {
        minHeight: '76px',
    },
    searchFieldInput: {
        height: '48px',
    },
    searchIcon: {
        paddingLeft: '8px',
        color: '#b9c1c5',
    },
    dateToBlock: {
        minHeight: '76px',
    },
});

export const SearchFieldBlock = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(1),
    boxShadow: '0 4px 40px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.inputBackground,
    order: 2,
    position: 'sticky',
    top: 116,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 1,
    },
}));

export const SearchFields = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16)
}));

export const SearchSingleItem = styled(Grid)(() => ({
    width: '100%',
}));

export const ItemBlock = styled('div')(() => ({
    minHeight: 68,
}));

export const OrderStatusSearchBlock = styled('div')(() => ({
    minHeight: 76,
}));

export const Test1 = styled(Grid)(() => ({
    width: '100%',
}));

export const SearchDoubleItem = styled(Grid)(() => ({
    width: '50%',
}));

export const DateFromBlock = styled('div')(() => ({
    minHeight: 76,
}));

export const DateToBlock = styled('div')(() => ({
    minHeight: 76,
}));

export const StyledButtonClear = styled(Button)(({theme}) => ({
    '&.MuiButton-root': {
        width: '100%',
        height: 46,
        borderRadius: theme.spacing(0.5),
        fontSize: 18,
        fontWeight: 500,
        backgroundColor: theme.palette.techStopBlockBg,
    },
    'label': {
        textTransform: 'none',
    }
}));

export const StyledButtonSearch = styled(Button)(({theme}) => ({
    '&.MuiButton-root': {
        width: '100%',
        height: 46,
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.main,
        fontSize: 18,
        fontWeight: 500,
        color: theme.palette.searchTextForm,

        '&:hover': {
            backgroundColor: theme.palette.buttonHover.subscribeBg,
        },
        '&:active': {
            backgroundColor: theme.palette.buttonHover.modalBtnHover,
        },
    },
    label: {
        textTransform: 'none',
    },
}));
