import React, { useEffect, useState } from "react";

import { ThemeProvider as StyledThemeProvider } from "styled-components";
import getTheme from "../../theme";

import { DARK_THEME, LIGHT_THEME } from "../../theme/constants";

import ThemeContext from "./context";

const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(LIGHT_THEME);

  const setMode = mode => {
    window.localStorage.setItem('theme', mode);
    setCurrentTheme(mode);
};

  const themeToggle = () => {
    currentTheme === LIGHT_THEME ? setMode(DARK_THEME) : setMode(LIGHT_THEME);
  };

  useEffect(() => {
      const localTheme = window.localStorage.getItem('theme');
      localTheme && setCurrentTheme(localTheme);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
        themeToggle,
      }}
    >
      <StyledThemeProvider theme={getTheme(currentTheme)}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  )
};

export default ThemeProvider;
