import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {
    Container,
    From,
    ReplaceRouteButton,
    ReplaceRouteButtonLeftIcon,
    ReplaceRouteButtonRightIcon,
    To,
    DateFromTo,
    PassengersAndClass,
    SubmitButton,
} from './components';
import AirportField from '../../fields/airport';
import DateFromToField from '../../fields/dateFromTo';
import PassengersAndClassField from '../../fields/passengersAndClass';
import { setRoutes, setFlightType, setCalendarPrices } from '../../../../../../store/searchParams/actions';
import { RT } from '../../../../../../containers/search/constants';
import { ReactComponent as ArrowLeftIcon } from '../../../../../../assets/icons/arrowLeftIcon.svg';
import useLocalization from '../../../../../../contexts/localization/hook';
import API from '../../../../../../lib/api';

const SimpleRoute = ({handleSearch}) => {
    const {t} = useLocalization();

    const { routes, notFilledFields } = useSelector(state => state.searchParams);
    const { currency } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();

    const getCalendarPrices = async (from, to) => {
        if (from && to) {
            API.calendarPrices({ from, to, currency }).then(response => {
                dispatch(setCalendarPrices(response));
            });
        }
    };

    const handleAirportFromSelect = value => {
        const currentRoutes = [...routes];
        currentRoutes[0].from = value;
        currentRoutes[1].to = value;
        dispatch(setRoutes(currentRoutes));

        getCalendarPrices(currentRoutes[0].from?.code, currentRoutes[0].to?.code);
    };

    const handleAirportToSelect = value => {
        const currentRoutes = [...routes];
        currentRoutes[0].to = value;
        currentRoutes[1].from = value;
        dispatch(setRoutes(currentRoutes));

        getCalendarPrices(currentRoutes[0].from?.code, currentRoutes[0].to?.code);
    };

    const handleDateFromToSelect = dates => {
        const currentRoutes = [...routes];
        currentRoutes[0].date_from = dates[0] !== null ? moment(dates[0]).format('YYYY-MM-DD') : null;
        if (dates[1] !== null) {
            currentRoutes[1].date_from = moment(dates[1]).format('YYYY-MM-DD');
            dispatch(setFlightType(RT));
        } else {
            currentRoutes[1].date_from = null;
        }
        dispatch(setRoutes(currentRoutes));
    };

    const handleReplaceRoute = () => {
        const currentRoutes = [...routes];
        const newRoutes = [];

        if (currentRoutes[0].from && currentRoutes[1].from) {
            newRoutes.push({
                from: currentRoutes[0].to,
                to: currentRoutes[0].from,
                date_from: currentRoutes[0].date_from,
            });

            newRoutes.push({
                from: currentRoutes[1].to,
                to: currentRoutes[1].from,
                date_from: currentRoutes[1].date_from,
            });

            dispatch(setRoutes(newRoutes));
        }

    };

    return (
        <Container>
            <From notFilled={notFilledFields.from}>
                <AirportField type="from" isSimple onSelect={handleAirportFromSelect} selected={routes[0].from}/>
            </From>

            <ReplaceRouteButton onClick={() => handleReplaceRoute()}>
                <ReplaceRouteButtonLeftIcon>
                    <ArrowLeftIcon/>
                </ReplaceRouteButtonLeftIcon>

                <ReplaceRouteButtonRightIcon>
                    <ArrowLeftIcon/>
                </ReplaceRouteButtonRightIcon>
            </ReplaceRouteButton>

            <To notFilled={notFilledFields.to}>
                <AirportField type="to" isSimple onSelect={handleAirportToSelect} selected={routes[0].to}/>
            </To>

            <DateFromTo notFilled={notFilledFields.date}>
                <DateFromToField
                    onSelect={handleDateFromToSelect}
                    selected={[routes[0].date_from, routes[1].date_from]}
                />
            </DateFromTo>

            <PassengersAndClass>
                <PassengersAndClassField dWidth pWidth/>
            </PassengersAndClass>

            <SubmitButton onClick={handleSearch}>{t.search}</SubmitButton>
        </Container>
    );
};

export default SimpleRoute;
