import { LIGHT_THEME, DARK_THEME } from './constants';
import { PassengerName } from '../components/order/bookData/components';
import { InputLabel } from '../pages/chargingBalancePage/components';

const basicColor = {
    white: '#fff',
    black: '#000',
    doveGray: '#6c6c6c',
    azureRadiance: '#0085ff',
    mineShaft: '#212121',
    grayChateau: '#9da8ae',
    riverBed: '#404b5a',
};

const palette = {
    [DARK_THEME]: {
        disabledBtn: '#797981',
        calendarDays: '#9ea9b7',
        calendarDaysBg: basicColor.doveGray,
        calendarWeeks: basicColor.white,
        calendarWeeksBg: basicColor.doveGray,
        calendarWeeksActive: basicColor.white,
        calendarWeeksNoActive: '#b6b7d7',
        calendarWeeksDisabled: '#6d666e',
        calendarWeeksBetween: '#eaf5ff',
        thereButton: '#515f77',
        white: '#4c4d4f',
        black: '#d2c9c9',
        balance: '#aeaeae',
        bodyBg: '#1e1e1e',
        countryDropdown: '#c4c0c0',
        dropDown: '#003b6e19',
        headerBg: '#0f1926',
        footerBg: '#1c222a',
        clientName: '#0c73fe',
        contactsBlock: '#53aafa',
        inputBackground: '#2e3035',
        menuHeaderBorderBottom: '#212125',
        line: '#dfe5ec',
        main: '#FF7863',
        mainText: '#fff',
        enterAndRegistrationBorderBottom: '#f1f1f1',
        searchTextForm: '#fff',
        subscribeText: '#aabacc',
        hoverToPopularDestinationText: '#fff',
        subscribeBg: '#161a1d',
        placeholderTextSearchOrder: '#c6c6d3',
        deactivatedIcons: '#b9c1c5',
        airplaneIconAndThereText: '#6F5BA6',
        departureTimeResultPage: '#212121',
        departureCountryAndAirCompany: '#9da8ae',
        contactInfo: '#fbb600',
        transparent: 'transparent',
        languages: '#9eb3c7',
        error: '#ff0000',
        borderColor: '#e0e0e0',
        destinationItemClick: '#768a9dcc',
        successResult: '#3eab50',
        errorResult: '#e81b1b',
        maximumRoutesText: '#959595',
        inputPlaceholder: '#d3d3d3',
        searchTitle: basicColor.white,
        searchBlockBorder: '#e4e7e8',
        specialTextBgColor: '#1B893C	',
        specialTextColor: basicColor.white,
        bonusCard: '#7e878b',
        transferContainer: '#f2f4f5',
        techStopBlockBorder: '#cfd8dc',
        techStopBlockBg: '#fafbfc',
        flightTimesBorder: '#d0d0d0',
        filtersBorderBottom: '#adadad',
        rangeSlider: '#f2f4f5',
        rail: '#b7c7d6',
        inputUiBorder: '#ffffff',
        pricesTableBoxShadow: 'rgba(255,178,99, 0.1)',
        switchToggle: '#8796A5',
        switchIconDark: '#cfd1dc',
        switchIconLight: '#e11d1d',
        switchCircle: '#0085ff',
        switchTrack: '#78a7d1',
        lowCost: '#4caf50',
        charter: '#67d457',
        transfersBetween: '#ff531d',
        disabledInputBg: '#5e6a71',
        disabledInputText: '#4ea4ce',
        inputPlaceholderText: '#9b9b9b',
        inputBorder: '#cfd8dc',
        checkIcon: '#3bb30a',
        menuItemBackgroundSelected: '#717171',
        menuItemBackgroundHover: '#6d6d6d',
        transactionTableTitleFirstRowBg: '#585858',
        transactionTableTitleSecondRowBg: '#383838',
        transactionTableTitle: '#47484c',
        transactionTableItem: '#fff',
        lineDivider: '#747474',
        timeCounterBg: '#404B5A',
        timeCounterColor: '#f2f4f5',
        passengerNameBookData: '#e8e8e8',
        passengerParamTitle: '#c0c0c0',
        dividerBooking: 'rgba(248,248,248,0.28)',
        inputLabel: '#c4c4c4',
        searchForm: '#A66420',
        blocksBackground: '#2e3035',
        popularDestinationsHover: '#565656',
        cashback: '#F7B407',
        flyCash: '#F1B101',
        ticketBackground: "#2e3035",
        ticketboxShadow: "inset -5px -5px 5px 0 rgb(0 0 0 / 64%), inset 5px 5px 5px 0 rgb(255 255 255 / 7%)",
        newButtonBg: "#fbb600",
        
        button: {
            deleteButton: '#c62828',
            clearForm: '#fff',
            buttonWhite: '#fff',
            buttonBorder: '#FFD4A9',
            newsBtn: '#cfd9d9',
            addRouteBg: '#52be00',
            addRoute: '#fff',
            clearRouteBg: '#e4e9f3',
            clearRoute: '#d60c0c',
            searchButtonBg: '#fbb600',
            searchButton: '#bdc6d3',
            checkboxClass: '#a0b0b9',
            deactivatedButtonPassengers: '#cdd4de',
            advancedRouteAndHistory: '#fff',
            searchHistory: '#fff',
            shadow: 'rgba(0,0,0, 0.3)'
        },
        buttonHover: {
            dropDownItem: '#6c6c6e',
            buttonBg: '#d0bfbf',
            subscribeBg: '#2a5dde',
            exitButton: '#d0bfbf33',
            headerButtons: 'rgba(53,102,152,0.78)',
            contactNumbers: '#f8ecf1',
            there: '#1662a7',
            destinationItem: '#6c6c6e',
            showMore: 'rgba(208, 191, 191, 0.2)',
            hoverToPopularDestinationText: '#fff',
            advancedRouteAndHistorySearch: '#ff8800',
            citizenshipBlockHover: '#fbe9e7',
            pricesTable: '#e0eef9',
            modalBtnHover: '#0779e1',
        },
        recommendation: {
            flightInfoBorder: basicColor.white,
            flightTime: basicColor.white,
            classInfo: basicColor.grayChateau,
            currency: basicColor.grayChateau,
        },
    },
    [LIGHT_THEME]: {
        disabledBtn: '',
        calendarDays: '#9ea9b7',
        calendarDaysBg: '#f6f9ff',
        calendarDaysPrice: '#989898',
        calendarDaysPriceActive: '#f2f2f2',
        calendarWeeks: '#fff',
        calendarWeeksBg: '#0c73fe',
        calendarWeeksActive: '#fff',
        calendarWeeksNoActive: '#000000',
        calendarWeeksDisabled: '#eaf5ff',
        calendarWeeksBetween: '#eaf5ff',
        thereButton: '#0085ff',
        white: '#FFF',
        black: '#000',
        blackOpacity: '#000000b2',
        balance: '#aeaeae',
        bodyBg: '#f8f8ff',
        countryDropdown: '#90a4ae',
        dropDown: '#003b6e19',
        headerBg: '#f6f9ff',
        footerBg: '#1c222a',
        clientName: '#0c73fe',
        contactsBlock: '#53aafa',
        inputBackground: '#ffffff',
        menuHeaderBorderBottom: '#eee',
        line: '#dfe5ec',
        main: '#4EA6BF',
        mainText: '#404b5a',
        enterAndRegistrationBorderBottom: '#d2d2d2',
        searchTextForm: '#fff',
        subscribeText: '#aabacc',
        subscribeBg: '#fff',
        placeholderTextSearchOrder: '#c3d6e8',
        deactivatedIcons: '#b9c1c5',
        airplaneIconAndThereText: '#6f5ba6',
        departureTimeResultPage: '#212121',
        departureCountryAndAirCompany: '#9da8ae',
        contactInfo: '#fbb600',
        transparent: 'transparent',
        languages: '#9eb3c7',
        error: '#ff0000',
        borderColor: '#e0e0e0',
        destinationItemClick: '#0085ffcc',
        successResult: '#3eab50',
        errorResult: '#e81b1b',
        maximumRoutesText: '#707070',
        inputPlaceholder: '#5f5f5f',
        searchTitle: basicColor.white,
        specialTextBgColor: '#1B893C',
        specialTextColor: basicColor.white,
        searchBlockBorder: '#e4e7e8',
        bonusCard: '#7e878b',
        transferContainer: '#f2f4f5',
        techStopBlockBorder: '#cfd8dc',
        techStopBlockBg: '#fafbfc',
        flightTimesBorder: '#0085ff',
        filtersBorderBottom: '#cfd8dc',
        rangeSlider: '#f2f4f5',
        rail: '#b7c7d6',
        inputUiBorder: '#ff7043',
        pricesTableBoxShadow: 'rgba(0, 59, 110, 0.1)',
        switchToggle: '#8796A5',
        switchIconDark: '#cfd1dc',
        switchIconLight: '#e11d1d',
        switchCircle: '#ffcc00',
        switchTrack: '#aab4be',
        lowCost: '#4caf50',
        charter: '#67d457',
        transfersBetween: '#ff531d',
        disabledInputBg: '#f2f4f5',
        disabledInputText: '#b9c1c5',
        inputPlaceholderText: '#a0a0a0',
        inputBorder: '#cfd8dc',
        checkIcon: '#3bb30a',
        menuItemBackgroundSelected: '#dedede',
        menuItemBackgroundHover: '#9ccffe',
        transactionTableTitleFirstRowBg: '#fff',
        transactionTableTitleSecondRowBg: '#f6f9ff',
        transactionTableTitle: '#fff',
        transactionTableItem: '#47484c',
        lineDivider: '#f2f4f5',
        timeCounterBg: '#f2f4f5',
        timeCounterColor: '#404B5A',
        passengerNameBookData: '#212121',
        passengerParamTitle: '#7e878b',
        dividerBooking: '#0000001E',
        inputLabel: '#c4c4c4',
        searchForm: '#19667C',
        blocksBackground: '#fff',
        popularDestinationsHover: '#0085ff',
        cashback: '#F7B407',
        flyCash: '#F1B101',
        ticketBackground: "#f6f9ff",
        ticketboxShadow: "inset -5px -5px 5px 0 rgb(0 0 0 / 9%), inset 5px 5px 5px 0 rgb(255 255 255)",
        newButtonBg: "#4EA6BF",
        button: {
            clearForm: '#fff',
            buttonWhite: '#fff',
            buttonBorder: '#96CFDF',
            newsBtn: '#0085ff',
            addRouteBg: '#4EA6BF',
            addRoute: '#fff',
            clearRouteBg: '#e4e9f3',
            clearRoute: '#d60c0c',
            searchButtonBg: '#4EA6BF',
            searchButton: '#fff',
            checkboxClass: '#a0b0b9',
            deactivatedButtonPassengers: '#cdd4de',
            deleteButton: '#c62828',
            advancedRouteAndHistory: '#19667C',
            searchHistory: '#0085ff',
            shadow: 'rgba(255,178,99, 0.3)'
        },
        buttonHover: {
            dropDownItem: '#eaf5ff',
            buttonBg: '#d0bfbf',
            subscribeBg: '#2a5dde',
            exitButton: '#d0bfbf33',
            headerButtons: '#ecf2f8',
            contactNumbers: '#f8ecf1',
            there: '#1662a7',
            destinationItem: '#0085ff',
            showMore: 'rgba(208, 191, 191, 0.2)',
            hoverToPopularDestinationText: '#fff',
            advancedRouteAndHistorySearch: '#ff8800',
            citizenshipBlockHover: '#fbe9e7',
            pricesTable: '#e0eef9',
            modalBtnHover: '#0779e1',
        },
        recommendation: {
            flightInfoBorder: basicColor.azureRadiance,
            flightTime: basicColor.mineShaft,
            classInfo: basicColor.mineShaft,
            currency: basicColor.riverBed,
        },
    },
};

export default palette;
