import React from 'react';

import { Wrapper } from './style';
import classNames from 'classnames';

const Grid = ({
  children,
  container = false,
  item = false,
  spacing = 0,
  alignItems = 'stretch',
  justifyContent = 'flex-start',
  xs = 0,
  sm = 0,
  md = 0,
  lg = 0,
  xl = 0,
  ...rest
}) => {
  const getClassNames = () => {
    const classes = {};

    for (let i = 1; i <= 12; i++) {
      classes[`gridItem-xs-${i}`] = xs === i;
    }

    for (let i = 1; i <= 12; i++) {
      classes[`gridItem-sm-${i}`] = sm === i;
    }

    for (let i = 1; i <= 12; i++) {
      classes[`gridItem-md-${i}`] = md === i;
    }

    for (let i = 1; i <= 12; i++) {
      classes[`gridItem-lg-${i}`] = lg === i;
    }

    for (let i = 1; i <= 12; i++) {
      classes[`gridItem-xl-${i}`] = xl === i;
    }

    for (let i = 1; i <= 12; i++) {
      classes[`gridContainer-spacing-${spacing}`] = container && spacing === i;
    }

    return classes;
  };

  return (
    <Wrapper
      $container={container}
      $item={item}
      $spacing={spacing}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $widths={{ xs, sm, md, lg, xl }}
      className={classNames({
        ...getClassNames(),
        'gridItem': item,
        'gridContainer': container,
      })}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default Grid;
