import styled from 'styled-components';

export const TariffsWrapper = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 0),
}));

export const TariffsText = styled('div')(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    ...theme.paddings(8, 0),
    color: theme.palette.mainText,
    fontSize: 16,
}));

export const TariffsBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 0, 0),
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20
}));

export const LoadingWrapper = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.paddings(16, 0),
    flexDirection: 'column',
    minHeight: 470,
}));
