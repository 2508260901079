import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import moment from 'moment';

import useLocalization from '../../../../../contexts/localization/hook';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import configs from '../../../../../config';

import Grid from '../../../ui/grid';
import Container from '../../../ui/container';

import {
    Header,
    Icon,
    NewsItem,
    ArrowIcons,
    IconButton,
    CardBlock,
    NewsImage,
    NewsInform,
    HeaderNews,
    DateNews,
    ButtonToOpenMore,
    LinkToAllNews,
    AllNewsBlock,
    ToTheNewsPage,
} from './components';

import arrowIcon from '../../../../../assets/icons/arrowRight.svg';
import lastNewsIcon from '../../../../../assets/icons/lastNews.svg';
import newsDefaultImage from '../../../../../images/news-default.jpg';
import LineDivider from '../lineDivider';

const LastNews = ({ news }) => {
    const clickToChangePage = useRef();
    const { t, language } = useLocalization();
    const { width } = useWindowDimensions();

    const [showedNews, setShowedNews] = useState([]);
    const authRoutes = configs.authRoutes;

    useEffect(() => {
        const newsArray = [];

        for (const item of news) {
            const result = item.contents.find(x => x.language === language);
            if (result) {
                newsArray.push({
                    id: item.id,
                    slug: item.slug,
                    newsDate: item.news_date,
                    image: item.photo,
                    contentId: result.id,
                    title: result.title,
                    body: result.body,
                    language,
                });
            }
        }

        setShowedNews(newsArray);
    }, [language, news]);

    const renderButton = () => {
        let check =
            (width >= 1300 && showedNews.length > 3) ||
            (width < 1300 && width > 960 && showedNews.length > 2) ||
            (width < 960 && showedNews.length > 1);

        if (check) {
            return (
                <ArrowIcons>
                    <IconButton src={arrowIcon} onClick={() => clickToChangePage.current.slickPrev()} isArrowLeft />
                    <IconButton src={arrowIcon} onClick={() => clickToChangePage.current.slickNext()} />
                </ArrowIcons>
            );
        }

        return null;
    };

    const renderNewsItems = item => {
        return (
            <NewsItem key={item.id}>
                <CardBlock>
                    <NewsImage
                        backgroundImage={
                            item.image ? `${configs.envConfigs.uploadHost}${item.image}` : newsDefaultImage
                        }
                    />

                    <NewsInform>
                        <DateNews>{moment(item.newsDate).format('DD.MM.YYYY')}</DateNews>

                        <HeaderNews title={item.title}>{item.title}</HeaderNews>

                        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 15px' }}>
                            <ButtonToOpenMore to={`/news/${item.slug}`}>{t.more_details}</ButtonToOpenMore>
                        </div>
                    </NewsInform>
                </CardBlock>
            </NewsItem>
        );
    };

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        infinite: showedNews.length > 3,
        arrows: false,
        speed: 300,
        draggable: true,
        useCSS: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1299,
                settings: {
                    slidesToShow: 2,
                    infinite: showedNews.length > 2,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: showedNews.length > 1,
                    variableWidth: false,
                },
            },
        ],
    };

    if (showedNews.length === 0) return null;

    return (
        <>
            <Container fixed>
                <Header>
                    <Icon src={lastNewsIcon} />
                    {t.last_news}
                </Header>

                {renderButton()}

                <Grid item xs={12}>
                    <Slider {...settings} ref={clickToChangePage}>
                        {showedNews.map(item => renderNewsItems(item))}
                    </Slider>
                </Grid>

                <AllNewsBlock>
                    <ToTheNewsPage>
                        <LinkToAllNews to={authRoutes.newsPage}>{t.all_news}</LinkToAllNews>
                    </ToTheNewsPage>
                </AllNewsBlock>
            </Container>

            <LineDivider />
        </>
    );
};

export default LastNews;
