import React, { Fragment } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import _ from 'lodash';
import API from '../../../lib/api';
import { checkForMIMEType } from '../../../lib/pdfConvert';
import {
    checkAllowedMK,
    checkInProgressStatus,
    getAge,
    getAgeMonths,
    getAgeString,
    getDocumentType,
    getMonthsString,
} from '../../result/common';
import { CERTIFICATE_ISSUED_CODE } from '../../../containers/order/constants';
import { normalizePrice } from '../../../lib/normalize';
import useLocalization from '../../../contexts/localization/hook';
import { INS } from '../../../containers/search/constants';

import Grid from '../../design_v2/ui/grid';
import {
    BookDataBlock,
    BookDataTitle,
    BookLabel,
    BoughtOptions,
    Line,
    LineBlock,
    LocatorItem,
    PassengerBlock,
    PassengerName,
    PassengerParam,
    PassengerParamTitle,
    PriceIcon,
    RouteInformation,
    RouteInformationText,
    TotalPriceText,
} from './components';

export default function BookData(props) {
    const { language, t } = useLocalization();
    const {
        bookingNumber,
        payerEmail,
        payerPhone,
        passengers,
        bookStatus,
        orderId,
        sessionId,
        currency,
        totalPrice,
        airlineBookingNumber,
        options,
    } = props;

    if (!payerPhone || !payerEmail) {
        window.location.reload();
    }

    const isInProgress = checkInProgressStatus(bookStatus);

    const clearedAirlineBookingNumber = clearAirlineBookingNumber(airlineBookingNumber);

    return (
        <div>
            <BookDataBlock container>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <BookLabel item xs={12} lg={3}>
                            {t.book}
                        </BookLabel>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9} sm={8}>
                                            <BookDataTitle>{t.pnr}</BookDataTitle>
                                        </Grid>
                                        <Grid item xs={3} sm={4}>
                                            <LocatorItem>{bookingNumber}</LocatorItem>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9} sm={8}>
                                            <BookDataTitle>{t.online_registration_locator}</BookDataTitle>
                                        </Grid>
                                        <Grid item xs={3} sm={4}>
                                            <LocatorItem>
                                                {clearedAirlineBookingNumber || bookingNumber}
                                            </LocatorItem>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <BookingMkLink bookStatus={bookStatus} orderId={orderId} sessionId={sessionId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <LineBlock item xs={12}>
                    <Line/>
                </LineBlock>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <BookLabel item xs={12} md={4} lg={3}>
                            {t.passengers}
                        </BookLabel>
                        <Grid item xs={12} md={8} lg={9}>
                            <Passengers passengers={passengers} orderId={orderId} sessionId={sessionId} />
                        </Grid>
                    </Grid>
                </Grid>
                <LineBlock item xs={12}>
                    <Line />
                </LineBlock>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <BookLabel item xs={12} md={4} lg={3}>
                            {t.buyer}
                        </BookLabel>
                       <BookDataTitle item xs={12} md={8} lg={9}>
                            {payerEmail && <div>{payerEmail}</div>}
                            {payerPhone && <div>{payerPhone}</div>}
                        </BookDataTitle>
                    </Grid>
                </Grid>

                {options.length ? (
                    <>
                        <LineBlock item xs={12}>
                            <Line />
                        </LineBlock>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <BookLabel item xs={12} md={4} lg={3}>
                                    {t.additional_options}
                                </BookLabel>

                                <Grid item xs={12} md={8} lg={9}>
                                    <BoughtOptions>
                                        {options.map((option, index) => {
                                            const optionName = JSON.parse(option.option.names)[language];

                                            return (
                                                <div key={index}>
                                                    {optionName} - {option.total_price} - {option.passenger_surname}{' '}
                                                    {option.passenger_name}
                                                </div>
                                            );
                                        })}
                                    </BoughtOptions>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : null}

                {!isInProgress && (
                    <Fragment>
                        <LineBlock item xs={12}>
                            <Line />
                        </LineBlock>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <BookLabel item xs={12} md={4} lg={3}>
                                    {t.order_cost}
                                </BookLabel>
                                <Grid item xs={12} sm={8} lg={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TotalPriceText>
                                                {normalizePrice(totalPrice)} {currency}
                                                <PriceIcon>
                                                    <ErrorOutlineIcon fontSize="small" />
                                                </PriceIcon>
                                            </TotalPriceText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
            </BookDataBlock>
        </div>
    );
}

const clearAirlineBookingNumber = number => {
    if (number?.length > 2) {
        return number.split(',').join(', ');
    }

    return null;
};

const BookingMkLink = props => {
    const { bookStatus, orderId, sessionId } = props;

    const isAllowedMK = checkAllowedMK(bookStatus);

    if (isAllowedMK) {
        return (
            <Grid item xs={12}>
                <RouteInformation>
                    <MkLink bookStatus={bookStatus} orderId={orderId} sessionId={sessionId} />
                </RouteInformation>
            </Grid>
        );
    }

    return null;
};

const MkLink = props => {

    const { t } = useLocalization();
    const { bookStatus, orderId, sessionId } = props;

    return (
        <Fragment>
            <GetAppIcon />
            <RouteInformationText onClick={() => downloadMk(orderId, sessionId)}>
                {bookStatus === CERTIFICATE_ISSUED_CODE ? t.download_charter_certificate : t.download_itinerary_receipt}
            </RouteInformationText>
        </Fragment>
    );
};

export const downloadMk = (orderId, sessionId, setSuccess = () => {}) => {
    const params = {
        type: 'mk',
        order_id: orderId,
        session_id: sessionId,
    };

    API.apiGetDocument(params).then(response => {
        const { pdf } = response;

        setSuccess();
        checkForMIMEType(pdf, 'pdf');
    });
};

const PassengersNamesTickets = props => {

    const { passenger } = props;
    const { name, middle_name, surname } = passenger;

    return (
        <PassengerName>
            {name} {middle_name} {surname}
        </PassengerName>
    );
};

const Passengers = props => {

    const { t } = useLocalization();

    const { passengers, orderId, sessionId } = props;

    return _.map(passengers, (passenger, index) => {
        const age = getAge(passenger.date_of_birth);

        let ageString;

        if (age > 0) {
            ageString = getAgeString(age, t);
        } else {
            const age = getAgeMonths(passenger.date_of_birth);

            ageString = getMonthsString(age, t);
        }

        return (
            <PassengerBlock key={index}>
                <PassengersNamesTickets passenger={passenger} orderId={orderId} sessionId={sessionId} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container>
                            <PassengerParamTitle item xs={6} sm={4} lg={3}>
                                {t.document}
                            </PassengerParamTitle>
                            <PassengerParam item xs={6} sm={8} lg={9}>
                                {getDocumentType(passenger.document_type, t)}, {passenger.document_number}, {t.before}{' '}
                                {formatDates(passenger.expiration_date)}
                            </PassengerParam>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <PassengerParamTitle item xs={6} sm={4} lg={3}>
                                {t.citizenship}
                            </PassengerParamTitle>
                            <PassengerParam item xs={6} sm={8} lg={9}>
                                {passenger.citizenship}
                            </PassengerParam>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <PassengerParamTitle item xs={6} sm={4} lg={3}>
                                {t.age}
                            </PassengerParamTitle>
                            <PassengerParam item xs={6} sm={8} lg={9}>
                                {ageString}, {getAgeType(passenger.type, t)}
                            </PassengerParam>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <PassengerParamTitle item xs={6} sm={4} lg={3}>
                                {t.date_of_birth}
                            </PassengerParamTitle>
                            <PassengerParam item xs={6} sm={8} lg={9}>
                                {formatDates(passenger.date_of_birth)}
                            </PassengerParam>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <PassengerParamTitle item xs={6} sm={4} lg={3}>
                                {t.gender}
                            </PassengerParamTitle>
                            <PassengerParam item xs={6} sm={8} lg={9}>
                                {getGender(passenger.gender, t)}
                            </PassengerParam>
                        </Grid>
                    </Grid>
                </Grid>
            </PassengerBlock>
        );
    });
};

const formatDates = data => {
    const dataArray = data.split('-');

    return dataArray[2] + '-' + dataArray[1] + '-' + dataArray[0];
};

const getGender = (type, t) => {
    const GENDER_ITEMS = [
        { value: 'male', label: t.male },
        { value: 'female', label: t.female },
    ];

    const genderString = _.find(GENDER_ITEMS, { value: type });

    return genderString.label;
};

const getAgeType = (type, t) => {
    return type === INS ? `${t[`${type}_main_label`]} (${t.inf_with_seat_second_label})` : t[`${type}_main_label`];
};
