import styled from 'styled-components';

export const Items = styled('div')(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    flexDirection: 'column',
    textAlign: 'center',
}));

export const Item = styled('div')(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    justifyContent: 'space-between',
    color: theme.palette.black,

    '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.buttonHover.headerButtons,
        borderRadius: 8,
    }
}));

export const PhoneNumber = styled('a')(({theme}) => ({
    textDecoration: 'none',
    color: theme.palette.main,
    lineHeight: 1,
    fontWeight: 600,
    padding: '10px 15px',
}));
