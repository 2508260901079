import React from "react";

import useLocalization from "../../../../../contexts/localization/hook";

import Container from "../../../ui/container";

import DestinationsIcon from "../../../../../assets/icons/directions-icon.svg";
import PopularDestinations from "./popularDestinations";

import { Header } from "./components";
import TitleUI from "../../../ui/titleUI";
import directions from "../../../../../assets/icons/iconsForTitle/directions.svg"

const AdsAndFlights = ({ data }) => {
	const { t } = useLocalization();

	const { destinations, cities, airports, airLines } = data || {};

	if (!data?.destinations.length) return null;

	const onlyTashkentDestinations = destinations.find(destination => destination.from === "TAS");

	return (
		<>
			<Container fixed>
				<TitleUI as="h2" textTransform="uppercase" position="center" icon={directions}>{t.popular_destinations}</TitleUI>
				<PopularDestinations
					key={onlyTashkentDestinations.from}
					destination={onlyTashkentDestinations}
					cities={cities}
					airports={airports}
					airlines={airLines}
				/>
			</Container>
		</>
	);
};

export default AdsAndFlights;
