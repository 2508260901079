import styled from 'styled-components';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';


export const Wrapper = styled('div')(({ theme, borderBottom }) => ({
    ...theme.mixins.flexDirectionColumn,
    borderBottom: borderBottom ? `1px solid ${theme.palette.filtersBorderBottom}` : 'none',
    color: theme.palette.mainText,
}));

export const Header = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterSpaceBetween,
    ...theme.paddings(8, 0),
    cursor: 'pointer',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddings(20, 0),
    },
}));

export const Title = styled('div')(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.mainText,
}));

export const IconBlock = styled('div')(({ theme }) => ({
    transform: 'rotate(0deg)',

    '&.active': {
        transform: 'rotate(-90deg)',
    },
}));

export const CaretIcon = styled(ArrowLeftIcon)(({ theme }) => ({
    display: 'block',
    height: 14,
    color: theme.palette.mainText
}));

export const Body = styled('div')(({ theme }) => ({
    height: 0,
    overflow: 'hidden',

    '&.active': {
        height: 'auto',
        overflow: 'initial',
        paddingBottom: theme.spacing(1),
    },
}));
