import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        maxHeight: '100%',
    },
}));

export const WeekNamesBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    backgroundColor: theme.palette.calendarDaysBg,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
}));

export const WeekNames = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(0.5),

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        width: '100%',
    },
}));

export const WeekName = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    textTransform: 'uppercase',
    lineHeight: 1,
    width: 78,
    height: 36,
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: 'auto',
    },
}));

export const Months = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 320,
    overflowY: 'auto',
    '-msOverflowStyle': 'none',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
        display: 'none',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        maxHeight: 'none',
    },
}));

export const MonthBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5),

    '&:nth-of-type(1)': {
        marginTop: theme.spacing(2.5),
    },

    '&:nth-last-of-type(1)': {
        marginBottom: theme.spacing(1.25),
    },
}));

export const Month = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: '100%',
    },
}));

export const MonthTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(1),
    color: theme.palette.mainText,
}));

export const MonthWeeks = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
}));

export const WeekBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(0.5),

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'grid',
        gridTemplateColumns: ' repeat(7, 1fr)',
    },
}));

export const WeekdayButton = styled('div')(({ theme, active, between, disabled }) => ({
    ...theme.mixins.flexCenterCenter,
    flexDirection: 'column',
    width: 78,
    height: 44,
    fontSize: '0.8rem',
    fontWeight: 600,
    borderRadius: theme.spacing(0.375),
    gap: theme.spacing(0.25),
    backgroundColor: active ? theme.palette.calendarWeeksBg : between ? theme.palette.calendarWeeksBetween : theme.palette.transparent,
    color: active ? theme.palette.calendarWeeksActive : theme.palette.calendarWeeksNoActive,
    opacity: disabled ? 0.3 : 1,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: '100%',
        height: 44,
        margin: 0,
    },

    '&:hover': {
        backgroundColor: active ? theme.palette.calendarWeeksBg : disabled ? theme.palette.transparent : theme.palette.calendarWeeksDisabled,
        border: disabled ? 'none' : `1px solid ${theme.palette.calendarWeeksBg}`,
        cursor: disabled ? 'default' : 'pointer',
    },
}));

export const DayPrice = styled('div')(({ theme, active }) => ({
    fontSize: theme.spacing(1.2),
    fontWeight: 600,
    padding: theme.paddings(2, 4),
    // backgroundColor: active ? theme.palette.calendarWeeksActive : theme.palette.calendarWeeksBg,
    // borderRadius: theme.spacing(0.5),
    color: active ? theme.palette.calendarDaysPriceActive : theme.palette.calendarDaysPrice,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        padding: theme.paddings(1, 2),
        borderRadius: theme.spacing(0.25),
        fontSize: 8,
    },
}));
