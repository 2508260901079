import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Row = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterSpaceBetween,
    ...theme.paddings(6, 0),
    width: '100%',

    '&:hover': {
        cursor: 'pointer',
        color: theme.palette.clientName,
    },
}));

export const Checkbox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.button.clearRouteBg,
    width: 15,
    height: 15,
    position: 'relative',
    border: `1px solid ${theme.palette.button.checkboxClass}`,
    borderRadius: '50%',
    transition: 'border-color 0.26s ease',

    '&:after': {
        content: '',
        width: 8,
        height: 8,
        position: 'absolute',
        top: '50%',
        left: '50%',
        background: theme.palette.transparent,
        transform: 'translate(-50%, -50%)',
        transition: 'background-color 0.26s ease',
    },

    '&.active': {
        border: `${theme.spacing(0.375)} solid ${theme.palette.clientName}`,
    },
}));

export const Text = styled('div')(({ theme }) => ({
    ...theme.marginsX(8, 0),
    color: 'inherit',
    fontWeight: 600,
    fontSize: '0.9rem',
    lineHeight: 1.5,
    transition: 'color 0.26s ease',
    userSelect: 'none',

    '&.active': {
        color: theme.palette.clientName,
    },
}));

export const TextPlain = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 12),
    fontSize: '1rem',
    fontWeight: 600,
    width: '100%',
    color: theme.palette.main,
    textAlign: 'center',
}));
