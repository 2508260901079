import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';

import API from '../../../../../../lib/api';
import configs from '../../../../../../config';
import useLocalization from '../../../../../../contexts/localization/hook';

import { Input, IataCode } from './components';
import Dropdown from '../../dropdown';

const Airport = ({ type, isSimple, selected, onSelect, index = 0 }) => {
    const { t, language } = useLocalization();

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);
    const [items, setItems] = useState(type === 'from' ? configs.defaultFromCities : configs.defaultToCities);

    useEffect(() => {
        if (value.length === 0) {
            setItems(type === 'from' ? configs.defaultFromCities : configs.defaultToCities);
        }
    }, [value, type]);

    const getCities = useRef({
        func: _.debounce(value => {
            setLoading(true);

            API.getCities(value).then(cities => {
                setItems(cities);
                setLoading(false);
            });
        }, 300),
    });

    const handleChangeFocus = newFocusValue => {
        setIsFocus(newFocusValue);

        if (!newFocusValue && value.length > 0 && !selected) {
            onSelect(items[0], index);
        }
    };

    const handleSelect = city => {
        handleChangeFocus(false);
        onSelect(city, index);
    };

    const initialData = useRef({ selected, onSelect, index, handleSelect });

    useEffect(() => {
        if (type === 'from' && !initialData.current.selected && (!initialData.current.index || initialData.current.index === 0)) {
            API.getMyLocation().then(location => {
                if (location?.city) {
                    API.getCities(location.city).then(cities => {
                        if (cities.length > 0) initialData.current.handleSelect(cities[0]);
                    });
                }
            });
        }
    }, [initialData, type]);

    const handleChangeValue = newValue => {
        setValue(newValue);

        if (value.length === 0 && !selected) {
            setItems(type === 'from' ? configs.defaultFromCities : configs.defaultToCities);
        }
    };

    useEffect(() => {
        if (selected) {
            setValue(selected.value[language]);
        } else {
            setValue('');
            setItems(type === 'from' ? configs.defaultFromCities : configs.defaultToCities);
        }
    }, [selected, language, type]);

    const handleFocus = (e) => {
        e.target.select();
        if (!selected) {
            handleChangeFocus(true);
        }
    };

    const handleChange = e => {
        handleChangeValue(e.target.value);
        handleChangeFocus(true);

        if (e.target.value.length > 1) {
            getCities.current.func(e.target.value);
        }
    };

    const handleClose = () => {
        handleChangeFocus(false);

        if (selected) {
            handleChangeValue(selected.value[language]);
        }
    };

    const renderCodeOrLoading = () => {
        if (loading) return <CircularProgress size={16} style={{ color: '#fff' }} />;
        if (selected) return selected.code;
    };

    const handleClick = e => {
        handleChangeValue(e.target.value);
        handleChangeFocus(true);

        if (e.target.value.length > 1) {
            getCities.current.func(e.target.value);
        }
    };

    return (
        <>
            <Input
                type='text'
                placeholder={type === 'from' ? t.route_from : t.route_to}
                onFocus={(e) => handleFocus(e)}
                onChange={e => handleChange(e)}
                onClick={(e) => handleClick(e)}
                value={value}
                isSimple={isSimple}
                autoComplete='off'
            />
            <IataCode active>{renderCodeOrLoading()}</IataCode>
            {isFocus && <Dropdown isActive={isFocus} items={items} onSelect={handleSelect} handleClose={handleClose} />}
        </>
    );
};

export default Airport;
