import React from 'react';
import { OrdersBlock, OrdersBlockTitle } from './styles';
import { FormattedForOrdersGroup } from '../../../../components/result/common';
import OrderContainer from '../orderContainer/index';
import useLocalization from '../../../../contexts/localization/hook';

const OrdersContainer = props => {
    const { language } = useLocalization();
    const {
        item,
        setOrderDate,
        setRedirect,
        orderCancel,
        allowBalancePayment,
        setAlert,
        setOpenAlert,
        orderRefund,
        partnerCode,
        setShowActionButtons,
    } = props;
    const { orders, sortDate } = item;

    const formattedSortDate = FormattedForOrdersGroup(sortDate, language);

    return (
        <OrdersBlock>
            <OrdersBlockTitle>{formattedSortDate}</OrdersBlockTitle>
            {orders.map((order, index) => (
                <OrderContainer
                    order={order}
                    key={index}
                    setOrderDate={setOrderDate}
                    setRedirect={setRedirect}
                    orderCancel={orderCancel}
                    orderRefund={orderRefund}
                    partnerCode={partnerCode}
                    setAlert={setAlert}
                    setOpenAlert={setOpenAlert}
                    setShowActionButtons={setShowActionButtons}
                    allowBalancePayment={allowBalancePayment}
                />
            ))}
        </OrdersBlock>
    );
};

export default OrdersContainer;
