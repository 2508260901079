import styled from 'styled-components';

export const Component = styled('div')(({theme}) => ({
    marginRight: 16,
    position: 'relative',
}));

export const Button = styled("button")
(({theme, notAlign, notJustify, langColor, notBorder, addPadding, notRadius, noHover}) => ({
    display: 'flex',
    alignItems: notAlign ? null : 'center',
    justifyContent: notJustify ? null : 'center',
    backgroundColor: 'transparent',
    color: langColor ? theme.palette.white : theme.palette.main,
    border: notBorder ? 'none' : `1px solid ${theme.palette.main}`,
    minWidth: 50,
    padding: addPadding ? '' : '8px 12px',
    borderRadius: notRadius ? '' : '100px',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    height: 35,

    '&:hover': {
        cursor: 'pointer',
        border: noHover ? null : `1px solid ${theme.palette.main}`,
        backgroundColor: theme.palette.buttonHover.exitButton,
    },

    '& > svg': {
        marginLeft: 4,
    }
}));

export const Dropdown = styled('div')(({theme, active}) => ({
    display: active ? 'block' : 'none',
    position: 'absolute',
    top: 'calc(100% + 10px)',
    right: 0,
    backgroundColor: theme.palette.white,
    padding: 8,
    borderRadius: 12,
    width: 200,
    boxShadow: '0 0 5px 1px rgba(0, 59, 110, 0.1)',
}));

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Item = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.black,
    padding: 10,

    '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.buttonHover.headerButtons,
        borderRadius: 8,
    }
}));

export const Text = styled('div')(({theme}) => ({
    color: theme.palette.languages,
    marginLeft: 8,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    transition: 'color 0.26s ease',
    userSelect: 'none',
    textTransform: 'uppercase',
}));

export const TextLanguage = styled('div')(({theme}) => ({
    color: theme.palette.mainText,
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 400,
    position: 'relative',
    float: 'left',
    width: '80%',
}));

export const CheckboxBlock = styled('div')(({theme}) => ({
    ...theme.mixins.flexCenterCenter
}));

export const Checkbox = styled('div')(({theme}) => ({
    width: 15,
    height: 15,
    position: 'relative',
    border: `3px solid ${theme.palette.button.clearRouteBg}`,
    borderRadius: '50%',
    transition: 'border-color 0.26s ease',
    backgroundColor: theme.palette.button.clearRouteBg,

    '&:after': {
        content: '',
        width: 8,
        height: 8,
        position: 'absolute',
        top: '50%',
        left: '50%',
        background: 'transparent',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'background-color 0.26s ease',
    },

    '&.active': {
        borderColor: theme.palette.clientName
    }
}));

export const Label = styled('label')(({theme}) => ({
    color: theme.palette.contactInfo,
    marginLeft: 4
}));
