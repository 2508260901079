import styled from 'styled-components';
import ExpandMore from '@material-ui/icons/ExpandMore';

export const Accordion = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.inputBackground,
    boxShadow: `0 0 10px 1px ${theme.palette.pricesTableBoxShadow}`,
    borderRadius: theme.spacing(0.5),
}));

export const AccordionHeader = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 16),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
}));

export const AccordionHeaderTitle = styled('div')(({ theme }) => ({
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.mainText,
    flexGrow: 1,
    textAlign: 'center',
}));

export const AccordionHeaderButton = styled('button')(({ theme }) => ({
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.transparent,
    border: 'none',

    '&:hover': {
        cursor: 'pointer',
    },
}));

export const AccordionHeaderButtonIcon = styled(ExpandMore)(({ theme }) => ({
    display: 'block !important',
    fontSize: '30px !important',
    color: `${theme.palette.mainText} !important`,

    '&.active': {
        transform: 'rotate(180deg)',
    },
}));

export const AccordionBody = styled('div')(({ theme, active }) => ({
    ...theme.paddings(0, 16),
    height: active ? 'auto' : 0,
    overflow: 'hidden',
}));

export const AccordionFooter = styled('div')(({ theme, active }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: active ? 'auto' : 0,
    overflow: 'hidden',
    padding: active ? '0 16px 16px' : 0,
}));

export const AccordionFooterButton = styled('button')(({ theme }) => ({
    ...theme.paddings(8, 8),
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.transparent,
    border: 'none',
    borderRadius: theme.spacing(0.5),
    textTransform: 'uppercase',
    color: theme.palette.main,

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.pricesTable,
        cursor: 'pointer',
    },
}));
