import React from 'react';

import useLocalization from '../../../contexts/localization/hook';

import { StyledButton } from '../../../styles/common/index';
import { SubmitBlock } from './components';

export const Submit = props => {
    const { t } = useLocalization();
    const { disabled } = props;

    return (
        <SubmitBlock>
            <StyledButton type="submit" widthSize="100%" disabled={!disabled}>
                {t.book_and_proceed_to_payment}
            </StyledButton>
        </SubmitBlock>
    );
};
