import styled from 'styled-components';
import theme from '../../../../../theme';

export const Block = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
    alignItems: 'center',
    maxWidth: 320,
}));

export const RoutesTextBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 16),
    textAlign: 'center',
}));

export const RouteText = styled('div')(({ theme }) => ({
    display: 'inline',
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.mainText,
}));

export const RouteIcon = styled('div')(({ theme, verticalAlign }) => ({
    ...theme.margins(0, 12),
    display: 'inline-block',
    verticalAlign: verticalAlign,
    lineHeight: 1,
}));

export const Text = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 4),
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.mainText,
    fontWeight: 600,
}));
