import React from 'react';
import { ADT, CHD, INS, INF } from '../../../containers/search/constants';
import { getPassTypeCountString } from '../../result/common';
import _ from 'lodash';
import { normalizePrice } from '../../../lib/normalize';
import useLocalization from '../../../contexts/localization/hook';

import { PassAmountTitle, PassAmountValue, PassBlock, PassItem } from './components';

const PassengersAmountBlock = (props) => {
    const { t } = useLocalization();
    const { passengers, currency } = props;

    const adtCount = _.filter(passengers, function (o) {
        return o.type === ADT;
    }).length;
    const chdCount = _.filter(passengers, function (o) {
        return o.type === CHD;
    }).length;
    const insCount = _.filter(passengers, function (o) {
        return o.type === INS;
    }).length;
    const infCount = _.filter(passengers, function (o) {
        return o.type === INF;
    }).length;

    let adtAmount = 0;
    let chdAmount = 0;
    let insAmount = 0;
    let infAmount = 0;

    if (adtCount > 0) {
        adtAmount = _.find(passengers, function (o) {
            return o.type === ADT;
        }).total_price;
    }

    if (chdCount > 0) {
        chdAmount = _.find(passengers, function (o) {
            return o.type === CHD;
        }).total_price;
    }

    if (insCount > 0) {
        insAmount = _.find(passengers, function (o) {
            return o.type === INS;
        }).total_price;
    }

    if (infCount > 0) {
        infAmount = _.find(passengers, function (o) {
            return o.type === INF;
        }).total_price;
    }

    if (_.isNull(adtAmount)) {
        return null;
    }

    return (
        <PassBlock>
            <PassItem>
                <PassAmountTitle component="span">
                    {adtCount > 1 && adtCount} {getPassTypeCountString(adtCount, ADT, t)}
                </PassAmountTitle>
                <PassAmountValue component="span">
                    {normalizePrice(adtAmount)} {currency} {adtCount > 1 && 'х' + adtCount}
                </PassAmountValue>
            </PassItem>

            {chdCount > 0 && (
                <PassItem>
                    <PassAmountTitle component="span">
                        {chdCount > 1 && chdCount} {getPassTypeCountString(chdCount, CHD, t)}
                    </PassAmountTitle>
                    <PassAmountValue component="span">
                        {normalizePrice(chdAmount)} {currency} {chdCount > 1 && 'х' + chdCount}
                    </PassAmountValue>
                </PassItem>
            )}

            {insCount > 0 && (
                <PassItem>
                    <PassAmountTitle component="span">
                        {insCount > 1 && insCount} {getPassTypeCountString(insCount, INS, t)} (
                        {t.inf_with_seat_second_label})
                    </PassAmountTitle>
                    <PassAmountValue component="span">
                        {normalizePrice(insAmount)} {currency} {insCount > 1 && 'х' + insCount}
                    </PassAmountValue>
                </PassItem>
            )}

            {infCount > 0 && (
                <PassItem>
                    <PassAmountTitle component="span">
                        {infCount > 1 && infCount} {getPassTypeCountString(infCount, INF, t)}
                    </PassAmountTitle>
                    <PassAmountValue component="span">
                        {normalizePrice(infAmount)} {currency} {infCount > 1 && 'х' + infCount}
                    </PassAmountValue>
                </PassItem>
            )}
        </PassBlock>
    );
}

export default PassengersAmountBlock;