import styled from 'styled-components';
import Grid from '../../../../components/design_v2/ui/grid';

export const OrderContainer = styled('div')(({ theme }) => ({
    ...theme.marginsY(16, 0),
    ...theme.spacing(1),
    backgroundColor: theme.palette.inputBackground,
}));

export const OrderBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16),
}));

export const OrderMainBlock = styled(Grid)(() => ({
    width: '100%',
    cursor: 'pointer',
}));

export const OderIdBlock = styled(Grid)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.mainText,
}));

export const TimestampBlock = styled(Grid)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.mainText,
}));

export const RoutesBlock = styled(Grid)(({ theme }) => ({
    fontSize: 18,
    color: theme.palette.mainText,
    fontWeight: 500,
}));

export const DeparturesBlock = styled(Grid)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.mainText,
}));

export const AmountContainer = styled(Grid)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        ...theme.paddings(8, 0),
    },
}));

export const AmountBlock = styled(Grid)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.mainText,
    textAlign: 'right',
}));

export const StatusBlock = styled(Grid)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.mainText,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
}));

export const OrderSubmitBlock = styled('div')(({ theme }) => ({
    ...theme.margins(10, 0, 0, 138),
    display: 'inline-block',
    textAlign: 'center',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        ...theme.margins(10, 0, 0, 66),
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.margins(10, 0, 0, 44),
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.margins(10, 0, 0, 0),
    },
}));

export const ActionButtonContainer = styled(Grid)(({ theme }) => ({
    ...theme.marginsY(24, 0),
    width: '100%',
    borderTop: `2px solid ${theme.palette.transferContainer}`,
}));

export const ActionButtonBlock = styled('div')(({ theme }) => ({
    ...theme.paddingsY(24, 0),
    flexWrap: 'wrap',
    display: 'flex',
}));

export const ActionSubmitBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(0, 8, 8, 0),

    '& button': {
        ...theme.paddings(6, 12),
        fontSize: 12,
        height: 'auto',
    },
}));