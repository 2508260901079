import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme }) => ({
    ...theme.flexAlignCenter,
}));

export const Icon = styled('span')(({ theme }) => ({
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
}));
