import {
    SET_PRE_BOOK_DATA,
    SET_COUNTRIES,
    SET_PASSENGERS_LIST,
    SET_PASSENGERS_LIST_FILTER,
    SET_OPTIONS,
    SET_SELECTED_OPTIONS,
    SET_PASSENGER_OPTIONS,
    SET_OPTIONS_DYNAMIC_PRICES,
} from './constants';

export const setPreBookData = data => dispatch =>
    dispatch({
        type: SET_PRE_BOOK_DATA,
        payload: data,
    });

export const setCountries = data => dispatch =>
    dispatch({
        type: SET_COUNTRIES,
        payload: data,
    });

export const setPassengersList = data => dispatch =>
    dispatch({
        type: SET_PASSENGERS_LIST,
        payload: data,
    });

export const setPassengersListFilter = data => dispatch =>
    dispatch({
        type: SET_PASSENGERS_LIST_FILTER,
        payload: data,
    });

export const setOptions = data => dispatch => dispatch({ type: SET_OPTIONS, payload: data });

export const setSelectedOptions = data => dispatch => dispatch({ type: SET_SELECTED_OPTIONS, payload: data });

export const setPassengerOptions = data => dispatch => dispatch({ type: SET_PASSENGER_OPTIONS, payload: data });

export const setOptionsDynamicPrices = data => dispatch => dispatch({ type: SET_OPTIONS_DYNAMIC_PRICES, payload: data });
