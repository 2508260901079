import styled from 'styled-components';
import {Settings} from '@material-ui/icons';

export const Component = styled('div')(({theme}) => ({
    marginRight: theme.spacing(2),
    position: 'relative'
}));

export const Button = styled('button')(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    height: 42,
    width: 42,
    border: `1px solid ${theme.palette.main}`,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: theme.palette.transparent,

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.exitButton,
    }
}));

export const SettingsIcon = styled(Settings)(({theme}) => ({
    width: 36,
    height: 36,
    color: theme.palette.main,
}));

export const Dropdown = styled('div')(({theme, active}) => ({
    display: active ? 'block' : 'none',
    position: 'absolute',
    top: 'calc(100% + 10px)',
    right: 0,
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1.5),
    width: 200,
    boxShadow: '0 0 5px 1px rgba(0, 59, 110, 0.1)',
}));

export const Items = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '16px',

    '&:nth-last-of-type(1)': {
        marginBottom: 0
    }
}));

export const GroupTitle = styled('div')(({theme}) => ({
    fontWeight: 'bold',
    color: theme.palette.main,
    padding: '0 8px',
    marginBottom: theme.spacing(0.5),
}));

export const Item = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.black,
    padding: theme.spacing(1.25),

    '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.buttonHover.headerButtons,
        borderRadius: theme.spacing(1),
    }
}));

export const Text = styled('div')(({theme}) => ({
    color: theme.palette.languages,
    marginLeft: theme.spacing(1),
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    transition: 'color 0.26s ease',
    userSelect: 'none',
    textTransform: 'uppercase',
}));

export const TextLanguage = styled('div')(({theme}) => ({
    color: theme.palette.mainText,
    fontSize: 14,
    fontWeight: 400,
    position: 'relative',
    float: 'left',
    width: '80%',
}));

export const CheckboxBlock = styled('div')(({theme}) => ({
    ...theme.mixins.flexCenterCenter
}));

export const Checkbox = styled('div')(({theme}) => ({
    width: 15,
    height: 15,
    position: 'relative',
    border: `3px solid ${theme.palette.button.clearRouteBg}`,
    borderRadius: '50%',
    transition: 'border-color 0.26s ease',
    backgroundColor: theme.palette.button.clearRouteBg,

    '&:after': {
        content: '',
        width: 8,
        height: 8,
        position: 'absolute',
        top: '50%',
        left: '50%',
        background: theme.palette.transparent,
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'background-color 0.26s ease',
    },

    '&.active': {
        borderColor: theme.palette.clientName,
    }
}));

export const Label = styled('label')(({theme}) => ({
    color: theme.palette.contactInfo,
    marginLeft: theme.spacing(0.5),
}));
