import styled from 'styled-components';

import active from '../../../assets/icons/newDesign/checkboxCheckedIcon.svg';

export const Wrapper = styled("div")(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
    gap: theme.spacing(2),
}));

export const Title = styled("div")(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.mainText,
}));

export const OptionsBlock = styled("div")(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },

    [theme.breakpoints.down(480)]: {
        gridTemplateColumns: '1fr',
    },
}));

export const NotFoundOptionBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  color: theme.palette.mainText,
  fontSize: 18,
  fontWeight: 'bold',
}));

export const RouteBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexDirectionColumn,
  gap: theme.spacing(1),
}));

export const RouteTitle = styled("div")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.mainText,
}));

export const OptionItem = styled("div")(({ theme }) => ({
  ...theme.mixins.flexDirectionColumn,
  position: 'relative',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  border: `1px solid #c3d6e8`,
  backgroundColor: theme.palette.inputBackground,
  gap: theme.spacing(0.5),

  '&.active': {
    border: `1px solid ${theme.palette.main}`,
  },

  '&:hover': {
    border: `1px solid ${theme.palette.main}`,
    boxShadow: `0 0 10px 1px rgb(0 59 110 / 10%)`,
    cursor: 'pointer',
  },
}));

export const OptionItemName = styled("div")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.mainText,
  maxWidth: 'calc(100% - 24px)',
}));

export const OptionItemCategory = styled("div")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.departureCountryAndAirCompany,
}));

export const OptionItemPrice = styled("div")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.mainText,

  '& > span': {
    color: theme.palette.main,
  },
}));

export const CheckboxItem = styled("div")(({ theme, isActive }) => ({
  position: 'absolute',
  right: 12,
  top: 12,
  height: 16,
  width: 16,
  backgroundColor: theme.palette.transparent,
  backgroundImage: `url(${isActive ? active : ''})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 18,
  backgroundPosition: 'center center',
  border: `${isActive ? 'none' : `2px solid ${theme.palette.main}`}`,
  borderRadius: theme.spacing(0.5),
  cursor: 'pointer',
  
  '&:focus': {
    outline: 'none',
  },
}));
