import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/closeIcon.svg';

export const ButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 16px;
`;

export const Button = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    color: "#fff",
    border: "none",
    backgroundColor: theme.palette.main,
    textTransform: "uppercase",
    borderRadius: 8,
    padding: "8px 20px",
    fontSize: "20px",
    lineHeight: 1,
    cursor: "pointer",
}));

export const Icon = styled(CloseIcon)`
    margin-right: 4px;
`;
