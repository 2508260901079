export const authRoutes = {
    result: '/result/:searchId',
    booking: '/booking',
    order: '/order/:orderId',
    staticPage: '/infoPage/:staticUrl',
    orders: '/orders',
    personalInfo: '/contractSettings/personalInfo',
    partnerSittings: '/contractSettings/partnerSettings',
    passengers: '/contractSettings/passengers',
    users: '/contractSettings/users',
    companies: '/contractSettings/companies',
    balanceMovements: '/finances/balanceMovements',
    main: '/',
    notFoundPage: '*',
    userProfile: '/userProfile',
    transactions: '/transactions',
    newsPage: '/newspages',
    singleNews: '/news/:slug',
    toursPage: '/tours/:slug',
    staticPages: '/staticInfoPage/:slug',
    rechargeBalancePage: '/rechargeBalance',
    partners: '/ourPartners/:slug',
    orderMk: '/order-mk',
};

export const nonAuthRoutes = {
    registration: '/registration',
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset/:token',
    activationUser: '/activationUser/:activationCode',
    popularDestinations: '/popularDestinations',
    contactsPage: '/contacts',
    theyTrustUs: '/theyTrustUs',
    usefulInfo: '/useful-info',
    unSubscribe: '/unsubscribe',
    flyCash: '/fly-cash',
    refundOrder: '/refund-order',
    successfulPayment: '/successfulPayment'
};
