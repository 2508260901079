const initialState = {
    firstName: '',
    lastName: '',
    login: '',
    email: '',
    phone: '',
    loaded: false,
};

export default initialState;
