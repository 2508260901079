import styled from 'styled-components';

export const AmountBlock = styled('div')(() => ({
    position: 'relative',
}));

export const HeaderMiddle = styled('h3')(({ theme }) => ({
    color: theme.palette.mainText,
}));

export const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        flexDirection: 'column',
    },
}));

export const MainHeader = styled('h1')(({ theme }) => ({
    ...theme.marginsY(0, 36),
    color: theme.palette.mainText,
    textAlign: 'center',
}));

export const Input = styled('input')(({ theme }) => ({
    width: 20,
    height: 20,
    position: 'absolute',
    right: 8,
    top: 8,
    backgroundColor: theme.palette.searchTextForm,
    color: theme.palette.mainText,
    fontSize: 22,

    '&:focus': {
        border: `1px solid ${theme.palette.main}`,
    },

    '::-webkitInnerSpinButton, ::-webkitOuterSpinButton': {
        '-webkitAppearance': 'none',
        margin: 0,
    },

    '::placeholder': {
        color: '#c4c4c4',
    },

    '&.active': {
        border: `1px solid ${theme.palette.main}`,
    },
}));

export const Button = styled('button')(({ theme }) => ({
    ...theme.margins(32, 0, 16),
    width: '100%',
    maxWidth: 200,
    height: 60,
    border: 'none',
    borderRadius: theme.spacing(1.5),
    boxShadow: `0 0 5px 1px rgba(0, 59, 110, 0.1)`,
    backgroundColor: theme.palette.main,
    fontSize: 26,
    color: theme.palette.searchTextForm,
    fontWeight: 600,
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.subscribeBg,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.margins(32, 'auto', 16),
        ...theme.paddings(12, 24),
        display: 'block',
        height: 'auto',
        fontSize: 18,
        width: 'auto',
        minWidth: 120,
    },
}));

export const FormBlock = styled.div``;

export const PaymentMethodsBlock = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
}));

export const ChargeOnlyTJS = styled('p')(({ theme }) => ({
    color: theme.palette.mainText,
    fontWeight: 300,
}));

export const InputBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.paddings(8, 8),
    flexDirection: 'column',
    position: 'relative',
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.borderColor}`,

    '&.active': {
        borderColor: theme.palette.main,
    },

    '&:hover': {
        cursor: 'pointer',
    },
}));

export const PaymentDescription = styled('p')(({ theme }) => ({
    ...theme.margins(8, 0, 0),
    textAlign: 'center',
    color: theme.palette.mainText,
}));

export const PaymentIMG = styled('img')(({ theme }) => ({
    maxHeight: 100,
    maxWidth: 160,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        maxHeight: 60,
    },
}));

export const CurrentBalance = styled('h2')(({ theme }) => ({
    color: theme.palette.mainText,
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        height: 15,
        marginTop: 5,
    },
}));

export const InputLabel = styled('label')(({ theme }) => ({
    position: 'absolute',
    top: 20,
    right: 12,
    color: theme.palette.inputLabel,
}));

export const ChargingBalanceBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 22),
    backgroundColor: theme.palette.inputBackground,
    borderRadius: theme.spacing(1.5),
    boxShadow: '0 0 5px 1px rgba(0, 59, 110, 0.1)',
}));

export const AllItems = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 16),
}));

export const Loading = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
}));

export const CurrentBalanceSpan = styled('span')(({ theme }) => ({
    fontSize: 18,
    color: theme.palette.mainText,
}));

export const CurrencySpan = styled('span')(({ theme }) => ({
    color: theme.palette.main,
}));
