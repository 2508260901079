import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        width: '1272px',
        margin: 'auto',
        position: 'relative',
    },
    rootContainer: {
        width: '895px',
    },
    routeContainer: {
        marginTop: '24px',
    },
    routeTitleFirst: {
        padding: '16px',
    },
    flightInfo: {
        order: 3,
        lineHeight: '1.6',
        textAlign: 'right',

        '@media screen and (max-width: 959px)': {
            order: 3,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    segmentTime: {
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#404B5A',

        '@media(max-width: 959px)': {
            order: 2,
            fontSize: '26px',
        },
    },
    showRulesBlock: {
        color: '#0085FF',
        fontSize: '14px',
        cursor: 'pointer',
    },
    priceFixedContainer: {
        position: 'absolute',
        left: '960px',
        top: '164px',
    },
    priceFeesBlock: {
        marginTop: '8px',
    },
    partnerFeesValue: {
        fontSize: '18px',
        fontWeight: '500',
        textAlign: 'right',
    },
    partnerFeesValueBook: {
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
    },
    partnerFeesTitleBook: {
        marginLeft: '8px',
        fontSize: '14px',
        textAlign: 'left',
    },
    partnerFeesTitle: {
        fontSize: '18px',
        textAlign: 'left',
    },
    priceIcon: {
        width: '16px',
        height: '16px',
        color: '#cfd8dc',
        cursor: 'pointer',
        position: 'relative',
        top: '3px',
    },
    passengersContainer: {
        flexGrow: 1,
    },
    partnerFeesTitleField: {
        fontSize: '24px',
        fontWeight: '500',
        paddingBottom: '8px',
    },
    passengerContainer: {
        paddingTop: '16px',
    },
    passengersTypeTitle: {
        fontSize: '19px',
        fontWeight: '500',
        color: '#404B5A',
        paddingBottom: '16px',
    },
    selectPassenger: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#ff7043',
        paddingBottom: '16px',
        paddingRight: '16px',
        cursor: 'pointer',
    },
    savePassengerBlock: {
        position: 'relative',
        paddingBottom: '16px',
    },
    passengersListButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    savePassengerItem: {
        textAlign: 'right',
        fontSize: '16px',
        fontWeight: '500',
    },
    savingProgress: {
        position: 'absolute',
        top: '9%',
        left: '28%',
        color: '#ffab91',
        cursor: 'text',
    },
    line: {
        width: '100%',
        height: '2px',
        backgroundColor: '#f2f4f5',
        margin: '16px 0',
    },
    agreementBookTextBlock: {
        paddingTop: '24px',
    },
    agreementBookText: {
        color: '#404B5A',
        fontSize: '16px',
    },
    linkToLoginForBooking: {
        color: '#404B5A',
        fontSize: '16px',
        textAlign: 'center',
    },
    modalRoot: {
        display: 'flex',
        height: '685px',
        overflow: 'auto !important',
    },
    descriptionRulesTitle: {
        fontSize: '24px',
        fontWeight: '500',
        backgroundColor: '#f2f4f5',
        paddingBottom: '24px',
    },
    rulesDescriptionContainer: {
        width: '38%',
        paddingTop: '23px',
        paddingLeft: '32px',
        paddingRight: '32px',
        backgroundColor: '#f2f4f5',
        fontSize: '16px',
    },
    rulesContainer: {
        width: '62%',
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    rulesTitle: {
        fontSize: '24px',
        fontWeight: '500',
        paddingBottom: '24px',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
    },
    sessionContainer: {
        paddingLeft: '8px',
        paddingBottom: '8px',
    },
    linkBackBlock: {
        marginBottom: '16px',
    },
    emptyInputBlock: {
        '@media screen and (max-width: 959px)': {
            display: 'none',
        },
    },
    segmentsContainer: {
        '@media screen and (max-width: 959px)': {
            padding: '16px',
        },
    },
    feesBlockButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    feesBlockTitle: {
        fontSize: '18px',
    },
    chevronDown: {
        transform: 'rotate(90deg)',
        transition: 'transform .3s ease',
    },
    chevronUp: {
        transform: 'rotate(-90deg)',
        transition: 'transform .3s ease',
    },
    showFeesBlock: {
        overflow: 'hidden',
        maxHeight: '100px',
        transition: 'max-height .3s linear',
    },
    hideFeesBlock: {
        overflow: 'hidden',
        maxHeight: 0,
        transition: 'max-height .3s linear',
    },
    terminal: {
        fontSize: '12px',
        color: '#9EB3C7',
    },
    contactBlock: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    formBlock: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    registerText: {
        fontSize: '16px',
        color: '#404B5A',
    },
    agreementTheBook: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '32px',
        padding: '16px 24px 0',
    },
}));
