import React from "react";
import _ from "lodash";
import moment from "moment";

import Grid from "../../../ui/grid";
import { useSelector } from "react-redux";
import useLocalization from "../../../../../contexts/localization/hook";
import {
  GridBlock,
  HeadBlockGrid,
  PriceBlock,
  TopOval,
  SupplierIcon,
  Lowcost,
  Charter,
  RouteBlockGrid,
  ItemInfoTitle,
  ItemInfoValue,
  Wrapper,
  SpecialText,
  CashbackBlock,
} from "./components";
import { normalizePrice } from "../../../../../lib/normalize";
import { getSupplierIcon } from "../../../../../lib/getImage";
import { getDurationTime } from "../../utils";
import MobileItemModal from "./modal";
import { ChantText, SpanChartText } from "../desktop/components";
import OpenTicketById from "../openTicketById/OpenTicketById";
// import CharterMessageBlock from "../charterMessageBlock";

const MobileRecommendation = ({
  recommendation,
  index,
  getBrandFares,
  getFareRules,
  onBookingClick,
  specialText,
  withoutPrice,
  modalShow,
  setModalShow,
}) => {
  const {
    validating_supplier,
    routes,
    total_price,
    brandRecId,
    lowcost,
    cashback,
  } = recommendation;
  const { brandFares } = useSelector((state) => state.resultData);
  const { currency } = useSelector((state) => state.appConfigs);
  const { t } = useLocalization();

  const firstRoute = _.first(routes);
  const departureSegment = _.first(firstRoute.segments);
  const { is_charter } = departureSegment;

  let actualTotalPrice = total_price;

  if (brandRecId) {
    const { rec_id } = recommendation;
    const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

    actualTotalPrice = actualBrandFare.total_price;
  }

  const renderTransfers = (segments) => {
    const transfersCount = segments.length - 1;

    if (transfersCount === 0) {
      return t.no_transfers;
    }

    return segments
      .slice(0, -1)
      .map((segment) => segment.arrival.airport)
      .join(", ");
  };

  const renderTransfersCountLabel = (segments) => {
    const transfersCount = segments.length - 1;

    if (transfersCount === 0) {
      return t.transfers;
    }

    if (transfersCount === 1) {
      return t.transfer_1;
    }

    if (transfersCount > 1 && transfersCount < 5) {
      return t.transfer_2_4;
    }

    return t.transfer_5;
  };

  const renderChangeAirportAndNightTransferText = () => {
    let isNightTransfer = false;
    let isChangeAirport = false;

    recommendation.routes.forEach((route) => {
      if (route.segments.length > 1) {
        for (let i = 0; i < route.segments.length; i++) {
          if (
            i > 0 &&
            route.segments[i].departure.airport !==
              route.segments[i - 1].arrival.airport
          ) {
            isChangeAirport = true;
          }

          const timeArray = route.segments[i].arrival.time.split(" ");

          const arrivalTime = moment(timeArray[1], "hh:mm"),
            beforeTime = moment("06:00", "hh:mm"),
            afterTime = moment("18:00", "hh:mm");

          if (
            (arrivalTime.isSameOrBefore(beforeTime) ||
              arrivalTime.isSameOrAfter(afterTime)) &&
            i < route.segments.length - 1
          ) {
            isNightTransfer = true;
          }
        }
      }
    });

    if (isNightTransfer || isChangeAirport) {
      return (
        <>
          {isChangeAirport ? (
            <ItemInfoValue right color="#0085ff" fontSize="12px">
              {t.change_airport}
            </ItemInfoValue>
          ) : null}
          {isNightTransfer ? (
            <ItemInfoValue right color="#0085ff" fontSize="12px">
              {t.night_flight}
            </ItemInfoValue>
          ) : null}
        </>
      );
    }

    return null;
  };

  const splitedRecId = OpenTicketById(recommendation, setModalShow);

  const handleShowDetails = (e) => {
    e.preventDefault();
    setModalShow(splitedRecId);

    window.history.pushState(null, null, `#${splitedRecId}`);
  };

  const handleCloseModal = () => {
    setModalShow(0);

    window.history.pushState(null, null, " ");
  };

  return (
    <Wrapper id={splitedRecId} hasSpecialText={specialText}>
      {specialText && <SpecialText>{specialText}</SpecialText>}

      <Grid container wrap="wrap" onClick={(e) => handleShowDetails(e)}>
        <HeadBlockGrid item xs={12}>
          <GridBlock
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            <Grid item>
              {!withoutPrice ? (
                <>
                  <PriceBlock>
                    <span>{normalizePrice(actualTotalPrice[currency])}</span>{" "}
                    {currency}
                  </PriceBlock>
                  {_.isObject(cashback) && cashback[currency] ? (
                    <CashbackBlock>
                      {t.cashback}: {cashback[currency]} {currency}
                    </CashbackBlock>
                  ) : null}
                </>
              ) : (
                t.flight_without_seats
              )}
            </Grid>

            <Grid item>
              {!specialText && <TopOval />}
              <SupplierIcon
                src={getSupplierIcon(validating_supplier)}
                alt={"supplier-icon"}
              />
              {lowcost && <Lowcost>{t.lowcost}</Lowcost>}
              {is_charter && <Charter>{t.charter}</Charter>}
            </Grid>
          </GridBlock>
        </HeadBlockGrid>
        {routes.map((value, ind) => {
          const { duration, segments } = value;
          const first = _.first(segments).departure;
          const last = _.last(segments).arrival;
          return (
            <RouteBlockGrid
              item
              xs={12}
              key={`mobile-item-${index}-route-${ind}`}
            >
              <Grid container>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ItemInfoTitle>
                        {first.airport} - {last.airport}
                      </ItemInfoTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <ItemInfoValue>
                        {first.time.split(" ")[1]} - {last.time.split(" ")[1]}
                      </ItemInfoValue>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ItemInfoTitle>{t.on_way}</ItemInfoTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <ItemInfoValue>
                        {getDurationTime(
                          duration,
                          t.hour_short,
                          t.minutes_short_3
                        )}
                      </ItemInfoValue>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ItemInfoTitle right>
                        {`${
                          segments.length > 1 ? segments.length - 1 : ""
                        } ${renderTransfersCountLabel(segments)}`}
                      </ItemInfoTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <ItemInfoValue right>
                        {renderTransfers(segments)}
                      </ItemInfoValue>
                      <ItemInfoValue right>
                        {renderChangeAirportAndNightTransferText()}
                      </ItemInfoValue>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </RouteBlockGrid>
          );
        })}
        <Grid item>
          {is_charter &&
            window.location.pathname === "/result/RT-DYU0606AMM0706-A1" && (
              <Grid item xs={12}>
                {recommendation.fare.TJS === 5999 && (
                  <SpanChartText>Только авиаперелет</SpanChartText>
                )}
                <ChantText>Тоҷикистон ба пеш!!!</ChantText>
              </Grid>
            )}
        </Grid>
        {/* <CharterMessageBlock charter={recommendation} /> */}
        
      </Grid>

      <MobileItemModal
        recommendation={recommendation}
        open={modalShow === splitedRecId}
        onClose={handleCloseModal}
        getBrandFares={getBrandFares}
        getFareRules={getFareRules}
        onBookingClick={onBookingClick}
        withoutPrice={withoutPrice}
      />
    </Wrapper>
  );
};

export default MobileRecommendation;
