import styled from "styled-components";
import Slider from "react-slick";

export const PriceSliderItem = styled.div`
  text-align: center;
  font-size: 14px;
`;

export const PriceSlider = styled(Slider)`
  margin-top: 20px;
  padding: 0px 36px;
  border: 1px solid #E1E5EC;
  border-radius: 10px;
  backdrop-filter: blur(3px);

  .slick-prev:before, .slick-next:before{
    color: #0085ff !important;
    height: 100px !important;
  }

  .slick-next {
    &::before {
      display: none !important;
    }
  }

  .slick-prev {
    &::before {
      display: none !important;
    }
  }

  .slick-prev img{
    width: 13px
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); 
  }
  
  .slick-next img{
    width: 13px
  }

.slick-next{
    right: 5px !important;
}

.slick-prev{
    left: 10px !important;
}
`;

export const Chart = styled.div`
  border-right: 1px solid #e1e5ec;
  cursor: pointer;
  padding: 15px 0;

  &:hover {
    background-color: #e1e5ec;
  }
`;

export const ChartItem = styled('span')(({ theme }) => ({
  display: 'block',
  color: theme.palette.mainText,
}));

export const Price = styled("div")(({ theme }) => ({
  marginTop: 5,
  fontWeight: 600,
  color: theme.palette.main
}))
