import styled from "styled-components";

export const IframeWrapper = styled("section")(({ theme }) => ({
  margin: "64px auto 48px auto",

  h1: {
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.palette.mainText,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",

    img: {
      width: "34px",
      height: "34px",

      [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: "none"
      }
    }
  },
  
  ".StationInformerDesktop": {
    backgroundColor: "red !important",
  }
}));

export const FrameBlock = styled("div")(({ theme }) => ({
  position: "relative",
  maxWidth: "800px",
  margin: "0 auto"
}))

export const TitleOverlay = styled("div")(({ theme }) => ({
  width: "100%",
  height: "50px",
  position: "absolute",
  top: 0,
  left: 0,
  borderRadius: "16px",

  [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
    backgroundColor: "#ffffff"
  }
}))

export const MobileOverlayTitle = styled("div")(({ theme }) => ({
  display: "none",
  borderRadius: "16px",

  [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
    display: "block",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100px",
  }
}))

export const Iframe = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",

	iframe: {
		borderRadius: "16px",
		height: "642px",

    [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
      height: "1157px",
    }
	},
}));

export const IframeOverlay = styled("div")(({ theme }) => ({
  height: "557px",
  width: "100%",
  position: "absolute",
  bottom: 0, 
  left: 0,
  borderRadius: "16px",

  [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
    height: "1006px",
  },
}))

export const DesktopHideBlock = styled("div")(({ theme }) => ({
  width: "100%",
  height: "30px",
  backgroundColor: "#ffffff",
  position: "absolute",
  bottom: "10px",
  left: 0,
  borderRadius: "16px",
}))