import styled from 'styled-components';

export const Title = styled('h1')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    color: theme.palette.mainText,
}));

export const Image = styled('img')(() => ({
    width: '100%',
}));

export const Body = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    ...theme.paddingsY(0, 32),

    '& img': {
        maxWidth: '100% !important',
        height: 'auto !important',
    },
}));

export const Date = styled('h5')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    color: theme.palette.mainText,
}));
