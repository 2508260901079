import React, { useContext } from "react";
import { useSelector } from "react-redux";

import {
  SearchContainer,
  SearchContainerWithoutBg,
} from "./components";
import SearchForm from "./parts/form";
import useLocalization from "../../../contexts/localization/hook";
import ThemeContext from "../../../contexts/theme/context";
import TitleUI from "../ui/titleUI";

const SearchComponent = () => {
  const {
    showSearchBlock,
    isStickySearchBlock,
    isSmallSearchBlock,
    isFullScreenSearchBlock,
  } = useSelector((state) => state.appConfigs);
  const { flightType } = useSelector((state) => state.searchParams);
  const { t } = useLocalization();

  const changeTheme = useContext(ThemeContext);

  return isFullScreenSearchBlock ? (
    <SearchContainer
      showSearchBlock={showSearchBlock}
      isStickySearchBlock={isStickySearchBlock}
      isSmallSearchBlock={isSmallSearchBlock}
      isFullScreenSearchBlock={isFullScreenSearchBlock}
      flightType={flightType}
    >
      {!isSmallSearchBlock && (
        <>
          <TitleUI position="center">{t.fly_tagline}</TitleUI>
        </>
      )}
      <SearchForm />
    </SearchContainer>
  ) : (
    <SearchContainerWithoutBg
      showSearchBlock={showSearchBlock}
      isStickySearchBlock={isStickySearchBlock}
      isSmallSearchBlock={isSmallSearchBlock}
      isFullScreenSearchBlock={isFullScreenSearchBlock}
      flightType={flightType}
    >
      {!isSmallSearchBlock && (
        <>
          <TitleUI position="center">{t.fly_tagline}</TitleUI>
        </>
      )}
      <SearchForm />
    </SearchContainerWithoutBg>
  );
};

export default SearchComponent;
