import React, { useState } from 'react';
import Grid from '../../../components/design_v2/ui/grid';
import {
    OrdersItemsContainer,
    OrdersMainContainer,
    OrdersTitle,
    PaginationContainer,
    SearchFieldsContainer,
} from './styles';
import configs from '../../../config';
import SearchFieldsForm from '../searchFieldsForm/searchFieldsForm';
import SnackbarAlert from '../../../components/ui/components/SnackbarAlert/SnackbarAlert';
import EmptyOrdersBox from '../parts/emptyOrderBox/index';
import PaginationBlock from '../parts/PaginationBlock/index';
import OrdersContainer from '../parts/ordersContainer/index';
import useLocalization from '../../../contexts/localization/hook';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function OrdersForm(props) {
    const { t } = useLocalization();
    const {
        ordersData,
        totalOrdersCount,
        changePage,
        setFilters,
        allowBalancePayment,
        currentPage,
        orderCancel,
        childPartners,
        orderRefund,
        partnerCode,
        setShowActionButtons,
    } = props;
    const [orderDate, setOrderDate] = useState({});
    const [redirect, setRedirect] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        severity: null,
        message: null,
    });

    const handelSetOpenAlert = () => {
        setOpenAlert(true);
    };

    const history = useHistory();

    if (redirect) {
        const { orderId } = orderDate;
        history.push(`/order/${orderId}`);
        window.location.reload();
    }

    return (
        <OrdersMainContainer fixed>
            <OrdersTitle>{t.orders}</OrdersTitle>
            <Grid container direction={'row'} spacing={2}>
                <OrdersItemsContainer item xs={12} md={8}>
                    {totalOrdersCount > 0 ? (
                        ordersData.map((item, index) => (
                            <OrdersContainer
                                item={item}
                                key={index}
                                setOrderDate={setOrderDate}
                                setRedirect={setRedirect}
                                orderCancel={orderCancel}
                                orderRefund={orderRefund}
                                setAlert={setAlertData}
                                partnerCode={partnerCode}
                                setOpenAlert={handelSetOpenAlert}
                                setShowActionButtons={setShowActionButtons}
                                allowBalancePayment={allowBalancePayment}
                            />
                        ))
                    ) : (
                        <EmptyOrdersBox />
                    )}
                </OrdersItemsContainer>
                <SearchFieldsContainer item xs={12} md={4}>
                    <SearchFieldsForm setFilters={setFilters} partnersList={childPartners} />
                </SearchFieldsContainer>
                <PaginationContainer item xs={12} md={8}>
                    <PaginationBlock
                        changePage={changePage}
                        currentPage={currentPage}
                        countOrdersLimit={configs.mainConfigs.countOrdersLimit}
                        totalRecords={totalOrdersCount}
                    />
                </PaginationContainer>
                <SnackbarAlert
                    severity={alertData.severity}
                    message={alertData.message}
                    isOpen={openAlert}
                    setIsOpen={setOpenAlert}
                />
            </Grid>
        </OrdersMainContainer>
    );
}
