import React, { useRef, useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { setAppConfigs } from '../../store/appConfigs/actions';
import API from '../../lib/api';
import {
    CardBlock,
    NewsImage,
    NewsInform,
    HeaderNews,
    DateNews,
    ButtonToOpenMore,
    Header,
    Icon,
} from '../../components/design_v2/main/parts/lastNews/components';
import { NotFoundComponent } from './components';
import newsDefaultImage from '../../images/news-default.jpg';
import Loading from '../../components/design_v2/ui/loading';
import configs from '../../config';
import useLocalization from '../../contexts/localization/hook';
import LastnewsIcon from '../../assets/icons/lastNews.svg';
import { useStyles } from './styles';

const LastNewsPage = () => {
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const [getNews, setGetNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { envConfigs } = configs;
    const classes = useStyles();
    const { t, language } = useLocalization();

    useEffect(() => {
        document.title = `${t.page_news} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: false,
            })
        );

        getNewsPage();
    }, []);

    useEffect(() => {
        document.title = `${t.page_news} | ${envConfigs.appTitle}`;
    }, [t, envConfigs]);

    useEffect(() => {
        getNewsPage();
    }, [language]);

    const executeScroll = () => {
        const y = containerRef?.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const getNewsPage = async page => {
        setIsLoading(true);

        await API.getNewsList({ page, limit: 12, language }).then(response => {
            const sorted = response.data.sort(function compare(a, b) {
                var dateA = new Date(a.news_date);
                var dateB = new Date(b.news_date);
                return dateB - dateA;
            });

            setGetNews(sorted);
            executeScroll();
            setIsLoading(false);
        });
    };

    const handleChangePage = async (event, page) => {
        getNewsPage(page);
    };

    return (
        <Container fixed ref={containerRef}>
            <Header>
                <Icon src={LastnewsIcon} />
                {t.last_news}
            </Header>

            {isLoading && <Loading />}

            {!isLoading && getNews.length === 0 && <NotFoundComponent>{t.not_found_content}!</NotFoundComponent>}

            {!isLoading && getNews.length > 0 && (
                <>
                    <Grid container>
                        {getNews.map(value => {
                            const languageItem = value.contents.find(x => x.language === language);

                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} key={value.id} className={classes.newsCards}>
                                    <CardBlock>
                                        <NewsImage
                                            backgroundImage={
                                                value.photo
                                                    ? `${envConfigs.uploadHost}${value.photo}`
                                                    : newsDefaultImage
                                            }
                                        />

                                        <NewsInform>
                                            <DateNews>{moment(value.news_date).format('DD.MM.YYYY')}</DateNews>

                                            <HeaderNews>{languageItem?.title}</HeaderNews>

                                            <div className={classes.openMore}>
                                                <ButtonToOpenMore to={`/news/${value.slug}`}>
                                                    {t.more_details}
                                                </ButtonToOpenMore>
                                            </div>
                                        </NewsInform>
                                    </CardBlock>
                                </Grid>
                            );
                        })}
                    </Grid>

                    <div className={classes.paginationBlock}>
                        <Pagination
                            onChange={handleChangePage}
                            count={getNews.last_page}
                            variant="outlined"
                            shape="rounded"
                            className={classes.pagination}
                        />
                    </div>
                </>
            )}
        </Container>
    );
};

export default LastNewsPage;
