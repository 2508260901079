import styled from "styled-components";

export const Wrapper = styled("div")(({ theme, fixed, flex }) => ({
  ...theme.paddings(0, 24),
  ...theme.margins(0, "auto"),
  ...(fixed
    ? {
        maxWidth: 1280,

        [theme.breakpoints.upDown(1000, 1300)]: {
          maxWidth: 960,
        },

        [theme.breakpoints.upDown(800, 1000)]: {
          maxWidth: 768,
        },

        [theme.breakpoints.upDown(600, 800)]: {
          maxWidth: 560,
        },

        [theme.breakpoints.down(599)]: {
          maxWidth: "100%",
        },
      }
    : {
        maxWidth: "100%",
      }),

  [theme.breakpoints.down(799)]: {
    ...theme.paddings(0, 16),
  },
  ...(flex ? { display: "flex" } : {}),
}));
