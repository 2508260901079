import {
    SET_SEARCH_PARAMS,
    SET_FLIGHT_TYPE,
    SET_CABIN,
    SET_PASSEGERS,
    SET_ROUTES,
    SET_HAS_DATA,
    SET_NOT_FILLED_FIELDS,
    SET_CALENDAR_PRICES,
} from './constants';

export const setSearchParams = data => dispatch =>
    dispatch({
        type: SET_SEARCH_PARAMS,
        payload: data,
    });

export const setFlightType = data => dispatch =>
    dispatch({
        type: SET_FLIGHT_TYPE,
        payload: data,
    });

export const setCabin = data => dispatch =>
    dispatch({
        type: SET_CABIN,
        payload: data,
    });

export const setPassengers = data => dispatch =>
    dispatch({
        type: SET_PASSEGERS,
        payload: data,
    });

export const setRoutes = data => dispatch =>
    dispatch({
        type: SET_ROUTES,
        payload: data,
    });

export const setHasData = data => dispatch =>
    dispatch({
        type: SET_HAS_DATA,
        payload: data,
    });

export const setNotFilledFields = data => dispatch =>
    dispatch({
        type: SET_NOT_FILLED_FIELDS,
        payload: data,
    });

export const setCalendarPrices = data => dispatch =>
    dispatch({
        type: SET_CALENDAR_PRICES,
        payload: data,
    });
