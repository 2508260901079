import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useLocalization from '../../../../../../contexts/localization/hook';
import calendarIcon from '../../../../../../assets/icons/calendarIcon.svg';
import { Input, Icon } from './components';
import ScrollingCalendar from '../../scrollingCalendar';

const DateFromField = ({ onSelect, selected, index }) => {
    const { t } = useLocalization();
    const { routes } = useSelector(state => state.searchParams);
    const [value, setValue] = useState(selected);
    const [isActive, setIsActive] = useState(false);
    const [lastSelectedDate, setLastSelectedDate] = useState(null);

    useEffect(() => {
        if (index > 0) {
            setLastSelectedDate(routes[index - 1].date_from);
        }
        setValue(routes[index].date_from);
    }, [routes]);

    const handleFocus = input => {
        setIsActive(input);
    };

    const handleSelect = date => {
        setValue(date);
        onSelect(date, index);
    };

    const handleClose = () => {
        setIsActive(false);
    };

    return (
        <>
            <Input
                type="text"
                readOnly
                placeholder={t.date_from}
                onFocus={() => handleFocus('from')}
                value={value ? moment(value).format('DD MMMM YYYY') : ''}
                isActive={isActive}
            />
            <Icon src={calendarIcon} active />
            <ScrollingCalendar
                type="oneSelect"
                isActive={isActive}
                onClose={handleClose}
                onSelect={handleSelect}
                value={value}
                activeDirection="from"
                setActiveDirection={() => null}
                lastSelectedDate={lastSelectedDate}
            />
        </>
    );
};

export default DateFromField;
