import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.transparent,
    minHeight: '100%',
    color: theme.palette.mainText,

    '& > *': {
        userSelect: 'none',
    },
}));

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Line = styled('div')(({ theme }) => ({
    ...theme.margins(16, 0, 12),
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.line,
}));

export const Footer = styled.div`
  height: 48px;
`;

export const Button = styled('button')(({ theme }) => ({
    height: 48,
    width: '100%',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.main,
    border: 'none',
    color: theme.palette.searchTextForm,
    fontSize: 18,
    fontWeight: 600,
}));
