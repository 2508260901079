const defaultLanguages = {
    ru: {
        uz: 'Узбекский',
        ru: 'Русский',
        en: 'Английский',
    },
    uz: {
        uz: 'Ozbekcha',
        ru: 'Rus',
        en: 'Ingliz',
    },
    en: {
        uz: 'Uzbek',
        ru: 'Russian',
        en: 'English',
    },
};

export default defaultLanguages;
