import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setCurrency } from '../../../../../../../../store/appConfigs/actions';
import useLocalization from '../../../../../../../../contexts/localization/hook';
import defaultCurrencies from '../../../../../../../../config/defaultCurrencies';
import defaultLanguages from '../../../../../../../../config/defaultLanguages';

import {
    Component,
    Button,
    Items,
    Item,
    Text,
    TextLanguage,
    CheckboxBlock,
    Checkbox,
    SettingsIcon,
    GroupTitle,
} from './components';
import Dropdown from '../../../../../../ui/dropdown';

const SelectCurrencies = () => {
    const { availableCurrencies, currency } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();
    const { language, availableLanguages, switchLang, t } = useLocalization();

    const [isActive, setIsActive] = useState(false);

    const handleSelectCurrency = useCallback(currency => {
        localStorage.setItem('currency', currency);
        dispatch(setCurrency(currency));
        setIsActive(false);
    }, []);

    const handleSelectLanguage = useCallback(language => {
        setIsActive(false);
        switchLang(language);
    }, []);

    const currencies = useMemo(() => {
        return availableCurrencies.map((value, index) => {
            return (
                <Item key={`currency-${value}`} onClick={() => handleSelectCurrency(value)}>
                    <Text>{value}</Text>
                    <TextLanguage>{defaultCurrencies[language][value]}</TextLanguage>

                    <CheckboxBlock>
                        <Checkbox className={value === currency && 'active'} />
                    </CheckboxBlock>
                </Item>
            );
        });
    }, [availableCurrencies, currency, language]);

    const renderLanguages = useMemo(() => {
        return availableLanguages.map(value => {
            return (
                <Item key={`languages-${value}`} onClick={() => handleSelectLanguage(value)}>
                    <Text>{value}</Text>

                    <TextLanguage className={value === language && 'active'}>
                        {defaultLanguages[language][value]}
                    </TextLanguage>

                    <CheckboxBlock>
                        <Checkbox className={value === language && 'active'} />
                    </CheckboxBlock>
                </Item>
            );
        });
    }, [availableLanguages, language]);

    return (
        <Component>
            <Button onClick={() => setIsActive(true)}>
                <SettingsIcon />
            </Button>
            <Dropdown isActive={isActive} onClickOutside={() => setIsActive(false)} padding='16px 12px 12px'>
                <GroupTitle>{t.currency}</GroupTitle>
                <Items>{currencies}</Items>

                <GroupTitle>{t.language}</GroupTitle>
                <Items>{renderLanguages}</Items>
            </Dropdown>
        </Component>
    );
};

export default SelectCurrencies;
