import styled from "styled-components";

import bgCoins from "../../../../images/flyCash/bgCoins.png";

export const Wrapper = styled("section")(({ theme }) => ({
    background: `url(${bgCoins})`,
    backgroundPosition: "center",
    height: 680,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
      height: 480,
    }
}));

export const InfoBlock = styled("div")(({ theme }) => ({
    ...theme.paddings(0, 25),
    display: "flex",
    alignItems: "flex-end",
    margin: "0 auto",
    maxWidth: 1280,
    height: "100%",
    position: "relative",

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.paddings(0, 0),
    }
}));

export const TextBlock = styled("div")(({ theme }) => ({
    ...theme.paddings(80, 60),
    flexGrow: 1,
    height: 460,
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "0 16px 16px 0",
    backdropFilter: "blur(15px)",
    fontSize: 78,
    color: "#fff",
    fontWeight: 600,
    lineHeight: 1.2,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 60,
    },

    [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
        fontSize: 48,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
      ...theme.paddings(60, 25),
        height: 320,
        fontSize: 32,
        borderRadius: "16px 16px 0 0",
        lineHeight: 1.6,
    },

    "& > img": {
        display: "block",
        marginLeft: -60,

        [theme.breakpoints.down(theme.breakpoints.tablet)]: {
            maxHeight: 80,
            marginLeft: -40,
        },

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            marginLeft: -33,
            maxHeight: 70,
        },
    }
}));