const initialState = {
    countriesItems: [],
    routes: [],
    fareRules: [],
    included: [],
    recId: null,
    totalPrice: null,
    partnerFees: null,
    clientFees: null,
    isLoad: false,
    passengersList: null,
    filterString: null,
    flightType: null,
    options: [],
    passengerOptions: [],
    optionsDynamicPrices: [],
    selectedOptions: [],
};

export default initialState;
