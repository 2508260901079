import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import flyCash from "../../../../images/flyCash/flyCash.png";
import flyCash2 from "../../../../images/flyCash/flyCash2.png";
import flyCash4 from "../../../../images/flyCash/flyCash4.png";
import flyCash5 from "../../../../images/flyCash/flyCash5.png";
import flyCash7 from "../../../../images/flyCash/flyCash7.png";
import flyCash14 from "../../../../images/flyCash/flyCash14.png";
import coin from "../../../../images/flyCash/coin.png";

import { DescriptionBlock, ImageBlock, InfoBlock, TextBlock, TitleBlock, Wrapper } from "./components";

const HowItWorksPart = () => {
  const { language } = useLocalization();

    if (language === "ru") {
        return (
            <Wrapper>
                <InfoBlock>
                    <TextBlock>
                        <TitleBlock className="desktop">Как работает <img src={flyCash5} className="cash5" alt="fly-cash-text" /></TitleBlock>
                        <TitleBlock className="mobile">Как работает <img src={flyCash2} className="cash2" alt="fly-cash-text" /></TitleBlock>
                        <DescriptionBlock>Очень просто! При каждой покупке авиабилетов через наш сайт, вы будете получать кешбэк, который можно накапливать и обменивать при покупке любых авиабилетов. Чем больше покупок вы осуществляете через нас, тем больше кешбэка вы накапливаете! Кэшбек начисляется после успешного вылета на ваш баланс в личном кабинете Fly.tj. Мы ценим вашу лояльность и готовы отблагодарить вас за выбор Fly.tj. Мы стремимся сделать каждое ваше путешествие незабываемым и комфортным. C помощью FLY CASH мы хотим сделать его еще более выгодным.</DescriptionBlock>
                    </TextBlock>
                    <ImageBlock>
                        <img src={coin} alt="coin" />
                    </ImageBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    if (language === "tj") {
        return (
            <Wrapper>
                <InfoBlock>
                    <TextBlock>
                        <TitleBlock className="desktop"><img src={flyCash14} className="cash5" alt="fly-cash-text" style={{ marginLeft: -36 }} /> чӣ гуна кор мекунад?</TitleBlock>
                        <TitleBlock className="mobile"><img src={flyCash} className="cash2" alt="fly-cash-text" style={{ marginTop: 0, marginBottom: -20 }} /> чӣ гуна кор мекунад?</TitleBlock>
                        <DescriptionBlock>Хеле содда! Ҳар дафъае, ки шумо тавассути вебсайти мо чиптаҳои ҳавопаймо мехаред, шумо соҳиби кэшбэк мешавед. Кэшбэки шумо  метавонад ҷамъ шавад ва шумо имкон доред то ки онро ҳангоми харидани чиптаҳои ҳавопаймо иваз кунед. Бештар харидҳое, ки шумо тавассути мо анҷом медиҳед, ҳамон қадар кэшбэк бештар ба даст меоред. Шумо ҳатто метавонед онро ҷамъ кунед! Пас аз рафтани бомуваффақияти шумо кэшбек ба ҳисоби шахсии Fly.tj интиқол дода мешавад. Мо садоқати шуморо қадр мекунем. Мо садоқати шуморо қадр мекунем.Ташаккур барои интихоби Fly.tj. Мо кушиш мекунем, то ки ҳар як сафари Шумо фаромушнашаванда ва бароҳат баргузарад ва бо кумаки FLY CASH мо мехоҳем онро барои Шумо боз ҳам фоидаовар созем.</DescriptionBlock>
                    </TextBlock>
                    <ImageBlock>
                        <img src={coin} alt="coin" />
                    </ImageBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <InfoBlock>
                <TextBlock>
                    <TitleBlock className="desktop">How does <img src={flyCash7} className="cash5" alt="fly-cash-text" style={{ marginBottom: -20 }}  /> works?</TitleBlock>
                    <TitleBlock className="mobile">How does <img src={flyCash4} className="cash2" alt="fly-cash-text" width={214} style={{ display: "inline", marginTop: -4, marginBottom: -20 }} /> works?</TitleBlock>
                    <DescriptionBlock>Very simple! Every time you purchase air tickets through our website, you will receive cashback, which can then be accumulated and exchanged after successfully purchasing air ticket. The more purchases you make through us, the more cashback you get to accumulate! Cashback will be transferred to the balance of your personal Fly.tj account right after your successful departure. We value your loyalty. Thank you for choosing fly.tj. We strive to make every effort possible to make your trip unforgettable and comfortable, and with the help of FLY CASH we want to make it even more profitable.</DescriptionBlock>
                </TextBlock>
                <ImageBlock>
                    <img src={coin} alt="coin" />
                </ImageBlock>
            </InfoBlock>
        </Wrapper>
    );
};

export default HowItWorksPart;
