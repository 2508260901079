const locales = {
    ru: {
        one: 'пассажир',
        few: 'пассажира',
        more: 'пассажиров',
    },
    en: {
        one: 'passenger',
        few: 'passengers',
        more: 'passengers',
    },
    uz: {
        one: "yo'lovchi",
        few: "yo'lovchi",
        more: "yo'lovchi",
    },
};

export default locales;
