import styled from 'styled-components';

export const Input = styled('input')(({ theme }) => ({
    ...theme.paddings(8, 66, 8, 16),
    backgroundColor: theme.palette.transparent,
    color: theme.palette.searchTextForm,
    width: 250,
    height: 60,
    margin: 0,
    border: 'none',
    borderRadius: theme.spacing(1.5),
    fontSize: '1rem',
    position: 'relative',
    cursor: 'pointer',
    zIndex: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: '100%',
        borderRadius: theme.spacing(1.5),
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
        borderRadius: theme.spacing(1.5),
    },

    '::placeholder': {
        color: theme.palette.searchTextForm,
    },
}));

export const IataCode = styled('span')(({ theme, active }) => ({
    display: active ? 'block' : 'none',
    position: 'absolute',
    right: 16,
    top: 'calc(50% - 0.45rem)',
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    lineHeight: 1,
    color: theme.palette.white,
}));