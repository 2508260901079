import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import flyCash4 from "../../../../images/flyCash/flyCash4.png";

import { InfoBlock, TextBlock, Wrapper } from "./components";

const HeaderPart = () => {
  const { language } = useLocalization();

    if (language === "ru") {
        return (
            <Wrapper>
                <InfoBlock>
                    <TextBlock>
                        Добро пожаловать<br /> в уникальный мир <img src={flyCash4} alt="fly-cash-text" />
                    </TextBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    if (language === "tj") {
        return (
            <Wrapper>
                <InfoBlock>
                    <TextBlock>
                        Хуш омадед<br /> ба ҷаҳони беназири <img src={flyCash4} alt="fly-cash-text" />
                    </TextBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <InfoBlock>
                <TextBlock>
                    Welcome<br /> into a unique world of <img src={flyCash4} alt="fly-cash-text" />
                </TextBlock>
            </InfoBlock>
        </Wrapper>
    );
};

export default HeaderPart;
