import React, { useState } from "react";

import _ from "lodash";
import moment from "moment";

import { useSelector } from "react-redux";

import { getDayMonthYearFormat, getServiceClass } from "../../utils";

import FlightInfoIcons from "../../flightInfoIcons";
import AdditionallyForm from "../../additionallyForm";
import { normalizePrice } from "../../../../../lib/normalize";
import useLocalization from "../../../../../contexts/localization/hook";
import { RT } from "../../../../../containers/search/constants";
import TicketModalComponent from "../../../ui/ticketModal";
import CopiedLinkNotification from "../copiedLinkNotification/CopiedLinkNotification";
import OpenTicketById from "../openTicketById/OpenTicketById";
// import CharterMessageBlock from "../charterMessageBlock";

import {
  getFlightIcon,
  getSupplierIcon,
  getTransferArrowIcon,
} from "../../../../../lib/getImage";
import { ReactComponent as ShareIcon } from "../../../../../assets/icons/newDesign/share.svg";

import Grid from "../../../ui/grid";
import { COLOR_THEME } from "../../../../../store/resultData/constants";
import {
  AirlineLogo,
  AirlineLogoBlock,
  FlightInfo,
  DurationBlock,
  FlightInfoGrid,
  PentagonShape,
  RouteBlock,
  RouteMainInfo,
  RouteTitle,
  RouteTitleContainer,
  TimeRouteBlock,
  Wrapper,
  TransfersArrowImg,
  StringSpan,
  ChangeTariffContainer,
  TariffChangeBlock,
  LowcostCharterContainer,
  Charter,
  Lowcost,
  ClassBlock,
  AdditionalInformationShort,
  InlineBlock,
  FreeSeatsLabelBlock,
  ButtonContainer,
  SupplierBlock,
  AmountSubmitBlock,
  AmountBlock,
  ChoosedButtonBlock,
  ChoosedButton,
  OrderInfoGrid,
  RouteAdditionallyInfo,
  ArrivalInfo,
  SpecialText,
  NightTransfer,
  BaggageInfoBlock,
  CashbackBlock,
  ChantText,
  SpanChartText,
  DetailsWrap,
  ThirdBlock,
  SwitcherBlock,
  ModalSectionBlock,
  ShareLinkBlock,
  RouteMainInfoWrapper,
} from "./components";
import BaggageAndHandLuggageUI from "../../../ui/baggageAndHandLuggageUI";

const DesktopRecommendation = ({
  recommendation,
  onBookingClick,
  getBrandFares,
  getFareRules,
  specialText,
  withoutPrice,
  modalShow,
  setModalShow,
}) => {
  const { brandFares, included, session } = useSelector(
    (state) => state.resultData
  );
  const { currency } = useSelector((state) => state.appConfigs);
  const { flightType } = useSelector((state) => state.searchParams);
  const { t, language } = useLocalization();

  const [showAdditionalInfoItems, setShowAdditionalInfoItems] = useState(
    recommendation.routes.map((route) => {
      return {
        showDetailsTariffsButtons: false,
        showDetails: false,
        showTariffs: false,
      };
    })
  );
  const [snakBarShow, setSnakBarShow] = useState(false);

  const getCityString = (airportCode, cityCode) => {
    const { city, airport } = included;

    const cityName = city[cityCode].name[language];
    const airportName = airport[airportCode].name[language];

    if (cityName === airportName) {
      return cityName;
    } else {
      return (
        <div>
          <div>{cityName},</div>
          <div>{airportName} </div>
        </div>
      );
    }
  };

  const renderDepartureInfo = (departureSegment) => {
    const { time, airport, city } = departureSegment.departure;

    return (
      <FlightInfo>
        <RouteBlock>{getDayMonthYearFormat(time, language)}</RouteBlock>
        <TimeRouteBlock>{time.split(" ")[1]}</TimeRouteBlock>
        <RouteBlock>{getCityString(airport, city)}</RouteBlock>
      </FlightInfo>
    );
  };

  const renderDurationTime = (duration) => {
    const hours = Math.trunc(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);

    return (
      <span>
        {hours} {t.hour_short} {minutes} {t.minutes_short_3}
      </span>
    );
  };

  const renderTransfersArrow = (segments) => {
    const transferCount = segments.length - 1;

    const imgUrl = getTransferArrowIcon(transferCount);

    return <TransfersArrowImg imgUrl={imgUrl} />;
  };

  const renderTransfersInfo = (segments) => {
    const transfersCount = segments.length - 1;

    if (transfersCount === 0) {
      return <div>{t.no_transfers}</div>;
    }

    let isNightTransfer = false;
    let isChangeAirport = false;

    recommendation.routes.forEach((route) => {
      if (route.segments.length > 1) {
        for (let i = 0; i < route.segments.length; i++) {
          if (
            i > 0 &&
            route.segments[i].departure.airport !==
              route.segments[i - 1].arrival.airport
          ) {
            isChangeAirport = true;
          }

          const timeArray = route.segments[i].arrival.time.split(" ");

          const arrivalTime = moment(timeArray[1], "hh:mm"),
            beforeTime = moment("06:00", "hh:mm"),
            afterTime = moment("18:00", "hh:mm");

          if (
            (arrivalTime.isSameOrBefore(beforeTime) ||
              arrivalTime.isSameOrAfter(afterTime)) &&
            i < route.segments.length - 1
          ) {
            isNightTransfer = true;
          }
        }
      }
    });

    const TRANSFER_COUNT_LABEL = {
      1: t.transfer_1,
      2: t.transfer_2_4,
      3: t.transfer_2_4,
      4: t.transfer_2_4,
      5: t.transfer_5,
    };

    const transfersCodeList = segments
      .slice(0, -1)
      .map((segment) => segment.arrival.airport)
      .join(", ");

    return (
      <>
        <div>
          <StringSpan>{transfersCount}</StringSpan>
          <StringSpan>{TRANSFER_COUNT_LABEL[transfersCount]}:</StringSpan>
          <StringSpan>{transfersCodeList}</StringSpan>
        </div>
        {isChangeAirport ? (
          <NightTransfer>{t.change_airport}</NightTransfer>
        ) : null}
        {isNightTransfer ? (
          <NightTransfer>{t.night_flight}</NightTransfer>
        ) : null}
      </>
    );
  };

  const renderDuration = (route) => {
    return (
      <DurationBlock>
        {renderDurationTime(route.duration)}
        {renderTransfersArrow(route.segments)}
        {renderTransfersInfo(route.segments)}
      </DurationBlock>
    );
  };

  const renderArrivalInfo = (arrivalSegment) => {
    const { time, airport, city } = arrivalSegment.arrival;

    return (
      <ArrivalInfo>
        <RouteBlock>{getDayMonthYearFormat(time, language)}</RouteBlock>
        <TimeRouteBlock>{time.split(" ")[1]}</TimeRouteBlock>
        <RouteBlock>{getCityString(airport, city)}</RouteBlock>
      </ArrivalInfo>
    );
  };

  const getFreeSeatsLabel = (freeSeats) => {
    if (withoutPrice) return t.free_seats_0;

    if (freeSeats === 1) {
      return freeSeats + " " + t.free_seats_1;
    }

    if (freeSeats > 1 && freeSeats < 5) {
      return freeSeats + " " + t.free_seats_2_4;
    }

    if (freeSeats > 4 && freeSeats < 9) {
      return freeSeats + " " + t.free_seats_5;
    }

    if (freeSeats > 8) {
      return "9+ " + t.free_seats_5;
    }
  };

  const handleShowRouteDetails = (routeIndex) => {
    let newValues = [...showAdditionalInfoItems];
    newValues[routeIndex] = {
      showDetailsTariffsButtons: true,
      showDetails: true,
      showTariffs: false,
    };
    setShowAdditionalInfoItems(newValues);
  };

  const handleShowTariffs = (recId, routeIndex) => {
    let newValues = [...showAdditionalInfoItems];
    newValues[routeIndex] = {
      showDetailsTariffsButtons: true,
      showDetails: false,
      showTariffs: true,
    };
    setShowAdditionalInfoItems(newValues);
    getBrandFares(recId);
  };

  const chooseTariffs = (e, recId, routeIndex) => {
    e.stopPropagation();

    handleShowTariffs(recId, routeIndex);

    setModalShow(splitedRecId);
  };

  const shortTariffInfo = (
    departureSegment,
    recId,
    route,
    has_branded_tariffs,
    lowcost
  ) => {
    const {
      service_class,
      free_seats,
      baggage,
      baggage_weight,
      hand_luggage,
      hand_luggage_weight,
      is_charter,
    } = departureSegment;
    const { showDetails, showTariffs } = showAdditionalInfoItems[route.index];
    const routeIndex = route.index;

    const freeSeatsLabel = getFreeSeatsLabel(free_seats);

    const switcherText = (haseBrandedTariffs, baggageValue) => {
      const baggageToNum = parseInt(baggageValue);

      if (haseBrandedTariffs) {
        return `${t.add_baggage} ➜`;
      } else if (!haseBrandedTariffs) {
        if (baggageToNum !== 0) {
          return t.baggage_included;
        } else if (baggageToNum === 0) {
          return t.fare_without_baggage;
        }
      }
    };

    if (showDetails || showTariffs) {
      return (
        <Grid>
          <ThirdBlock>
            <Grid item xs={12}>
              {is_charter && (
                <LowcostCharterContainer>
                  <Charter>{t.charter}</Charter>
                </LowcostCharterContainer>
              )}
            </Grid>

            <Grid item xs={12}>
              {lowcost && (
                <LowcostCharterContainer>
                  <Lowcost>{t.lowcost}</Lowcost>
                </LowcostCharterContainer>
              )}
            </Grid>

            <Grid item>
              <ClassBlock>{getServiceClass(service_class, t.class)}</ClassBlock>
            </Grid>

            <Grid item xs={12}>
              <AdditionalInformationShort>
                <InlineBlock>
                  <FlightInfoIcons data={departureSegment} />
                </InlineBlock>
                <FreeSeatsLabelBlock>{freeSeatsLabel}</FreeSeatsLabelBlock>
              </AdditionalInformationShort>
            </Grid>
              <BaggageAndHandLuggageUI  baggage={baggage} handLuggage={hand_luggage}/>
            <SwitcherBlock
              onClick={(e) =>
                has_branded_tariffs && chooseTariffs(e, recId, routeIndex)
              }
            >
              <span>{switcherText(has_branded_tariffs, baggage)}</span>
              <TariffChangeBlock
                title={switcherText(has_branded_tariffs, baggage)}
                haseBaggage={parseInt(baggage) !== 0}
              >
                <div></div>
              </TariffChangeBlock>
            </SwitcherBlock>
          </ThirdBlock>
        </Grid>
      );
    }

    handleShowRouteDetails(routeIndex);
    return (
      <Grid item xs={12}>
        <ChangeTariffContainer>
          <TariffChangeBlock onClick={() => handleShowRouteDetails(routeIndex)}>
            {t.flight_details}
          </TariffChangeBlock>

          {has_branded_tariffs && !withoutPrice && (
            <TariffChangeBlock
              onClick={() => handleShowTariffs(recId, routeIndex)}
            >
              {t.change_tariff}
            </TariffChangeBlock>
          )}
        </ChangeTariffContainer>
      </Grid>
    );
  };

  const getDepartureTitle = (index) => {
    if (flightType === RT) {
      if (index === 0) {
        return t.there_title;
      } else {
        return t.back_title;
      }
    }

    return t.departure_title;
  };

  const splitedRecId = OpenTicketById(recommendation, setModalShow);

  const renderRoute = (recommendation, route, index) => {
    const { has_branded_tariffs, brandRecId, rec_id, lowcost } = recommendation;
    const brandFaresItem = brandFares[rec_id];

    let actualRoute = JSON.parse(JSON.stringify(route));

    if (brandFaresItem) {
      const currentBrandFare = brandFaresItem.find(
        (item) => item.rec_id === brandRecId
      );

      if (currentBrandFare) {
        actualRoute.segments = currentBrandFare.routes[index].segments;
      }
    }

    const departureSegment = _.first(actualRoute.segments);
    const arrivalSegment = _.last(actualRoute.segments);
    const themeColor = COLOR_THEME[index];
    const imgUrl = getFlightIcon(index);

    const airLineLogos = () => {
      const logosFromRoutes = recommendation.routes.map((route) =>
        route.segments.map((segment) => segment.operation_supplier)
      );

      return _.uniq(logosFromRoutes[0]);
    };

    return (
      <RouteMainInfoWrapper>
        <Grid container key={index}>
          <Grid item xs={12}>
            <RouteTitleContainer imgUrl={imgUrl}>
              <RouteTitle themeColor={themeColor}>
                {getDepartureTitle(index)}
              </RouteTitle>
            </RouteTitleContainer>
          </Grid>

          <Grid item xs={12}>
            <RouteMainInfo themeColor={themeColor}>
              <PentagonShape themeColor={themeColor} />

              <Grid container>
                <Grid item xs={6} sm={6} xl={3}>
                  <AirlineLogoBlock>
                    {airLineLogos().map((logo) => (
                      <AirlineLogo
                        key={Math.random(100)}
                        imgUrl={getSupplierIcon(logo)}
                      />
                    ))}
                  </AirlineLogoBlock>
                </Grid>

                <FlightInfoGrid item xs={12} sm={12} xl={6}>
                  <Grid container spacing={3} alignItems={"center"}>
                    <Grid item xs={4}>
                      {renderDepartureInfo(departureSegment)}
                    </Grid>
                    <Grid id={splitedRecId} item xs={4}>
                      {renderDuration(route)}
                    </Grid>
                    <Grid item xs={4}>
                      {renderArrivalInfo(arrivalSegment)}
                    </Grid>
                  </Grid>
                </FlightInfoGrid>

                <OrderInfoGrid item xs={6} sm={6} xl={3}>
                  <Grid container justifyContent={"flex-end"}>
                    {shortTariffInfo(
                      departureSegment,
                      rec_id,
                      actualRoute,
                      has_branded_tariffs,
                      lowcost
                    )}
                  </Grid>
                </OrderInfoGrid>
              </Grid>
            </RouteMainInfo>
          </Grid>
        </Grid>
      </RouteMainInfoWrapper>
    );
  };

  const handleShowDetails = (e, route) => {
    e.preventDefault();

    const routeIndex = route.index;

    handleShowRouteDetails(routeIndex);
    setModalShow(splitedRecId);

    window.history.pushState(null, null, `#${splitedRecId}`);
  };

  const handleCloseModal = () => {
    setModalShow(0);

    window.history.pushState(null, null, " ");
  };

  const renderSupplierBlock = (validatingSupplier) => {
    const { supplier } = included;
    const supplierArray = _.find(supplier, { iata: validatingSupplier });
    const validatingSupplierName = supplierArray
      ? supplierArray.name[language]
      : null;

    return (
      <SupplierBlock>
        {validatingSupplierName &&
          `${validatingSupplierName} — ${t.validates_flights}`}
      </SupplierBlock>
    );
  };

  const renderSubmit = (recommendation) => {
    const { brandRecId, rec_id } = recommendation;

    const recId = brandRecId || rec_id;

    return (
      <ChoosedButtonBlock>
        <ChoosedButton
          type="submit"
          onClick={() => onBookingClick(recId, session)}
        >
          {t.choose}
        </ChoosedButton>
      </ChoosedButtonBlock>
    );
  };

  const renderButtonContainer = (recommendation) => {
    const { validating_supplier, total_price, cashback, routes } =
      recommendation;

    const departureSegment = _.first(_.first(routes).segments);
    const { is_charter } = departureSegment;

    let actualTotalPrice = total_price;

    return (
      <ButtonContainer>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                {renderSupplierBlock(validating_supplier)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <AmountSubmitBlock>
              {withoutPrice ? (
                t.flight_without_seats
              ) : (
                <>
                  {_.isObject(cashback) && cashback[currency] ? (
                    <CashbackBlock>
                      {t.cashback}: {cashback[currency]} {currency}
                    </CashbackBlock>
                  ) : null}

                  <AmountBlock>
                    <span>{normalizePrice(actualTotalPrice[currency])}</span>{" "}
                    {currency}
                  </AmountBlock>
                </>
              )}
            </AmountSubmitBlock>
          </Grid>
          {is_charter &&
            window.location.pathname === "/result/RT-DYU0606AMM0706-A1" && (
              <>
                {recommendation.fare.TJS === 5999 && (
                  <SpanChartText>Только авиаперелет</SpanChartText>
                )}
                <ChantText>Тоҷикистон ба пеш!!!</ChantText>
              </>
            )}
        </Grid>
      </ButtonContainer>
    );
  };

  const detailsInfo = (recommendation, route, index) => {
    const { brandRecId, brand_name, rec_id } = recommendation;
    const { showDetails, showTariffs } = showAdditionalInfoItems[route.index];
    const brandFaresItem = brandFares[rec_id];
    let actualRoute = JSON.parse(JSON.stringify(route));

    if (brandFaresItem) {
      const currentBrandFare = brandFaresItem.find(
        (item) => item.rec_id === brandRecId
      );

      if (currentBrandFare) {
        actualRoute.segments = currentBrandFare.routes[index].segments;
      }
    }

    return (
      <Grid container key={index} style={{ height: "100%" }}>
        <Grid item xs={12}>
          <RouteAdditionallyInfo>
            <AdditionallyForm
              route={actualRoute}
              recommendation={recommendation}
              included={included}
              routeIndex={index}
              recId={rec_id}
              brandRecId={brandRecId}
              brandFares={brandFaresItem}
              showTariffs={showTariffs}
              showDetails={showDetails}
              setShowRouteDetails={handleShowRouteDetails}
              setShowTariffs={handleShowTariffs}
              getFareRules={getFareRules}
              brandName={brand_name}
              currency={currency}
            />
          </RouteAdditionallyInfo>
        </Grid>
      </Grid>
    );
  };

  const handleShareLink = (e) => {
    e.stopPropagation();

    setModalShow(0);

    const getUrl = `${window.location.href}#${splitedRecId}`;

    navigator.clipboard
      .writeText(getUrl)
      .then(() => {
        setSnakBarShow(true);
      })
      .catch((error) => {
        console.error("Ошибка при копировании", error);
      });
  };

  return (
    <Wrapper>
      <CopiedLinkNotification
        snakBarShow={snakBarShow}
        setSnakBarShow={setSnakBarShow}
      />
      {specialText && <SpecialText>{specialText}</SpecialText>}
      <ShareLinkBlock onClick={(e) => handleShareLink(e)}>
        <ShareIcon />
        <p>{t.share}</p>
      </ShareLinkBlock>
      {recommendation.routes.map((route, number) => (
        <div
          onClick={(e) => handleShowDetails(e, route, recommendation.rec_id)}
        >
          {renderRoute(recommendation, route, number)}
        </div>
      ))}
      {/* <CharterMessageBlock charter={recommendation} /> */}
      {renderButtonContainer(recommendation)}
      <TicketModalComponent
        active={modalShow === splitedRecId}
        onClose={handleCloseModal}
        maxWidth={700}
        fixedWidth
      >
        
        <ModalSectionBlock>
          <DetailsWrap>
            {recommendation.routes.map((route, number) =>
              detailsInfo(recommendation, route, number)
            )}
          </DetailsWrap>
          {renderSubmit(recommendation)}
        </ModalSectionBlock>
      </TicketModalComponent>
    </Wrapper>
  );
};

export default DesktopRecommendation;
