import ru from "./ru";
import en from "./en";
import uz from "./uz";

const translations = {
    ru,
    en,
    uz
};

export default translations;
