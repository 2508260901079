import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import API from '../../lib/api';
import { setCountries, setOptions, setPreBookData } from './actions';
import { setAllLoadngData } from '../../store/loading/actions';
import BookingForm from '../../components/booking/bookingForm/bookingForm';
import { change, getFormValues, getFormSyncErrors } from 'redux-form';
import { PS, SR, RU, PSP, FRIENDLY_COUNTRY, NP, ONLY_RU_COUNTRY, ALL_DOCUMENTS_ITEMS, M } from './constants';
import { ADT } from '../search/constants';
import ErrorModal from '../../components/ui/components/ErrorModal/ErrorModal';
import {
    friendlyDocumentsForAllCitizenship,
    allDocumentsForAllCitizenship,
    documentsForAllCountries,
    documentsForFriendlyCountries,
    documentsForOnlyRu,
    getFlightDocsType,
    getRoutesCountries,
    ruDocumentsForAllCitizenship,
} from '../../components/booking/common';
import configs from '../../config';
import { setAppConfigs } from '../../store/appConfigs/actions';
import envConfigs from '../../config/envConfigs';
import useLocalization from '../../contexts/localization/hook';
import { LoadingBlock } from '../../components/order/paymentForm/components';
import Loading from '../../components/design_v2/ui/loading';

function BookingContainer(props) {
    const {
        setPreBookData,
        isLoad,
        currency,
        setCountries,
        dispatch,
        formValues,
        flightType,
        recId,
        countriesItems,
        syncErrors,
        selectedOptions,
        routes,
        included,
        cashback,
    } = props;

    const reduxDispatch = useDispatch();
    const { t } = useLocalization();
    const { isAuth } = useSelector(state => state.appConfigs);

    const [error, setError] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [isLoadingBook, setIsLoadingBook] = useState(false);
    const [order, setOrder] = useState(null);
    const [passengers, setPassengers] = useState({ adt: 1, chd: 0, inf: 0, ins: 0 });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const rec_id = urlParams.get("rec");
    const session_id = urlParams.get("session");
    const source = urlParams.get("source");

    useEffect(() => {
        let isMounted = true;
    
        const getPassengers = async () => {
            try {
                const result = await API.getRecommendation(rec_id);
                if (isMounted) setPassengers(result);
            } catch (err) {
                console.log({ err });
            }
        };
    
        if (rec_id && session_id) {
            getPassengers();
    
            API.apiPrebook({ rec_id, session_id })
                .then(response => {
                    if (isMounted) {
                        const { routes, rec_id, price, fare_rules, included, partner_fees, client_fee, cashback } = response;
                        
                        const routesCountries = getRoutesCountries(routes, included);
                        const flightDocsType = getFlightDocsType(routesCountries);
    
                        setPreBookData({
                            routes,
                            recId: rec_id,
                            totalPrice: price,
                            partnerFees: partner_fees,
                            clientFees: client_fee,
                            fareRules: fare_rules,
                            included: included,
                            loadStatus: true,
                            flightType: flightDocsType,
                            cashback,
                        });
                    }
                })
                .catch(error => {
                    if (isMounted) setError(error.response);
                });
    
            API.getOptions().then(response => {
                if (isMounted) reduxDispatch(setOptions(response));
            });
        }
    
        reduxDispatch(
            setAppConfigs({
                showSearchBlock: false,
            })
        );
    
        return () => {
            isMounted = false;
            
            setPreBookData({
                routes: [],
                fareRules: [],
                included: [],
                recId: null,
                totalPrice: null,
                isLoad: false,
                loadStatus: false,
            });
    
            reduxDispatch(
                setAppConfigs({
                    showSearchBlock: true,
                })
            );
        };
    }, []);

    useEffect(() => {
        if (isLoad && !isLoadingBook) {
            dispatch(setAllLoadngData({ isPrebookLoading: false }));
        } else {
            if (isLoadingBook) {
                dispatch(setAllLoadngData({ isBookingLoading: true }));
            }
        }
    }, [isLoad, isLoadingBook]);

    useEffect(() => {
        document.title = `${t.page_booking || ""} | ${envConfigs.appTitle}`;
    }, [t]);

    const getCountries = data => {
        if (data.length > 1) {
            API.getCountries(data).then(response => {
                setCountries(response);
            });
        }
    };

    const resetCountriesData = () => {
        setCountries([]);
    };

    const setPayerEmailToPassenger = email => {
        dispatch(change('booking', 'passengers[0].email', email));
    };

    const setPayerPhoneToPassenger = phone => {
        dispatch(change('booking', 'passengers[0].phone', phone));
    };

    const setPassengerType = (type, index) => {
        dispatch(change('booking', 'passengers[' + index + '].type', type));
        dispatch(change('booking', 'passengers[' + index + '].gender', ''));
        dispatch(change('booking', 'passengers[' + index + '].isShowMiddleName', true));

        let documentItems;

        if (flightType === ONLY_RU_COUNTRY) {
            documentItems = ruDocumentsForAllCitizenship(type);
        } else if (flightType === FRIENDLY_COUNTRY) {
            documentItems = friendlyDocumentsForAllCitizenship(type);
        } else {
            documentItems = allDocumentsForAllCitizenship();
        }

        dispatch(change('booking', 'passengers[' + index + '].availableDocumentTypes', documentItems));

        setDocumentType(index, _.first(documentItems));
    };

    const setDocumentType = (index, type) => {
        dispatch(change('booking', 'passengers[' + index + '].document_type', type));
    };

    const clearMiddleName = index => {
        dispatch(change('booking', 'passengers[' + index + '].middle_name', ''));
    };

    const clearDocumentData = index => {
        dispatch(change('booking', 'passengers[' + index + '].document_number', ''));
        dispatch(change('booking', 'passengers[' + index + '].expiration_date', ''));
    };

    const setIsShowMiddleName = (index, status) => {
        dispatch(change('booking', 'passengers[' + index + '].isShowMiddleName', status));
    };

    const setAvailableDocumentTypes = (index, documents) => {
        dispatch(change('booking', 'passengers[' + index + '].availableDocumentTypes', documents));
    };

    const onUpdateCitizenship = (value, index) => {
        onSelectAvailableDocumentTypes(value, index);

        dispatch(change('booking', 'passengers[' + index + '].citizenship', value));
    };

    const onSelectAvailableDocumentTypes = (value, index) => {
        const { code } = value;
        const passengerType = formValues.passengers[index].type;

        let actualDocument = PSP;
        let actualAvailableDocumentTypes;

        if (code === RU) {
            setIsShowMiddleName(index, true);

            if (flightType === ONLY_RU_COUNTRY) {
                actualAvailableDocumentTypes = documentsForOnlyRu(passengerType);

                setAvailableDocumentTypes(index, actualAvailableDocumentTypes);

                actualDocument = passengerType === ADT ? PS : SR;
            } else if (flightType === FRIENDLY_COUNTRY) {
                actualAvailableDocumentTypes = documentsForFriendlyCountries(passengerType);

                setAvailableDocumentTypes(index, actualAvailableDocumentTypes);

                actualDocument = passengerType === ADT ? PS : PSP;
            } else {
                actualAvailableDocumentTypes = documentsForAllCountries();

                setAvailableDocumentTypes(index, actualAvailableDocumentTypes);
            }

            setDocumentType(index, actualDocument);
        } else {
            setIsShowMiddleName(index, false);
            clearMiddleName(index);

            actualAvailableDocumentTypes = _.filter(ALL_DOCUMENTS_ITEMS, function (o) {
                return o === NP;
            });

            setAvailableDocumentTypes(index, actualAvailableDocumentTypes);

            setDocumentType(index, NP);
        }

        clearDocumentData(index);

        return actualAvailableDocumentTypes;
    };

    const getDepartureDate = routeIndex => {
        const route = routes[routeIndex];
        const segment = route.segments[0];
        const date = segment.departure.time.split(' ')[0].split('.').reverse().join('-');
        return moment(date).format('YYYY-MM-DD');
    };
    const getArrivalDate = routeIndex => {
        const route = routes[routeIndex];
        const segment = route.segments[route.segments.length - 1];
        const date = segment.arrival.time.split(' ')[0].split('.').reverse().join('-');
        return moment(date).format('YYYY-MM-DD');
    };
    const getArrivalCountryCode = routeIndex => {
        const route = routes[routeIndex];
        const segment = route.segments[route.segments.length - 1];
        const arrivalCityCode = segment.arrival.city;
        return included?.city[arrivalCityCode]?.country;
    };

    const onSendBooking = async data => {
        setIsLoadingBook(true);

        const passengers = [];

        data.passengers.forEach((passenger, index) => {
            const {
                name,
                surname,
                middle_name,
                citizenship,
                gender,
                type,
                document_type,
                document_number,
                email,
                date_of_birth,
                phone,
                expiration_date,
            } = passenger;

            const passengerPhone = type === ADT ? normalizePhone(phone) : normalizePhone(data.passengers[0].phone);
            const passengerEmail = type === ADT ? email : data.passengers[0].email;
            const expirationDate = expiration_date ? expiration_date.split('.').join('-') : '12-12-2099';

            passengers[index] = {
                index,
                name: name,
                surname: surname,
                middle_name: middle_name || '',
                citizenship: citizenship.code,
                gender: gender,
                type: type,
                document_type: document_type,
                document_number: document_number,
                email: passengerEmail,
                phone: passengerPhone,
                expiration_date: expirationDate,
                date_of_birth: date_of_birth.split('.').join('-'),
            };
        });

        const params = {
            passengers,
            rec_id: recId,
            partner_fees: data.partnerFees || 0,
            session_id: session_id,
            payer_phone: normalizePhone(data.payerPhone),
            payer_email: data.payerEmail,
            payer_whatsapp: normalizePhone(data.payerWhatsApp),
            meta: {
                currency: currency,
                language: configs.mainConfigs.language,
            },
            ...(source ? { source } : {}),
        };

        if (selectedOptions.length) {
            for (let i = 0; i < routes.length; i++) {
                const routeOptions = selectedOptions.filter(option => option.routeIndex === i);
                if (routeOptions.length) {
                    const passengersOptions = [];
                    routeOptions.forEach(selected => {
                        const passengerIndex = passengersOptions.findIndex(x => x.index === selected.passengerIndex);
                        if (passengerIndex >= 0) {
                            passengersOptions[passengerIndex].options.push({
                                optionId: selected.optionId,
                                routeIndex: selected.routeIndex,
                            });
                        } else {
                            const passenger = passengers[selected.passengerIndex];
                            const birthday = moment(passenger?.date_of_birth.split('-').reverse().join('-')).format(
                                'YYYY-MM-DD'
                            );
                            const expirationDate = moment(passenger.expiration_date.split('-').reverse().join('.')).format(
                                'YYYY-MM-DD'
                            );
                            passengersOptions.push({
                                index: selected.passengerIndex,
                                name: passenger?.name,
                                surname: passenger?.surname,
                                middlename: passenger?.middle_name || '',
                                gender: passenger?.gender === M ? 'male' : 'female',
                                document_type: passenger?.document_type,
                                document_number: passenger?.document_number,
                                email: passenger?.email,
                                phone: normalizePhone(passenger?.phone),
                                date_of_birth: birthday,
                                citizenship: passenger?.citizenship,
                                expiration_date: expirationDate,
                                options: [{ optionId: selected.optionId, routeIndex: selected.routeIndex }],
                            });
                        }
                    });
                    await API.addOptions({
                        session_id,
                        start_date: getDepartureDate(i),
                        end_date: routes[i + 1]
                            ? getArrivalDate(i + 1)
                            : moment(getDepartureDate(i)).add(7, 'days').format('YYYY-MM-DD'),
                        country_code: getArrivalCountryCode(i),
                        passengers: passengersOptions,
                        segments: routes[i]?.segments?.map(segment => ({
                            start_date: segment.departure.time.split(" ")[0],
                            end_date: segment.arrival.time.split(" ")[0],
                            country_code: included.city[segment.arrival.city].country,
                            carrier_number: `${segment.operation_supplier}-${segment.carrier_number}`,
                        })) || [],
                    });
                }
            }
        }

        API.apiBook(params)
            .then(response => {
                setOrder(response.data);
                setRedirect(true);
                dispatch(setAllLoadngData({ isBookingLoading: false }));
            })
            .catch(error => {
                setError(error.response.data);
            });
    };

    const normalizePhone = phone => {
        const formattedPhone = phone.replace(/[^\d]/g, '');

        return `+${formattedPhone}`;
    };

    if (error) {
        return <ErrorModal {...error} sessionId={session_id} />;
    }

    if (redirect) {
        if (isAuth) return <Redirect to={{ pathname: `/order/${order.order_id}` }} />;

        return <Redirect to={{ pathname: `/order/${order.order_id}`, search: `?key=${order?.key}` }} />;
    }

    if (!rec_id || !session_id) {
        return <Redirect to={{ pathname: '/' }} />;
    }

    if (!isLoad || isLoadingBook) {
        return (
            <LoadingBlock>
                <Loading />
            </LoadingBlock>
        )
    };

    return (
        <BookingForm
            {...props}
            sessionId={session_id}
            passengers={passengers}
            onSendBooking={onSendBooking}
            onUpdateCitizenship={onUpdateCitizenship}
            getCountries={getCountries}
            countries={countriesItems}
            resetCountries={resetCountriesData}
            setPassengerType={setPassengerType}
            clearDocumentData={clearDocumentData}
            currency={currency}
            formValues={formValues}
            formErrors={syncErrors}
            setPayerEmailToPassenger={setPayerEmailToPassenger}
            setPayerPhoneToPassenger={setPayerPhoneToPassenger}
            onSelectAvailableDocumentTypes={onSelectAvailableDocumentTypes}
            cashback={cashback}
        />
    );
}

const mapStateToProps = state => ({
    countriesItems: state.setPreBookData.countriesItems,
    isLoad: state.setPreBookData.isLoad,
    totalPrice: state.setPreBookData.totalPrice,
    partnerFees: state.setPreBookData.partnerFees,
    clientFees: state.setPreBookData.clientFees,
    routes: state.setPreBookData.routes,
    included: state.setPreBookData.included,
    flightType: state.setPreBookData.flightType,
    recId: state.setPreBookData.recId,
    fareRules: state.setPreBookData.fareRules,
    filterString: state.setPreBookData.filterString,
    currency: state.appConfigs.currency,
    selectedOptions: state.setPreBookData.selectedOptions,
    cashback: state.setPreBookData.cashback,
    formValues: getFormValues('booking')(state),
    syncErrors: getFormSyncErrors('booking')(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPreBookData,
            setCountries,
            dispatch,
        },
        dispatch
    );

export default compose(connect(mapStateToProps, mapDispatchToProps))(BookingContainer);
