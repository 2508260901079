import styled from 'styled-components';

export const Container = styled('div')(({ theme, dWidth }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 236,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.25)',
    color: theme.palette.mainText,
    backgroundColor: theme.palette.inputBackground,
    borderRadius: theme.spacing(1.5),
    ...theme.marginsY(8, 0),
    ...theme.paddings(16, 16, 16, 16),

    '&:hover': {
        cursor: 'default',
    },

    '& > * ': {
        userSelect: 'none',
    },

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: dWidth ? 440 : 220,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const Line = styled('div')(({ theme }) => ({
    ...theme.margins(16, 0, 12),
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.line,
}));
