import styled from 'styled-components';
import Grid from '../../../../components/design_v2/ui/grid';

export const OrdersBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddingsY(24, 0),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddingsY(16, 0),
    },
}));

export const OrdersBlockTitle = styled(Grid)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.mainText,
}));
