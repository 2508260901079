import React, { useEffect, useState, useRef } from 'react';
import { Container } from '@material-ui/core';
import API from '../../lib/api';
import { useDispatch } from 'react-redux';
import { setAppConfigs } from '../../store/appConfigs/actions';
import useLocalization from '../../contexts/localization/hook';
import Loading from '../../components/design_v2/ui/loading/index';
import { useLocation } from 'react-router';
import NotFoundContent from '../../components/design_v2/ui/notFoundContent';
import envConfigs from '../../config/envConfigs';
import AboutUs from './aboutUs/AboutUs';
import { StaticPageBody, StaticPageContainer, StaticPageHeader, StaticPageInformBlock } from './components';

const StaticPages = props => {
    const {
        match: { params },
    } = props;
    const { slug } = params;
    const { t, language } = useLocalization();

    const location = useLocation();
    const dispatch = useDispatch();
    const containerRef = useRef(null);

    const [getInfo, setGetInfo] = useState([]);
    const [notFound, setNotFound] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(location.pathname);

    useEffect(() => {
        document.title = `${t.page_booking} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                showSearchBlock: true,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    useEffect(() => {
        document.title = `${t.page_booking} | ${envConfigs.appTitle}`;
    }, [t]);

    useEffect(() => {
        getStaticPage();
    }, [language]);

    useEffect(() => {
        if (location.pathname !== currentLocation) {
            getStaticPage();
            setCurrentLocation(location.pathname);
        }
    }, [location, currentLocation]);

    const executeScroll = () => {
        const y = containerRef?.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const getStaticPage = async () => {
        setIsLoading(true);
        setNotFound(false);

        await API.getStaticPageBySlug({ slug, language })
            .then(res => {
                res.data.footer_content.forEach(page => {
                    if (page.language === language) {
                        setGetInfo(page);
                        document.title = `${page.page_name} | ${envConfigs.appTitle}`;
                    }
                });
            })
            .catch(error => {
                setNotFound(true);
            });
        setIsLoading(false);
        executeScroll();
    };

    if (isLoading)
        return (
            <StaticPageContainer>
                <Loading />
            </StaticPageContainer>
        );

    return (
        <Container fixed ref={containerRef}>
            <StaticPageContainer>
                {notFound ? (
                    <NotFoundContent />
                ) : (
                    <StaticPageInformBlock>
                        { currentLocation === "/staticInfoPage/o-nas"
                        ? <AboutUs />
                        : <>
                            <StaticPageHeader>{getInfo.page_name}</StaticPageHeader>
                            <StaticPageBody dangerouslySetInnerHTML={{ __html: getInfo.body }} />
                        </>}
                    </StaticPageInformBlock>
                )}
            </StaticPageContainer>
        </Container>
    );
};

export default StaticPages;
