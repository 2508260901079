import styled from 'styled-components';

export const Button = styled("button")(({theme, marginTop, desktop}) => ({
    ...theme.mixins.flexCenterCenter,
    backgroundColor: theme.palette.transparent,
    color: theme.palette.main,
    border: `1px solid ${theme.palette.main}`,
    minWidth: 50,
    padding: '8px 12px',
    borderRadius: 100,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    height: 35,
    marginRight: 16,

    '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.buttonHover.exitButton,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'block',
        fontSize: 18,
        lineHeight: 1.6,
        margin: marginTop ? 16 : '0 0 16px',
        ...(desktop && {margin: 8}),
        border: 'none',
        padding: 0,
    }
}));

export const FormGroup = styled('div')(({}) => ({
    width: '100%',
    margin: '0 0 16px',
    position: 'relative',
}));

export const Label = styled('label')(({theme}) => ({
    position: 'absolute',
    fontSize: 12,
    top: 8,
    left: 16,
    fontWeight: 'bold',
    color: theme.palette.mainText,
}));

export const Input = styled('input')(({theme, error, touched}) => ({
    width: ' 100%',
    padding: '32px 16px 8px',
    fontSize: 16,
    border: `1px solid ${error && touched ?
        theme.palette.error : theme.palette.placeholderTextSearchOrder}`,
    borderRadius: 12,
    backgroundColor: theme.palette.inputBackground,

    "::placeholder": {
        color: theme.palette.placeholderTextSearchOrder
    }
}));

export const FormSubmitButton = styled("button")(({theme}) => ({
    margin: '12px auto 0',
    backgroundColor: theme.palette.main,
    border: 'none',
    borderRadius: 12,
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.button.searchButton,
    cursor: 'pointer',
    display: 'block',
    padding: '12px 32px',

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.subscribeBg,
    }
}));

export const ErrorText = styled('div')(({theme}) => ({
    color: theme.palette.error,
    fontSize: 14,
    marginTop: 4,
}));

export const NotFoundText = styled("div")(({theme}) => ({
    color: theme.palette.error,
    fontSize: 14,
    marginTop: 4,
    textAlign: 'center',
}));
