import styled from 'styled-components';

export const StaticPageContainer = styled('div')(({ theme }) => ({
    ...theme.paddings(64, 0),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddings(32, 0, 64),
    },
}));

export const StaticPageInformBlock = styled('div')(({ theme }) => ({
    width: '100%',
}));


export const StaticPageHeader = styled('h1')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.mainText,
}));

export const StaticPageBody = styled('p')(({ theme }) => ({
    fontSize: 16,

    '& img': {
        maxWidth: '100% !important',
        height: 'auto !important',
    },

    '& span': {
        maxWidth: '100% !important',
        height: 'auto !important',
        backgroundColor: `${theme.palette.bodyBg} !important`,
        color: `${theme.palette.mainText} !important`,
    },
}));

