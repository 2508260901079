import React from 'react';

import { useSelector } from 'react-redux';

import MainLoading from '../components/design_v2/mainLoading';

const LoadingProvider = ({ children }) => {
    const {
        isLocalizationLoading,
        isAuthCheckLoading,
        isSearchLoading,
        isPrebookLoading,
        isBookingLoading,
        isOrderLoading,
        isOrdersLoading,
    } = useSelector(state => state.loading);

    const checkShowLoading = isLocalizationLoading || isAuthCheckLoading || isSearchLoading || isPrebookLoading || isBookingLoading || isOrderLoading || isOrdersLoading;

    return (
        <>
            {children}
            {checkShowLoading && <MainLoading />}
        </>
    );
};

export default LoadingProvider;
