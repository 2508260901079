import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';


export const CarouselBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(8, 32),
}));

export const CarouselItem = styled('div')(({ theme }) => ({
    ...theme.paddings(8, 8),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
}));

export const CarouselItemLink = styled(NavLink)(({ theme }) => ({
    ...theme.paddingsX(16, 0),
    color: theme.palette.mainText,
    fontSize: 20,
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'uppercase',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 16,
    },
}));

export const CarouselItemImage = styled('img')(({ theme }) => ({
    ...theme.marginsY(0, 8),
    display: 'block',
    width: '100%',
    height: 225,
    borderRadius: theme.spacing(2),

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        height: 243,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        height: 300,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        height: 'calc(175px + (310 - 175) * ((100vw - 360px) / (600 - 360)))',
    },
}));

export const CarouselDots = styled('div')(({ theme }) => ({
    bottom: 'auto',
    top: 'calc(100% + 8px)',

    '& > .slick-active > div': {
        backgroundColor: theme.palette.main,
    },
}));

export const CarouselDot = styled('div')(({ theme }) => ({
    width: 16,
    height: 16,
    backgroundColor: theme.palette.button.clearRouteBg,
    borderRadius: '50%',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: 12,
        height: 12,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: 10,
        height: 10,
    },
}));

export const StyledSlider = styled(Slider)(({ theme }) => ({
    "& .slick-dots": {
        bottom: 'auto',
        top: 'calc(100% + 8px)',

        [`& .slick-active > div`]: {
            backgroundColor: theme.palette.main,
        },
    },
}));