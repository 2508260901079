import React from 'react';
import { useSelector } from 'react-redux';
import Search from '../search';
import { HeaderBlock, HeaderContainer } from './styles';
import Nav from './parts/nav';
import LogoUI from '../ui/logoUI';

const Header = () => {
    const { showHeader, showSearchBlock } = useSelector(store => store.appConfigs);

    if (showHeader) {
        return (
            <>
                <HeaderBlock>
                    <HeaderContainer fixed>
                        <LogoUI />
                        <Nav />
                    </HeaderContainer>
                </HeaderBlock>

                {showSearchBlock && <Search />}
            </>
        );
    }

    return null;
};

export default Header;
