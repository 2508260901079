import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Component = styled.div`
  background-color: #2f2f2f;
  @media (max-width: 959px) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainBlock = styled.div`
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 0;

  @media (max-width: 959px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Column = styled.div`

  @media (max-width: 959px) {
    margin: 0 auto;
    
  }
`;

export const LogoBlock = styled.div`
  margin-bottom: 16px;
  @media (max-width: 959px) {
    margin: 0 auto;
  }
`;

export const ContactUsText = styled.div`
  font-size: 18px;
  color: #fff;
  margin-bottom: 8px;
`;

export const ContactsEmail = styled.div`
  color: #fff;
  margin-bottom: 8px;

  a {
    color: #fff;
    text-decoration: none;
  }
`;

export const ContactPhone = styled.div`
  font-size: 18px;
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;
  }
`;

export const LinkToDownload = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 8px;

  &::nth-last-of-type(1) {
    margin-bottom: 0;
  }

  &:hover {
    color: #fbb600;
  }
`;

export const LinkToStaticPage = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #ffffff;
  border-radius: 8px
  text-align: center;
  padding: 8px 16px;

  &:hover {
    color: #fbb600;
    border-color: #fbb600;
  }
`;

export const SocialsBlock = styled.div`
  order: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  padding: 24px 0;

  @media (max-width: 959px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
  }
`;

export const SocialsLinks = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    color: rgb(231, 94, 94);
  }

  @media (max-width: 959px) {
    margin-top: 16px;
  }
`;

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #e4e9f3;
  border-radius: 50%;
  color: initial;
  margin-right: 16px;

  &:hover {
    color: #204dc0;
  }

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;

export const DontCopyText = styled.div`
  text-align: center;
  color: #fff;
  margin-bottom: 32px;
`;

export const PaymentMethods = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 959px) {
    align-items: center;
    justify-content: center;
  }
`;

export const PaymentMethodsText = styled.div`
  font-size: 18px;
  color: #fff;
  margin-bottom: 4px;
  text-align: end;
`;

export const PaymentMethodsLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  div {
    background: #ffffff;
    border-radius: 8px;
    padding: 2px
  }

  img {
    max-width: 60px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
`;

export const PaymentMethodLogo = styled.img`
  max-width: ${(props) => props.maxWidth};
`;
export const imgSize = styled.img`
  width: 50px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; // Добавляет поддержку переноса строк, если необходимо
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end; // Опционально для центрирования по горизонтали
  justify-content: center; // Опционально для центрирования по вертикали
  gap: 16px;

  @media (max-width: 960px) {
    align-items: center;
    margin: 10px auto;
  }

  @media (max-width: 800px) {
    margin-top: 48px;
  }
`;

export const HiddenOnMobileText = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  @media (max-width: 959px) {
    display: none;
  }
`;

export const LogoImg = styled.img`
  max-width: 200px;
`
