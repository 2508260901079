import React, { useState } from 'react';
import { Field } from 'redux-form';

import useLocalization from '../../../contexts/localization/hook';

import FormCheckbox from '../../ui/components/FormCheckbox/FormCheckbox';
import FormField from '../../ui/components/FormField/FormField';

import {
    BonusCardCheckbox,
    BonusCardContainer,
    BonusCardInput,
    BonusCardTitle,
} from './components';

export const AirlineBonusCard = () => {
    const { t } = useLocalization();
    const [isAirlineBonus, setIsAirlineBonus] = useState(false);

    return (
        <BonusCardContainer container>
            <BonusCardCheckbox item>
                <FormCheckbox
                    onSelect={() => setIsAirlineBonus(!isAirlineBonus)}
                    isChecked={isAirlineBonus}
                    backgroundColor='#fff'
                />
            </BonusCardCheckbox>

            <BonusCardTitle item>
                {t.airline_bonus_card}
            </BonusCardTitle>

            {isAirlineBonus && (
                <BonusCardInput item>
                    <Field
                        component={FormField}
                        name='bonusCard'
                        label={t.number_bonus_card}
                        widthSize='236px'
                        heightSize='15px'
                        isPadding={true}
                    />
                </BonusCardInput>
            )}
        </BonusCardContainer>
    );
};
