import React from "react";
import { IconBlock, TitleWrapper } from "./components";

const TitleUI = ({ as: Title = "h1", icon, children, position, textTransform }) => {
  // use position for title from props give left, center or right string: default position is "left"
    
    return (
      <TitleWrapper position={position} textTransform={textTransform}>
      {icon ? (
        <IconBlock>
          <img src={icon} alt="" />
        </IconBlock>
      ) : null}
      <Title>{children}</Title>
    </TitleWrapper>
    )
};

export default TitleUI;
