import React, { useState, useEffect } from 'react';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import API from '../../lib/api';
import {
    setAdditionallyOptions,
    setFilters,
    setRecommendations,
    setBrandFares,
    setBrandRules,
    resetAllFilters,
} from '../../store/resultData/actions';
import { setAppConfigs } from '../../store/appConfigs/actions';
import { setSearchParams } from '../../store/searchParams/actions';
import { setAllLoadngData } from '../../store/loading/actions';
import ResultForm from '../../components/result/resultForm';
import './index.css';
import { getDataForFilter } from './filters';
import ErrorModal from '../../components/ui/components/ErrorModal/ErrorModal';
import { decodeSearchId } from '../../lib/searchId';
import { decodeParamsFromUrl } from '../../lib/filters';

import { getFilterRecommendations, getSortingRecommendations, getPriceRange } from './utils';
import { OW, RT } from '../search/constants';
import { setAllFilterParams, setFiltersData } from '../../store/filters/actions';
import useLocalization from '../../contexts/localization/hook';
import envConfigs from '../../config/envConfigs';
import LimitErrorModal from '../../components/design_v2/ui/limitErrorModal';

const ResultContainer = () => {
    const { recommendations, nonPriceFlights, brandFares, brandRules, session, included, isLoad, searchData } = useSelector(
        state => state.resultData
    );
    const searchParams = useSelector(state => state.searchParams);
    const { currency } = useSelector(state => state.appConfigs);
    const { filterParams } = useSelector(state => state.filters);

    const dispatch = useDispatch();

    const history = useHistory();
    const location = useLocation();

    const { searchId } = useParams();
    const { t } = useLocalization();

    const [airlinesData, setAirlinesData] = useState([]);
    const [stateRoutesData, setStateRoutesData] = useState(null);
    const [priceRange, setPriceRange] = useState({});
    const [error, setError] = useState(null);
    const [limitError, setLimitError] = useState(false);

    useEffect(() => {
        document.title = `${t.page_results} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: true,
                isFullScreenSearchBlock: false,
            })
        );

        dispatch(
            setAllLoadngData({
                isSearchLoading: true,
            })
        );

        getParams(searchParams);

        return () => {
            dispatch(
                setRecommendations({
                    flights: [],
                    included: {},
                    session: null,
                    loadStatus: false,
                })
            );

            dispatch(resetAllFilters());
        };
    }, [location]);

    useEffect(() => {
        document.title = `${t.page_results || ""} | ${envConfigs.appTitle}`;
    }, [t]);

    useEffect(() => {
        if (recommendations.length > 0) {
            const priceRange = getPriceRange(recommendations, currency);

            dispatch(setFiltersData({ priceRange }));
            dispatch(setAllFilterParams(decodeParamsFromUrl(priceRange)));
        }
    }, [currency, recommendations]);

    useEffect(() => {
        if (isLoad || error) {
            dispatch(
                setAllLoadngData({
                    isSearchLoading: false,
                    searchData: null,
                })
            );
        }
    }, [isLoad, error]);

    const getParams = async params => {
        if (params.routes[0].from) {
            getResult(readySearchParams(params));
            dispatch(setAllLoadngData({ searchData: params }));
        } else {
            try {
                const result = await decodeSearchId(searchId);
                dispatch(
                    setAllLoadngData({
                        searchData: {
                            flightType: result.searchParams.flightType,
                            cabin: result.searchParams.cabin,
                            passengers: result.searchParams.passengers,
                            routes:
                                result.searchParams.flightType === 'OW'
                                    ? [
                                          {
                                              from: result.searchParams.searchRoutes[0].from,
                                              to: result.searchParams.searchRoutes[0].to,
                                              date_from: result.searchParams.searchRoutes[0].date_from,
                                          },
                                          {
                                              from: result.searchParams.searchRoutes[0].from,
                                              to: result.searchParams.searchRoutes[0].to,
                                              date_from: null,
                                          },
                                      ]
                                    : result.searchParams.searchRoutes,
                        },
                    })
                );

                dispatch(
                    setSearchParams({
                        flightType: result.searchParams.flightType,
                        cabin: result.searchParams.cabin,
                        passengers: result.searchParams.passengers,
                        routes:
                            result.searchParams.flightType === 'OW'
                                ? [
                                      {
                                          from: result.searchParams.searchRoutes[0].from,
                                          to: result.searchParams.searchRoutes[0].to,
                                          date_from: result.searchParams.searchRoutes[0].date_from,
                                      },
                                      {
                                          from: result.searchParams.searchRoutes[0].from,
                                          to: result.searchParams.searchRoutes[0].to,
                                          date_from: null,
                                      },
                                  ]
                                : result.searchParams.searchRoutes,
                    })
                );

                getResult(readySearchParams(result.searchParams));
            } catch (error) {
                setError(error);
            }
        }
    };

    const readySearchParams = searchData => {
        let params = {
            cabin: searchData.cabin,
            passengers: searchData.passengers,
            flight_type: searchData.flightType,
            routes: [],
        };

        if (searchData.searchRoutes) {
            params.routes = searchData.routes;
        } else {
            searchData.routes.map(route => {
                if (route.date_from) {
                    params.routes.push({
                        from: route.from.code,
                        to: route.to.code,
                        date: route.date_from,
                    });
                }
            });
        }

        if (searchData.flightType === RT && params.routes.length < 2) {
            params.flight_type = OW;
        }

        return params;
    };

    const getResult = searchParams => {
        API.search(searchParams).then(response => {
            dispatch(setSearchParams({ hasData: true }));

            if (response.error_code) {
                setError(response);
            } else if (response.msg) {
                setLimitError(true);

                dispatch(
                    setAllLoadngData({
                        isSearchLoading: false,
                        searchData: null,
                    })
                );
            } else {
                let { flights, nonPriceFlights, included, session } = response;

                if (flights.length > 0) {
                    let data = [];

                    _.map(flights, flight => {
                        if (flight.validating_supplier) {
                            data = [flight.validating_supplier, ...data];
                        }
                    });

                    const [filterTemplate, routesData] = getDataForFilter(flights);
                    const airlinesData = _.uniq(data);
                    const priceRange = getPriceRange(flights, currency);

                    setPriceRange(priceRange);
                    setAirlinesData(airlinesData);
                    setStateRoutesData(routesData);

                    dispatch(setFiltersData({ priceRange, routesData }));
                    dispatch(setAllFilterParams(decodeParamsFromUrl(priceRange)));
                    dispatch(
                        setFilters({
                            type: 'routesData',
                            status: filterTemplate,
                        })
                    );
                }

                dispatch(
                    setRecommendations({
                        nonPriceFlights,
                        flights,
                        included,
                        session,
                        loadStatus: true,
                    })
                );
            }
        });
    };

    const getBrandFares = recId => {
        if (brandFares.length === 0 || !brandFares[recId]) {
            const requestParams = {
                session_id: session,
                flight_type: searchParams.flightType,
                rec_id: recId,
            };

            API.brandFares(requestParams).then(response => {
                dispatch(
                    setBrandFares({
                        recId,
                        flights: response.flights,
                    })
                );
            });
        }
    };

    const getFareRules = recId => {
        if (brandRules.length === 0 || !brandRules[recId]) {
            API.apiPrebook({ rec_id: recId, session_id: session }).then(response => {
                dispatch(
                    setBrandRules({
                        data: response,
                        recId: recId,
                    })
                );
            });
        }
    };

    const bookingClick = (rec_id, session_id) => {
        dispatch(setAllLoadngData({ isPrebookLoading: true }));

        history.push(`/booking?session=${session_id}&rec=${rec_id}`);
    };

    if (error) {
        return <ErrorModal history={history} {...error} />;
    }

    if (limitError) {
        return <LimitErrorModal active={limitError} />;
    }

    if (!searchData.searchParams && !isLoad && !limitError) return null;

    const filterRecommendation = getFilterRecommendations(filterParams, recommendations, currency);
    const sortRecommendation = getSortingRecommendations(filterParams, filterRecommendation, currency);

    return (
        <ResultForm
            included={included}
            session={session}
            searchParams={searchParams}
            setFilters={setFilters}
            getBrandFares={getBrandFares}
            filterParams={filterParams}
            routesData={stateRoutesData}
            airlinesData={airlinesData}
            priceRange={priceRange}
            sortedRecommendations={sortRecommendation}
            originalRecommendations={recommendations}
            nonPriceFlights={nonPriceFlights}
            brandFares={brandFares}
            brandRules={brandRules}
            currency={currency}
            setAdditionallyOptions={setAdditionallyOptions}
            bookingClick={bookingClick}
            getFareRules={getFareRules}
        />
    );
};

export default withRouter(ResultContainer);
