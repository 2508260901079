import React from "react";

import logoImg from "../../../../assets/icons/newDesign/flyLogoColoredIcon.png";
import { LogoImg } from "./components";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const LogoUI = ({width = 180}) => {

  return (
    <NavLink to="/"><LogoImg src={logoImg} alt="logo" width={width} /></NavLink>
  )
}

export default LogoUI;