import React, { useEffect, useState } from "react";

import Container from "../ui/container";

import {
  Component,
  Block,
  MainBlock,
  Column,
  LogoBlock,
  ContactUsText,
  ContactsEmail,
  ContactPhone,
  LinkToDownload,
  LinkToStaticPage,
  SocialsBlock,
  SocialsLinks,
  SocialLink,
  DontCopyText,
  PaymentMethods,
  PaymentMethodsText,
  PaymentMethodsLogos,
  PaymentMethodLogo,
  FlexColumn,
  HiddenOnMobileText,
  LogoImg,
} from "./components";

import useLocalization from "../../../contexts/localization/hook";

import { ReactComponent as TelegramIcon } from "../../../assets/icons/newDesign/telegramIcon.svg";
import { ReactComponent as FlyLogoWhite } from "../../../assets/icons/newDesign/flyLogoWhite.svg";
import logo from "../../../assets/icons/newDesign/flyLogoColoredIcon.png"
import { ReactComponent as FacebookIcon } from "../../../assets/icons/newDesign/facebookIcon.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/newDesign/instagramIcon.svg";
import iataLogoWhite from "../../../images/mainLogo/iata-logo-white.png";
// import kortiMilliLogo from "../../../images/otherLogos/kort.png";
// import visaLogo from "../../../images/otherLogos/visa.png";
import API from "../../../lib/api";
import envConfigs from "../../../config/envConfigs";

import uzCard from "../../../images/paymentMethod/Uzcard.png";
import humo from "../../../images/paymentMethod/HUMO_LOGO.png";
import visa from "../../../images/paymentMethod/visa_payment.png";
import masterCard from "../../../images/paymentMethod/master_card.png";
import LogoUI from "../ui/logoUI";

const Footer = () => {
  const { t, language } = useLocalization();
  const [state, setState] = useState([]);

  useEffect(() => {
    staticInfoDiscriptions();
  }, []);

  const staticInfoDiscriptions = async () => {
    await API.getStaticDiscriptions().then((res) => {
      setState(res.data);
    });
  };

  const divideBlocks = (array, chunks) => {
    let result = [];
    let n = state.length;

    for (let i = 0; i < n; i += chunks) {
      result = [...result, array.slice(i, i + chunks)];
    }

    return result;
  };

  let columns = divideBlocks(state, 6);

  const renderCurrentYear = () => {
    const currentYear = new Date().getFullYear();

    return currentYear;
  };

  return (
    <Component>
      <Container fixed maxWidth="lg">
        <Block>
          <MainBlock>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                flexWrap: "wrap",
              }}
            >
              {columns.map((items, key) => {
                return (
                  <Column key={`footer-list${key}`}>
                    <HiddenOnMobileText>
                      Формальные документы
                    </HiddenOnMobileText>
                    <HiddenOnMobileText>Клиентам</HiddenOnMobileText>
                    <HiddenOnMobileText>Компания</HiddenOnMobileText>
                    {key === 0 && (
                      <LinkToStaticPage to={`/useful-info`}>
                        {t.useful_info || "Полезная информация"}
                      </LinkToStaticPage>
                    )}
                    <LinkToStaticPage to={`/refund-order`}>
                      {t.refund_order || "Заявление о возврате"}
                    </LinkToStaticPage>

                    {items.map((item, index) => {
                      const contentByLanguage = item.footer_content.find(
                        (content) => content.language === language
                      );
                      if (contentByLanguage?.attachment) {
                        return (
                          <LinkToDownload
                            href={`${envConfigs.uploadHost}${contentByLanguage?.attachment}`}
                            key={`list-${index}`}
                          >
                            {contentByLanguage?.page_name}
                          </LinkToDownload>
                        );
                      }

                      return (
                        <LinkToStaticPage
                          to={`/staticInfoPage/${item.slug}`}
                          key={`list-${index}`}
                        >
                          {contentByLanguage?.page_name}
                        </LinkToStaticPage>
                      );
                    })}
                  </Column>
                );
              })}
            </div>
            {/* Block N2 */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
            >
              <Column>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <LinkToStaticPage to={`/useful-informations`}>
                    {t.useful_info || "Полезная информация"}
                  </LinkToStaticPage>
                  <ContactUsText>{t.contact_us}:</ContactUsText>
                  <ContactsEmail>
                    {t.technical_support}:{" "}
                    <a href="mailto:info@flytrip.uz">info@flytrip.uz</a>
                  </ContactsEmail>
                  <ContactPhone>
                    Сабрина: <a href="tel:+998908309668">+998 90 830 96 68</a>
                  </ContactPhone>
                  <ContactPhone>
                    Виктория: <a href="tel:+998908304064">+998 90 830 40 64</a>
                  </ContactPhone>
                </div>
              </Column>

              <FlexColumn>
                <div>
                  <LogoUI />
                </div>
                <LogoBlock>
                  <Column>
                    <PaymentMethods>
                      <PaymentMethodsText>
                        {t.payment_methods || "Способы оплаты"}:
                      </PaymentMethodsText>
                      <PaymentMethodsLogos>
                        <div><PaymentMethodLogo src={uzCard} /></div>
                        <div><PaymentMethodLogo src={humo} /></div>
                        <div><PaymentMethodLogo src={visa} /></div>
                        <div><PaymentMethodLogo src={masterCard} /></div>
                      </PaymentMethodsLogos>
                    </PaymentMethods>
                  </Column>
                </LogoBlock>
              </FlexColumn>
            </div>
          </MainBlock>
          {/* Block N3 */}
          <SocialsBlock>
            <DontCopyText>Copyright © {renderCurrentYear()}</DontCopyText>
            <SocialsLinks>
              <SocialLink
                target="_blank"
                href="https://www.facebook.com/people/Fly-Trip-Uz/61564435212313/?locale=ru_RU"
              >
                <FacebookIcon />
              </SocialLink>

              <SocialLink
                target="_blank"
                href="https://www.instagram.com/flytrip.uz?igsh=MWMzZ3JoOTYxNGdpcQ=="
              >
                <InstagramIcon />
              </SocialLink>

              <SocialLink target="_blank" href="https://t.me/flytripuz_tashkent">
                <TelegramIcon />
              </SocialLink>
            </SocialsLinks>
          </SocialsBlock>
        </Block>
      </Container>
    </Component>
  );
};

export default Footer;
