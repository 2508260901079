const ru = {
  login_text: "Логин",
  password_text: "Пароль",
  forgot_password_text: "Забыли пароль?",
  registration_text: "Регистрация",
  enter_text: "Войти",
  invalid_password: "Минимум 6 символов",
  invalid_email: "Неверный email",
  required: "Обязательное поле",
  travel_time: "Время в пути",
  flight: "Рейс",
  terminal: "Терминал",
  baggage: "Багаж",
  hand_luggage: "Ручная кладь",
  refund_ticket: "Возврат билета",
  change: "Обмен билета",
  no_refund: "Нет возврата",
  yes_refund: "Есть возврат",
  no_change: "Нет обмена",
  yes_change: "Есть обмен",
  yes_baggage: "Есть багаж",
  no_baggage: "Нет багажа",
  yes_hand_luggage: "Есть ручная кладь",
  no_hand_luggage: "Нет ручной клади",
  show_rules: "Показать правила",
  total: "Итого",
  passengers: "Пассажиры",
  select_from_list: "Выбрать из списка",
  name_as_in_document: "Имя как в документе",
  middlename_as_in_document: "Отчество как в документе",
  surname_as_in_document: "Фамилия как в документе",
  date_of_birth: "Дата рождения",
  gender: "Пол",
  citizenship: "Гражданство",
  age: "Возраст",
  document: "Документ",
  payer: "Покупатель",
  for_payer_mess:
    "На почту (и WhatsApp) отправим информацию о бронировании. Телефон нужен для срочных оповещений при измении в расписании",
  series_and_number: "Серия и номер",
  valid_until: "Годен до",
  airline_bonus_card: "Бонусная карта авиакомпании",
  number_bonus_card: "Номер бонусной карты",
  phone_number: "Номер телефона",
  email: "Email",
  how_to_read_rules: "Как читать правила",
  tariff_rules: "Правила тарифа",
  book_and_proceed_to_payment: "Забронировать и перейти к оплате",
  agreement_book_link: "условиями тарифа авиакомпании",
  everywhere_local_time: "Везде указано местное время вылета и прибытия ",
  duration: "продолжительность",
  in: "в",
  no_data_contact_technical_support:
    "Данных нет. Обратитесь в техническую поддержку.",
  back_to_orders: "Назад к заказам",
  incorrect_date_from_adt: "Неверная дата для взрослого",
  incorrect_date_from_chd: "Неверная дата для ребенка",
  incorrect_date_from_inf: "Неверная дата для младенца",
  incorrect_date: "Неверная дата",
  check_date_and_change_doc:
    "Проверьте дату рождения или измените тип документа",
  warning_inf_date:
    "Авиакомпания может запросить справку о  допуске новорожденного к полету",
  ps_should_be_10_digits: "В паспорте РФ должно быть 10 цифр",
  psp_should_be_9_digits: "В загран. паспорте РФ должно быть 9 цифр",
  incorrect_doc_number: "Неверный номер документа",
  hint_for_ps_doc: "Укажите серию и номер паспорта, например 1234567890",
  hint_for_psp_doc: "Укажите номер паспорта, например 123456789",
  hint_for_sr_doc:
    "Укажите номер свидетельства о рождении,  например IXЯЯ123456",
  hint_for_np_doc: "Укажите серию и номер документа",
  male: "Мужской",
  female: "Женский",
  russian_passport: "Паспорт РФ",
  birth_certificate: "Свид-во о рождении РФ",
  international_passport: "Загран паспорт РФ",
  foreign_document: "Иностранный документ",
  place_one: "место",
  place_more: "места",
  place_many: "мест",
  customer_support: "Клиентская поддержка",
  facebook: "Facebook",
  currency: "Валюта",
  language: "Язык",
  search: "Поиск",
  personal_info: "Персональные данные",
  to_main: "На главную",
  log_out: "Выйти",
  session: "Session",
  balance: "Баланс",
  otb: "Баланс",
  own_funds: "Собственные средства",
  credit_line: "Кредитная линия",
  no: "Нет",
  for_route: "Для маршрута",
  only_a_z_space: "Только латинские символы и пробел",
  only_a_z: "Только латинские символы",
  invalid_phone: "Неверный номер телефона",
  min_2_symbol: "Минимум 2 символа",
  select_value_from_list: "Выбрать значение из списка",
  check_date: "Проверьте дату",
  incorrect_number: "Неверный номер карты",
  incorrect_cvc: "Неверный код",
  incorrect_card_holder: "Неверное имя держателя карты",
  incorrect_card_month: "Неверный месяц",
  incorrect_card_year: "Неверный год",
  code: "Код",
  message: "Сообщение",
  close: "Закрыть",
  error_message_part_1:
    "Кажется, что-то пошло не так. Чтобы мы смогли разобраться в причинах возникшей проблемы, пожалуйста, обратитесь в техподдержку",
  error_message_part_2: "и сообщите номер сессии",
  error_message_part_3:
    "После закрытия сообщения, Вы будете  перенаправлены на главную страницу сервиса.",
  choose: "Выбрать",
  collapse: "Свернуть",
  looking_tickets: "Ищем билеты",
  wait_please: "Пожалуйста, подождите",
  we_issue_tickets: "Мы выписываем билеты, это не займет много времени",
  created: "Создан",
  left: "Осталось",
  less_than_minute: "менее минуты",
  help: "Помощь",
  exchange_and_return_rules: "Обмен и возврат: правила",
  after_flight: "После полёта",
  go_to_section: "Перейти в раздел",
  next: "Дальше",
  not_book_tickets: "У вас нет забронированных авиабилетов",
  route_from: "Откуда",
  route_to: "Куда",
  date_from: "Туда",
  date_to: "Обратно",
  find: "Найти",
  one_way: "В одну сторону",
  two_way: "Туда и обратно",
  multi_way: "Сложный маршрут",
  economy: "Эконом класс",
  business: "Бизнес класс",
  first: "Первый класс",
  all_class: "Без привязки к классу",
  adt_main_label: "Взрослый",
  inf_main_label: "Младенец",
  chd_main_label: "Ребенок",
  adt_second_label: "от 12 лет и старше",
  chd_second_label: "2 - 12 лет",
  inf_second_label: "до 2 лет (без места)",
  inf_with_seat_second_label: "c местом",
  passenger_1: "Один пассажир",
  passenger_2: "Два пассажира",
  passenger_3: "Три пассажира",
  passenger_4: "Четыре пассажира",
  passenger_5: "Пять пассажиров",
  passenger_6: "Шесть пассажиров",
  agents: "Агентам",
  passenger_7: "Семь пассажиров",
  passenger_8: "Восемь пассажиров",
  passenger_9: "Девять пассажиров",
  only_there: "Туда",
  roundtrip: "Туда-обратно",
  client_name: "Имя клиента",
  client_code: "Код клиента",
  partner_code: "Код партнера",
  partner_name: "Имя партнера",
  partner_currency: "Валюта партнера",
  current_balance: "Текущий баланс",
  credit_limit: "Кредитный лимит",
  deposit: "Депозит",
  save: "Сохранить",
  departure_title: "Вылет",
  there_title: "Туда",
  back_title: "Обратно",
  validates_flights: "валидирует перелёты",
  hour_short: "ч",
  day_short: "дн",
  minutes_short_3: "мин",
  on_way: "В пути",
  no_transfers: "Без пересадок",
  class: "Класс",
  tariff_options: "Опции тарифа",
  with_luggage: "С багажом",
  without_luggage: "Без багажа",
  price: "Цена",
  detail: "Подробнее",
  label_price_by_min: "Цена (по возрастанию)",
  label_price_by_max: "Цена (по убыванию)",
  transfers: "Пересадки",
  all_flights: "Все рейсы",
  without_transfers: "Без пересадок",
  one_transfer: "1 пересадка",
  many_transfer: "2 пересадки и более",
  not_found_recomm_first_text:
    "Мы не смогли найти билеты, соответствующие  Вашему запросу",
  not_found_recomm_second_text:
    "Пожалуйста, попробуйте еще раз с другими  датами или аэропортом",
  not_found_recomm_by_filters: "Пожалуйста, измените условия фильтров",
  flight_details: "Детали рейса",
  change_tariff: "Сменить тариф",
  tariffs: "Тарифы",
  teh_stop: "Техническая остановка",
  transfer: "Пересадка",
  local_time_message: "Время вылета и прилета местное",
  route_tariffs_message: "Тариф действует на весь маршрут",
  airlines: "Авиакомпании",
  airports: "Аэропорты",
  departure: "Отправление",
  arrival: "Прибытие",
  departure_time: "Время вылета",
  arrival_time: "Время прилета",
  flight_number: "Номер рейса",
  multiple_numbers_separated_by_spaces: "Несколько номеров через пробел",
  lowcost: "lowcost",
  transfer_1: "пересадка",
  transfer_2_4: "пересадки",
  transfer_5: "пересадок",
  free_seats_0: "нет мест",
  free_seats_1: "место",
  free_seats_2_4: "места",
  free_seats_5: "мест",
  one_adt: "Взрослый",
  more_adt: "Взрослых",
  many_adt: "Взрослых",
  one_chd: "Ребенок",
  more_chd: "Ребенка",
  many_chd: "Детей",
  one_inf: "Младенец",
  more_inf: "Младенца",
  many_inf: "Младенцев",
  flight_one: "перелет",
  flights_more: "перелета",
  flights_many: "перелетов",
  found_one: "Найден",
  found_more: "Найдено",
  flight_overview: "Обзор перелёта",
  to_top: "Наверх",
  book: "Бронирование",
  buyer: "Покупатель",
  pnr: "Код бронирования / PNR",
  online_registration_locator: "Локатор для онлайн регистрации",
  route_information: "Информация о маршруте",
  order: "Заказ",
  flights_tickets: "Авиабилеты",
  void_text: "Вы можете аннулировать заказ в любой момент",
  void_order: "Аннулировать заказ",
  payment_method: "Способ оплаты",
  left_before_the_time_limit: "осталось до истечения таймлимита",
  expires: "Истекает",
  payment_timed_out: "Время оплаты истекло",
  time_expired: "Время истекло",
  order_created: "Заказ создан",
  return_ticket: "Вернуть билет",
  download_itinerary_receipt: "Скачать маршрутную квитанцию",
  order_cost: "Стоимость заказа",
  void_in_process: "Идет отмена заказа",
  initial: "Создан заказ",
  success: "Успешная оплата, билеты выписаны",
  booking_fail: "Ошибка при бронировании",
  order_fail: "Ошибка при создании заказа",
  pay_fail: "Ошибка при оплате",
  order_cancel_from_client: "Заказ отменен клиентом",
  order_cancel_from_admin: "Заказ отменен администратором",
  order_cancel_by_job: "Заказ отменен по таймлимиту",
  order_cancel_error: "Ошибка при отмене заказа",
  application_refund: "Создана заявка на возврат средств",
  partial_refund: "Частичный возврат средств по заказу",
  refund: "Полный возврат средств по заказу",
  ticketing_done: "Успешная оплата, билеты выписаны",
  pay_success: "Успешная оплата, билеты в процессе выписки",
  booking_done: "Билет забронирован",
  exchange: "Обмен билета",
  void: "Заказ аннулирован",
  ticketing_fail: "Ошибка при выписке билетов",
  year_one: "год",
  year_more: "года",
  years: "лет",
  day_one: "день",
  day_more: "дня",
  days: "дней",
  hour_one: "час",
  hour_more: "часа",
  hours: "часов",
  minute_one: "минута",
  minute_more: "минуты",
  minutes: "минут",
  second_one: "секунда",
  second_more: "секунды",
  seconds: "секунд",
  your_name: "Ваше имя",
  search_order: "Поиск заказа",
  locator: "Локатор",
  cancel: "Отмена",
  do_not_find_order_message:
    "Заказ не найден. Пожалуйста, измените параметры поиска",
  orders: "Заказы",
  main: "Основные",
  users: "Пользователи",
  all_orders_label: "Все заказы",
  book_done_label: "Забронирован",
  pay_done_label: "Оплачен",
  ticket_done_label: "Выписан",
  cancel_done_label: "Отменен",
  wait_refund_label: "Ожидает возврата",
  refund_done_label: "Возвращен",
  partial_refund_done_label: "Частично возвращен",
  void_done_label: "Аннулирован",
  error_order_label: "Ошибка заказа",
  date_create_from: "Дата создания с",
  order_status_label: "Статус заказа",
  search_title: "Введите фамилию, локатор или город",
  date_create_to: "Дата создания по",
  clear: "Очистить",
  passenger_success_save: "Пассажир успешно сохранен",
  payment_with_dpt: "Оплатить с баланса",
  payment_with_unt: 'Оплатить через "Uniteller"',
  payment_with_spt: 'Оплатить с "Корти милли" и "VISA"',
  before: "до",
  month_one: "месяц",
  months_more: "месяца",
  months: "месяцев",
  all_passengers: "Все пассажиры",
  companies: "Компании",
  no_passengers: "У Вас пока нет сохраненных пассажиров",
  value: "Значение",
  reset: "Сбросить",
  invalid_value: "Неверное значение",
  no_orders_found: "Заказы не найдены",
  personal_data: "Личные данные",
  upload: "Загрузить",
  your_logo: "Ваш логотип",
  image_too_large: "Слишком большой объем изображения",
  image_resolution_too_high: "Слишком большое разрешение изображения",
  image_resolution_too_small: "Слишком маленькое разрешение изображения",
  amount: "Сумма",
  rules_text_1: "Обратите внимание на следующие фразы в разделе",
  rules_text_2: "билет не подлежит возврату",
  rules_text_3: "билет не подлежит возврату в случае неявки на рейс",
  rules_text_4: "Правила обмена описаны в разделе с подзаголовком",
  line: "Строка",
  rules_text_5:
    "означает, что изменения не разрешены, поэтому поменять даты полета невозможно",
  passenger_data: "Данные пассажира",
  comment: "Комментарий",
  remove: "Удалить",
  created_at: "Дата создания",
  phone: "Телефон",
  create_user: "Создать пользователя",
  add: "Добавить",
  edit: "Редактировать",
  confirm: "Подтвердить",
  record_one: "запись",
  record_more: "записи",
  record_many: "записей",
  security_guarantees_unitaller: "Гарантии безопасности",
  activation_title: "Активация пользователя",
  confirmation_activation_password: "Подтвердите пароль",
  activation: "Активация",
  login: "Авторизация",
  error: "Ошибка",
  to_login_form: "На страницу авторизации",
  expired_date: "Истек срок действия",
  refund_button_title: "Возврат",
  itinerary_receipt: "Маршрутная квитанция",
  action: "Действие",
  to_pay: "Оплатить",
  order_cancel_error_mess: "Ошибка при отмене заказа",
  order_cancel_successfully_mess: "Успешная отмена",
  partner: "Партнер",
  inn: "ИНН",
  kpp: "КПП",
  logo: "Логотип",
  status: "Статус",
  return_request_created: "Создана заявка на возврат",
  error_creating_refund_request: "Ошибка при создании заявки на возврат",
  placeholder_date_picker: "дд.мм.гггг",
  transactions: "Транзакции",
  weight_unit: "кг",
  approved_balance_request_successfully:
    "Заявка на выписку с баланса подтверждена",
  error_approved_balance_request_successfully:
    "Ошибка при подтверждении заявки на выписку с баланса",
  balance_payment_request: "Заявка на выписку с баланса",
  balance_payment_approved: "Заявка на выписку с баланса подтверждена",
  payment_with_uzc: 'Оплатить с "Uzcard"',
  client: "Клиент",
  was_changed_to: "был изменен на",
  registration: "Регистрация",
  fare: "Тариф",
  fees: "Сборы",
  partner_fees: "Сборы партнера",
  taxes: "Налоги",
  ticket: "Билет",
  pay_for_the_order: "Оплатить заказ",
  operating_company: "Оперирующая компания",
  download_charter_certificate: "Скачать чартерный сертификат",
  certificate_issued: "Выписан чартерный сертификат",
  charter: "Чартер",
  service: "Сервис",
  income: "Приход",
  spending: "Расход",
  remainder: "Остаток",
  description: "Описание",
  contract: "Договор",
  no_result: "Нет результатов",
  date: "Дата",
  order_num: "Номер заказа",
  payment_with_vsl: 'Оплатить с "Корти милли" и "VISA"',
  popular_destinations: "Популярные  направления",
  more_options: "Ещё варианты",
  straight: "Прямые",
  with_transfer: "С пересадками",
  contacts: "Контакты",
  difficult_routes: "Cоставить сложный маршрут",
  search_history: "История поиска",
  find_tickets: "найти билеты",
  tours_discount: "Выгодные туры и скидки",
  last_news: "Последние новости",
  more_details: "Подробнее",
  about_company: "О компании",
  customer_rew: "Отзывы клиентов",
  my_profile: "Мой профиль",
  contact_us: "Свяжитесь с нами",
  last_name: "Фамилия",
  fist_name: "Имя",
  enter_name: "Введите свое имя",
  enter_surname: "Введите свою фамилию",
  enter_password: "Введите ваш пароль",
  log_in: "Войти",
  return_to_simple_route: "Вернуться к простому маршруту",
  delete: "Удалить",
  max_num_flights: "Вы добавили максимальное количество перелётов",
  select_dep_date: "Выберите дату вылета",
  select_return_date: "Выберите дату возвращения",
  select_class: " Выберите класс",
  login_as_user: "Войдите как пользователь",
  enter_recieved_code: "Введите полученный код",
  come_up_your_pass: "Придумайте свой пароль",
  enter_valid_mail: "Введите валидный email",
  must_field_characters: "поле должно содержать минимум 6 символов",
  register_to_save_data: "Зарегистрируйтесь чтобы сохранить свои данные",
  ins_main_label: " Младенец",
  ins_second_label: " до 2 лет (с местом)",
  newsletter_subscription: "Подписка на рассылку",
  newsletter_subscription_text:
    "Подпишитесь, чтобы быть в курсе всех важных новостей и выгодных предложений!",
  subscribe: "Подписаться",
  successfully_subscribed: "Вы успешно подписались!",
  subscription_error: "Ошибка при подписке, повторите попытку позже!",
  all_news: "Все новости",
  tel: "Тел",
  enter_email: "Введите свой email",
  menu: "Меню",
  settings: " Настройки",
  deposit_method: "Выберите метод пополнения",
  recharging_amount: "Сумма пополнения",
  recharge: " Пополнить",
  recharging_balance: "Пополнить баланс",
  done: " Готово",
  not_found_content: " Не найдено",
  not_found_content_text:
    " К сожалению, данный контент не доступен на выбранном языке",
  airport_code: "Код аэропорта",
  from_city: "Из г.",
  to_city: "В город",
  weekdays: "Дни недели",
  airline: "Авиакомпания",
  min_number: "Минимальная сумма пополнения ",
  only_numbers: " Только числа",
  daily: "Ежедневно",
  fly_tagline: "Билеты в два клика, удобно и легко",
  sorting: "Сортировка",
  i_read_fares: "Я ознакомился с",
  i_accept_fares: "и соглашаюсь с ними",
  prices_table: "Таблица цен",
  continue: "Продолжить",
  reset_error_title: "Ошибка",
  reset_error_content: "Извините, ошибка произошла во время выполнение запроса",
  reset_success_title: "Вы успешно изменили свой пароль!",
  reset_success_content:
    'Пожалуйста, нажмите кнопку "Войти", чтобы совершить вход с новым паролем',
  forgot_success_title: "Успешно!",
  forgot_success_content:
    "Ссылка на сброс пароля была отправлена на вашу почту",
  forgot_error_title: "Ошибка",
  forgot_error_content: "Извините, но Вы ввели неправильный E-Mail",
  password_confirm: "Подтверждение пароля",
  password_confirm_error: "Пароли не совпадают",
  enter_password_confirm: "Подтвердите пароль",
  enter_new_password: "Введите новый пароль",
  forgot_password: "Забыли пароль?",
  forgot_password_summary: "Введите свою электроную почту для сброса пароля",
  payment: 'Оплата по "Корти милли"',
  from_airport: "Из аэропорта",
  not_enough_balance: "Недостаточно средств для оплаты",
  payment_methods: "Способы оплаты",
  our_partners: "Наши партнеры",
  confirm_actions: "Подтвердите ваше действие на сайте",
  payer_contacts: "Контакты покупателя",
  buyer_email: "Email покупателя",
  enter_buyer_email: "Введите email покупателя",
  locator_order_number_or_booking_number:
    "Локатор, номер заказа или номер бронирования",
  enter_locator_order_number_or_booking_number:
    "Введите локатор, номер заказа или номер бронирования",
  activate: "Активировать",
  register: "Регистрируйся",
  page_home: "Онлайн покупка билетов, без посещения касс.",
  page_faq: "Ответы на популярные вопросы",
  page_login: "Соверши вход в свой личный кабинет",
  page_registration: "Регистрируйся и покупай авиабилеты",
  page_results: "Результаты по интересующему маршруту",
  page_booking: "Бронирование заданного билета",
  page_order: "Информация о совершенном заказе",
  page_orders_list: "Информация о совершенных заказах",
  page_my_profile: "Личная информация",
  page_add_balance: "Пополнение баланса личного кабинета",
  page_popular_destinations: "Расписание популярных вылетов",
  page_news: "Популярные новости авиации и Fly TJ",
  page_forgot_password: "Покупка авиабилетов Онлайн не выходя из дома!",
  page_reset_password: "Покупка авиабилетов Онлайн не выходя из дома!",
  useful_info: "Полезная информация",
  copy_link: "Скопировать ссылку",
  copy_order_link_text:
    "Скопируйте ссылку, чтобы поделиться доступом к данному заказу",
  link_copied_to_clipboard: "Ссылка скопирована в буфер обмена",
  unsubscribe_success: "Вы успешно отписались от рассылки на новость!",
  unsubscribe_error: "Произошла ошибка во время обработки",
  activation_code_send_to_email:
    "Код для подтверждения будет отправлен вам на указанную почту",
  choose_gender: "Выберите пол",
  old_information: "Информация устарела!",
  refresh: "Обновить",
  refresh_page:
    "Цены на авиабилеты обновляются несколько раз в день. Обновите страницу для проверки актуальных цен.",
  will_take_2_minutes: "Это может занять некоторое время",
  additional_options: "Дополнительные услуги",
  additional_options_not_found: "Дополнительные услуги не найдены!",
  your_id: "Ваш ID",
  cheapest_without_baggage: "Самый дешевый без багажа",
  cheapest_with_baggage: "Самый дешевый с багажом",
  most_convenient_transfes: "Самая удобная пересадка",
  refund_order: "Заявление о возврате",
  refund_reason: "Причина возврата",
  order_payment_method: "Метод оплаты заказа",
  payer_user_id: "ID пользователя оплатившего заказ",
  four_last_digits_of_card: "4 последних цифры карты оплаты",
  user_id: "ID пользователя",
  buyer_phone: "Телефон покупателя",
  requisite_of_account_to_return:
    "Реквизиты счета на который будет осуществлен возврат средств",
  requisite_of_account: "Реквизиты счета",
  passenger_name_and_surname: "Фамилия и имя пассажира",
  passport_serial_and_number: "Серия и номер паспорта",
  passport_copy: "Копия паспорта",
  send: "Отправить",
  payment_from_client_balance: "Оплата с баланса клиента",
  payment_from_card: "Оплата с помощью карты",
  technical_support: "Техническая Поддержка",
  buy_tickets_to_all_destinations: "и покупай авиабилеты по всем направлениям!",
  filters: "Фильтры",
  night_flight: "Ночная пересадка",
  change_airport: "Смена аэропорта",
  and: "и",
  from: "от",
  refund_order_success: "Успешно отправлено заявление о возврате",
  refund_order_error: "Ошибка при отправке заявления о возврате",
  buy_tour: "Купить тур",
  search_limits_exceeded:
    "Уважаемый пользователь, Вы превысили количество поисков, пожалуйста пройдите авторизацию/регистрацию чтобы дальше продолжить свою работу",
  flight_without_seats: "К сожалению, закончились места в данном классе но есть места в эконом классе",
  cashback: "Кэшбек",
  or_use_socials: "или используйте соц. сети",
  insurance: "Страхование",
  organization_info: "Общество с ограниченной ответственностью «АНТАРЕС»",
  organization_address: "Фактический адрес: Республика Таджикистан, город Душанбе, улица Бухоро 32-34,",
  inn_address: "ИНН 030018191",
  ein_address: "ЕИН 0310005585",
  we_are_multifaceted: "Мы многогранны",
  who_are_we: "Кто мы?",
  fly_information: `
    «FLY.TJ» работает на рынке с 2010 года и стала одной из самых успешных компаний в отрасли авиаперевозки.
    Также имеем официальный статус IATA «Аккредитованный агент IATA»,
    Сейчас мы являемся первой и единственной компанией в Таджикистане,
    имеющей собственную национальную систему онлайн бронирования с доступом и прямым партнерством с большим
    количеством международных авиакомпаний, таких как:
    Turkish Airlines, FlyDubai, Air Astana, Узбекистан Airways, Belavia, China Southern и другие.
  `,
  our_advantages: "Наши преимущества",
  our_services: `
  Спектр услуг, которые оказывает наша компания, весьма разнообразна.
  Помимо пассажирских авиаперевозок, наши специалисты также помогут Вам в организации
  Вашей деловой поездки (забронировать отель, оформить страховой полис и визу).
  Компания «FLY.TJ» является лидером продаж авиабилетов по всем направлениям на рынке авиаперевозок.
  За это время нами была разработана и оптимизирована онлайн система по реализации авиабилетов,
  которая не имеет аналогов в Таджикистане. Данная система позволяет Вам легко и просто оценивать,
  бронировать и покупать авиабилеты самостоятельно на сайт.
  `,
  convenience: "Удобство",
  user_friendly_interface: "Понятный интерфейс",
  access247: "Доступ 24/7",
  purchase_of_tickets_to_any_destinations: "Покупка билетов на любые направления",
  uniqueness: "Уникальность",
  access_to_a_unique_product: "Доступ к уникальному продукту",
  always_on_the_phone: "Всегда на связи",
  customer_information_support_247: "Информационная поддержка клиентов 24/7",
  without_providing_data_to_third_parties: "Без предоставления  данных третьим лицам",
  privacy: "Конфиденциальность",
  safety: "Безопасность",
  secure_connection: "Безопасное соединение",
  professionalism: "Профессионализм",
  prompt_service: "Оперативное обслуживание",
  soft: "Софт",
  own_booking_system: "Собственная система бронированияm",
  we_will_send_the_ticket_to_your_inbox_within_5_minutes: "Мы отправим билет на вашу почту в течении 2 - 3 минуты.",
  go_to_the_main_page: "Перейти на главную страницу",
  your_order_has_been_successfully_paid_for: "Ваш заказ успешно оплачен!",
  the_ticket_will_be_sent_to_your_email_within_1_2_minutes: "Электронный билет будет отправлен на Вашу почту в течение 1-2 минут",
  mon: "пн",
  tues: "вт",
  wed: "ср",
  thur: "чт",
  fri: "пн",
  sat: "сб",
  sun: "вс",
  show_more: "Показать ещё",
  go_to_the_payment_page: "Перейти на страницу оплаты",
  no_orders_to_confirm_payment: "Нет заказов для подтверждения оплаты",
  go_to_the_orders_page: "Перейти на страницу заказы",
  change_the_tariff: "Меняйте тариф: Багаж, класс и т.д",
  add_baggage: "Поменять тариф",
  baggage_included: "Багаж включён",
  fare_without_baggage: "Тариф без багажа",
  share: "Поделиться",
  passenger_search: "Поиск пассажиров",
  this_passenger_is_not_found_on_your_list: "Данный пассажир не найден в вашем списке",
  go_to_orders_page: "Перейти на страницу заказов",
};

export default ru;
