import styled from 'styled-components';

export const Input = styled('input')(({ theme, isActive }) => ({
    backgroundColor: theme.palette.transparent,
    width: 397,
    height: 60,
    margin: 0,
    border: 'none',
    borderRadius: `0 ${theme.spacing(1.25)} ${theme.spacing(1.25)} 0`,
    fontSize: '1rem',
    position: 'relative',
    left: 16,
    cursor: 'pointer',
    color: theme.palette.searchTextForm,
    zIndex: isActive ? 10 : 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        borderRadius: theme.spacing(1.5),
        width: '100%',
    },

    '::placeholder': {
        color: theme.palette.searchTextForm,
    },
}));

export const Icon = styled('img')(({ active }) => ({
    display: active ? 'block' : 'none',
    position: 'absolute',
    right: 8,
    top: 'calc(50% - 12px)',
}));
