import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    font-family: Roboto, sans-serif;
    caret-color: #ff7043;
    background-color: #fbfcff;
    height: 100%;

    &.hidden {
      overflow-y: hidden;
    }

    /* @media (min-width: 1280px) and (max-width: 1300px) {
      overflow-y: overlay;
    } */
  }
`;

export const AppContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    flex: '1 0 auto',
    zIndex: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.bodyBg,
}));

export const StyledButton = styled.button`
  width: ${props => props.widthSize || '174px'};
  height: ${props => props.heightSize || '77px'};
  border-radius: 12px;
  background-color: #fbb600;
  font-weight: bold;
  font-size: ${props => props.fontSize || '20px'};
  color: ${props => props.colorType || '#ffffff'};
  cursor: pointer;
  border: none;
  max-width: 600px;
  margin: ${props => props.marginAdd || null};

  &:active {
    background-color: #fbb600;
  }

  &:focus {
    outline: none;
  }

  :disabled {
    background-color: grey;
    color: #fff;
    cursor: default;
  }

  @media (max-width: 599px) {
    width: 335px;
    font-size: 18px;
  }
`;



export const StyledVoidButton = styled(StyledButton)`
  font-size: 16px;
  width: 206px;
`;




export const StyledInput = styled('input')(({ theme, isPadding, heightSize, widthSize, borderColor, disabled, textTransform }) => ({
    ...(isPadding ? { ...theme.paddings(30, 2, 8, 16) } : {}),
    height: heightSize || 52,
    width: widthSize || 297,
    borderRadius: theme.spacing(0.5),
    border: `solid ${borderColor ? '2px' : '1px'} ${borderColor || theme.palette.inputBorder}`,
    backgroundColor: disabled ? theme.palette.disabledInputBg : theme.palette.inputBackground,
    fontSize: 18,
    color: disabled ? theme.palette.disabledInputText : theme.palette.mainText,
    textTransform: textTransform || 'none',

    ':focus': {
        outline: 'none',
        border: `solid 1px ${theme.palette.inputUiBorder}`,
    },
}));

export const StyledLink = styled.a`
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
`;

export const InvalidMessage = styled.div`
  font-size: 14px;
  color: #c62828;
`;

export const WarningMessage = styled.div`
  font-size: 14px;
  color: #ff7043;
`;

export const ErrorsFieldBlock = styled.div`
  text-align: left;
`;

export const FormFieldLabel = styled.label`
  font-size: 12px;
  color: #9da8ae;
  position: absolute;
  top: 10px;
  left: 16px;
`;

export const FormGroup = styled.div`
  position: relative;
  width: 100%;
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
`;

export const ArrowDown = styled(Arrow)`
  border-top: 5px solid ${props => (props.isInvert ? '#212121' : '#ffffff')};
`;

export const ArrowUp = styled(Arrow)`
  border-bottom: 5px solid ${props => (props.isInvert ? '#212121' : '#ffffff')};
`;

export const SelectArrowDown = styled(ArrowDown)`
  margin-left: 0;
`;

export const InvertSelectArrowDown = styled(SelectArrowDown)`
  border-top: 8px solid #212121;
`;

export const LinkText = styled.div`
  display: inline-block;
`;

export const ResponsiveInput = styled('input')((
    { theme, isPadding, borderColor, disabled, textTransform }) => ({
    ...(isPadding ? { ...theme.paddings(30, 2, 8, 16) } : {}),
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(1.5),
    border: `solid ${borderColor ? '2px' : '1px'} ${borderColor || theme.palette.placeholderTextSearchOrder}`,
    backgroundColor: disabled ? theme.palette.disabledInputBg : theme.palette.inputBackground,
    fontSize: 18,
    color: disabled ? theme.palette.disabledInputText : theme.palette.mainText,
    textTransform: textTransform || 'none',
    boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',

    ':focus': {
        outline: 'none',
        border: `solid 1px ${theme.palette.main}`,
    },

    '::placeholder': {
        color: theme.palette.inputPlaceholderText,
        fontSize: 17,
    },
}));


