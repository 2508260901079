import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Navigation = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: 500,
    margin: '50px auto',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.margins(30, 0),
        justifyContent: 'space-around',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.enterAndRegistrationBorderBottom}`,
    },
}));

export const NavigationLink = styled(Link)(({ theme, active }) => ({
    ...theme.paddings(15, 25),
    fontSize: 28,
    color: active ? theme.palette.main : theme.palette.mainText,
    textDecoration: 'none',
    borderBottom: active ? `2px solid ${theme.palette.main}` : 'none',
    fontWeight: 600,

    '&:hover': {
        color: theme.palette.main,
        borderBottom: `2px solid ${theme.palette.main}`,
    },

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        fontSize: 24,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 18,
        borderBottom: 'none',

        '&:hover': {
            borderBottom: 'none',
        },
    },
}));

export const Form = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
    ...theme.margins(0, 'auto', 100),
    width: 500,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const FormBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
    width: 500,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const FormTitle = styled('div')(({ theme }) => ({
    fontSize: 50,
    color: theme.palette.mainText,
    textAlign: 'center',
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        fontSize: 36,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 24,
    },
}));

export const FormSubtitle = styled('div')(({ theme }) => ({
    fontSize: 20,
    color: theme.palette.mainText,
    textAlign: 'center',
    marginBottom: 30,
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        fontSize: 16,
    },
}));

export const FormGroup = styled('div')(({ theme }) => ({
    ...theme.margins(0, 'auto', 30),
    width: '100%',
    maxWidth: 380,
}));

export const FormSubmitButton = styled('button')(({ theme, marginAdd, heightAdd, widthSize }) => ({
    margin: marginAdd ? '0 auto 20px' : '20px auto',
    height: heightAdd ? '60px' : '70px',
    minWidth: widthSize || '184px',
    backgroundColor: theme.palette.main,
    border: 'none',
    borderRadius: theme.spacing(1.5),
    fontSize: 28,
    fontWeight: 600,
    color: theme.palette.button.buttonWhite,
    cursor: 'pointer',
    display: 'block',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        height: 60,
        fontSize: 24,
    },
}));

export const MobileBlock = styled('div')(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'block',
    },
}));

export const DesktopBlock = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'none',
    },
}));

export const ForgotPasswordLink = styled(Link)(({ theme }) => ({
    color: theme.palette.main,
    fontSize: 16,
    fontWeight: 'inherit',
    textDecoration: 'none',
}));

const BackgroundFilledText = styled('div')(({ theme }) => ({
    ...theme.paddings(0, 8),
    backgroundColor: theme.palette.bodyBg,
    lineHeight: 1,
    color: theme.palette.mainText,
}));

export const LineWithText = styled(({ children, ...props }) => (
    <div {...props}>
        <BackgroundFilledText>
            {children}
        </BackgroundFilledText>
    </div>
))(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.margins(20, 'auto'),
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.placeholderTextSearchOrder,
}));

export const SocialsButtons = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    gap: 24,
    marginTop: 12,
}));

export const SocialButton = styled('button')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    backgroundColor: theme.palette.transparent,
    padding: 0,
    margin: 0,
    border: 'none',
    cursor: 'pointer',
}));
