import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router';

import { setAppConfigs } from '../../store/appConfigs/actions';
import LoginPage from '../../pages/newLogin';
import useLocalization from '../../contexts/localization/hook';
import envConfigs from '../../config/envConfigs';

const LoginContainer = () => {
    const { isAuth } = useSelector(state => state.appConfigs);
    const { t } = useLocalization();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: false,
            })
        );
    }, []);

    useEffect(() => {
        document.title = `${t.page_login || ""} | ${envConfigs.appTitle}`;
    }, [t]);

    if (isAuth) return <Redirect to="/" />;

    return <LoginPage />;
};

export default withRouter(LoginContainer);
