import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const OpenTicketById = (recommendation, setModalShow) => {
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let splitedRecId;

  if (!recommendation.rec_id) {
    splitedRecId = recommendation?.id;

  } else {
    splitedRecId = recommendation.rec_id.split("|")[0];
  }
    

  useMemo(() => {
    if (window.location.hash === `#${splitedRecId}`) {
      setModalShow(splitedRecId);
    }
  }, [setModalShow, splitedRecId]);

  return splitedRecId;
};

export default OpenTicketById;
