import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    setCalendarPrices,
    setFlightType,
    setNotFilledFields,
    setRoutes
} from '../../../../../store/searchParams/actions';
import { setAllLoadngData } from '../../../../../store/loading/actions';
import {
    Block,
    FlightTypeToggleButton,
    FlightTypeIcon,
    SubmitButton,
    SubmitButtonContainer,
    RoutesAndHistorySearchButtons,
} from './components';
import SimpleRoute from './simpleRoute';
import AdvancedRoute from './advancedRoute';
import ClearRoute from './clearRoute';
import simpleRouteIcon from '../../../../../assets/icons/simpleRouteIcon.svg';
import simpleRouteIconWhite from '../../../../../assets/icons/simpleRouteIconWhite.svg';
import advancedRouteIcon from '../../../../../assets/icons/advancedRouteIcon.svg';
import advancedRouteIconWhite from '../../../../../assets/icons/advancedRouteIconWhite.svg';
import SearchHistory from '../searchHistory';
import { encodeSearchId } from '../../../../../lib/searchId';
import { useHistory } from 'react-router-dom';
import { appendToLocalStorage } from '../../../../../lib/searchHistory';
import useLocalization from '../../../../../contexts/localization/hook';
import { setFilterParams } from '../../../../../store/filters/actions';
import useTheme from '../../../../../contexts/theme/hook';
import API from "../../../../../lib/api";
import PriceChart from "../priceChart";
import Container from '../../../ui/container';

const SearchForm = () => {
    const { flightType, cabin, routes, calendarPrices, passengers, hasData } = useSelector(state => state.searchParams);
    const { isFullScreenSearchBlock } = useSelector(state => state.appConfigs);
    const { currency } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useLocalization();
    const theme = useTheme();

    const toggleFlightType = () => {
        if (flightType === 'CF') {
            const newRoutes = [...routes];
            if (checkIsRoundTrip()) {
                dispatch(setRoutes([...newRoutes.slice(0, 2)]));
                dispatch(setFlightType('RT'));
            } else {
                newRoutes[1].from = newRoutes[0].to;
                newRoutes[1].to = newRoutes[0].from;
                newRoutes[1].date_from = null;
                dispatch(setRoutes(newRoutes));
                dispatch(setFlightType('OW'));
            }
        } else {
            const newRoutes = [...routes];
            if (routes.length === 1) {
                dispatch(
                    setRoutes([
                        ...routes,
                        {
                            from: null,
                            to: null,
                            date_from: null,
                        },
                    ])
                );
            } else {
                if (!routes[1].date_from) {
                    const newRoute = [
                        {
                            from: null,
                            to: null,
                            date_from: null,
                        },
                    ];
                    dispatch(setRoutes([...newRoutes.slice(0, 1), ...newRoute]));
                } else {
                    dispatch(
                        setRoutes([
                            ...newRoutes.slice(0, 1),
                            {
                                from: newRoutes[0].to,
                                to: newRoutes[0].from,
                                date_from: newRoutes[1].date_from,
                            },
                        ])
                    );
                }
            }
            dispatch(setFlightType('CF'));
        }
    };

    const checkIsRoundTrip = () => {
        if (routes.length >= 2) {
            if (routes[0].from && routes[0].to && routes[1].from && routes[1].to) {
                if (routes[0].from.code === routes[1].to.code && routes[0].to.code === routes[1].from.code) {
                    return true;
                }
            }
        }

        return false;
    };

    const handleSearch = () => {
        let currentRoutes = [...routes];
        let currentFlightType = flightType;

        if (flightType === 'CF') {
            currentRoutes = currentRoutes.filter(item => {
                return item.from && item.to && item.date_from;
            });
        }

        if (currentRoutes[0].from && currentRoutes[0].to && currentRoutes[0].date_from) {
            const searchId = encodeSearchId(flightType, cabin, currentRoutes, passengers);
            appendToLocalStorage(searchId, currentRoutes[0].date_from);
            history.push(`/result/${searchId}`);

            if (currentFlightType === 'CF') {
                if (currentRoutes.length === 1) {
                    dispatch(setFlightType('OW'));
                    dispatch(
                        setRoutes([
                            ...currentRoutes,
                            {
                                from: null,
                                to: null,
                                date_from: null,
                            },
                        ])
                    );
                    currentFlightType = 'OW';
                }

                if (
                    currentRoutes.length === 2 &&
                    currentRoutes[0].from === currentRoutes[1].to &&
                    currentRoutes[0].to === currentRoutes[1].from
                ) {
                    dispatch(setFlightType('RT'));
                    currentFlightType = 'RT';
                }
            }

            dispatch(
                setFilterParams({
                    type: 'pricesTable',
                    values: {
                        isActive: false,
                        values: {
                            type: null,
                            supplier: null,
                            price: null,
                        },
                    },
                })
            );

            dispatch(
                setAllLoadngData({
                    isSearchLoading: true,
                    searchLoading: true,
                    searchData: {
                        flightType: currentFlightType,
                        cabin,
                        passengers,
                        routes: currentRoutes,
                    },
                })
            );
        } else {
            dispatch(
                setNotFilledFields({
                    from: !!!currentRoutes[0].from,
                    to: !!!currentRoutes[0].to,
                    date: !!!currentRoutes[0].date_from,
                })
            );

            setTimeout(() => {
                dispatch(
                    setNotFilledFields({
                        from: false,
                        to: false,
                        date: false,
                    })
                );
            }, 3000);
        }
    };

    const [showChart, setShowChart] = useState(false);

    const getCalendarPrices = async (from, to) => {
        if (from && to) {
            API.calendarPrices({ from, to, currency }).then(response => {
                dispatch(setCalendarPrices(response));
                if(response.length){
                    setShowChart(true)
                }else{
                    setShowChart(false)
                }
            });
        }
    };

    useEffect(() => {
        getCalendarPrices(routes[0].from?.code, routes[0].to?.code);
    }, [routes, currency]);

    return (
        <Container fixed>
            <Block>
                <ClearRoute disabled={hasData} />
                {flightType === 'CF' ? <AdvancedRoute handleSearch={handleSearch} /> : <SimpleRoute handleSearch={handleSearch} />}
                <RoutesAndHistorySearchButtons>
                    <FlightTypeToggleButton onClick={() => toggleFlightType()}>
                        {flightType === 'CF' ? (
                            <>
                                <FlightTypeIcon src={theme.theme === 'LIGHT_THEME' ? simpleRouteIcon : simpleRouteIconWhite} />
                                {t.return_to_simple_route}
                            </>
                        ) : (
                            <>
                                <FlightTypeIcon src={theme.theme === 'LIGHT_THEME' ? advancedRouteIcon : advancedRouteIconWhite} />
                                {t.difficult_routes}
                            </>
                        )}
                    </FlightTypeToggleButton>
                    <SearchHistory />
                </RoutesAndHistorySearchButtons>
                {showChart && <PriceChart days={calendarPrices} />}
            </Block>

            <SubmitButtonContainer smallSearchBlock={!isFullScreenSearchBlock}>
                <SubmitButton onClick={() => handleSearch()}>{t.find_tickets}</SubmitButton>
            </SubmitButtonContainer>
        </Container>
    );
};

export default SearchForm;
