import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 48),

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        display: 'none',
    },
}));

export const Tabs = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const TabsHeader = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
}));

export const TabsButtons = styled.div`
  display: flex;
`;

export const TabsButton = styled('button')(({ theme, active }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.margins(0, 32),
    ...theme.paddings(12, 32),
    position: 'relative',
    backgroundColor: theme.palette.transparent,
    borderRadius: theme.spacing(3.5),
    border: `1px solid ${active ? theme.palette.contactsBlock : theme.palette.transparent}`,
    color: active ? theme.palette.contactsBlock : theme.palette.mainText,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 600,
}));

export const TabsBody = styled('div')(({ paddingBottom }) => ({
    paddingBottom: paddingBottom ? '24px' : 0,
}));

export const TabsContent = styled('div')(({ theme }) => ({
    ...theme.paddings(24, 0, 0),
    display: 'none',

    '&.active': {
        display: 'block',
    },
}));

export const Suppliers = styled('div')(() => ({
    display: 'flex',
}));

export const SupplierItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const SupplierHeader = styled.div``;

export const SupplierLogo = styled('img')(() => ({
    display: 'block',
    maxWidth: 80,
}));

export const SupplierPrices = styled('div')(({ theme }) => ({
    ...theme.paddingsY(12, 0),
}));

export const SupplierPrice = styled('div')(({ theme }) => ({
    ...theme.marginsY(4, 0),
    textAlign: 'center',
}));

export const SupplierPriceButton = styled('button')(({ theme }) => ({
    ...theme.paddings(8, 12),
    backgroundColor: theme.palette.transparent,
    border: 'none',
    fontWeight: 600,
    color: theme.palette.mainText,
    fontSize: 14,
    borderRadius: theme.spacing(0.5),

    '& > span': {
        color: theme.palette.mainText,
    },

    '&:hover': {
        backgroundColor: theme.palette.main,
        color: theme.palette.button.addRoute,
        cursor: 'pointer',

        '& > span': {
            color: theme.palette.button.addRoute,
        },
    },

    '&.active': {
        backgroundColor: theme.palette.main,
        color: theme.palette.button.addRoute,

        '& > span': {
            color: theme.palette.button.addRoute,
        },
    },
}));

export const ActiveIndicator = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: 20,
    top: 6,
    width: 10,
    height: 10,
    backgroundColor: 'red',
    borderRadius: '50%',
}));

