import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

export const Header = styled('h1')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.marginsY(0, 16),
    textAlign: 'center',
    fontSize: '1.6rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.mainText,
    marginTop: '50px',
}));

export const Icon = styled('img')(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const CardBlock = styled('div')(({ theme }) => ({
    boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.blocksBackground,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: '100%',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsY(0, 16),
        display: 'none',
        width: '100%',

        '&:nth-child(1)': {
            display: 'block',
        },
    },
}));

export const NewsImage = styled('div')(({ theme, backgroundImage, noneBorderRadius, minHeight }) => ({
    background: `url(${backgroundImage})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '112%',
    width: '100%',
    borderRadius: noneBorderRadius ? 'none' : theme.spacing(1.5),
    minHeight: minHeight ? '720px' : '250px',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        minHeight: minHeight ? '480px' : '240px',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        minHeight: 240,
    },
}));

export const NewsInform = styled('div')(({ theme }) => ({
    ...theme.paddings(1,16),
}));

export const HeaderNews = styled('div')(({ theme }) => ({
    fontSize: 18,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.mainText,
    height: 50,
    overflow: 'hidden',
}));

export const DateNews = styled('p')(({ theme }) => ({
    color: theme.palette.mainText,
}));

export const ButtonToOpenMore = styled(NavLink)(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.paddings(0, 16),
    height: 35,
    borderRadius: theme.spacing(2.5),
    backgroundColor: theme.palette.transparent,
    color: theme.palette.button.newsBtn,
    border: `1px solid ${theme.palette.button.newsBtn}`,
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: 16,
    cursor: 'pointer',
    textDecoration: 'none',

    '&:hover': {
        backgroundColor: 'rgba(208, 191, 191, 0.2)',
    },
}));

export const AllNewsBlock = styled('div')(({ theme }) => ({
    ...theme.margins(24, 0, 0),
    display: 'flex',
    justifyContent: 'center',
}));

export const ToTheNewsPage = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: 35,
    borderRadius: 20,
    backgroundColor: theme.palette.transparent,
    color: theme.palette.main,
    border: `1px solid ${theme.palette.main}`,
    textDecoration: 'none',
    fontSize: 16,
    cursor: 'pointer',
    fontWeight: 600,
    ...theme.paddings(0, 16),

    '&:hover': {
        backgroundColor: ' rgba(208, 191, 191, 0.2)',
    },
}));

export const LinkToAllNews = styled(Link)(({ theme }) => ({
    color: theme.palette.main,
    textDecoration: 'none',
}));

export const NewsItem = styled('div')(({ theme }) => ({
    ...theme.paddings(12, 16),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100% !important',
    },
}));

export const ArrowIcons = styled('div')(({ theme }) => ({
    ...theme.margins(0, 20, 16, 0),
    display: 'flex',
    justifyContent: 'flex-end',

    '&.hide': {
        display: 'none',
    },

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        '&.hide': {
            display: 'block',
            float: 'right',
        },
    },
}));

export const IconButton = styled('img')(({ isArrowLeft }) => ({
    ...(isArrowLeft ? { transform: 'rotateY(180deg)' } : {}),
    cursor: 'pointer',
}));
