import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalization from '../../../../../../contexts/localization/hook';
import moment from 'moment';

import CloseIcon from '@material-ui/icons/Close';
import {
    Container,
    RouteContainer,
    From,
    To,
    FromDate,
    PassengersAndClass,
    AdvancedRouteAddRouteButtonContainer,
    AdvancedRouteAddRouteButton,
    MaximumRoutesText,
    RemoveRouteButton,
    DesktopRemoveRouteButton,
    SubmitButton,
} from './components';
import AirportField from '../../fields/airport';
import DateFrom from '../../fields/dateFrom';
import PassengersAndClassField from '../../fields/passengersAndClass';

import { setRoutes, setFlightType, setCalendarPrices } from '../../../../../../store/searchParams/actions';
import API from '../../../../../../lib/api';

const AdvancedRoute = ({ handleSearch }) => {
    const { routes, notFilledFields } = useSelector(state => state.searchParams);
    const { currency } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();
    const { t } = useLocalization();

    const handleAddRoute = () => {
        if (routes.length < 4) {
            dispatch(
                setRoutes([
                    ...routes,
                    {
                        from: null,
                        to: null,
                        date_from: null,
                    },
                ])
            );
        }
    };

    const handleRemoveRoute = () => {
        if (routes.length > 2) {
            const newRoutes = [...routes];
            newRoutes.pop();
            dispatch(setRoutes(newRoutes));
        } else {
            dispatch(setFlightType('RT'));
        }
    };

    const getCalendarPrices = async (from, to) => {
        if (from && to) {
            API.calendarPrices({ from, to, currency }).then(response => {
                dispatch(setCalendarPrices(response));
            });
        }
    };

    const handleAirportFromSelect = (value, index) => {
        const currentRoutes = [...routes];
        currentRoutes[index].from = value;
        dispatch(setRoutes(currentRoutes));

        getCalendarPrices(currentRoutes[0].from?.code, currentRoutes[0].to?.code);
    };

    const handleAirportToSelect = (value, index) => {
        const currentRoutes = [...routes];
        currentRoutes[index].to = value;
        dispatch(setRoutes(currentRoutes));

        getCalendarPrices(currentRoutes[0].from?.code, currentRoutes[0].to?.code);
    };

    const handleDateFromSelect = (date, index) => {
        const currentRoutes = [...routes];
        currentRoutes[index].date_from = date !== null ? moment(date).format('YYYY-MM-DD') : null;

        if (currentRoutes.length > index + 1) {
            for (let i = index + 1; i < currentRoutes.length; i++) {
                if (moment(currentRoutes[i].date_from).isBefore(date)) {
                    currentRoutes[i].date_from = null;
                }
            }
        }

        dispatch(setRoutes(currentRoutes));
    };

    const renderRoutes = () => {
        return routes.map((route, index) => {
            return (
                <RouteContainer key={`route-${index}`}>
                    {index > 0 && <RemoveRouteButton onClick={() => handleRemoveRoute()}>{t.delete}</RemoveRouteButton>}
                    <From notFilled={index === 0 && notFilledFields.from}>
                        <AirportField
                            type="from"
                            isSimple={false}
                            onSelect={handleAirportFromSelect}
                            selected={route.from}
                            index={index}
                        />
                    </From>
                    <To notFilled={index === 0 && notFilledFields.from}>
                        <AirportField
                            type="to"
                            isSimple={false}
                            onSelect={handleAirportToSelect}
                            selected={route.to}
                            index={index}
                        />
                    </To>
                    {index > 0 ? (
                        <FromDate marginBottom>
                            <DateFrom onSelect={handleDateFromSelect} selected={route.date_from} index={index} />
                        </FromDate>
                    ) : (
                        <FromDate notFilled={notFilledFields.date}>
                            <DateFrom onSelect={handleDateFromSelect} selected={route.date_from} index={index} />
                        </FromDate>
                    )}
                    {index === 0 && (
                        <PassengersAndClass>
                            <PassengersAndClassField />
                        </PassengersAndClass>
                    )}
                    {index > 0 && (
                        <DesktopRemoveRouteButton onClick={() => handleRemoveRoute()}>
                            {t.delete}
                        </DesktopRemoveRouteButton>
                    )}
                </RouteContainer>
            );
        });
    };

    return (
        <Container>
            {renderRoutes()}
            <AdvancedRouteAddRouteButtonContainer>
                <SubmitButton onClick={handleSearch}>{t.find_tickets}</SubmitButton>
                {routes.length < 4 ? (
                    <AdvancedRouteAddRouteButton onClick={() => handleAddRoute()}>
                        + {t.add}
                    </AdvancedRouteAddRouteButton>
                ) : (
                    <MaximumRoutesText>
                        <CloseIcon style={{ color: '#D60C0C' }} />
                        {t.max_num_flights}
                    </MaximumRoutesText>
                )}
            </AdvancedRouteAddRouteButtonContainer>
        </Container>
    );
};

export default AdvancedRoute;
