import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import configs from '../../config';

import { setAllLoadngData } from '../../store/loading/actions';
import { setAppConfigs } from '../../store/appConfigs/actions';

import LocalizationContext from './context';

import API from '../../lib/api';

import translations from "../../translations";

const language = configs.mainConfigs.language;

export default props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);

    const [appLanguage, setAppLanguage] = useState(params.get('lang') || language);
    const [availableLanguages, setAvailableLanguages] = useState([]);

    useEffect(() => {
        API.getRequisites().then(response => {
            const { available_languages, logo, available_currencies, currency } = response.data;

            let lsCurrency = localStorage.getItem('currency');
            const availableCurrencies = JSON.parse(available_currencies);

            if (lsCurrency) {
                const include = _.includes(availableCurrencies, lsCurrency);

                if (!include) {
                    localStorage.setItem('currency', availableCurrencies[0]);
                    lsCurrency = availableCurrencies[0];
                }
            } else {
                localStorage.setItem('currency', currency);
                lsCurrency = currency;
            }

            const languages = Object.keys(translations);

            if (JSON.parse(available_languages).findIndex(x => x === appLanguage) > -1) {
                localStorage.setItem('language', appLanguage);
                document.documentElement.setAttribute('lang', appLanguage);
                moment.locale(appLanguage === 'uz' ? 'tg' : appLanguage);
            } else {
                localStorage.setItem('language', languages[0]);
                setAppLanguage(languages[0]);
                document.documentElement.setAttribute('lang', languages[0]);
                moment.locale(languages[0] === 'uz' ? 'tg' : languages[0]);
            }

            setAvailableLanguages(JSON.parse(available_languages));
            dispatch(setAppConfigs({ logoUrl: logo, currency: lsCurrency, availableCurrencies }));
            dispatch(setAllLoadngData({ isLocalizationLoading: false }));
        });
    }, []);

    const switchLang = lang => {
        setAppLanguage(lang);
        moment.locale(lang === 'uz' ? 'tg' : lang);
        localStorage.setItem('language', lang);
        document.documentElement.setAttribute('lang', lang);
    };

    return (
        <LocalizationContext.Provider
            value={{
                language: appLanguage,
                switchLang,
                availableLanguages,
                t: availableLanguages.includes(appLanguage) && translations[appLanguage] ? translations[appLanguage] : {},
            }}
        >
            {props.children}
        </LocalizationContext.Provider>
    );
};
