import styled from 'styled-components';

export const BrandFareInfoItem = styled('div')(({ theme }) => ({
    ...theme.paddingsY(6),
    fontSize: 16,
    color: theme.palette.mainText,
}));

export const BrandOptionIcon = styled('div')({ display: 'inline-block' });

export const BrandOptionTitle = styled('div')(({ theme }) => ({
    display: 'inline-block',
    position: 'relative',
    fontSize: 14,
    bottom: 4,
    marginLeft: theme.spacing(0.75),
}));
