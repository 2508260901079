export const defaultFromCities = [
    {
        code: 'DYU',
        countryCode: 'UZ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            uz: 'Tojikiston',
        },
        value: {
            ru: 'Душанбе',
            en: 'Dushanbe',
            uz: 'Dushanbe',
        },
        isMain: 1,
    },
    {
        code: 'LBD',
        countryCode: 'UZ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            uz: 'Tojikiston',
        },
        value: {
            ru: 'Худжанд',
            en: 'Khudzhand',
            uz: 'Xujand',
        },
        isMain: 1,
    },
    {
        code: 'KQT',
        countryCode: 'UZ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            uz: 'Tojikiston',
        },
        value: {
            ru: 'Бохтар',
            en: 'Bokhtar',
            uz: 'Bochtar',
        },
        isMain: 1,
    },
    {
        code: 'TJU',
        countryCode: 'UZ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            uz: 'Tojikiston',
        },
        value: {
            ru: 'Куляб',
            en: 'Kulyab',
            uz: 'Qulob',
        },
        isMain: 1,
    },
    {
        code: 'MOW',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Москва',
            en: 'Moscow',
            uz: 'Moskva',
        },
        isMain: 1,
    },
    {
        code: 'SVO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Шереметьево',
            en: 'Sheremetyevo International',
            uz: 'Sheremetyevo International',
        },
        isMain: 0,
    },
    {
        code: 'VKO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Внуково',
            en: 'Vnukovo International',
            uz: 'Vnukovo International',
        },
        isMain: 0,
    },
    {
        code: 'DME',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Домодедово',
            en: 'Domodedovo',
            uz: 'Domodedovo',
        },
        isMain: 0,
    },
    {
        code: 'ALA',
        countryCode: 'KZ',
        countryName: {
            ru: 'Казахстан',
            en: 'Kazakhstan',
            uz: 'Qozogiston',
        },
        value: {
            ru: 'Алматы',
            en: 'Almaty',
            uz: 'Almaty',
        },
        isMain: 1,
    },
    {
        code: 'IST',
        countryCode: 'TR',
        countryName: {
            ru: 'Турция',
            en: 'Turkey',
            uz: 'Turkiya',
        },
        value: {
            ru: 'Стамбул',
            en: 'Istanbul',
            uz: 'Istanbul',
        },
        isMain: 1,
    },
];

export const defaultToCities = [
    {
        code: 'LBD',
        countryCode: 'UZ',
        countryName: {
            ru: 'Таджикистан',
            en: 'Tajikistan',
            uz: 'Tojikiston',
        },
        value: {
            ru: 'Худжанд',
            en: 'Khudzhand',
            uz: 'Xujand',
        },
        isMain: 1,
    },
    {
        code: 'MOW',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Москва',
            en: 'Moscow',
            uz: 'Moskva',
        },
        isMain: 1,
    },
    {
        code: 'SVO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Шереметьево',
            en: 'Sheremetyevo International',
            uz: 'Sheremetyevo International',
        },
        isMain: 0,
    },
    {
        code: 'VKO',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Внуково',
            en: 'Vnukovo International',
            uz: 'Vnukovo International',
        },
        isMain: 0,
    },
    {
        code: 'DME',
        countryCode: 'RU',
        countryName: {
            ru: 'Россия',
            en: 'Russia',
            uz: 'Rossiya',
        },
        value: {
            ru: 'Домодедово',
            en: 'Domodedovo',
            uz: 'Domodedovo',
        },
        isMain: 0,
    },
    {
        code: 'DXB',
        countryCode: 'AE',
        countryName: {
            ru: 'Объединенные Арабские Эмираты',
            en: 'United Arab Emirates',
            uz: 'Birlashgan Arab Amirliklari',
        },
        value: {
            ru: 'Дубай',
            en: 'Dubai',
            uz: 'Dubay',
        },
        isMain: 1,
    },
    {
        code: 'IST',
        countryCode: 'TR',
        countryName: {
            ru: 'Турция',
            en: 'Turkey',
            uz: 'Turkiya',
        },
        value: {
            ru: 'Стамбул',
            en: 'Istanbul',
            uz: 'Istanbul',
        },
        isMain: 1,
    },
    {
        code: 'ALA',
        countryCode: 'KZ',
        countryName: {
            ru: 'Казахстан',
            en: 'Kazakhstan',
            uz: 'Qozogiston',
        },
        value: {
            ru: 'Алматы',
            en: 'Almaty',
            uz: 'Almaty',
        },
        isMain: 1,
    },
    {
        code: 'TAS',
        countryCode: 'UZ',
        countryName: {
            ru: 'Республика Узбекистан',
            en: 'Uzbekistan',
            uz: "O'zbekiston Respublikasi",
        },
        value: {
            ru: 'Ташкент Международный аэропорт',
            en: 'Tashkent International Airport',
            uz: 'Toshkent xalqaro aeroporti',
        },
        isMain: 1,
    }
];
