import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import envConfigs from "../../config/envConfigs";

import { setAppConfigs } from "../../store/appConfigs/actions";

import { Wrapper } from "./components";
import HeaderPart from "./parts/HeaderPart";
import TravelPart from "./parts/TravelPart";
import HowItWorksPart from "./parts/HowItWorksPart";
import ChancePart from "./parts/ChancePart";
import PersonPart from "./parts/PersonPart";

const FlyCashPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = `FLY CASH | ${envConfigs.appTitle}`;

        dispatch(setAppConfigs({ showSearchBlock: false }));

        return () => {
            dispatch(setAppConfigs({ showSearchBlock: true }));
        };
    }, []);

    return (
        <Wrapper>
            <HeaderPart />
            <TravelPart />
            <HowItWorksPart />
            <ChancePart />
            <PersonPart />
        </Wrapper>
    );
};

export default FlyCashPage;
