import styled from "styled-components";

export const Section = styled("section")(({ theme }) => ({
	marginBottom: "100px",
	paddingTop: "60px",

	[theme.breakpoints.down(theme.breakpoints.tablet)]: {
		gridTemplateColumns: "1fr",
	},
}));

export const TextBlock = styled("div")(({ theme }) => ({
	padding: "2rem",
	boxShadow: "0px 0px 9px 0 #8989893b",
	borderRadius: "20px",
	height: "100%",
	display: "flex",
	gap: "12px",
	alignItems: "center",
	justifyContent: "space-evenly",
	flexDirection: "column",
	color: theme.palette.mainText,

	svg: {
		width: "50px",
		height: "50px",
		marginBottom: "16px"
	},

	h1: {
		margin: "0",
		fontWeight: "400",
		fontSize: "24px",
		textAlign: "center"
	},
	h2: {
		margin: "0",
		fontWeight: "400",
		fontSize: "18px",
		textAlign: "center"
	},

	a: {
		marginTop: "70px",
		textDecoration: "none",
		backgroundColor: theme.palette.button.searchButtonBg,
		width: "fit-content",
		padding: "1rem",
		borderRadius: "10px",
		color: theme.palette.mainText,
		textAlign: "center"
	},
}));
