import styled from 'styled-components';
import { AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';

export const AccordionFaqBlock = styled(Accordion)(({ theme }) => ({
    '&.MuiAccordion-root': {
        backgroundColor: theme.palette.inputBackground,
    }
}));

export const Title = styled('h1')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    color: theme.palette.mainText,
}));

export const Block = styled('div')(({ theme }) => ({
    ...theme.paddings(32, 0, 64),
}));

export const AccordionHeader = styled(AccordionSummary)(({ theme }) => ({
    padding: '8px 16px !important',
    fontSize: 20,
    fontWeight: 600,
    color: `${theme.palette.mainText} !important`,
    '&.MuiButtonBase-root': {
        backgroundColor: theme.palette.inputBackground,
    }
}));

export const AccordionBody = styled(AccordionDetails)(() => ({
    width: '100%',
}));

export const LinksBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumnStart,
    width: '100%',

}));

export const Link = styled('button')(({ theme }) => ({
    ...theme.marginsY(0, 16),
    ...theme.paddings(0, 0),
    color: theme.palette.main,
    textDecoration: 'none',
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: theme.palette.transparent,
    border: 'none',
    cursor: 'pointer',
    textAlign: 'left',

    '&:hover': {
        opacity: 0.7,
    },
}));

export const HtmlView = styled('div')(({ theme }) => ({
    '& img': {
        maxWidth: '100% !important',
    },
}));

export const MobileModalTitle = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 16),
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.mainText,
}));
