import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyledButton } from '../../../../styles/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import useLocalization from '../../../../contexts/localization/hook';

function SubmitButton(props) {
    const { t } = useLocalization();
    const { isLoading, disabled } = props;

    const useStyles = makeStyles({
        buttonLoading: {
            width: '111px',
            height: '40px',
            borderRadius: '4px',
            backgroundColor: '#0085FF',
            color: '#fff',
            fontSize: '16px',
            fontWeight: '500',
            position: 'relative',
            textAlign: 'center',
        },
        buttonProgress: {
            position: 'relative',
            bottom: '23px',
        },
        buttonLoadingLabel: {
            paddingTop: '10px',
        },
    });

    const classes = useStyles();

    return (
        <Fragment>
            {!isLoading ? (
                <StyledButton type="submit" widthSize="112px" heightSize="40px" fontSize="16px" disabled={disabled}>
                    {t.save}
                </StyledButton>
            ) : (
                <Fragment>
                    <div className={classes.buttonLoading}>
                        <div className={classes.buttonLoadingLabel}>{t.save}</div>
                        <CircularProgress style={{ color: '#ff7043' }} size={24} className={classes.buttonProgress} />
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
}

export default SubmitButton;
