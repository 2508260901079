import styled from 'styled-components';
import { ExitToApp, Person } from '@material-ui/icons';

import Link from '../../../../../ui/components/Link/Link';

export const Wrapper = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    justifyContent: 'space-between',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const Block = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    position: 'relative',
}));

export const ExitToAppIcon = styled(ExitToApp)(({ theme }) => ({
    width: 28,
    height: 28,
    color: theme.palette.main,
}));

export const AccountCircleIcon = styled(Person)(({ theme }) => ({
    
    color: theme.palette.main,
}));

export const EnterBlock = styled('button')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    height: 42,
    width: 42,
    border: `1px solid ${theme.palette.main}`,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: theme.palette.transparent,
    marginRight: '16px',

    '&:hover': {
        backgroundColor: `${theme.palette.buttonHover.exitButton}`,
    },
}));

export const Dropdown = styled('div')(({ theme, active, right }) => ({
    display: `${active ? 'block' : 'none'}`,
    position: 'absolute',
    top: 'calc(100% + 10px)',
    right: `${right ? '-12px' : '0'}`,
    backgroundColor: theme.palette.blocksBackground,
    boxShadow: `1px 10px 10px 0 ${theme.palette.dropDown}`,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
    width: 220,

    '&::before': {
        content: '',
        display: 'block',
        width: 0,
        height: 0,
        borderRight: `10px solid ${theme.palette.transparent}`,
        borderBottom: `8px solid ${theme.palette.white}`,
        borderLeft: `10px solid ${theme.palette.transparent}`,
        position: 'absolute',
        top: -8,
        right: 22,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        right: `${right ? 6 : 0}`,
    },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
    display: 'block',
    color: theme.palette.mainText,
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.6,
    ...theme.margins(theme.spacing(1), 0, theme.spacing(1), theme.spacing(1)),
    paddingLeft: theme.spacing(1),

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.headerButtons,
        borderRadius: theme.spacing(0.625),
    },
}));

export const LinkForAgents = styled('a')(({ theme }) => ({
    display: 'block',
    color: theme.palette.mainText,
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.6,
    textDecoration: 'none',
    border: 'none',
    backgroundColor: theme.palette.transparent,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.headerButtons,
        borderRadius: theme.spacing(0.625),
    },
}));

export const Component = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    position: 'relative',
}));

export const Button = styled('button')(({ theme }) => ({
    backgroundColor: theme.palette.transparent,
    color: theme.palette.white,
    width: '42px',
    height: '42px',
    border: `1px solid ${theme.palette.main}`,
    borderRadius: '50%',
    marginRight: '16px',
   

    '&:hover': {
        cursor: 'pointer',
        backgroundColor: `${theme.palette.buttonHover.exitButton}`,
    },
}));

export const UserBalance = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.main,
    borderRadius: '20px',
    marginRight: '16px',
    padding: '0 16px',
    fontWeight: 'bold',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    'span': {
        margin: '0 10px'
    }
}))

export const UserBtnWrap = styled("div")(({ theme }) => ({
    'a': {
        color: '#ffffff',
        textDecoration: 'none'
    }
}))

export const addBalance = styled(Link)(({ theme }) => ({
    color: '#ffffff',
    textDecoration: 'none'
}))

export const FlyCashButton = styled("button")(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    backgroundColor: theme.palette.transparent,
    color: theme.palette.flyCash,
    border: `1px solid ${theme.palette.flyCash}`,
    minWidth: 50,
    padding: '8px 12px',
    borderRadius: 100,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    height: 35,
    marginRight: 16,

    '&:hover': {
        cursor: 'pointer',
    },
}));

export const ClientName = styled('div')(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.main,
    marginLeft: theme.spacing(1),
}));

export const ClientLogin = styled('div')(({ theme }) => ({
    color: theme.palette.balance,
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

export const ClientBalanceBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1),
    fontSize: 14,
}));

export const ClientBalanceTitle = styled('div')(({ theme }) => ({
    color: theme.palette.balance,
    marginRight: theme.spacing(1),
}));

export const ClientBalanceValue = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    fontWeight: 600,

    '& > span': {
        marginLeft: theme.spacing(0.5),
        color: theme.palette.main,
    },
}));

export const ExitButton = styled('div')(({ theme }) => ({
    color: theme.palette.black,
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.6,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.25),

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.exitButton,
        borderRadius: theme.spacing(0.625),
    },
}));
