import styled from 'styled-components';

export const Title = styled('h1')(({ theme }) => ({
    ...theme.margins(30, 0),
    fontSize: 30,
    color: theme.palette.mainText,
    fontWeight: 600,
    textAlign: 'center',

    [theme.breakpoints.down(theme.palette.laptop)]: {
        fontSize: 36,
    },

    [theme.breakpoints.down(theme.palette.tablet)]: {
        fontSize: 24,
    },
}));

export const CityBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 16),
    ...theme.marginsY(0, 32),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.blocksBackground,
}));

export const CityName = styled('h2')(({ theme }) => ({
    ...theme.marginsY(0, 16),
    color: theme.palette.mainText,
}));

export const CityTable = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
}));

export const CityTableRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.borderColor}`,
    position: 'relative',
    color: theme.palette.mainText,

    '&.header': {
        fontWeight: 600,
        color: theme.palette.mainText,

        '&:hover': {
            backgroundColor: theme.palette.transparent,
            color: theme.palette.mainText,
            cursor: 'default',
        },
    },

    '&:hover': {
        backgroundColor: theme.palette.popularDestinationsHover,
        color: theme.palette.buttonHover.hoverToPopularDestinationText,
        cursor: 'pointer',
    },

    '&:nth-last-of-type(1)': {
        borderBottom: 'none',
    },
}));

export const CityTableRowLoading = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: 'rgba(0, 133, 255, 0.8)',
    color: theme.palette.mainText,
}));

export const CityTableColumn = styled('div')(({ theme }) => ({
    ...theme.paddings(8, 8),
    textAlign: 'center',
}));

export const CityTableColumnTo = styled(CityTableColumn)(({ theme }) => ({
    width: '30%',
    textAlign: 'left',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '40%',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: '60%',
    },
}));

export const CityTableColumnAirportCode = styled(CityTableColumn)(({ theme }) => ({
    width: '10%',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        display: 'none',
    },
}));

export const CityTableColumnWeekDays = styled(CityTableColumn)(({ theme }) => ({
    width: '20%',

    '&.uppercase': {
        textTransform: 'uppercase',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '30%',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'none',
    },
}));

export const CityTableColumnAirline = styled(CityTableColumn)(({ theme }) => ({
    width: '25%',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const CityTableColumnPrice = styled(CityTableColumn)(({ theme }) => ({
    width: '15%',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: '25%',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '30%',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: '40%',
    },
}));

export const NoResultsBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    height: 200,
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.mainText,
}));
