import React, { useState, useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import _ from "lodash";
import { setAvailablePaymentMethods, setOrderData, setPaymentMethod } from "./actions";
import { setAppConfigs } from "../../store/appConfigs/actions";
import OrderForm from "../../components/order/orderForm/orderForm";
import BrokenOrderForm from "../../components/order/brokenOrderForm";
import API from "../../lib/api";
import { B2C, EPA, PDC } from "./constants";
import ErrorModal from "../../components/ui/components/ErrorModal/ErrorModal";
import { checkInAccessToPaymentStatus } from "../../components/result/common";
import useLocalization from "../../contexts/localization/hook";
import useAuth from "../../contexts/auth/hook";
import { setAllLoadngData, setShowLoading } from "../../store/loading/actions";
import envConfigs from "../../config/envConfigs";
import DcPaymentPopup from "../../components/design_v2/dcPaymentPopup";
import VisaPaymentPopup from "../../components/design_v2/visaPaymentPopup";
import { Redirect } from 'react-router-dom';


function OrderContainer(props) {
	const { t } = useLocalization();
	const { refreshUserData } = useAuth();
	const dispatch = useDispatch();
	const {
		match: { params },
	} = props;
	const { orderId } = params;

	const { orderData, isLoad, paymentMethod, setPaymentMethod, setAvailablePaymentMethods, availablePaymentMethods, setOrderData, partnerCode } =
		props;
	const [redirectToPayment, setRedirectToPayment] = useState(false);
	const [responseError, setResponseError] = useState(null);
	const [isChangedStatus, setIsChangedStatus] = useState(false);
	const [dcPaymentModal, setDcPaymentModal] = useState(null);
	const [visaPaymentModal, setVisaPaymentModal] = useState(null);

	useEffect(() => {
		dispatch(setShowLoading(true));

		getOrderData();

		dispatch(
			setAppConfigs({
				showSearchBlock: false,
			}),
		);

		return () => {
			setOrderData({
				orderData: [],
				loadStatus: false,
			});

			setAvailablePaymentMethods({
				methods: [],
				loadStatus: false,
			});

			setPaymentMethod({
				method: null,
			});
		};
	}, []);

	const changeIsLoadHandler = () => {
		dispatch(setAllLoadngData({ isOrderLoading: !isLoad }));
	};

	useEffect(changeIsLoadHandler, [isLoad]);

	useEffect(() => {
		document.title = `${t.page_order || ""} | ${envConfigs.appTitle}`;
	}, [t]);

	const orderCancel = () => {
		const { session_id, key } = orderData;

		const params = {
			order_id: orderId,
			session_id,
			key,
		};

		API.apiOrderCancel(params).then(() => {
			getOrderData();
		});
	};

	const refundOrder = (setIsLoadingRefund, setAlert = () => {}) => {
		// const setSuccess = () => {
		//     setIsLoadingRefund(false);
		//     setAlert(true);
		// };
		// const setError = () => {
		//     setIsLoadingRefund(false);
		//     setAlert(false);
		// };
		// const params = {
		//     value: [orderId],
		// };
		// API.apiRefund(params).then(() => {
		//     getOrderData(setSuccess);
		// })
		// .catch(() => {
		//     getOrderData(setError);
		// });
	};

	const sendPayment = (setStatus = () => {}) => {
		const { session_id, total_price, currency } = orderData;
		const methodData = _.find(availablePaymentMethods, { code: paymentMethod });

		setRedirectToPayment(true);

		const params = {
			order_id: orderId,
			session_id,
			total_price: {
				[currency]: total_price,
			},
			payment_system_id: methodData.id,
			return_url: "https://fly.tj/successfulPayment",
			fail_url: window.location.href,
		};

		API.billingPayment(params)
		.then(response => {
				if (paymentMethod === B2C) {
						getOrderData();
						refreshUserData();
						setRedirectToPayment(false);
				} else {
						window.location.replace(response['payment_link']);
				}
		})
		.catch(error => {
				setResponseError(error.response.data);
		});
	};

	const getOrderData = (callback = () => {}) => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		API.apiOrderData({
			order_id: orderId,
			key: urlParams.get("key"),
		})
			.then(response => {
				const { booking_status, order_status } = response;
				const isAccessToPayment = checkInAccessToPaymentStatus(booking_status, order_status);
				if (isAccessToPayment) {
					API.billingGetPaymentSystems().then(response => {
						setAvailablePaymentMethods({
							methods: response,
							loadStatus: true,
						});

						const actualMethod = _.first(response);

						setPaymentMethod({ method: actualMethod ? actualMethod.code : null });
					});
				} else {
					setAvailablePaymentMethods({
						loadStatus: true,
					});
				}

				setOrderData({
					orderData: response,
					loadStatus: true,
				});

				callback();
			})
			.catch(error => {
				setResponseError({
					error_code: error.response?.data.code,
					error_desc: error.response?.data.message,
					custom_error: t.do_not_find_order_message,
				});
			});
	};

	if (responseError) {
		return <ErrorModal sessionId={orderData?.session_id} {...responseError} />;
	}

	if (orderData?.brokenOrder) {
		return <BrokenOrderForm orderData={orderData} />;
	}

	return (
		<>
			{orderData && (
				<OrderForm
					orderData={orderData}
					sendPayment={sendPayment}
					orderCancel={orderCancel}
					refundOrder={refundOrder}
					partnerCode={partnerCode}
					setIsChangedStatus={setIsChangedStatus}
					isChangedStatus={isChangedStatus}
					redirectToPayment={redirectToPayment}
				/>
			)}
			<DcPaymentPopup
				showPopup={dcPaymentModal?.showPopup}
				handleClose={dcPaymentModal?.handleClose}
				payUrl={dcPaymentModal?.payment_link}
				payform={dcPaymentModal?.payform}
			/>
			<VisaPaymentPopup showPopup={visaPaymentModal?.showPopup} handleClose={visaPaymentModal?.handleClose} data={visaPaymentModal?.data} />
		</>
	);
}

const mapStateToProps = state => ({
	isLoad: state.setOrderData.isLoad,
	isLoadPaymentMethod: state.setOrderData.isLoadPaymentMethod,
	orderData: state.setOrderData.orderData,
	paymentMethod: state.setOrderData.paymentMethod,
	availablePaymentMethods: state.setOrderData.availablePaymentMethods,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setOrderData,
			setPaymentMethod,
			setAvailablePaymentMethods,
		},
		dispatch,
	);

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrderContainer);
