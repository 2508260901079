import React from 'react';
import MaskedInput from 'react-text-mask';
import { ErrorsFieldBlock } from '../../../../styles/common/index';
import InputMessage from '../InputMessage/InputMessage';
import InputWarning from '../InputWarning/InputWarning';
import useLocalization from '../../../../contexts/localization/hook';
import { FormControlUi, InputUi, LabelUi } from './components';


function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
        />
    );
}

export default function DatePickerForm(props) {
    const { t } = useLocalization();
    const {
        label,
        input,
        fieldError,
        warning,
        meta: { touched, error },
    } = props;

    let errorStyle = {};

    if (fieldError || (touched && error)) {
        errorStyle = {
            border: 'solid 2px #c62828',
        };
    }

    return (
        <FormControlUi>
            <LabelUi>
                <span>{label}</span>
            </LabelUi>

            <InputUi
                {...input}
                autoComplete="off"
                inputComponent={TextMaskCustom}
                placeholder={t.placeholder_date_picker}
                disableUnderline={true}
                style={errorStyle}
            />

            <ErrorsFieldBlock>
                {touched && error && <InputMessage text={t[error]} />}
                {touched && !error && warning && <InputWarning text={t[warning]} />}
                {fieldError && <InputMessage text={fieldError} />}
            </ErrorsFieldBlock>
        </FormControlUi>
    );
}

DatePickerForm.defaultProps = {
    label: null,
};
