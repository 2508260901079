import styled from "styled-components";

export const ShareLinkBlock = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  cursor: "pointer",

  svg: {
    path: {
      fill: theme.palette.main,
    },

    [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
      width: 13,
      height: 13,
    },
  },

  p: {
    margin: 0,
    color: theme.palette.main,

    [theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
      fontSize: 14,
    },
  },
}));
