import styled from 'styled-components';

export const NoPassengers = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 0),
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.mainText,
}));

export const SearchBlock = styled('div')(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 41px",
    marginBottom: 16,
    gap: 8,

    svg: {
        width: "100%",
        height: "100%",
        cursor: "pointer",
        border: "1px solid",
        borderColor: theme.palette.main,
        borderRadius: "50%",
        padding: 6,

        g: {
            fill: theme.palette.main,
        }
    }
}))

export const SearchInput = styled('input')(({ theme }) => ({
    backgroundColor: theme.palette.inputBackground,
    width: "100%",
    borderColor: theme.palette.placeholderTextSearchOrder,
    borderStyle: "solid",
    borderRadius: 24,
    padding: "8px 16px",
    color: theme.palette.mainText,
    fontSize: 18,

    "placeholder": {
        color: "red"
    }
}))

export const PassengerItem = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
    ...theme.paddings(16, 16),
    ...theme.marginsY(0, 16),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.blocksBackground,
    border: `1px solid ${theme.palette.searchBlockBorder}`,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.paddings(8, 8),
    },
}));

export const PassengerInfoBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
}));

export const PassengerName = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.mainText,
}));

const InfoItem = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    fontSize: 14,

    '& > span': {
        color: theme.palette.departureCountryAndAirCompany,
        fontSize: 14,
    },
}));

export const PassengerCitizenship = styled(InfoItem)``;

export const PassengerGender = styled(InfoItem)``;

export const PassengerBirthday = styled(InfoItem)``;

export const PassengerDocument = styled(InfoItem)``;

export const PassengerEmail = styled(InfoItem)``;

export const PassengerPhone = styled(InfoItem)``;

export const ButtonsBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    ...theme.marginsY(16, 0),

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.marginsY(8, 0),
    },
}));

const Button = styled('button')(({ theme }) => ({
    ...theme.paddings(8, 16),
    border: 'none',
    borderRadius: 8,
    boxShadow: 'none',
    fontSize: 16,
    color: theme.palette.searchTextForm,
    fontWeight: 600,
    cursor: 'pointer',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.paddings(8, 12),
        fontSize: 14,
    },
}));

export const ChooseButton = styled(Button)(({ theme }) => ({
    ...theme.marginsX(0, 16),
    backgroundColor: theme.palette.main,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.marginsX(0, 8),
    },
}));

export const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.deleteButton
}));
