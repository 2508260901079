export const SET_PRE_BOOK_DATA = 'SET_PRE_BOOK_DATA';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_PASSENGERS_LIST = 'SET_PASSENGERS_LIST';
export const SET_PASSENGERS_LIST_FILTER = 'SET_PASSENGERS_LIST_FILTER';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS';
export const SET_PASSENGER_OPTIONS = 'SET_PASSENGER_OPTIONS';
export const SET_OPTIONS_DYNAMIC_PRICES = 'SET_OPTIONS_DYNAMIC_PRICES';

export const ONLY_RU_COUNTRY = 'ONLY_RU_COUNTRY';
export const FRIENDLY_COUNTRY = 'FRIENDLY_COUNTRY';
export const ALL_COUNTRY = 'ALL_COUNTRY';
export const RU = 'RU';

export const M = 'M';
export const F = 'F';

export const PS = 'PS';
export const SR = 'SR';
export const PSP = 'PSP';
export const NP = 'NP';

export const ALL_DOCUMENTS_ITEMS = [NP, PS, SR, PSP];
