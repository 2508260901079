import React from 'react';

import { Flight, Security } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../../../../../config';

import useLocalization from '../../../../../../contexts/localization/hook';

import { setRoutes, setHasData, setPassengers, setCabin } from '../../../../../../store/searchParams/actions';

import {
    ClearRoutesButtonContainer,
    ClearRoutesButton,
    CloseIconImg,
    Tabs,
    TabButton,
    TabLink,
} from './components';

const ClearRouteButton = props => {
    const { routes } = useSelector(state => state.searchParams);
    const dispatch = useDispatch();
    const { t } = useLocalization();

    const clearRoutes = () => {
        const newRoutes = routes.map(() => ({
            from: null,
            to: null,
            date_from: null,
        }));

        dispatch(setRoutes(newRoutes.slice(0, 2)));
        dispatch(setPassengers({adt: 1, chd: 0, inf: 0, ins: 0}));
        dispatch(setCabin('all'));
        dispatch(setHasData(false));
    };

    return (
        <ClearRoutesButtonContainer>
            <div />
            {/* <Tabs>
                <TabButton>
                    <Flight />
                    {t.flights_tickets}
                </TabButton>
                <TabLink href={config.envConfigs.bimaPageUrl} target="_blank">
                    <Security />
                    {t.insurance}
                </TabLink>
            </Tabs> */}
            <ClearRoutesButton disabled={!props.disabled} onClick={clearRoutes}>
                <CloseIconImg/>
                {t.clear}
            </ClearRoutesButton>
        </ClearRoutesButtonContainer>
    );
};

export default ClearRouteButton;
