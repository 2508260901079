import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/closeIcon.svg';

export const ClearRoutesButtonContainer = styled('div')(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    justifyContent: 'space-between',
    flexGrow: 1,
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.25),
}));

export const ClearRoutesButton = styled('button')(({ theme, disabled }) => ({
    border: 'none',
    backgroundColor: 'transparent',
    cursor: disabled ? 'disabled' : 'pointer',
    fontSize: '14px',
    color: disabled ? theme.palette.disabledBtn : theme.palette.button.searchHistory,
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'none',
    }
}));

export const CloseIconImg = styled(CloseIcon)`
    width: 20px;
    margin: 0 4px;
`;

export const Tabs = styled("div")(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
}));

const getCommonButtonStyles = (theme) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.paddings(0, 16),
    gap: theme.spacing(0.75),
    height: 40,
    border: `1px solid ${theme.palette.main}`,
    lineHeight: 0,
});

export const TabButton = styled("div")(({ theme }) => ({
    ...getCommonButtonStyles(theme),
    borderTopLeftRadius: theme.spacing(1.5),
    color: theme.palette.main,
    cursor: 'default',

    '& > svg': {
        transform: "rotate(90deg)",
    },
}));

export const TabLink = styled("a")(({ theme }) => ({
    ...getCommonButtonStyles(theme),
    borderTopRightRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.main,
    color: theme.palette.searchTextForm,
    textDecoration: 'none',
}));
