const uz = {
  login_text: "Kirish",
  password_text: "Parol",
  forgot_password_text: "Parolni unutdingizmi?",
  registration_text: "Ro'yxatdan o'tish",
  enter_text: "Kirish",
  invalid_password: "Kamida 6 ta belgi",
  invalid_email: "Noto'g'ri elektron pochta",
  required: "Majburiy maydon",
  travel_time: "Sayohat vaqti",
  flight: "Parvoz",
  terminal: "Terminal",
  baggage: "Bagaj",
  hand_luggage: "Qo'l yuki",
  refund_ticket: "Chiptani qaytarish",
  change: "Chipta almashish",
  no_refund: "Qaytish yo'q",
  yes_refund: "Qaytish bor",
  no_change: "Almashish yo'q",
  yes_change: "Almashish mavjud",
  yes_baggage: "Bagaj bor",
  no_baggage: "Bagaj yo'q",
  yes_hand_luggage: "Qo'l yuki bor",
  no_hand_luggage: "Qo'l yuki yo'q",
  show_rules: "Qoidalarni ko'rsatish",
  total: "Jami",
  passengers: "Yo'lovchilar",
  select_from_list: "Ro'yxatdan tanlang",
  name_as_in_document: "Hujjatdagi kabi ism",
  middlename_as_in_document: "Hujjatdagi kabi otasining ismi",
  surname_as_in_document: "Hujjatdagi kabi familiya",
  date_of_birth: "Tug'ilgan sana",
  gender: "Jins",
  citizenship: "Fuqarolik",
  age: "Yoshi",
  document: "Hujjat",
  payer: "Xaridor",
  for_payer_mess:
    "Pochta (va WhatsApp) ga buyurtma ma'lumotlarini yuboramiz. Jadvalga o'zgartirish kiritilganda shoshilinch ogohlantirishlar uchun telefon kerak",
  series_and_number: "Seriya va raqam",
  valid_until: "Yaxshi",
  airline_bonus_card: "Aviakompaniya bonus kartasi",
  number_bonus_card: "Bonus karta raqami",
  phone_number: "Telefon raqami",
  email: "Email",
  how_to_read_rules: "Qoidalarni qanday o'qish kerak",
  tariff_rules: "Tarif qoidalari",
  book_and_proceed_to_payment: "Rezervasyon qiling va to'lovga o'ting",
  agreement_book_link: "aviakompaniya tarif shartlari",
  everywhere_local_time: "Hamma joyda mahalliy jo'nash va kelish vaqtlari ko'rsatilgan ",
  duration: "davomiyligi",
  in: "ichida",
  no_data_contact_technical_support:
    "Ma'lumotlar yo'q. Texnik yordamga murojaat qiling.",
  back_to_orders: "Buyurtmalarga qaytish",
  incorrect_date_from_adt: "Kattalar uchun noto'g'ri sana",
  incorrect_date_from_chd: "Bola uchun noto'g'ri sana",
  incorrect_date_from_inf: "Chaqaloq uchun noto'g'ri sana",
  incorrect_date: "Noto'g'ri sana",
  check_date_and_change_doc:
    "Tug'ilgan sanani tekshiring yoki hujjat turini o'zgartiring",
  warning_inf_date:
    "Aviakompaniya yangi tug'ilgan chaqaloqni parvozga qabul qilish to'g'risidagi guvohnomani talab qilishi mumkin",
  ps_should_be_10_digits: "Rossiya federatsiyasi pasportida 10 ta raqam bo'lishi kerak",
  psp_should_be_9_digits: "Rossiya Federatsiyasining pasportida 9 ta raqam bo'lishi kerak",
  incorrect_doc_number: "Noto'g'ri hujjat raqami",
  hint_for_ps_doc: "Pasport seriyasi va raqamini ko'rsating, masalan 1234567890",
  hint_for_psp_doc: "Pasport raqamini ko'rsating, masalan 123456789",
  hint_for_sr_doc:
    "Tug'ilganlik to'g'risidagi guvohnoma raqamini ko'rsating, masalan IXYA123456",
  hint_for_np_doc: "Hujjat seriyasi va raqamini ko'rsating",
  male: "Erkak",
  female: "Ayol",
  russian_passport: "Rossiya Federatsiyasining pasporti",
  birth_certificate: "Svid-vo Rossiya Federatsiyasining tug'ilishi to'g'risida",
  international_passport: "Rossiya Federatsiyasining pasportlari",
  foreign_document: "Xorijiy hujjat",
  place_one: "joy",
  place_more: "joylar",
  place_many: "joylar",
  customer_support: "Mijozlarni qo'llab-quvvatlash",
  facebook: "Facebook",
  currency: "Valyuta",
  language: "Til",
  search: "Qidiruv",
  personal_info: "Shaxsiy ma'lumotlar",
  to_main: "Asosiy",
  log_out: "Chiqish",
  session: "Session",
  balance: "Balans",
  otb: "Balans",
  own_funds: "O'z mablag'lari",
  credit_line: "Kredit liniyasi",
  no: "Yo'q",
  for_route: "Yo'nalish uchun",
  only_a_z_space: "Faqat lotin belgilari va bo'sh joy",
  only_a_z: "Faqat lotin ramzlari",
  invalid_phone: "Noto'g'ri telefon raqami",
  min_2_symbol: "Kamida 2 ta belgi",
  select_value_from_list: "Ro'yxatdan qiymatni tanlang",
  check_date: "Sanani tekshiring",
  incorrect_number: "Noto'g'ri karta raqami",
  incorrect_cvc: "Noto'g'ri kod",
  incorrect_card_holder: "Karta egasining noto'g'ri nomi",
  incorrect_card_month: "Noto'g'ri oy",
  incorrect_card_year: "Noto'g'ri yil",
  code: "Kod",
  message: "Xabar",
  close: "Yopish",
  error_message_part_1:
    "Biror narsa noto'g'ri ketganga o'xshaydi. Muammoning sabablarini tushunishimiz uchun, iltimos, texnik yordamga murojaat qiling",
  error_message_part_2: "va sessiya raqamini xabar qiling",
  error_message_part_3:
    "Xabar yopilgandan so'ng, siz xizmatning asosiy sahifasiga yo'naltirilasiz.",
  choose: "Tanlang",
  collapse: "Katlama",
  looking_tickets: "Biz chiptalarni qidirmoqdamiz",
  wait_please: "Iltimos, kuting",
  we_issue_tickets: "Biz chiptalarni yozamiz, bu ko'p vaqt talab qilmaydi",
  created: "Yaratilgan",
  left: "Qolgan",
  less_than_minute: "bir daqiqadan kam",
  help: "Yordam",
  exchange_and_return_rules: "Almashish va qaytarish: qoidalar",
  after_flight: "Parvozdan keyin",
  go_to_section: "Bo'limga o'ting",
  next: "Keyingi",
  not_book_tickets: "Sizda bron qilingan aviachiptalar yo'q",
  route_from: "Qayerdan",
  route_to: "Qayerda",
  date_from: "U erda",
  date_to: "Orqaga",
  find: "Toping",
  one_way: "Bir tomonga",
  two_way: "Oldinga va orqaga",
  multi_way: "Qiyin marshrut",
  economy: "Econom klassi",
  business: "Biznes klassi",
  first: "Birinchi klassi",
  all_class: "Sinfga murojaat qilmasdan",
  adt_main_label: "Kattalar",
  inf_main_label: "Chaqaloq",
  chd_main_label: "Bola",
  adt_second_label: "12 yosh va undan katta",
  chd_second_label: "2-12 yil",
  inf_second_label: "2 yoshgacha (bo'sh joy yo'q)",
  inf_with_seat_second_label: "joy bilan",
  passenger_1: "Bitta yo'lovchi",
  passenger_2: "Ikki yo'lovchi",
  passenger_3: "Uch yo'lovchi",
  passenger_4: "To'rt yo'lovchi",
  passenger_5: "Besh yo'lovchi",
  passenger_6: "Olti yo'lovchi",
  agents: "Agentlarga",
  passenger_7: "Etti yo'lovchi",
  passenger_8: "Sakkiz yo'lovchi",
  passenger_9: "To'qqiz yo'lovchi",
  only_there: "U erda",
  roundtrip: "Oldinga va orqaga",
  client_name: "Mijozning ismi",
  client_code: "Mijoz kodi",
  partner_code: "Hamkor kodi",
  partner_name: "Hamkorning ismi",
  partner_currency: "Hamkor valyutasi",
  current_balance: "Joriy balans",
  credit_limit: "Kredit limiti",
  deposit: "Depozit",
  save: "Saqlash",
  departure_title: "Ketish",
  there_title: "U erda",
  back_title: "Orqaga",
  validates_flights: "parvozlarni tasdiqlaydi",
  hour_short: "s",
  day_short: "kn",
  minutes_short_3: "daq",
  on_way: "Yo'lda",
  no_transfers: "Transplantatsiyasiz",
  class: "Sinf",
  tariff_options: "Tarif variantlari",
  with_luggage: "Bagaj bilan",
  without_luggage: "Bagajsiz",
  price: "Narxi",
  detail: "Batafsil",
  label_price_by_min: "Narx (o'sish bo'yicha)",
  label_price_by_max: "Narx (pasayish)",
  transfers: "Transplantatsiya",
  all_flights: "Barcha reyslar",
  without_transfers: "Transplantatsiyasiz",
  one_transfer: "1 transplantatsiya",
  many_transfer: "2 transplantatsiya yoki undan ko'p",
  not_found_recomm_first_text:
    "Biz sizning so'rovingizga mos keladigan chiptalarni topa olmadik",
  not_found_recomm_second_text:
    "Iltimos, boshqa sanalar yoki aeroport bilan qayta urinib ko'ring",
  not_found_recomm_by_filters: "Iltimos, filtr shartlarini o'zgartiring",
  flight_details: "Parvoz tafsilotlari",
  change_tariff: "Tarifni o'zgartiring",
  tariffs: "Tariflar",
  teh_stop: "Texnik to'xtash",
  transfer: "Transplantatsiya",
  local_time_message: "Ketish va kelish vaqti mahalliy",
  route_tariffs_message: "Tarif butun yo'nalish bo'yicha amal qiladi",
  airlines: "Aviakompaniyalar",
  airports: "Aeroportlar",
  departure: "Ketish",
  arrival: "Kelish",
  departure_time: "Uchish vaqti",
  arrival_time: "Kelish vaqti",
  flight_number: "Parvoz raqami",
  multiple_numbers_separated_by_spaces: "Bo'shliq orqali bir nechta raqamlar",
  lowcost: "lowcost",
  transfer_1: "transplantatsiya",
  transfer_2_4: "transplantatsiya",
  transfer_5: "transplantatsiya",
  free_seats_0: "joylar yo'q",
  free_seats_1: "joy",
  free_seats_2_4: "joylar",
  free_seats_5: "joylar",
  one_adt: "Kattalar",
  more_adt: "Kattalar",
  many_adt: "Kattalar",
  one_chd: "Bola",
  more_chd: "Bolalar",
  many_chd: "Bolalar",
  one_inf: "Chaqaloq",
  more_inf: "Chaqaloq",
  many_inf: "Chaqaloqlar",
  flight_one: "parvoz",
  flights_more: "parvoz",
  flights_many: "parvozlar",
  found_one: "Topildi",
  found_more: "Topildi",
  flight_overview: "Parvoz haqida umumiy ma'lumot",
  to_top: "Yuqoriga",
  book: "Yuqoriga",
  buyer: "Xaridor",
  pnr: "Rezervasyon kodi / PNR",
  online_registration_locator: "Onlayn ro'yxatdan o'tish uchun lokator",
  route_information: "Yo'nalish haqida ma'lumot",
  order: "Buyurtma",
  flights_tickets: "Aviachiptalar",
  void_text: "Buyurtmani istalgan vaqtda bekor qilishingiz mumkin",
  void_order: "Buyurtmani bekor qilish",
  payment_method: "To'lov usuli",
  left_before_the_time_limit: "vaqt chegarasi tugashidan oldin qoldi",
  expires: "Muddati tugaydi",
  payment_timed_out: "To'lov muddati tugadi",
  time_expired: "Vaqt tugadi",
  order_created: "Buyurtma yaratildi",
  return_ticket: "Chiptani qaytaring",
  download_itinerary_receipt: "Marshrut kvitansiyasini yuklab oling",
  order_cost: "Buyurtma narxi",
  void_in_process: "Buyurtmani bekor qilish davom etmoqda",
  initial: "Buyurtma yaratildi",
  success: "Muvaffaqiyatli to'lov, chiptalar chiqarildi",
  booking_fail: "Rezervasyon paytida xato",
  order_fail: "Buyurtmani yaratishda xato",
  pay_fail: "To'lov xatosi",
  order_cancel_from_client: "Mijoz tomonidan bekor qilingan buyurtma",
  order_cancel_from_admin: "Administrator tomonidan bekor qilingan buyurtma",
  order_cancel_by_job: "Vaqt chegarasi bo'yicha buyurtma bekor qilindi",
  order_cancel_error: "Buyurtmani bekor qilishda xato",
  application_refund: "To'lovni qaytarish uchun ariza yaratildi",
  partial_refund: "Buyurtma bo'yicha qisman qaytarib berish",
  refund: "Buyurtma bo'yicha to'lovni to'liq qaytarish",
  ticketing_done: "Muvaffaqiyatli to'lov, chiptalar chiqarildi",
  pay_success: "Muvaffaqiyatli to'lov, bo'shatish jarayonida chiptalar",
  booking_done: "Chipta bron qilingan",
  exchange: "Chipta almashish",
  void: "Buyurtma bekor qilindi",
  ticketing_fail: "Chiptalarni chiqarishda xato",
  year_one: "yil",
  year_more: "yillar",
  years: "yillar",
  day_one: "kun",
  day_more: "kun",
  days: "kunlar",
  hour_one: "soat",
  hour_more: "soat",
  hours: "soat",
  minute_one: "daqiqa",
  minute_more: "daqiqalar",
  minutes: "daqiqa",
  second_one: "ikkinchi",
  second_more: "soniyalar",
  seconds: "soniya",
  your_name: "Sizning ismingiz",
  search_order: "Buyurtmani qidirish",
  locator: "Lokator",
  cancel: "Bekor qilish",
  do_not_find_order_message:
    "Buyurtma topilmadi. Iltimos, qidiruv parametrlarini o'zgartiring",
  orders: "Buyurtmalar",
  main: "Asosiy",
  users: "Foydalanuvchilar",
  all_orders_label: "Barcha buyurtmalar",
  book_done_label: "Buyurtma qilingan",
  pay_done_label: "To'langan",
  ticket_done_label: "Chiqarilgan",
  cancel_done_label: "Bekor qilindi",
  wait_refund_label: "Qaytishni kutmoqda",
  refund_done_label: "Qaytarilgan",
  partial_refund_done_label: "Qisman qaytarilgan",
  void_done_label: "Bekor qilingan",
  error_order_label: "Buyurtma xatosi",
  date_create_from: "Bilan yaratilgan sana",
  order_status_label: "Buyurtma holati",
  search_title: "Familiyani, lokatorni yoki shaharni kiriting",
  date_create_to: "Dasturiy ta'minotni yaratish sanasi",
  clear: "Tozalash",
  passenger_success_save: "Yo'lovchi muvaffaqiyatli saqlanib qoldi",
  payment_with_dpt: "Balansdan to'lash",
  payment_with_unt: '"Uniteller" orqali tolash',
  payment_with_spt: '"Korti millie" va "VISA"bilan tolash',
  before: "oldin",
  month_one: "oy",
  months_more: "oylar",
  months: "oylar",
  all_passengers: "Barcha yo'lovchilar",
  companies: "Kompaniyalar",
  no_passengers: "Sizda hali saqlangan yo'lovchilar yo'q",
  value: "Ma'nosi",
  reset: "Qayta tiklash",
  invalid_value: "Noto'g'ri qiymat",
  no_orders_found: "Buyurtmalar topilmadi",
  personal_data: "Shaxsiy ma'lumotlar",
  upload: "Yuklash",
  your_logo: "Sizning logotipingiz",
  image_too_large: "Juda katta rasm hajmi",
  image_resolution_too_high: "Juda katta rasm o'lchamlari",
  image_resolution_too_small: "Juda kichik rasm o'lchamlari",
  amount: "Miqdori",
  rules_text_1: "Bo'limdagi quyidagi iboralarga e'tibor bering",
  rules_text_2: "chipta qaytarib berilmaydi",
  rules_text_3: "parvozga kelmagan taqdirda chipta qaytarib berilmaydi",
  rules_text_4: "Almashish qoidalari subtitr bilan bo'limda tasvirlangan",
  line: "Qator",
  rules_text_5:
    "bu o'zgarishlarga yo'l qo'yilmasligini anglatadi, shuning uchun parvoz sanalarini o'zgartirish mumkin emas",
  passenger_data: "Yo'lovchi ma'lumotlari",
  comment: "Комментарий",
  remove: "O'chirish",
  created_at: "Yaratilgan sana",
  phone: "Telefon",
  create_user: "Foydalanuvchi yarating",
  add: "Qo'shish",
  edit: "Tahrirlash",
  confirm: "Tasdiqlash",
  record_one: "yozuv",
  record_more: "yozuvlar",
  record_many: "yozuvlar",
  security_guarantees_unitaller: "Xavfsizlik kafolatlari",
  activation_title: "Foydalanuvchini faollashtirish",
  confirmation_activation_password: "Parolni tasdiqlang",
  activation: "Faollashtirish",
  login: "Avtorizatsiya",
  error: "Xato",
  to_login_form: "Avtorizatsiya sahifasiga",
  expired_date: "Muddati tugadi",
  refund_button_title: "Qaytish",
  itinerary_receipt: "Yo'nalish kvitansiyasi",
  action: "Harakat",
  to_pay: "To'lash",
  order_cancel_error_mess: "Buyurtmani bekor qilishda xato",
  order_cancel_successfully_mess: "Muvaffaqiyatli bekor qilish",
  partner: "Hamkor",
  inn: "INN",
  kpp: "KPP",
  logo: "Logotip",
  status: "Holati",
  return_request_created: "Qaytish uchun ariza yaratildi",
  error_creating_refund_request: "Qaytish arizasini yaratishda xato",
  placeholder_date_picker: "dd.MM.yyyy",
  transactions: "Tranzaktsiyalar",
  weight_unit: "kg",
  approved_balance_request_successfully:
    "Balansdan chiqarish uchun ariza tasdiqlandi",
  error_approved_balance_request_successfully:
    "Balans bayonnomasini tasdiqlashda xato",
  balance_payment_request: "Balansdan chiqarish uchun ariza",
  balance_payment_approved: "Balansdan chiqarish uchun ariza tasdiqlandi",
  payment_with_uzc: 'Uzcard bilan tolash"',
  client: "Mijoz",
  was_changed_to: "o'zgartirilgan",
  registration: "Ro'yxatdan o'tish",
  fare: "Tarif",
  fees: "To'lovlar",
  partner_fees: "Hamkor to'lovlari",
  taxes: "Soliqlar",
  ticket: "Chipta",
  pay_for_the_order: "Buyurtmani to'lash",
  operating_company: "Operatsion kompaniya",
  download_charter_certificate: "Charter sertifikatini yuklab oling",
  certificate_issued: "Charter sertifikati berildi",
  charter: "Charter",
  service: "Xizmat",
  income: "Kelish",
  spending: "Iste'mol",
  remainder: "Qolgan",
  description: "Tavsif",
  contract: "Shartnoma",
  no_result: "Natijalar yo'q",
  date: "Sana",
  order_num: "Buyurtma raqami",
  payment_with_vsl: '"Korti millie" va "VISA" bilan tolash"',
  popular_destinations: "Ommabop yo'nalishlar",
  more_options: "Boshqa variantlar",
  straight: "To'g'ri",
  with_transfer: "Transplantatsiya bilan",
  contacts: "Kontaktlar",
  difficult_routes: "Qiyin marshrutni tuzing",
  search_history: "Qidiruv tarixi",
  find_tickets: "chiptalarni toping",
  tours_discount: "Foydali sayohatlar va chegirmalar",
  last_news: "So'nggi yangiliklar",
  more_details: "Batafsil",
  about_company: "Kompaniya haqida",
  customer_rew: "Mijozlarning sharhlari",
  my_profile: "Mening profilim",
  contact_us: "Biz bilan bog'laning",
  last_name: "Familiyasi",
  fist_name: "Ism",
  enter_name: "Ismingizni kiriting",
  enter_surname: "Familiyangizni kiriting",
  enter_password: "Parolingizni kiriting",
  log_in: "Kirish",
  return_to_simple_route: "Oddiy marshrutga qaytish",
  delete: "O'chirish",
  max_num_flights: "Siz maksimal parvozlar sonini qo'shdingiz",
  select_dep_date: "Ketish sanasini tanlang",
  select_return_date: "Qaytish sanasini tanlang",
  select_class: " Sinfni tanlang",
  login_as_user: "Foydalanuvchi sifatida tizimga kiring",
  enter_recieved_code: "Olingan kodni kiriting",
  come_up_your_pass: "Parolingizni o'ylab ko'ring",
  enter_valid_mail: "Haqiqiy elektron pochtani kiriting",
  must_field_characters: "maydonda kamida 6 ta belgi bo'lishi kerak",
  register_to_save_data: "Ro'yxatdan o'ting ma'lumotlaringizni saqlash uchun",
  ins_main_label: " Chaqaloq",
  ins_second_label: " 2 yoshgacha (joy bilan)",
  newsletter_subscription: "Axborot byulleteniga obuna bo'lish",
  newsletter_subscription_text:
    "Barcha muhim yangiliklar va ajoyib bitimlardan xabardor bo'lish uchun obuna bo'ling!",
  subscribe: "Obuna bo'lish",
  successfully_subscribed: "Siz muvaffaqiyatli ro'yxatdan o'tdingiz!",
  subscription_error: "Obuna xatosi, keyinroq qayta urinib ko'ring!",
  all_news: "Barcha yangiliklar",
  tel: "tel",
  enter_email: "Elektron pochtangizni kiriting",
  menu: "Menyu",
  settings: " Sozlamalar",
  deposit_method: "To'ldirish usulini tanlang",
  recharging_amount: "To'ldirish miqdori",
  recharge: " To'ldirish",
  recharging_balance: "Balansni to'ldiring",
  done: " Tayyor",
  not_found_content: " Topilmadi",
  not_found_content_text:
    " Afsuski, ushbu tarkib tanlangan tilda mavjud emas",
  airport_code: "Aeroport kodi",
  from_city: "G. dan",
  to_city: "Shaharga",
  weekdays: "Haftaning kunlari",
  airline: "Aviakompaniya",
  min_number: "Minimal to'ldirish miqdori ",
  only_numbers: " Faqat raqamlar",
  daily: "Har kuni",
  fly_tagline: "Chiptalarni ikki marta bosish orqali sotib oling, qulay va oson",
  sorting: "Saralash",
  i_read_fares: "Men bilan tanishdim",
  i_accept_fares: "va men ularga qo'shilaman",
  prices_table: "Narxlar jadvali",
  continue: "Davom eting",
  reset_error_title: "Xato",
  reset_error_content: "Kechirasiz, so'rov bajarilayotganda xato yuz berdi",
  reset_success_title: "Siz parolingizni muvaffaqiyatli o'zgartirdingiz!",
  reset_success_content:
    'Iltimos, yangi parol bilan kirish uchun "kirish" tugmasini bosing',
  forgot_success_title: "Muvaffaqiyatli!",
  forgot_success_content:
    "Parolni tiklash havolasi sizning pochtangizga yuborilgan",
  forgot_error_title: "Xato",
  forgot_error_content: "Kechirasiz, lekin siz noto'g'ri elektron pochta xabarini kiritdingiz",
  password_confirm: "Parolni tasdiqlash",
  password_confirm_error: "Parollar mos kelmaydi",
  enter_password_confirm: "Parolni tasdiqlang",
  enter_new_password: "Yangi parolni kiriting",
  forgot_password: "Parolni unutdingizmi?",
  forgot_password_summary: "Parolni tiklash uchun elektron pochtangizni kiriting",
  payment: '"Korti millie" uchun tolov"',
  from_airport: "Aeroportdan",
  not_enough_balance: "To'lov uchun etarli mablag ' yo'q",
  payment_methods: "To'lov usullari",
  our_partners: "Bizning sheriklarimiz",
  confirm_actions: "Saytdagi harakatlaringizni tasdiqlang",
  payer_contacts: "Xaridorning aloqalari",
  buyer_email: "Xaridorning elektron pochtasi",
  enter_buyer_email: "Xaridorning elektron pochtasini kiriting",
  locator_order_number_or_booking_number:
    "Lokator, buyurtma raqami yoki bron raqami",
  enter_locator_order_number_or_booking_number:
    "Lokator, buyurtma raqami yoki bron raqamini kiriting",
  activate: "Faollashtirish",
  register: "Ro'yxatdan o'ting",
  page_home: "Onlayn chiptalarni sotib olish, kassalarga tashrif buyurmasdan.",
  page_faq: "Ommabop savollarga javoblar",
  page_login: "Shaxsiy hisobingizga kiring",
  page_registration: "Ro'yxatdan o'ting va aviachiptalarni sotib oling",
  page_results: "Qiziqish yo'nalishi bo'yicha natijalar",
  page_booking: "Berilgan chiptani bron qilish",
  page_order: "Buyurtma haqida ma'lumot",
  page_orders_list: "Buyurtmalar haqida ma'lumot",
  page_my_profile: "Shaxsiy ma'lumotlar",
  page_add_balance: "Shaxsiy hisob balansini to'ldirish",
  page_popular_destinations: "Mashhur parvozlar jadvali",
  page_news: "Mashhur aviatsiya yangiliklari va flytrip.uz",
  page_forgot_password: "Uydan chiqmasdan onlayn aviachiptalarni sotib oling!",
  page_reset_password: "Uydan chiqmasdan onlayn aviachiptalarni sotib oling!",
  useful_info: "Foydali ma'lumotlar",
  copy_link: "Havolani nusxalash",
  copy_order_link_text:
    "Ushbu buyurtmaga kirishni baham ko'rish uchun havolani nusxalash",
  link_copied_to_clipboard: "Havola buferga ko'chirilgan",
  unsubscribe_success: "Siz yangiliklarga obunani muvaffaqiyatli bekor qildingiz!",
  unsubscribe_error: "Qayta ishlash jarayonida xatolik yuz berdi",
  activation_code_send_to_email:
    "Tasdiqlash kodi sizga ko'rsatilgan pochtaga yuboriladi",
  choose_gender: "Zaminni tanlang",
  old_information: "Ma'lumotlar eskirgan!",
  refresh: "Yangilash",
  refresh_page:
    "Aviachiptalar narxi kuniga bir necha marta yangilanadi. Joriy narxlarni tekshirish uchun sahifani yangilang.",
  will_take_2_minutes: "Bu biroz vaqt talab qilishi mumkin",
  additional_options: "Qo'shimcha xizmatlar",
  additional_options_not_found: "Qo'shimcha xizmatlar topilmadi!",
  your_id: "Sizning ID",
  cheapest_without_baggage: "Bagajsiz eng arzon",
  cheapest_with_baggage: "Bagaj bilan eng arzon",
  most_convenient_transfes: "Eng qulay transplantatsiya",
  refund_order: "Qaytish bayonoti",
  refund_reason: "Qaytish sababi",
  order_payment_method: "Buyurtmani to'lash usuli",
  payer_user_id: "Buyurtmani to'lagan foydalanuvchi identifikatori",
  four_last_digits_of_card: "To'lov kartasining oxirgi 4 raqami",
  user_id: "Foydalanuvchi ID",
  buyer_phone: "Xaridorning telefoni",
  requisite_of_account_to_return:
    "To'lovni qaytarish amalga oshiriladigan hisob ma'lumotlari",
  requisite_of_account: "Hisob ma'lumotlari",
  passenger_name_and_surname: "Yo'lovchining familiyasi va ismi",
  passport_serial_and_number: "Pasport seriyasi va raqami",
  passport_copy: "Pasport nusxasi",
  send: "Yuborish",
  payment_from_client_balance: "Mijozning balansidan to'lov",
  payment_from_card: "Karta bilan to'lash",
  technical_support: "Texnik Yordam",
  buy_tickets_to_all_destinations: "va bortga aviachiptalarni sotib oling!",
  filters: "Filtrlar",
  night_flight: "Tungi transplantatsiya",
  change_airport: "Aeroport o'zgarishi",
  and: "va",
  from: "dan",
  refund_order_success: "Qaytish bayonoti muvaffaqiyatli yuborildi",
  refund_order_error: "Qaytish bayonotini yuborishda xato",
  buy_tour: "Turni sotib oling",
  search_limits_exceeded:
    "Hurmatli foydalanuvchi, siz qidiruvlar sonidan oshib ketdingiz, iltimos avtorizatsiya/ro'yxatdan o'ting ishingizni davom ettirish uchun",
  flight_without_seats: "Afsuski, ma'lum bir sinfda o'rindiqlar tugadi, ammo iqtisod sinfida joylar mavjud",
  cashback: "Cashback",
  or_use_socials: "yoki ijtimoiy tarmoqlardan foydalaning",
  insurance: "Sug'urta",
  organization_info: "flytrip.uz mas'uliyati cheklangan jamiyati",
  organization_address: "Haqiqiy manzil: Tojikiston Respublikasi, Dushanbe shahri, Buxoro ko'chasi 32-34,",
  inn_address: "ИНН 030018191",
  ein_address: "ЕИН 0310005585",
  we_are_multifaceted: "Biz ko'p qirrali",
  who_are_we: "Biz kimmiz?",
  fly_information: `
    «FLY.TJ» работает на рынке с 2010 года и стала одной из самых успешных компаний в отрасли авиаперевозки.
    Также имеем официальный статус IATA «Аккредитованный агент IATA»,
    Сейчас мы являемся первой и единственной компанией в Таджикистане,
    имеющей собственную национальную систему онлайн бронирования с доступом и прямым партнерством с большим
    количеством международных авиакомпаний, таких как:
    Turkish Airlines, FlyDubai, Air Astana, Узбекистан Airways, Belavia, China Southern и другие.
  `,
  our_advantages: "Наши преимущества",
  our_services: `
  Спектр услуг, которые оказывает наша компания, весьма разнообразна.
  Помимо пассажирских авиаперевозок, наши специалисты также помогут Вам в организации
  Вашей деловой поездки (забронировать отель, оформить страховой полис и визу).
  Компания «FLY.TJ» является лидером продаж авиабилетов по всем направлениям на рынке авиаперевозок.
  За это время нами была разработана и оптимизирована онлайн система по реализации авиабилетов,
  которая не имеет аналогов в Таджикистане. Данная система позволяет Вам легко и просто оценивать,
  бронировать и покупать авиабилеты самостоятельно на сайт.
  `,
  convenience: "Qulaylik",
  user_friendly_interface: "Aniq interfeys",
  access247: "Kirish 24/7",
  purchase_of_tickets_to_any_destinations: "Har qanday yo'nalish uchun chiptalarni sotib olish",
  uniqueness: "Noyoblik",
  access_to_a_unique_product: "Noyob mahsulotga kirish",
  always_on_the_phone: "Har doim aloqada",
  customer_information_support_247: "Mijozlarni axborot bilan qo'llab-quvvatlash 24/7",
  without_providing_data_to_third_parties: "Uchinchi shaxslarga ma'lumot bermasdan",
  privacy: "Maxfiylik",
  safety: "Xavfsizlik",
  secure_connection: "Xavfsiz ulanish",
  professionalism: "Professionallik",
  prompt_service: "Operatsion xizmat",
  soft: "Dasturiy ta'minot",
  own_booking_system: "Xususiy bronlash tizimi",
  we_will_send_the_ticket_to_your_inbox_within_5_minutes: "Biz sizning pochtangizga chiptani 2-3 daqiqa ichida yuboramiz.",
  go_to_the_main_page: "Bosh sahifaga o'ting",
  your_order_has_been_successfully_paid_for: "Sizning buyurtmangiz muvaffaqiyatli to'langan!",
  the_ticket_will_be_sent_to_your_email_within_1_2_minutes: "Elektron chipta sizning pochtangizga 1-2 daqiqa ichida yuboriladi",
  mon: "dsh",
  tues: "ssh",
  wed: "chsh",
  thur: "pn",
  fri: "jm",
  sat: "shb",
  sun: "ysh",
  show_more: "Ko'proq ko'rsatish",
  go_to_the_payment_page: "To'lov sahifasiga o'ting",
  no_orders_to_confirm_payment: "To'lovni tasdiqlash uchun buyurtmalar yo'q",
  go_to_the_orders_page: "Buyurtmalar sahifasiga o'ting",
  change_the_tariff: "Tarifni o'zgartiring: bagaj, sinf va boshqalar",
  add_baggage: "Tarifni o'zgartiring",
  baggage_included: "Bagaj kiritilgan",
  fare_without_baggage: "Yuksiz tarif",
  share: "Ulashish",
  passenger_search: "Yo'lovchilarni qidirish",
  this_passenger_is_not_found_on_your_list: "Ushbu yo'lovchi sizning ro'yxatingizda topilmadi",
  go_to_orders_page: "Buyurtma sahifasiga o'ting",
};

export default uz;
