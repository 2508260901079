import styled from "styled-components";

export const TitleWrapper = styled("div")(({ theme, position, textTransform }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent:
    position === "center"
      ? "center"
      : position === "right"
      ? "flex-end"
      : "flex-start",
  textTransform: textTransform === "uppercase" ? "uppercase" : "none",
  gap: 16,
  color: theme.palette.mainText,

  h1: {
    fontSize: "clamp(26px, 3vw, 42px)"
  },
  h2: {
    fontSize: "clamp(20px, 3vw, 34px) "
  },
  h3: {
    fontSize: "clamp(16px, 2vw, 26px)"
  },
  h4: {
    fontSize: "clamp(14px, 2vw, 18px)"
  }
}));

export const IconBlock = styled("div")(({ theme }) => ({
  width: 30,
  height: 30,
  transform: "rotate(45deg)",
  backgroundColor: theme.palette.main,
  padding: 4,
  borderRadius: 6,

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transform: "rotate(-45deg)"
  },

  [theme.breakpoints.down(theme.breakpoints.mobile)]: {
    display: "none",
  }
}));
