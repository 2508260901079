import styled from "styled-components";

export const Section = styled("section")(({ theme }) => ({
	padding: "clamp(38px, 6vw, 95px) 0",
	// backgroundColor: theme ? 'transparent' : "#F7FAFF",

	"& h1": {
		color: theme.palette.mainText,
		fontSize: "clamp(26px, 5vw, 72px)",
		textAlign: "center",
		opacity: "0.9",
	},
}));

export const ItemsBlock = styled("div")(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(4, 1fr)",
	gap: "30px",

	'@media (max-width: 1280px)': {
		gridTemplateColumns: "repeat(3, 1fr)",
	},

	'@media (max-width: 960px)': {
		gridTemplateColumns: "repeat(2, 1fr)",
	},

	'@media (max-width: 600px)': {
		gridTemplateColumns: '1fr',
	},

	"& div": {
		backgroundColor: theme.palette.transactionTableTitle,
		borderRadius: "10px",
		padding: "35px",
		textAlign: "center",
		color: theme.palette.mainText,

		'& img': {
			width: '100%',
			maxWidth: '130px',
		},

		"& h3": {
			fontSize: "20px",
			opacity: '0.9',
			wordBreak: 'break-all',
			margin: '10px 0'
		},

		'& span': {
			fontSize: '16px',
			opacity: '0.6',
		}
	},
}));
