import React from 'react';

import styled from 'styled-components';
import { Switch } from '@material-ui/core';
import { LIGHT_THEME } from '../../../../../../../../theme/constants';
import { ReactComponent as Moon } from "../../../../../../../../images/darkModeIcon/moon.svg"
import moon from '../../../../../../../../images/darkModeIcon/moon.svg'
import Sun from '../../../../../../../../images/darkModeIcon/sun.svg'

export const ThemeButton = styled('button')(({ theme }) => ({
    backgroundImage: `url(${theme.current === LIGHT_THEME ? moon : Sun})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    width: '42px',
    height: '42px',
    border: `1px solid  ${theme.palette.main}`,
    borderRadius: '50%',

    '&:hover': {
        cursor: 'pointer',
        backgroundColor: `${theme.palette.buttonHover.exitButton}`,
    },
}));