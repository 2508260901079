import React from "react";

import { Section,ItemsBlock } from "./components";

const SecondSection = ({ seconsSectionData }) => {
  const { title, advantageItems } = seconsSectionData;

	return (
  <Section>
    <h1>{title}</h1>
    <ItemsBlock>
    {advantageItems.map((items, index) => (
      <div key={index}>
        <img src={items.img} alt="" />
        <h3>{items.title}</h3>
        <span>{items.desc}</span>
      </div>
    ))}
    </ItemsBlock>
  </Section>
);
};

export default SecondSection;
