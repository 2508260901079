import moment from 'moment';
import _ from 'lodash';
import mainConfigs from '../../../config/mainConfigs';

const transferTimeFormat = mainConfigs.webTransferTimeFormat;

export const getTransferTime = (route, index, hoursShortText, minutesShortText) => {
    const prevDate = route.segments[index - 1].arrival.time;
    const actualDate = route.segments[index].departure.time;
    const prevDateUnix = moment(prevDate, transferTimeFormat).unix();
    const actualDateUnix = moment(actualDate, transferTimeFormat).unix();
    const unixDifference = actualDateUnix - prevDateUnix;
    const hours = Math.trunc(unixDifference / 3600);
    const minutes = Math.floor((unixDifference - hours * 3600) / 60);

    return `${hours} ${hoursShortText} ${minutes} ${minutesShortText}`;
};

export const checkIsNightFlightAndIsChangeAirport = (route, currentSegmentIndex) => {
    if (currentSegmentIndex === 0) {
        return [false, false];
    }

    let isNightTransfer = false;
    let isChangeAirport = false;

    if (
        route.segments[currentSegmentIndex].departure.airport !==
        route.segments[currentSegmentIndex - 1].arrival.airport
    ) {
        isChangeAirport = true;
    }

    const timeArray = route.segments[currentSegmentIndex - 1].arrival.time.split(' ');

    const arrivalTime = moment(timeArray[1], 'hh:mm'),
        beforeTime = moment('06:00', 'hh:mm'),
        afterTime = moment('18:00', 'hh:mm');

    if (arrivalTime.isSameOrBefore(beforeTime) || arrivalTime.isSameOrAfter(afterTime)) {
        isNightTransfer = true;
    }

    return [isChangeAirport, isNightTransfer];
};

export const getDurationTime = (duration, hoursShort, minutesShort) => {
    const hours = Math.trunc(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);

    return `${hours} ${hoursShort} ${minutes} ${minutesShort}`;
};

export const getCityAndAirportNames = (cities, airports, segment, language) => {
    const { city, airport } = segment;

    const cityName = cities[city]?.name[language];
    const airportName = airports[airport]?.name[language];

    if (cityName === airportName) {
        return cityName;
    }

    return `${airportName}, ${cityName}`;
};

export const getTechStops = (techStops, cities, language, hoursShortText, minutesShortText, techStopText) => {
    const techStopsText = techStops
        .map(item => {
            let time = '';

            if (item.duration.hour > 0) {
                time = `${item.duration.hour} ${hoursShortText} `;
            }

            time += `${item.duration.minute} ${minutesShortText}`;

            return `${cities[item.city.code].name[language]} ${time}`;
        })
        .join(', ');

    return `${techStopText}: ${techStopsText}`;
};

export const getServiceClass = (serviceClass, classText) => {
    const { code, name } = serviceClass;

    if (!_.isEmpty(code)) {
        return `${classText}: ${name} (${code})`;
    }

    return `${classText}: ${name}`;
};

export const parserLangCode = language => {
    return language === 'tj' ? 'tg' : language;
};

const getMomentObject = (data, language) => {
    return moment(data, transferTimeFormat).locale(parserLangCode(language));
};

export const getDayMonthYearFormat = (data, language) => {
    const momentObj = getMomentObject(data, language);

    return momentObj.format(mainConfigs.dateFormatWithoutPoint).replace('.', '');
};
