import React, { useContext } from 'react';
import { Input } from '@material-ui/core';
import device from 'current-device';
import NativeSelectStatelessUI from '../NativeSelectStatelessUI/NativeSelectStatelessUI';
import { ErrorsFieldBlock } from '../../../../styles/common';
import InputMessage from '../InputMessage/InputMessage';
import useLocalization from '../../../../contexts/localization/hook';
import { FormControlUi, InputLabelUi, StyledMenuItem, StyledSelect } from './components';
import useTheme from '../../../../contexts/theme/hook';
import getTheme from '../../../../theme';
import ThemeContext from '../../../../contexts/theme/context';


function SelectFormUI(props) {
    const changeTheme = useContext(ThemeContext);

    const { t } = useLocalization();
    const { input, items, color, fontSize, label, width, height, onClick, disabled, fieldError, value } = props;
    const { theme: currentTheme } = useTheme();

    const desktopSelect = () => (
        <StyledSelect
            input={<Input {...input} />}
            disabled={disabled}
            onClick={onClick}
            width={width}
            height={height}
            theme={getTheme(currentTheme)}
            themeColor={changeTheme.theme}
        >
            {items.map(({ label, value, disabled = false }) => (
                <StyledMenuItem disableRipple key={value} value={value} disabled={disabled}>
                    {label}
                </StyledMenuItem>
            ))}
        </StyledSelect>
    );

    return (
        <FormControlUi>
            {label && (
                <InputLabelUi focused={false}>
                    {label}
                </InputLabelUi>
            )}
            {device.type === 'desktop' ? desktopSelect() : <NativeSelectStatelessUI {...props} />}

            <ErrorsFieldBlock>
                {props?.meta?.touched && props?.meta?.error && <InputMessage text={t[props?.meta?.error]} />}
                {fieldError && <InputMessage text={fieldError} />}
            </ErrorsFieldBlock>
        </FormControlUi>
    );
}

export default SelectFormUI;
