import styled from "styled-components";

export const ActiveOrderWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  color: theme.palette.mainText,
}));

export const Button = styled("button")(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  height: 42,
  width: 42,
  border: `1px solid ${theme.palette.main}`,
  borderRadius: "50%",
  cursor: "pointer",
  backgroundColor: theme.palette.transparent,
  marginRight: 16,

  "&:hover": {
    backgroundColor: theme.palette.buttonHover.exitButton,
  },
}));

export const ActiveOrder = styled("div")(({ theme }) => ({
  position: "relative",

  svg: {
    circle: {
      stroke: theme.palette.main
    }
  }
}));

export const LoadingBlock = styled("div")(({ theme }) => ({
  transform: 'translateY(2px)'
}))

export const OrderCounter = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "26px",
  height: "26px",
  borderRadius: "50%",
  background: theme.palette.error,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontWeight: "600",
  fontSize: "18px",
  right: "-19px",
  top: "-10px",
  border: "2px solid",
  borderColor: theme.palette.bodyBg,
}));

export const Direction = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: "4px 16px",
  alignItems: "center",
  minHeight: 40,
  marginBottom: 8,
  background: theme.palette.bodyBg,
  padding: 8,
  borderRadius: 8,
  border: "1px solid #a5a5a596",

  div: {
    color: theme.palette.mainText,
    borderRadius: "8px",
    fontSize: 14,
  },

  svg: {
    justifySelf: "center",
  },

  a: {
    gridColumn: "1/4",
    justifySelf: "left",
    fontSize: 14,
    color: theme.palette.main,
    textDecoration: "none",
  },

  p: {
    gridColumn: "1/4"
  }
}));

export const SvgBlock = styled("div")(({ theme }) => ({
  svg: {
    width: 33,
    height: 33,
    fill: theme.palette.main,

    path: {
      stroke: "transparent ",
    },
  },
}));

export const OrderCreated = styled("div")(({ theme }) => ({
  gridColumn: "1/4",
  fontSize: "12px",
  opacity: "0.8",
}));
