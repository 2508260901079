import React from 'react';

import { useSelector } from 'react-redux';
import { CartIconBlock, Wrapper } from './components';
import SettingsMenu from './parts/settings';
import MainMenu from './parts/menu';
import ThemeBtn from '../desktop/parts/themeButton';
import ActiveOrdersIcon from '../desktop/parts/activeOrdersIcon';

const MobileNav = () => {
    const { isAuth } = useSelector(store => store.appConfigs);

    return (
        <Wrapper>
            <ThemeBtn />
            <SettingsMenu />
            {isAuth && <CartIconBlock><ActiveOrdersIcon/></CartIconBlock>}
            <MainMenu />
        </Wrapper>
    );
};

export default MobileNav;