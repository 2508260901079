import React from "react";

import useLocalization from "../../../contexts/localization/hook";

import rocket from "../../../assets/images/aboutUsPage/rocket.png";
import jumpingMan from "../../../assets/icons/aboutUsPage/jumping-man.png";
import access24 from "../../../assets/icons/aboutUsPage/24access.png"
import alwaysOnThePhone from "../../../assets/icons/aboutUsPage/Always_on_the_phone.png"
import convenience from "../../../assets/icons/aboutUsPage/Convenience.png"
import privacy from "../../../assets/icons/aboutUsPage/Privacy.png"
import professionalism from "../../../assets/icons/aboutUsPage/Professionalism.png"
import safety from "../../../assets/icons/aboutUsPage/Safety.png"
import soft from "../../../assets/icons/aboutUsPage/Soft.png"
import uniqueness from "../../../assets/icons/aboutUsPage/Uniqueness.png"

import TopAndBottomSection from "./topAndBottomSection/TopAndBottomSection";
import SecondSection from "./secondSection/SecondSection";

const AboutUs = () => {
  const { t } = useLocalization();

  const topData = {
    title: t.who_are_we,
    description: t.fly_information,
    image: rocket,
  };

const seconsSectionData = {
  title: `${t.our_advantages}:`,
  advantageItems: [
    {
      img: access24,
      title: t.convenience,
      desc: t.user_friendly_interface,
    },
    {
      img: alwaysOnThePhone,
      title: t.access247,
      desc: t.purchase_of_tickets_to_any_destinations,
    },
    {
      img: convenience,
      title: t.uniqueness,
      desc: t.access_to_a_unique_product,
    },
    {
      img: privacy,
      title: t.always_on_the_phone,
      desc: t.customer_information_support_247,
    },
    {
      img: professionalism,
      title: t.privacy,
      desc: t.without_providing_data_to_third_parties,
    },
    {
      img: safety,
      title: t.safety,
      desc: t.secure_connection,
    },
    {
      img: soft,
      title: t.professionalism,
      desc: t.prompt_service,
    },
    {
      img: uniqueness,
      title: t.soft,
      desc: t.own_booking_system,
    },
  ]
}

  const bottomData = {
    title: t.we_are_multifaceted,
    description: t.our_services,
    image: jumpingMan,
  };

  return (
    <div>
      <TopAndBottomSection data={topData} />
      <SecondSection seconsSectionData={seconsSectionData}/>
      <TopAndBottomSection data={bottomData} />
    
    </div>
  )
};

export default AboutUs;