import React, { useEffect, useMemo, useState } from "react";

import API from "../../../../../../../../lib/api";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

import { useSelector } from "react-redux";
import { selectOrderData } from "../../../../../../../../containers/order/reducers";

import { ReactComponent as ActiveOrderIcon } from "../../../../../../../../assets/icons/newDesign/cart.svg";

import Dropdown from "../../../../../../ui/dropdown";
import useLocalization from "../../../../../../../../contexts/localization/hook";
import { CircularProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

import {
  ActiveOrder,
  ActiveOrderWrapper,
  Button,
  Direction,
  LoadingBlock,
  OrderCounter,
  OrderCreated,
  SvgBlock,
} from "./components";

const ActiveOrdersIcon = () => {
  const { t, language } = useLocalization();
  const orderData = useSelector(selectOrderData);
  const [ordersData, setOrdersData] = useState({
    orders: [],
    bookedCounter: 0,
  });
  const [dropdownActive, setdropdownActive] = useState(false);
  const [laoding, setLoading] = useState(false);

  const bookingStatus = "bookingDone"; //"bookingDone";

  const orderPageActionsToTicket = orderData?.booking_status !== bookingStatus;

  const orderUrl = window.location.pathname;

  const urlAndOrderStatus =
    orderUrl === `/order/${orderData?.order_id}` && orderPageActionsToTicket;

  useEffect(() => {
    setLoading(true);
    API.apiGetOrders()
      .then((response) => {
        if (response) {
          setOrdersData((prevState) => ({
            ...prevState,
            orders: response.data,
          }));

          if (urlAndOrderStatus) {
            setOrdersData((prevstate) => ({ ...prevstate, bookedCounter: 0 }));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [urlAndOrderStatus, orderPageActionsToTicket]);

  useMemo(() => {
    if (ordersData.orders) {
      ordersData.orders.map((orderData) =>
        orderData?.orders.map(
          (order, index) =>
            order.booking_status === bookingStatus &&
            setOrdersData((prevState) => ({
              ...prevState,
              bookedCounter: ++index,
            }))
        )
      );
    }
  }, [ordersData.orders]);

  const bookingDoneOrders = ordersData.orders.map((item) =>
    item.orders.filter((filtered) => filtered.booking_status === bookingStatus)
  );

  return (
    <ActiveOrderWrapper>
      <Button onClick={() => !laoding && setdropdownActive(true)}>
        <ActiveOrder>
          {ordersData.bookedCounter ? (
            <OrderCounter>{ordersData.bookedCounter}</OrderCounter>
          ) : null}
          {laoding ? (
            <LoadingBlock>
              <CircularProgress size={20} />
            </LoadingBlock>
          ) : (
            <SvgBlock>
              <ActiveOrderIcon />
            </SvgBlock>
          )}
        </ActiveOrder>
      </Button>
      <Dropdown
        isActive={dropdownActive && !laoding}
        onClickOutside={() => setdropdownActive(false)}
        width="300px"
      >
        {ordersData.bookedCounter ? (
          bookingDoneOrders.map((item) =>
            item.map((order) =>
              order.routes.map((route) => (
                <Direction>
                  <OrderCreated>
                    {t.date}:{" "}
                    {order.created_at
                      .split("T")
                      .splice(0, 1)
                      .join("")
                      .split("-")
                      .reverse()
                      .join(".")}
                  </OrderCreated>
                  <div>
                    {
                      order.included.airport[route.departure_airport_code][
                        `name_${language}`
                      ]
                    }
                  </div>
                  <TrendingFlatIcon />
                  <div>
                    {
                      order.included.airport[route.arrival_airport_code][
                        `name_${language}`
                      ]
                    }
                  </div>
                  <a
                    onClick={() => setdropdownActive(false)}
                    href={`/order/${order.order_id}`}
                  >
                    {t.go_to_the_payment_page}
                  </a>
                </Direction>
              ))
            )
          )
        ) : (
          <Direction>
            <p>{t.no_orders_to_confirm_payment}</p>
            <NavLink onClick={() => setdropdownActive(false)} to="/orders">
              {t.go_to_the_orders_page}
            </NavLink>
          </Direction>
        )}
      </Dropdown>
    </ActiveOrderWrapper>
  );
};

export default ActiveOrdersIcon;
