import {IconButton} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import styled, {css, keyframes} from 'styled-components';


const fadeIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const Wrapper = styled('div')(({theme, isShow}) => ({
    display: isShow ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 250,
    backgroundColor: theme.palette.blackOpacity,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        backgroundColor: theme.palette.transparent,
    }
}));

export const Backdrop = styled('div')(
    ({theme}) => ({
        ...theme.mixins.flexCenterCenter,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 251,
        backgroundColor: theme.palette.transparent,
    }),
    css`
      animation: ${props => (props.isShow ? fadeIn : fadeOut)} 0.3s ease forwards;
    `
);


export const Modal = styled('div')(({theme, fixedWidth, maxWidth}) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.inputBackground,
    maxHeight: '90%',
    width: `${fixedWidth ? maxWidth + 'px' : 'auto'}`,
    maxWidth: `${maxWidth}px`,
    minWidth: 360,
    position: 'relative',
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        height: '100%',
        width: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        borderRadius: theme.spacing(0),
    }
}));

export const Header = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...theme.paddings(16, 16),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddings(0, 0),
        justifyContent: 'center',
        height: 50,
        minHeight: 50,
        borderBottom: `1px solid ${theme.palette.menuHeaderBorderBottom}`,
    }
}));

export const Title = styled('div')(({theme}) => ({
    fontWeight: 600,
    fontSize: '18px',
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        textAlign: 'center',
        maxWidth: '80%',
        textOverflow: 'ellipsis',
    }
}));

export const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 12px;
  top: 10px;
`;

export const CloseIcon = styled(Close)(({theme}) => ({
    color: theme.palette.mainText,
}));

export const Body = styled('div')(({theme}) => ({
    ...theme.paddings(0, 16, 16, 0),
    overflowY: 'auto',
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddings(16, 16),
        minHeight: 'calc(100% - 50px)'
    }
}));

export const LeftButton = styled('button')(({theme}) => ({
    ...theme.paddings(8, 8),
    backgroundColor: theme.palette.transparent,
    border: 'none',
    color: theme.palette.main,
    position: 'absolute !important',
    left: 8,
    top: 8,
    fontSize: 16,
}));
