import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme }) => ({
    ...theme.margins(0, 'auto'),
    ...theme.paddings(50, 16),
    maxWidth: 500,
    textAlign: 'center',
}));

export const NotFoundRecommMargin = styled('div')({ position: 'relative' });

export const NotFoundRecommFirstBlock = styled('div')(({ theme }) => ({
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: theme.palette.mainText

}));

export const NotFoundRecommSecondBlock = styled('div')(({ theme }) => ({
    fontSize: 18,
    color: theme.palette.mainText
}));
