import styled from "styled-components";

export const Section = styled("div")(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(2, 1fr)",
	alignItems: "center",

	"@media (max-width: 960px)": {
		gridTemplateColumns: "1fr",
	},

	"& article": {
		"& h1": {
			color: theme.palette.main,
			fontSize: "clamp(26px, 5vw, 72px)",
			margin: "0 0 18px 0",
		},

		"& p": {
			opacity: 0.7,
			lineHeight: "30px",
			fontSize: "21px",
      color: theme.palette.mainText,

			"@media (max-width: 1280px)": {
				fontSize: "16px",
			},
		},
	},
}));

export const ImageBlock = styled("div")(({ theme }) => ({
	"@media (max-width: 960px)": {
		gridRow: "1/2",
	},

	img: {
		width: "100%",
	},
}));
