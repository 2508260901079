import React, { Fragment, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Typography } from "@material-ui/core";

import mainConfigs from "../../config/mainConfigs";
import Filters from "../design_v2/resultPage/filters";
import FlightsCount from "../design_v2/resultPage/flightsCount";
import NotFoundFlightsTitle from "../design_v2/resultPage/notFoundFlightsTitle";
import Recommendation from "../design_v2/resultPage/recommendation";
import CompaniesAndPricesTable from "../design_v2/resultPage/companiesAndPricesTable";
import * as Styled from "../../styles/result/index";
import ScrollUpButton from "../design_v2/ui/scrollUpButton";
import LoadingBlock from "../design_v2/resultPage/loadingBlock";
import useLocalization from "../../contexts/localization/hook";
import ModalComponent from "../design_v2/ui/modal/index";
import { FormSubmitButton } from "../design_v2/header/parts/nav/desktop/parts/searchOrder/components";
import Container from "../design_v2/ui/container";

import "./styles.css";
import SnackbarComponent from "../design_v2/ui/snackbar";

const pageRecLimit = mainConfigs.pageRecLimit;

function ResultForm(props) {
  const {
    sortedRecommendations,
    originalRecommendations,
    nonPriceFlights,
    bookingClick,
    getBrandFares,
    getFareRules,
    routesData,
    airlinesData,
    priceRange,
  } = props;

  const { t } = useLocalization();
  const { filterParams } = useSelector((state) => state.filters);
  const { currency } = useSelector((state) => state.appConfigs);

  const [page, setPage] = useState(1);
  const [noRecommendations] = useState(originalRecommendations.length === 0);
  const [modalShow, setModalShow] = useState(false);

  const [timerModal, setTimerModal] = useState(0);
  const [snakBarShow, setSnakBarShow] = useState(false);

  useEffect(() => {
    let timerModal = setTimeout(() => setTimerModal(true), 900000);

    return () => {
      clearTimeout(timerModal);
    };
  }, []);

  const checkIsActive = () => {
    const {
      baggage,
      transfers,
      sorting,
      airlines,
      flightNumbers,
      price,
      airports,
      duration,
      departureTime,
      arrivalTime,
      pricesTable,
    } = filterParams;

    return (
      baggage.isActive ||
      transfers.isActive ||
      sorting.isActive ||
      airlines.isActive ||
      flightNumbers.isActive ||
      price.isActive ||
      airports.isActive ||
      duration.isActive ||
      departureTime.isActive ||
      arrivalTime.isActive ||
      pricesTable.isActive
    );
  };

  const refreshPage = () => {
    setTimerModal(false);
    window.location.reload();
  };

  const checkSpecialRecommendations = () => {
    if (originalRecommendations.length < 3 || checkIsActive()) return null;

    const result = [];
    const withTransfers = [];

    let withoutBaggageIndex = -1;
    let withBaggageIndex = -1;
    let minimumDurationIndex = -1;

    for (let i = 0; i < originalRecommendations.length; i++) {
      if (originalRecommendations[i].routes[0].segments[0].baggage) {
        if (withBaggageIndex < 0) {
          withBaggageIndex = i;
        } else {
          if (
            originalRecommendations[withBaggageIndex].total_price[currency] >
            originalRecommendations[i].total_price[currency]
          ) {
            withBaggageIndex = i;
          }
        }
      } else {
        if (withoutBaggageIndex < 0) {
          withoutBaggageIndex = i;
        } else {
          if (
            originalRecommendations[withoutBaggageIndex].total_price[currency] >
            originalRecommendations[i].total_price[currency]
          ) {
            withoutBaggageIndex = i;
          }
        }
      }

      let filterStatus = false;

      originalRecommendations[i].routes.forEach((route) => {
        if (route.segments.length > 1) {
          filterStatus = true;
        }
      });

      if (filterStatus) {
        withTransfers.push(i);
      }
    }

    if (withTransfers.length > 0) {
      const durations = withTransfers.map((value) => {
        let duration = 0;
        originalRecommendations[value].routes.forEach((route) => {
          duration += route.duration;
        });

        return { index: value, duration };
      });

      let minDurationIndex = 0;
      let minDuration = durations[minDurationIndex];

      durations.forEach((value, index) => {
        if (value.duration < minDuration) {
          minDuration = value.duration;
          minDurationIndex = index;
        }
      });

      minimumDurationIndex = durations[minDurationIndex].index;
    }

    if (withoutBaggageIndex > -1) {
      if (
        withBaggageIndex > -1 &&
        originalRecommendations[withoutBaggageIndex].total_price[currency] >
          originalRecommendations[withBaggageIndex].total_price[currency]
      ) {
        result.push({
          recommendation: originalRecommendations[withBaggageIndex],
          specialText: t.cheapest_with_baggage,
        });
      } else {
        result.push({
          recommendation: originalRecommendations[withoutBaggageIndex],
          specialText: t.cheapest_without_baggage,
        });
      }
    }

    if (withBaggageIndex > -1) {
      if (
        withoutBaggageIndex > -1 &&
        originalRecommendations[withoutBaggageIndex].total_price[currency] >
          originalRecommendations[withBaggageIndex].total_price[currency]
      ) {
        result.push({
          recommendation: originalRecommendations[withoutBaggageIndex],
          specialText: t.cheapest_without_baggage,
        });
      } else {
        result.push({
          recommendation: originalRecommendations[withBaggageIndex],
          specialText: t.cheapest_with_baggage,
        });
      }
    }

    if (
      minimumDurationIndex > -1 &&
      minimumDurationIndex !== withBaggageIndex &&
      minimumDurationIndex !== withoutBaggageIndex
    ) {
      result.push({
        recommendation: originalRecommendations[minimumDurationIndex],
        specialText: t.most_convenient_transfes,
      });
    }

    return (
      <div style={{ overflow: "auto" }}>
        {result.map((value) => (
          <Recommendation
            key={value.specialText}
            recommendation={value.recommendation}
            index={null}
            onBookingClick={handleBookClick}
            getFareRules={getFareRules}
            getBrandFares={getBrandFares}
            specialText={value.specialText}
            modalShow={modalShow}
            setModalShow={setModalShow}
          />
        ))}
      </div>
    );
  };

  const renderMainForm = () => {
    if (noRecommendations && !nonPriceFlights?.length) {
      return <NotFoundFlightsTitle />;
    }

    if (nonPriceFlights?.length) {
      return (
        <Container fixed>
          <FlightsCount
            recommendationsCount={nonPriceFlights.length}
            hasMarginTop
          />

          <Styled.MainResultNonPriceFlights>
            {renderNotPriceBlock()}
          </Styled.MainResultNonPriceFlights>
        </Container>
      );
    }

    return (
      <Container fixed>
        <Styled.MainResultContainer>
          <Styled.MainResultRecommendationsContainer>
            <CompaniesAndPricesTable />
            <FlightsCount recommendationsCount={sortedRecommendations.length} />
            {checkSpecialRecommendations()}
            {renderResultBlock()}
          </Styled.MainResultRecommendationsContainer>

          <Styled.MainResultFiltersContainer>
            <Filters
              routesData={routesData}
              airlinesData={airlinesData}
              priceRange={priceRange}
            />
          </Styled.MainResultFiltersContainer>
        </Styled.MainResultContainer>
      </Container>
    );
  };

  const loadMoreRecommendations = () => {
    setPage(page + 1);
  };

  const renderNotPriceBlock = () =>
    nonPriceFlights.map((flight, index) =>
      renderRecommendation(flight, index, true)
    );

  const renderResultBlock = () => {
    if (sortedRecommendations.length === 0) {
      return <NotFoundFlightsTitle byFilters={true} />;
    }

    const fullList = sortedRecommendations.map((rec, ind) =>
      renderRecommendation(rec, ind)
    );
    let list = _.filter(fullList, function (o) {
      return o.key < pageRecLimit * page;
    });

    return (
      <div>
        <InfiniteScroll
          dataLength={list.length}
          next={loadMoreRecommendations}
          hasMore={list.length < fullList.length}
          loader={<LoadingBlock key={0} withoutIcon />}
        >
          {list}
        </InfiniteScroll>
      </div>
    );
  };

  const handleBookClick = (rec_id, session_id) => {
    bookingClick(rec_id, session_id);
  };

  const renderRecommendation = (
    recommendation,
    index,
    withoutPrice = false
  ) => {
    return (
      <React.Fragment key={index}>
        <SnackbarComponent
          show={snakBarShow}
          duration={3000}
          onClose={() => setSnakBarShow}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          alertVariant="filled"
          alertSeverity="success"
          alertText="Ссылка скопирована"
        />
        <Recommendation
          recommendation={recommendation}
          index={index}
          onBookingClick={handleBookClick}
          getFareRules={getFareRules}
          getBrandFares={getBrandFares}
          withoutPrice={withoutPrice}
          modalShow={modalShow}
          setModalShow={setModalShow}
          setSnakBarShow={setSnakBarShow}
        />
      </React.Fragment>
    );
  };

  return (
    <Fragment>
      {timerModal ? (
        <ModalComponent
          active={timerModal}
          title={t.old_information}
          onClose={refreshPage}
          maxWidth={600}
        >
          <Typography>{t.refresh_page}</Typography>
          <FormSubmitButton type="button" onClick={refreshPage}>
            {t.refresh}
          </FormSubmitButton>
        </ModalComponent>
      ) : null}
      {renderMainForm()}
      <ScrollUpButton />
    </Fragment>
  );
}

export default ResultForm;
