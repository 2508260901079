import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";

import { Container } from "@material-ui/core";

import { useDispatch } from "react-redux";
import { setAppConfigs } from "../../store/appConfigs/actions";

import { data } from "./data";
import {
  DashedLine,
  Definition,
  DefinitionItems,
  DefinitionItemsWrap,
  Description,
  GeneralProvisionItems,
  GeneralProvisions,
  H2,
  H3,
  HotelSections,
  HotelSectionsWrapper,
  HotelTextBlock,
  IconAndTextList,
  IconAndTextListWrap,
  ImageMultiply,
  ImgBlock,
  InfoWrapper,
  List,
  ListSection,
  Number,
  OfferItems,
  Paragraph,
  Section,
  StickyIcon,
} from "./components";

const UsefulInformation = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(
      setAppConfigs({
        isStickySearchBlock: false,
        isSmallSearchBlock: true,
        isFullScreenSearchBlock: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 200 });
  }, []);

  const RenderGeneralProvisionsItems = ({ items }) => {
    if (items) {
      return (
        <GeneralProvisions>
          {items.map((item, index) => (
            <GeneralProvisionItems>
              <Number>{++index}</Number>
              <Paragraph key={index}>{item.description}</Paragraph>
            </GeneralProvisionItems>
          ))}
        </GeneralProvisions>
      );
    }
    return null;
  };

  const [definitionItemsIsHavered, setDefinitionItemsIsHavered] = useState(-1);

  const onMouseEnterHandle = (index) => {
    setDefinitionItemsIsHavered(index);

    console.log(definitionItemsIsHavered, index);
  };

  const onMouseLeaveHandle = () => {
    setDefinitionItemsIsHavered(-1);

    console.log(definitionItemsIsHavered);
  };

  const RenderDefinitionItems = ({ items }) => {
    if (items) {
      return (
        <Definition>
          {items.map((item, index) => (
            <DefinitionItemsWrap
              onMouseEnter={() => onMouseEnterHandle(index)}
              onMouseLeave={() => onMouseLeaveHandle}
              definitionItemsIsHavered={definitionItemsIsHavered}
              index={index}
            >
              <DefinitionItems
                key={index}
                definitionItemsIsHavered={definitionItemsIsHavered}
                index={index}
              >
                <img src={item.icon} alt="" />
                <H3>{item.title}</H3>
              </DefinitionItems>
              <Description>
                <p>{item.description}</p>
              </Description>
            </DefinitionItemsWrap>
          ))}
        </Definition>
      );
    }
    return null;
  };

  const RenderOfferItems = ({ items }) => {
    const { offerItems } = items;

    if (offerItems) {
      return (
        <OfferItems>
          <ImageMultiply src={items.image} alt="" />
          <ol>
            {offerItems.map((item, index) => (
              <List key={index}>{item}</List>
            ))}
          </ol>
        </OfferItems>
      );
    }
    return null;
  };

  const RenderIconSections = ({ items }) => {
    const RenderDescriptions = ({ descriptions }) => {
      if (descriptions) {
        return (
          <IconAndTextListWrap>
            <DashedLine />
            {descriptions.map((item, index) => (
              <IconAndTextList key={index}>
                <StickyIcon>
                  <ImgBlock>
                    <img src={item.icon} alt={item.icon} />
                  </ImgBlock>
                </StickyIcon>
                <Paragraph>{item.text}</Paragraph>
              </IconAndTextList>
            ))}
          </IconAndTextListWrap>
        );
      }
      return null;
    };

    if (items) {
      return (
        <div>
          {items.map((item, index) => (
            <>
              <div key={index}>
                <H2>{item.title}</H2>
              </div>
              <RenderDescriptions descriptions={item.descriptions} />
            </>
          ))}
        </div>
      );
    }
    return null;
  };

  const RenderHotelSections = ({ items }) => {
    if (items) {
      return (
        <HotelSectionsWrapper>
          {items.map((item, index) => (
            <HotelSections key={index}>
              <ImageMultiply src={item.image} alt={item.image} index={index} />
              <HotelTextBlock index={index}>
                <h4>{item.subtitle}</h4>
                <Paragraph>{item.description}</Paragraph>
                {item.ol && (
                  <ol>
                    {item.ol.map((li, index) => (
                      <List key={index}>{li}</List>
                    ))}
                  </ol>
                )}
              </HotelTextBlock>
            </HotelSections>
          ))}
        </HotelSectionsWrapper>
      );
    }
    return null;
  };

  const RenderSections = ({ items }) => {
    const RenderList = ({ list }) => {
      if (list) {
        return (
          <ol>
            {list.map((li, index) => (
              <List key={index}>{li}</List>
            ))}
          </ol>
        );
      }
      return null;
    };
    if (items) {
      return (
        <div>
          {items.map((item, index) => (
            <ListSection key={index}>
              <H2>{item.title}</H2>
              <RenderList list={item.ol} />
            </ListSection>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Container fixed maxWidth="lg">
      <InfoWrapper>
        {data &&
          data.map((item, index) => (
            <Section key={index}>
              <H2>{item.title}</H2>
              <Paragraph>{item.description}</Paragraph>
              <RenderGeneralProvisionsItems
                items={item.generalProvisionsItems}
              />
              <RenderDefinitionItems items={item.definitionItems} />
              <RenderOfferItems items={item} />
              <RenderIconSections items={item.iconSections} />
              <RenderHotelSections items={item.hotelSections} />
              <RenderSections items={item.sections} />
            </Section>
          ))}
      </InfoWrapper>
    </Container>
  );
};

export default UsefulInformation;
