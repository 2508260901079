import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import API from '../../../lib/api';
import { setClientData } from '../../../store/clientData/actions';
import { setAppConfigs } from '../../../store/appConfigs/actions';
import useLocalization from '../../../contexts/localization/hook';

import { FormGroup, FormSubmitButton } from '../../../components/design_v2/authPages/components';
import TextInput from '../../../components/design_v2/ui/textInput';

const validationSchema = Yup.object().shape({
    activation_code: Yup.string().required('Обязательное поле'),
    password: Yup.string().min(6, 'Поле должно содержать минимум 6 символов').required('Обязательное поле'),
    confirmationPassword: Yup.string()
        .min(6, 'Поле должно содержать минимум 6 символов')
        .required('Обязательное поле')
        .oneOf([Yup.ref('password'), null], 'Пароли должны совподать'),
});

const ActivationForm = () => {
    const { clientId } = useSelector(store => store.registration);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const handleFormSubmit = values => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        API.activation({ ...values, client_id: clientId })
            .then(response => {
                dispatch(
                    setClientData({
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        login: response.data.login,
                        balance: response.data.balance,
                        email: response.data.email,
                        phone: response.data.phone,
                        currency: response.data.currency
                    })
                );

                dispatch(
                    setAppConfigs({
                        isAuth: true,
                    })
                );

                window.localStorage.setItem('token', response.data.token);

                history.push('/');
            })
            .catch(error => {
                setFieldError('activation_code', error.response.data.message);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik({
        initialValues: {
            activation_code: '',
            password: '',
            confirmationPassword: '',
        },
        validationSchema,
        onSubmit: handleFormSubmit,
        validateOnBlur: true,
    });

    return (
        <>
            <FormGroup error={errors.activation_code}>
                <TextInput
                    value={values.activation_code}
                    onChangeText={handleChange('activation_code')}
                    type="text"
                    required
                    placeholder={t.enter_recieved_code}
                    label={t.code}
                    error={errors.activation_code}
                    onBlur={handleBlur('activation_code')}
                    touched={touched.activation_code}
                />
            </FormGroup>

            <FormGroup error={errors.password}>
                <TextInput
                    value={values.password}
                    onChangeText={handleChange('password')}
                    type="password"
                    required
                    placeholder={t.come_up_your_pass}
                    label={t.password_text}
                    error={errors.password}
                    onBlur={handleBlur('password')}
                    touched={touched.password}
                />
            </FormGroup>

            <FormGroup error={errors.confirmationPassword}>
                <TextInput
                    value={values.confirmationPassword}
                    onChangeText={handleChange('confirmationPassword')}
                    type="password"
                    required
                    placeholder={t.confirmation_activation_password}
                    label={t.password_text}
                    error={errors.confirmationPassword}
                    onBlur={handleBlur('confirmationPassword')}
                    touched={touched.confirmationPassword}
                />
            </FormGroup>

            <FormSubmitButton onClick={handleSubmit}>
                {isLoading ? <CircularProgress color="inherit" size={26} /> : t.activate}
            </FormSubmitButton>
        </>
    );
};

export default ActivationForm;
