import styled from 'styled-components';

export const DropdownHeader = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterSpaceBetween,
    position: 'relative',
    padding: theme.spacing(2),
    height: 62,
}));

export const DropdownHeaderTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.mainText,
    width: '100%',
}));

export const DropdownHeaderButton = styled('button')(({ theme }) => ({
    color: theme.palette.searchTextForm,
    border: 'none',
    padding: `0 ${theme.spacing(1)}`,
    borderRadius: theme.spacing(0.75),
    fontWeight: 600,
    fontSize: '0.8rem',
    cursor: 'pointer',
    backgroundColor: theme.palette.thereButton,
    height: 30,
    width: 140,

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.there,
    },
}));

export const OnlyThereButton = styled('button')(({ theme }) => ({
    ...theme.paddings(16, 32),
    ...theme.margins(16, 'auto', 0),
    display: 'flex',
    position: 'sticky',
    bottom: 0,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 1,
    color: theme.palette.searchTextForm,
    backgroundColor: theme.palette.button.searchButtonBg,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.08)',
    border: 'none',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
}));
