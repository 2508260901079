import {
    SET_PRE_BOOK_DATA,
    SET_COUNTRIES,
    SET_PASSENGERS_LIST,
    SET_PASSENGERS_LIST_FILTER,
    SET_OPTIONS,
    SET_SELECTED_OPTIONS,
    SET_PASSENGER_OPTIONS,
    SET_OPTIONS_DYNAMIC_PRICES,
} from './constants';
import initialState from './initialState';

export const setPreBookData = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRIES:
            return {
                ...state,
                countriesItems: action.payload,
            };
        case SET_PASSENGERS_LIST:
            return {
                ...state,
                passengersList: action.payload,
            };
        case SET_PASSENGERS_LIST_FILTER:
            return {
                ...state,
                filterString: action.payload.filterString,
            };
        case SET_PRE_BOOK_DATA:
            return {
                ...state,
                routes: action.payload.routes,
                included: action.payload.included,
                recId: action.payload.recId,
                totalPrice: action.payload.totalPrice,
                partnerFees: action.payload.partnerFees,
                clientFees: action.payload.clientFees,
                fareRules: action.payload.fareRules,
                isLoad: action.payload.loadStatus,
                flightType: action.payload.flightType,
                cashback: action.payload.cashback,
            };
        case SET_OPTIONS:
            return { ...state, options: action.payload };
        case SET_SELECTED_OPTIONS:
            return { ...state, selectedOptions: action.payload };
        case SET_PASSENGER_OPTIONS:
            return { ...state, passengerOptions: action.payload };
        case SET_OPTIONS_DYNAMIC_PRICES:
            return { ...state, optionsDynamicPrices: action.payload };
        default:
            return state;
    }
};
