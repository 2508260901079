import React from "react";

import useLocalization from "../../../../../contexts/localization/hook";

import SnackbarComponent from "../../../ui/snackbar";

const CopiedLinkNotification = ({ snakBarShow, setSnakBarShow }) => {
  const { t } = useLocalization();

  return (
    <SnackbarComponent
      show={snakBarShow}
      duration={3000}
      onClose={() => setSnakBarShow(false)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      alertVariant="filled"
      alertSeverity="success"
      alertText={t.link_copied_to_clipboard}
    />
  );
};

export default CopiedLinkNotification;
