import Grid from '../../../ui/grid';
import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme, hasSpecialText }) => ({
    ...theme.margins(12, 16),
    overflow: 'hidden',
    display: 'none',
    backgroundColor: theme.palette.inputBackground,
    padding: hasSpecialText ? `${theme.spacing(5.25)} ${theme.spacing(2)} ${theme.spacing(2)}` : theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    boxShadow: '0 0 5px 1px rgba(0, 59, 110, 0.1)',
    position: 'relative',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'block',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.margins(12, 0),
    },
}));

export const SpecialText = styled('div')(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    ...theme.paddings(0, 10),
    position: 'absolute',
    top: 8,
    right: 16,
    height: 24,
    textAlign: 'center',
    backgroundColor: theme.palette.specialTextBgColor,
    color: theme.palette.searchTextForm,
    fontSize: 14,
    borderRadius: 4,
}));

export const HeadBlockGrid = styled(Grid)(({ theme }) => ({
    paddingBottom: theme.spacing(1.5),
}));

export const GridBlock = styled(Grid)({
    position: 'relative',
    justifyContent: 'space-between',
});

export const PriceBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.mainText,

    '& > span': {
        color: theme.palette.main,
        marginRight: theme.spacing(1),
    }
}));

export const TopOval = styled('div')(({ theme }) => ({
    width: '67%',
    height: 44,
    borderRadius: '50%',
    backgroundColor: theme.palette.main,
    position: 'absolute',
    top: -52,
    left: '50%',
    transform: 'translateX(-50%)',
}));

export const SupplierIcon = styled('img')({
    display: 'block',
});

const FlightsBadge = styled('div')(({ theme }) => ({
    width: 100,
    marginTop: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.searchTextForm,
}));

export const Lowcost = styled(FlightsBadge)(({ theme }) => ({
    backgroundColor: theme.palette.lowCost,
}));

export const Charter = styled(FlightsBadge)(({ theme }) => ({
    backgroundColor: theme.palette.charter,
}));

export const RouteBlockGrid = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    fontWeight: 500,
    position: 'relative',
}));

export const ItemInfoTitle = styled('div')(({ theme, right }) => ({
    fontSize: 14,
    color: theme.palette.mainText,
    textTransform: 'capitalize',
    fontWeight: 600,
    textAlign: right ? 'right' : 'left',
}));

export const ItemInfoValue = styled('div')(({ theme, fontSize, right, color }) => ({
    fontSize: fontSize || 14,
    fontWeight: 600,
    textAlign: right ? 'right' : 'left',
    color: theme.palette.mainText,
}));

export const CashbackBlock = styled("div")(({ theme }) => ({
    ...theme.paddings(4, 8),
    backgroundColor: theme.palette.cashback,
    fontSize: 14,
    fontWeight: 'bold',
    borderRadius: 4,
    color: theme.palette.white,
    textAlign: "center",
}));
