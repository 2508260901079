import React from 'react';
import styled from 'styled-components';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

export const FormControlUi = styled('div')(() => ({
    position: 'relative',
}));

export const InputLabelUi = styled(InputLabel)(({ theme }) => ({
    position: 'absolute',
    top: 8,
    left: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.41',
    letterSpacing: 'normal',
    zIndex: 1,
    '&.MuiInputLabel-root': {
        color: theme.palette.departureCountryAndAirCompany,
        fontSize: 12,
    },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root&': {
        color: theme.palette.mainText,
        '&.Mui-selected': {
            backgroundColor: `${theme.palette.menuItemBackgroundSelected} !important`,
        },
        '&:hover': {
            backgroundColor: `${theme.palette.menuItemBackgroundHover} !important`,
        },
    },
}));

export const StyledSelect = styled(({ theme, ...rest }) => (
    <Select
        MenuProps={{
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            },
            getContentAnchorEl: null,
            MenuListProps: {
                style: {
                    backgroundColor: theme.palette.inputBackground,
                },
            },
        }}
        displayEmpty
        disableUnderline
        {...rest}
    />
))(({theme, themeColor, fontSize, meta, width, height, fieldError}) => ({
    '&.MuiInputBase-root': {
        width: '100%',
    },
    '& .MuiSelect-select': {
        ...theme.paddings(27, 0, 14, 16),
        overflow: 'hidden',
        backgroundColor: theme.palette.inputBackground,
        color: theme.palette.mainText,
        fontSize: fontSize ? fontSize : 16,
        borderRadius: 12,
        border: `${fieldError || meta?.touched && meta?.error ? '2px' : '1px'} solid ${fieldError || meta?.touched && meta?.error ?
            theme.palette.button.deleteButton : theme.palette.placeholderTextSearchOrder}`,
        width: width || 280,
        height: height || 18,
        boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',

        '&:focus': {
            borderRadius: 12,
            backgroundColor: theme.palette.inputBackground,
        },
        '&:hover': {
            borderRadius: 12,
            backgroundColor: theme.palette.inputBackground,
        },
    },
    '& .Mui-error': {
        border: `${fieldError || meta?.touched && meta?.error ? '2px' : '1px'} solid ${fieldError || meta?.touched && meta?.error ?
            theme.palette.button.deleteButton : theme.palette.placeholderTextSearchOrder}`,
    },
    '& .MuiSelect-icon': {
        color: themeColor === 'DARK_THEME' ? '#fff' : '#000',
        top: `calc(50% - 10px)`,
        right: 10,
    },
}));


