import breakpoints from './breakpoints';
import palette from './palette';

const getTheme = theme => ({
    current: theme,
    palette: palette[theme],
    spacing: value => `${value * 8}px`,
    marginsX: (left, right) => ({
        marginLeft: left,
        marginRight: right || left,
    }),
    marginsY: (top, bottom) => ({
        marginTop: top,
        marginBottom: bottom || top,
    }),
    margins: (top, right, bottom, left) => ({
        marginTop: top,
        marginRight: right,
        marginBottom: bottom || top,
        marginLeft: left || right,
    }),
    paddingsX: (left, right) => ({
        paddingLeft: left,
        paddingRight: right || left,
    }),
    paddingsY: (top, bottom) => ({
        paddingTop: top,
        paddingBottom: bottom || top,
    }),
    paddings: (top, right, bottom, left) => ({
        paddingTop: top,
        paddingRight: right,
        paddingBottom: bottom || top,
        paddingLeft: left || right,
    }),
    mixins: {
        flexCenterCenter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        flexStartCenter: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        flexCenterSpaceBetween: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        flexStartEnd: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'end',
        },
        flexCenterEnd: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        flexCenterStart: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        flexDirectionColumn: {
            display: 'flex',
            flexDirection: 'column'
        },
        flexAlignCenter: {
            display: 'flex',
            alignItems: 'center',
        },
        flexDirectionColumnStart: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    breakpoints,
});

export default getTheme;
