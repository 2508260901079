import styled from 'styled-components';
import Grid from '../../design_v2/ui/grid';
import vectorStroke from '../../../images/vector-stroke.png';
import vectorStrokeWhite from '../../../images/vector-stroke-white.png';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

export const RootRoute = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.blocksBackground,
    borderRadius: theme.spacing(1.5),
    boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
    position: 'relative',
    overflow: 'hidden',
}));

export const BolderLeftLine = styled('div')(({ themeColor }) => ({
    backgroundColor: themeColor,
    width: '62px',
    height: '89%',
    borderRadius: '50%',
    position: 'absolute',
    top: 62,
    left: -52,
}));

export const RouteTitleFirst = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16),
}));

export const DepartureTimeBlock = styled('div')(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.languages,
}));

export const DepartureRouteBlock = styled('div')(({ theme }) => ({
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 14,
    },
}));

export const Divider1 = styled('div')(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'block',
        width: '92%',
        margin: '0 auto',
        height: 1,
        borderBottom: `1px solid ${theme.palette.enterAndRegistrationBorderBottom}`,
    },
}));

export const RouteTitleSecond = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16),
    textAlign: 'end',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        textAlign: 'start',
    },
}));

export const TravelTimeLabel = styled('div')(({ theme }) => ({
    color: theme.palette.languages,
    fontSize: 12,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: '10px',
    },
}));

export const TravelTime = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    fontSize: 16,
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontWeight: 'normal',
        fontSize: 14,
    },
}));

export const Divider2 = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.mainText}`,
    width: '96%',
    height: 1,
    margin: '0 auto',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        width: '92%',
        borderBottom: `1px solid ${theme.palette.enterAndRegistrationBorderBottom}`,
    },
}));

export const SegmentsContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddings(16, 16),
    },
}));

export const SegmentBlock = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.blocksBackground,
    ...theme.paddings(16, 16, 16, 32),
    ...theme.mixins.flexCenterSpaceBetween,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        padding: '0 0 16px 0 !important',
    },
}));

export const TransferContainer = styled(Grid)(({ theme }) => ({
    ...theme.marginsY(0, 16),
    width: '100%',
    borderTop: `solid 2px ${theme.palette.transferContainer}`,
    borderBottom: `solid 2px ${theme.palette.transferContainer}`,
    display: 'flex',
    alignItems: 'center',
}));

export const TransferBlock = styled('div')(() => ({
    width: 16,
    minWidth: 16,
    height: 16,
    backgroundImage: `url(${AccessTimeIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom left',
    backgroundSize: '16px 16px',
}));

export const TransferData = styled('div')(({ theme }) => ({
    ...theme.paddingsX(10, 0),
    fontSize: 14,
    color: theme.palette.mainText,
    position: 'relative',
}));

export const FlightData = styled(Grid)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.departureTimeResultPage,
    order: 1,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const LogoBlock = styled('div')(({ theme }) => ({
    ...theme.paddingsY(0, 10),
}));

export const FlightNumber = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    fontSize: 14,
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        color: theme.palette.languages,
        fontSize: 10,
        fontWeight: 'normal',
    },
}));

export const AirCraft = styled('div')(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        display: 'block',
        color: theme.palette.languages,
        fontSize: 10,
    },
}));

export const FlightNumberAndTime = styled('div')(({ theme }) => ({
    color: theme.palette.mainText,
    fontSize: 14,
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const FlightTimes = styled(Grid)(({ theme }) => ({
    ...theme.marginsX(0, 30),
    ...theme.paddings(8, 16),
    borderRadius: theme.spacing(1.75),
    border: `1px solid ${theme.palette.flightTimesBorder}`,
    order: 2,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        border: 'none',
    },
    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.margins(0, 0, 8, 0),
        ...theme.paddings(0, 0),
        order: 2,
        border: 'none',
        borderBottom: `1px solid ${theme.palette.enterAndRegistrationBorderBottom}`,
        borderRadius: 0,
    },
}));


export const SegmentTime = styled('div')(({ theme }) => ({
    fontSize: 30,
    fontWeight: 600,
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 2,
        fontSize: 26,
    },
}));

export const PointBlock = styled('div')(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.mainText,
}));

export const DepartureData = styled(Grid)(() => ({
    width: '20%',
}));

export const DepartureTimeOnTop = styled('div')(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsY(8, 0),
        display: 'block',
        color: theme.palette.mainText,
        fontSize: 12,
    },
}));

export const DepartureTime = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const DepartCity = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        color: theme.palette.languages,
        fontSize: 12,
    },
}));

export const DepartAirport = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        color: theme.palette.languages,
        fontSize: 12,
    },
}));

export const FlightCode = styled('div')(({ theme }) => ({
    ...theme.paddingsX(5, 0),
    color: theme.palette.departureCountryAndAirCompany,
    fontSize: 16,
    fontWeight: 600,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddingsX(10, 0),
        fontSize: 14,
        color: theme.palette.languages,
    },
}));

export const ArrowBlock = styled(Grid)(({ changeTheme }) => ({
    width: '8%',
    position: 'relative',
    top: 56,
    height: 30,
    backgroundImage: changeTheme === 'DARK_THEME' ? `url(${vectorStrokeWhite})` : `url(${vectorStroke})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    backgroundSize: '40px 7px',
}));

export const ArrivalData = styled(Grid)(() => ({
    width: '25%',
}));

export const Terminal = styled('div')(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.languages,
}));

export const FlightInfo = styled(Grid)(({ theme }) => ({
    order: 3,
    lineHeight: 1.6,
    textAlign: 'right',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 3,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export const ClassBlock = styled('div')(({ theme }) => ({
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 12,
    },
}));

export const BaggageBlock = styled('div')(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 10,
    },
}));

export const ShowRulesBlock = styled('div')(({ theme }) => ({
    color: theme.palette.main,
    cursor: 'pointer',
    fontSize: 14,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 12,
    },
}));

export const TechStopContainer = styled(Grid)(({ theme }) => ({
    ...theme.paddings(19, 0, 0, 25),
}));

export const TechStopBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(8, 24, 8, 12),
    borderRadius: theme.spacing(0.5),
    fontSize: 14,
    border: `solid 1px ${theme.palette.techStopBlockBorder}`,
    backgroundColor: theme.palette.techStopBlockBg,
}));

export const TehStopTitle = styled('span')(({ theme }) => ({
    ...theme.paddingsX(0, 4),
    fontWeight: 500,
}));


