import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const InputTextFieldUi = styled(TextField)(({ theme }) => ({
    '&.MuiTextField-root': {
        ...theme.paddingsX(0),
        overflow: 'hidden',
        border: `1px solid ${theme.palette.mainText}`,
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.transparent,
        fontSize: 18,
    },

    '&.MuiInputBase-root': {
        backgroundColor: theme.palette.transparent,

        '&:hover': {
            backgroundColor: theme.palette.transparent,
        },
    },

    'input': {
        color: theme.palette.mainText,
    },

    'label': {
        color: theme.palette.mainText,
        '&.Mui-focused': {
            color: theme.palette.mainText,
        },
    },
}));