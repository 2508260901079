import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    flexWrap: 'wrap',
}));

export const RouteContainer = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    position: 'relative',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        flexWrap: 'wrap',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        justifyContent: 'flex-end',
    },
}));

export const From = styled('div')(({ theme, notFilled }) => ({
    ...theme.marginsY(0, 12),
    ...theme.marginsX(0, 8),
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 286,
    borderRadius: theme.spacing(1.5),
    position: 'relative',
    boxShadow: notFilled ? `0px 0px 4px 2px ${theme.palette.error}` : 'none',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 222,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsY(0, 8),
        ...theme.marginsX(0, 0),
        width: '100%',
    },
}));

export const To = styled('div')(({ theme, notFilled }) => ({
    ...theme.marginsY(0, 8),
    ...theme.marginsX(0, 8),
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 286,
    position: 'relative',
    borderRadius: theme.spacing(1.5),
    boxShadow: notFilled ? `0px 0px 4px 2px ${theme.palette.error}` : 'none',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 222,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: ' 100%',
        borderRadius: theme.spacing(1.5),
        marginRight: 0,
    },
}));

export const FromDate = styled('div')(({ theme, notFilled, marginBottom }) => ({
    ...theme.marginsY(0, 12),
    ...theme.marginsX(0, 8),
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 400,
    borderRadius: theme.spacing(1.5),
    position: 'relative',
    boxShadow: notFilled ? `0px 0px 4px 2px ${theme.palette.error}` : 'none',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 222,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        borderRadius: theme.spacing(1.5),
        width: '100%',
        marginBottom: marginBottom ? 40 : 12,
        marginRight: 0,
    },
}));

export const PassengersAndClass = styled('div')(({ theme }) => ({
    position: 'relative',
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 236,
    borderRadius: theme.spacing(1.25),

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 222,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsY(0, 40),
        ...theme.marginsX(0, 0),
        width: '100%',
        borderRadius: theme.spacing(1.25),
        position: 'unset',
    },
}));

export const AddRouteButtonContainer = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsY(0, 16),
        width: '100%',
    },
}));

export const AdvancedRouteAddRouteButtonContainer = styled(AddRouteButtonContainer)(({ theme }) => ({
    '@media (max-width: 404px)': {
        ...theme.marginsX(0, 0),
        display: 'flex',
        justifyContent: 'center',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsX(0, 0),
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const AddRouteButton = styled('button')(({ theme }) => ({
    height: 60,
    backgroundColor: theme.palette.button.addRouteBg,
    color: theme.palette.button.addRoute,
    border: 'none',
    borderRadius: theme.spacing(1.5),
    width: 236,
    fontSize: '1.2rem',
    fontWeight: 500,
    cursor: 'pointer',
    float: 'right',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        ...theme.marginsX(0, 22),
        fontSize: '1rem',
        width: 218,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
        fontSize: '1rem',
        backgroundColor: theme.palette.button.clearRouteBg,
        color: theme.palette.mainText,
    },
}));

export const AdvancedRouteAddRouteButton = styled(AddRouteButton)(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        ...theme.marginsX(0),
        width: 222,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const MaximumRoutesText = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: theme.palette.maximumRoutesText,
    fontSize: ' 0.9rem',
    width: '100%',
    height: '100%',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: ' 0.9rem',
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const DesktopRemoveRouteButton = styled('button')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    color: theme.palette.button.clearRoute,
    fontSize: 14,
    width: 236,
    height: 60,
    cursor: 'pointer',
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.button.clearRouteBg,
    border: 'none',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 222,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const RemoveRouteButton = styled('button')(({ theme }) => ({
    ...theme.marginsY(0),
    display: 'none',
    color: theme.palette.button.clearRoute,
    fontSize: 14,
    width: 60,
    height: 20,
    cursor: 'pointer',
    backgroundColor: theme.palette.transparent,
    border: 'none',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'flex',
    },
}));

export const SubmitButton = styled('button')(({ theme }) => ({
    height: 60,
    backgroundColor: theme.palette.button.searchButtonBg,
    color: theme.palette.button.addRoute,
    border: 'none',
    borderRadius: theme.spacing(1.25),
    width: 400,
    fontSize: '1.2rem',
    fontWeight: 600,
    marginRight: 8,
    textTransform: 'uppercase',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        display: 'none',
    },

    '&:hover': {
        cursor: 'pointer',
    },
}));
