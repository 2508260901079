import styled from 'styled-components';

import Container from "../../../ui/container";
import ourPartnersBg from "../../../../../assets/images/rectangle.png";

export const CarouselBlock = styled(Container)(({ theme }) => ({
    ...theme.paddings(8, "auto", 32),
    backgroundImage: `url(${ourPartnersBg})`,
    backgroundSize: 'cover',
    width: '100%',

    h2: {
        color: "#ffffff"
    }
    
}));

export const Header = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    textAlign: 'center',
    fontSize: '1.6rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    // color: theme.palette.mainText,
    color: 'white',
    paddingBottom: theme.spacing(4.75),
}));

export const Icon = styled('img')(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',

    },
}));

export const SliderItem = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    height: 120,

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        height: 80,
    },

    '& > a': {
        ...theme.mixins.flexCenterCenter,
        ...theme.paddings(24, 24),
        boxSizing: 'border-box',
        height: '100%',
    },
}));

export const SliderImage = styled('img')({
    display: 'block',
    width: '100%',
    height: 'auto',
});
