import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import flyCash7 from "../../../../images/flyCash/flyCash7.png";
import pareplane from "../../../../images/flyCash/pareplane.png";

import { ImageBlock, InfoBlock, TextBlock, TitleBlock, Wrapper } from "./components";

const ChancePart = () => {
  const { language } = useLocalization();

    if (language === "ru") {
        return (
            <Wrapper>
                <InfoBlock>
                    <ImageBlock>
                        <img src={pareplane} alt="pareplane" />
                    </ImageBlock>
                    <TextBlock>
                        <TitleBlock className="desktop">Не упустите шанс стать частью программы лояльности <img src={flyCash7} className="cash7" alt="fly-cash-text" /> и начать путешествовать с еще большими преимуществами.</TitleBlock>
                        <TitleBlock className="mobile">Не упустите шанс стать частью программы лояльности <img src={flyCash7} className="cash7" alt="fly-cash-text" style={{ maxHeight: 50 }} /> и начать путешествовать с еще большими преимуществами.</TitleBlock>
                    </TextBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    if (language === "tj") {
        return (
            <Wrapper>
                <InfoBlock>
                    <ImageBlock>
                        <img src={pareplane} alt="pareplane" />
                    </ImageBlock>
                    <TextBlock>
                        <TitleBlock className="desktop">Имконияти барномаи <img src={flyCash7} className="cash7" alt="fly-cash-text" /> шуданро аз даст надиҳед ва бо манфиатҳои бештар сафар карданро оғоз кунед.</TitleBlock>
                        <TitleBlock className="mobile">Имконияти барномаи <img src={flyCash7} className="cash7" alt="fly-cash-text" style={{ maxHeight: 50 }} /> шуданро аз даст надиҳед ва бо манфиатҳои бештар сафар карданро оғоз кунед.</TitleBlock>
                    </TextBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <InfoBlock>
                <ImageBlock>
                    <img src={pareplane} alt="pareplane" />
                </ImageBlock>
                <TextBlock>
                    <TitleBlock className="desktop">Don't miss the chance to be a part of <img src={flyCash7} className="cash7" alt="fly-cash-text" /> loyalty program and start traveling with more great advantages.</TitleBlock>
                    <TitleBlock className="mobile">Don't miss the chance to be a part of <img src={flyCash7} className="cash7" alt="fly-cash-text" style={{ maxHeight: 50 }} /> loyalty program and start traveling with moregreat advantages.</TitleBlock>
                </TextBlock>
            </InfoBlock>
        </Wrapper>
    );
};

export default ChancePart;
