import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import person from "../../../../images/flyCash/person.png";

import { ImageBlock, InfoBlock, TextBlock, TitleBlock, Wrapper } from "./components";

const PersonPart = () => {
  const { language } = useLocalization();

    if (language === "ru") {
        return (
            <Wrapper>
                <InfoBlock>
                    <ImageBlock>
                        <img src={person} alt="person" />
                    </ImageBlock>
                    <TextBlock>
                        <TitleBlock>Желаем вам приятных путешествий!</TitleBlock>
                    </TextBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    if (language === "tj") {
        return (
            <Wrapper>
                <InfoBlock>
                    <ImageBlock>
                        <img src={person} alt="person" />
                    </ImageBlock>
                    <TextBlock>
                        <TitleBlock>Ба шумо сафари гувороро таманно дорем!</TitleBlock>
                    </TextBlock>
                </InfoBlock>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <InfoBlock>
                <ImageBlock>
                    <img src={person} alt="person" />
                </ImageBlock>
                <TextBlock>
                    <TitleBlock>We wish you a pleasant travel!</TitleBlock>
                </TextBlock>
            </InfoBlock>
        </Wrapper>
    );
};

export default PersonPart;
