import React, { useState, Fragment } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { setPaymentMethod } from '../../../containers/order/actions';

import configs from '../../../config';
import { B2C } from '../../../containers/order/constants';

import { parserLangCode } from '../../result/common';

import useLocalization from '../../../contexts/localization/hook';

import Grid from '../../design_v2/ui/grid';
import TimelimitCounter from '../../ui/components/TimelimitCounter/TimelimitCounter';
import ButtonUI from '../../ui/components/ButtonUI/ButtonUI';
import PaymentMethods from '../paymentMethods';

import {
    PaymentBlock,
    PaymentButtonBlock,
    PaymentContainer,
    PaymentMethodItem,
    PaymentTimeLimitBlock,
    PaymentTitle,
    CardBlock,
    LineBlock,
    Line,
    TimelimitLabel,
    LoadingBlock,
} from './components';
import { CircularProgress } from '@material-ui/core';

const PaymentForm = ({ timelimit, setPaymentRequest, redirectToPayment }) => {
    const { t } = useLocalization();
    const { isLoadPaymentMethod, paymentMethod } = useSelector(state => state.setOrderData);

    const [timerActive, setTimerActive] = useState(timelimit > 0);

    const disableTimer = () => {
        setTimerActive(false);
    };

    if (timerActive) {
        return (
            <PaymentContainer>
                <PaymentBlock>
                    <PaymentTitle>
                        {t.payment_method}
                    </PaymentTitle>
                    <PaymentMethodItem item xs={12}>
                        {isLoadPaymentMethod ? <PaymentMethodsItems /> : (
                            <LoadingBlock>
                                <CircularProgress size={40} color="inherit" />
                            </LoadingBlock>
                        )}
                    </PaymentMethodItem>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} xl={'auto'}>
                                <PaymentTimeLimitBlock>
                                    <TimelimitCounter timelimit={timelimit} disableTimer={disableTimer} />
                                    <TimelimitLabels timelimit={timelimit} />
                                </PaymentTimeLimitBlock>
                            </Grid>
                            <Grid item xs={12} xl={'auto'}>
                                <PaymentButtonBlock>
                                    {!_.isNull(paymentMethod) && (
                                        <PaymentButton
                                            paymentMethod={paymentMethod}
                                            redirectToPayment={redirectToPayment}
                                            setPaymentRequest={setPaymentRequest}
                                        />
                                    )}
                                </PaymentButtonBlock>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaymentBlock>
            </PaymentContainer>
        );
    }

    return (
        <PaymentContainer>
            <PaymentBlock container>
                <PaymentTimeLimitBlock item>
                    <TimelimitCounter timelimit={0} disableTimer={disableTimer} />
                </PaymentTimeLimitBlock>
            </PaymentBlock>
        </PaymentContainer>
    );
};

export default PaymentForm;

const PaymentMethodsItems = () => {
    const dispatch = useDispatch();
    const { isAuth } = useSelector(state => state.appConfigs);
    const { availablePaymentMethods, paymentMethod } = useSelector(state => state.setOrderData);

    const handleChangePaymentMethod = value => {
        dispatch(setPaymentMethod({ method: value }));
    };

    return _.map(availablePaymentMethods, (item, index) => {
        if (!isAuth && item.code === B2C) {
            return null;
        }

        return (
            <Fragment key={index}>
                <CardBlock>
                    <PaymentMethods
                        onChange={handleChangePaymentMethod}
                        checkedPaymentMethod={paymentMethod}
                        methodData={item}
                    />
                </CardBlock>
                <LineBlock item>
                    <Line />
                </LineBlock>
            </Fragment>
        );
    });
};

const PaymentButton = props => {
    const { t } = useLocalization();
    const { paymentMethod, setPaymentRequest, redirectToPayment } = props;

    let buttonTitle = t.pay_for_the_order;

    return (
        <ButtonUI
            onClick={() => setPaymentRequest(paymentMethod)}
            title={buttonTitle}
            fontSize='20px'
            height='50px'
            isGreen={true}
            isLoading={redirectToPayment}
        />
    );
};

const TimelimitLabels = props => {

    const { t, language } = useLocalization();
    const { timelimit } = props;
    const timelimitTime = moment()
        .locale(parserLangCode(language))
        .add(timelimit, 'seconds')
        .format(configs.mainConfigs.serverDateTimeFormat);

    return (
        <TimelimitLabel>
            <div>{t.left_before_the_time_limit}</div>
            <div>{t.expires} {timelimitTime}</div>
        </TimelimitLabel>
    );
};
