import styled, { keyframes, css } from "styled-components";

const textColor = "#4D4D4D";
const primaryColor = "#4EA6BF";
const secondaryColor = "#FFB263";

export const InfoWrapper = styled("div")(({ theme }) => ({
  "section:nth-child(1)": {
    h2: {
      color: secondaryColor,
      fontSize: 36,

      "@media screen and (max-width: 600px)": {
        fontSize: 26,
      },
    },
  },
}));

export const Section = styled("section")(({ theme }) => ({
  margin: "80px 0",

  "@media screen and (max-width: 600px)": {
    margin: "60px 0",
  },
}));

export const H2 = styled("h2")(({ theme }) => ({
  color: primaryColor,
  fontWeight: "bold",
  fontSize: 26,
  margin: "10px 0",

  "@media screen and (max-width: 600px)": {
    fontSize: 20,
  },
}));

export const H3 = styled("h3")(({ theme }) => ({
  color: textColor,
}));

export const Paragraph = styled("p")(({ theme }) => ({
  color: theme.palette.mainText,
  margin: "0 0 16px 0",
}));

export const List = styled("li")(({ theme }) => ({
  color: theme.palette.mainText,
  marginBottom: 16,
}));

export const GeneralProvisions = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "repeat(3, 1fr)",
  gap: 20,

  "div:nth-child(1)": {
    gridColumn: "1/3",
  },

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",

    "div:nth-child(1)": {
      gridColumn: "1/2",
    },
  },
}));

export const Number = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 4,
  left: 4,
  width: 80,
  height: 80,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#0000000d",
  fontSize: 64,
  color: "#0000001a",
  filter: theme.current === "DARK_THEME" ? "invert(1)" : "none",

  "@media screen and (max-width: 600px)": {
    width: 60,
    height: 60,
    fontSize: 44,
  },
}));

export const GeneralProvisionItems = styled("div")(({ theme }) => ({
  padding: "50px 20px 20px 60px",
  boxShadow: `2px 2px 12px 0px ${theme.palette.pricesTableBoxShadow}`,
  borderRadius: "44px 16px 44px 16px",
  position: "relative",

  "@media screen and (max-width: 600px)": {
    padding: "30px 10px 10px 40px",
    borderRadius: "34px 10px 34px 10px",
  },
}));

export const Definition = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: 16,

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media screen and (max-width: 600px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const DefinitionItemsWrap = styled("div")(
  ({ theme, definitionItemsIsHavered, index }) => ({
    backgroundColor: secondaryColor,
    borderRadius: 16,
    position: "relative",
    aspectRatio: "1/1",

    div: {

      img: {
        width: definitionItemsIsHavered === index ? 50 : 100,
        height: definitionItemsIsHavered === index ? 50 : 100,
      },

      h3: {
        display: definitionItemsIsHavered === index ? "none" : "block",
      },
    },
  })
);

const animBlock = keyframes`
  20% {
    border-radius: 50%
  }
  40% {
    border-radius: 50%
    width: 100px;
    height: 100px;
  }
  60% {
    border-radius: 50%
    width: 100px;
    height: 100px;
  }
  80% {
    border-radius: 50%
    width: 100px;
    height: 100px;
    top: 60%;
  }
  100% {
    border-radius: 50%
    width: 100px;
    height: 100px;
    top: 0;
  }
`;

const definitionItemsStyles = `
  background-color: ${primaryColor};
  border-radius: 16;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
   padding: 16;
  text-align: center;
  gap: 3;
  transition: .3s;
  position: relative;
  z-index: 10;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0px 0px 3px 0px #000;
  cursor: pointer;
  position: absolute;
  left: 50%
  top: 50%
`;

export const DefinitionItems = styled.div`
  ${(props) => {
    const { definitionItemsIsHavered } = props;
    const { index } = props;

    if (definitionItemsIsHavered === index) {
      return css`
        ${definitionItemsStyles}
        animation: ${animBlock} .600s forwards;
      `;
    }

    return css`
      ${definitionItemsStyles}
      transition: 1s ease;

      h3 {
        color: #fff;
        margin: 0;
        font-weight: 400;
      }

      @media screen and (max-width: 1280px) and (min-width: 960px) {
        img {
          width: 60;
        }

        h3 {
          fontsize: 16;
        }
      }
    `;
  }}
`;

export const Description = styled("article")(({ theme }) => ({
  position: "absolute",
  top: 0,
  padding: "60px 10px 10px 10px",
  overflow: "auto",
  width: "100%",
  height: "100%",

  p: {
    color: "#4b4b4b",
    margin: 0,
    padding: 0,
  }
}));

export const OfferItems = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",

  img: {
    width: "100%",
  },

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const ImageMultiply = styled("img")(({ theme, index }) => ({
  mixBlendMode: theme.current === "DARK_THEME" ? "unset" : "multiply",
  gridColumn: index === 1 ? "2/3" : null,
  gridRow: index === 1 ? "1/2" : null,

  "@media screen and (max-width: 960px)": {
    gridColumn: "1/2",
  },
}));

export const IconAndTextListWrap = styled("div")(({ theme }) => ({
  position: "relative",
  marginBottom: 48,
}));

export const IconAndTextList = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 22,
  marginBottom: 24,
  position: "relative",
}));

export const StickyIcon = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "70%",
  height: "100%",
}));

export const ImgBlock = styled("div")(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: "50%",
  border: `2px solid ${secondaryColor}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff",
  padding: 8,

  img: {
    width: "100%",
    aspectRatio: 1 / 1,
  },
}));

export const DashedLine = styled("div")(({ theme }) => ({
  height: "100%",
  border: `2px dashed ${secondaryColor}`,
  position: "absolute",
  left: 29,
}));

export const HotelSectionsWrapper = styled("div")(({ theme }) => ({
  "img:nth-child(2)": {
    gridColumn: "2/3",
  },
}));

export const HotelSections = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: 32,
  margin: "48px 0",

  img: {
    width: "100%",
  },

  "@media screen and (max-width: 1280px)": {
    alignItems: "center",
  },

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const HotelTextBlock = styled("div")(({ theme, index }) => ({
  gridColumn: index === 1 ? "1/2" : null,
  gridRow: index === 1 ? "1/2" : null,

  h4: {
    color: theme.palette.mainText,
  },

  "@media screen and (max-width: 960px)": {
    gridRow: "2/3",
  },
}));

export const ListSection = styled("div")(({ theme }) => ({
  margin: "48px 0",
}));
