import styled from 'styled-components';

export const RouteBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(8, 0),

    '&:nth-of-type(1)': {
        ...theme.marginsY(2, 0),
    },
}));

export const RouteCities = styled('div')(({ theme }) => ({
    fontSize: 15,
    marginBottom: 4,
    fontWeight: 600,
    color: theme.palette.mainText,
}));

export const RouteFlightTitle = styled('div')(({ theme }) => ({
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: theme.palette.mainText,
}));

export const CheckboxBlock = styled.div`
  @media (max-width: 959px) {
    padding: 4px 0;
  }
`;

export const Label = styled.label`
  margin-left: 8px;
  vertical-align: 6px;
  font-size: 14px;
`;
