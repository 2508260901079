import styled from 'styled-components';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Accordion } from '@material-ui/core';

export const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(2),
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.recommendation.classInfo,
}));

export const RulesBlock = styled(Accordion)(({ theme }) => ({
    ...theme.marginsY(0, 16)
}));

export const SelectBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 16)
}));