import styled from 'styled-components';

export const Block = styled('div')(({}) => ({
    width: '100%',
}));

export const FlightTypeToggleButton = styled('button')(({ theme }) => ({
    ...theme.paddings(4, 0),
    ...theme.margins(12, 0),
    backgroundColor: theme.palette.transparent,
    color: theme.palette.button.advancedRouteAndHistory,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 14,

    '&:hover': {
        color: theme.palette.buttonHover.advancedRouteAndHistorySearch,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.margins(16, 0),
    },
}));

export const FlightTypeIcon = styled('img')(({ theme }) => ({
    ...theme.marginsX(0, 8),
}));

export const SubmitButtonContainer = styled('div')(({ theme, smallSearchBlock }) => ({
    display: 'none',
    justifyContent: 'center',
    margin: `${smallSearchBlock ? '16px' : '5rem'} auto`,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        display: 'flex',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.margins(16, 0, 0, 0),
    },
}));

export const SubmitButton = styled('button')(({ theme }) => ({
    height: 60,
    backgroundColor: theme.palette.button.searchButtonBg,
    color: theme.palette.button.addRoute,
    border: 'none',
    borderRadius: theme.spacing(1.25),
    width: '100%',
    maxWidth: 280,
    fontSize: '1.2rem',
    fontWeight: 600,
    textTransform: 'uppercase',

    '&:hover': {
        cursor: 'pointer',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 28,
        textTransform: 'uppercase',
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 20,
        textTransform: 'uppercase',
    },
}));

export const RoutesAndHistorySearchButtons = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        justifyContent: 'space-between',
    },
}));
