import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';

export const Title = styled('div')(({ theme }) => ({
    ...theme.margins(30, 0),
    fontSize: 30,
    color: theme.palette.mainText,
    fontWeight: 600,
    textAlign: 'center',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        fontSize: 36,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 24,
    },
}));

export const TableBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 24),
    overflowY: 'auto',
}));

export const Table = styled('table')(({ theme }) => ({
    ...theme.marginsY(0, 16),
    borderCollapse: 'collapse',
    width: '100%',
    minWidth: 550,
    overflowX: 'scroll',
}));

export const Tr = styled('tr')(({ theme }) => ({
    backgroundColor: theme.palette.transactionTableTitleFirstRowBg,

    '&:nth-child(even)': {
        backgroundColor: theme.palette.transactionTableTitleSecondRowBg,
    },
}));

export const Th = styled('th')(({ theme }) => ({
    ...theme.paddings(12, 8, 12, 8),
    border: `1px solid ${theme.palette.maximumRoutesText}`,
    textAlign: 'left',
    backgroundColor: theme.palette.mainText,
    color: theme.palette.transactionTableTitle,
}));

export const Td = styled('td')(({ theme, textColor }) => ({
    ...theme.paddings(8, 8),
    color: textColor || theme.palette.transactionTableItem,
    border: `1px solid ${theme.palette.maximumRoutesText}`,
}));

export const NoResultsBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    height: 200,
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.mainText,
}));

export const PaginationBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 8),
    display: 'flex',
    justifyContent: 'center',
}));

export const PaginationItemComp = styled(Pagination)(({ theme }) => ({
    '.MuiPagination-root': {
        ...theme.marginsY(0, 8),
        display: 'flex',
        justifyContent: 'center',
    },
    '.MuiPaginationItem-page': {
        color: theme.palette.mainText,
        border: `1px solid ${theme.palette.mainText}`,
    }
}));
