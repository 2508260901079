import React, { useEffect, useState } from "react";

import { downloadMk } from '../../components/order/bookData/bookData';

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OrderPageMk = () => {

  const [isLoadingMk, setIsLoadingMk] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoadingReturn, setIsLoadingReturn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const order_id = searchParams.get('order_id');
  const session_id = searchParams.get('session_id');

  const setSuccess = () => {
    setIsLoading(false);
    setIsLoadingMk(false);
    setIsLoadingCancel(false);
    setIsLoadingReturn(false);
  };

  const handelDownloadMk = () => {
    setIsLoading(true);
    setIsLoadingMk(true);
    downloadMk(order_id, session_id, setSuccess);
  };

  useEffect(() => {
//     if (!order_id && !session_id) {
//       history.push("/").
// };

    handelDownloadMk()
  }, [])

  return (
    <React.Fragment></React.Fragment>
  )
};


export default OrderPageMk;
