import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import noun from 'plural-ru';
import useLocalization from '../../../../contexts/localization/hook';
import {
    TimeLimitExpired,
    TimeLimitLabelItem,
    TimeLimitValueBlock,
    TimeLimitValueItem,
    ValuesContainer,
} from './components';

const useStyles = makeStyles({
    valuesContainer: {
        display: 'flex',

        '@media (max-width: 960px)': {
            marginBottom: '8px',
        },

        '@media (max-width: 599px)': {
            marginBottom: '16px',
        },
    },
    timelimitValueBlock: {
        width: '70px',
        height: '70px',
        borderRadius: '16px',
        backgroundColor: '#f2f4f5',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '16px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    timelimitValueItem: {
        paddingTop: '3px',
        fontWeight: '500',
        fontSize: '30px',
        color: '#404B5A',
    },
    timelimitLabelItem: {
        fontSize: '12px',
        color: '#404B5A',
    },
    timelimitLabelsContainer: {
        paddingTop: '12px',
        fontSize: '14px',
        color: '#7e878b',
    },
    timelimitExpired: {
        // paddingTop: '4px',
        fontWeight: '500',
        fontSize: '16px',
        color: '#D60C0C',
    },
});

export default function TimelimitCounter(props) {
    const classes = useStyles();
    const { t } = useLocalization();

    const { timelimit, disableTimer } = props;
    const [timelimitSecond, setTimelimitSecond] = useState(timelimit);

    useEffect(() => {
        let counter = setTimeout(() => setTimelimitSecond(timelimitSecond - 1), 1000);

        return () => {
            clearTimeout(counter);
        };
    });

    if (timelimitSecond < 0) {
        disableTimer();

        return <TimeLimitExpired>{t.payment_timed_out}</TimeLimitExpired>;
    }

    let seconds = Math.floor(timelimitSecond % 60);
    let minutes = Math.floor((timelimitSecond / 60) % 60);
    let hours = Math.floor((timelimitSecond / (60 * 60)) % 24);
    let days = Math.floor(timelimitSecond / (60 * 60 * 24));

    return (
        <ValuesContainer>
            <TimeLimitValueBlock>
                <TimeLimitValueItem>{days}</TimeLimitValueItem>
                <TimeLimitLabelItem>{getDaysString(days, t)}</TimeLimitLabelItem>
            </TimeLimitValueBlock>
            <TimeLimitValueBlock>
                <TimeLimitValueItem>{hours}</TimeLimitValueItem>
                <TimeLimitLabelItem>{getHoursString(hours, t)}</TimeLimitLabelItem>
            </TimeLimitValueBlock>
            <TimeLimitValueBlock>
                <TimeLimitValueItem>{minutes}</TimeLimitValueItem>
                <TimeLimitLabelItem>{getMinutesString(minutes, t)}</TimeLimitLabelItem>
            </TimeLimitValueBlock>
            <TimeLimitValueBlock>
                <TimeLimitValueItem>{seconds}</TimeLimitValueItem>
                <TimeLimitLabelItem>{getSecondsString(seconds, t)}</TimeLimitLabelItem>
            </TimeLimitValueBlock>
        </ValuesContainer>
    );
}

const getDaysString = (count, t) => {
    return noun(count, t.day_one, t.day_more, t.days);
};

const getHoursString = (count, t) => {
    return noun(count, t.hour_one, t.hour_more, t.hours);
};

const getMinutesString = (count, t) => {
    return noun(count, t.minute_one, t.minute_more, t.minutes);
};

const getSecondsString = (count, t) => {
    return noun(count, t.second_one, t.second_more, t.seconds);
};
