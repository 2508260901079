import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

export const FormControlUi = styled(FormControl)(({}) => ({
    position: 'relative',
    display: 'flex',
    width: '100%'
}));


export const LabelUi = styled('div')(({theme}) => ({
    position: 'absolute',
    fontSize: 12,
    top: 10,
    left: 16,
    zIndex: 1,
    color: theme.palette.departureCountryAndAirCompany,
}));


export const InputUi = styled(Input)(({theme}) => ({
    ...theme.paddings(24, 0, 3, 16),
    width: '100%',
    height: 'auto',
    borderRadius: theme.spacing(1.5),
    border: `solid 1px ${theme.palette.placeholderTextSearchOrder}`,
    backgroundColor: theme.palette.inputBackground,
    boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',

    '&.MuiInputBase-root': {
        color: theme.palette.mainText
    },
    '&.Mui-focused': {
        outline: 'none',
        border: 'solid 1px #0085FF',
    },
}));

