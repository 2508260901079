import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.marginsY(0, 16),

    '&:nth-last-of-type(1)': {
        marginBottom: 0,
    },
}));

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextTitle = styled.div`
  font-weight: 600;
`;

export const TextDescription = styled('div')(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.checkboxClass,
    lineHeight: 1.2,
}));

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled('div')(({ theme, disabled }) => ({
    ...theme.mixins.flexCenterCenter,
    border: 'none',
    backgroundColor: disabled ? theme.palette.button.deactivatedButtonPassengers : theme.palette.button.searchButtonBg,
    borderRadius: theme.spacing(0.75),
    width: 24,
    height: 24,
    cursor: 'pointer',

    '&:focus': {
        outline: 'none',
    },
}));

export const Count = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.margins(0, 12),
    fontWeight: 600,
}));
