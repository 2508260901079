import styled from "styled-components";

export const Wrapper = styled("section")(({ theme }) => ({
    backgroundColor: "#fff",
}));

export const InfoBlock = styled("div")(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    ...theme.paddings(120, 25),
    margin: "0 auto",
    maxWidth: 1280,
    height: "100%",
    position: "relative",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        flexDirection: "column",
        gap: 20,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.paddings(30, 15),
    }
}));

export const TextBlock = styled("div")(({ theme }) => ({
    width: "60%",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: "100%",
        order: 1,
    },
}));

export const TitleBlock = styled("div")(({ theme }) => ({
    fontSize: 36,
    color: "#004aad",
    fontWeight: 600,
    lineHeight: 1.3,
    marginBottom: 30,

    "&.mobile": {
        display: "none",

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            display: "block",
        },
    },

    "&.desktop": {
        display: "block",

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            display: "none",
        },
    },

    "& > img": {
        marginTop: -24,
        marginBottom: -12,
    },

    "& > .cash2": {
        display: "block",
        marginTop: -12,
        marginBottom: 0,
    },

    "& > .cash7": {
        marginTop: 0,
        marginBottom: -24,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 24,
        lineHeight: 1.6,
        marginBottom: 10,
    },
}));

export const ImageBlock = styled("div")(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    width: "40%",
    position: "relative",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: "100%",
        order: 2,
    },

    "& > img": {
        maxWidth: "100%",

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            maxWidth: 200,
        },
    }
}));