import styled from 'styled-components';
import { Container, Checkbox } from '@material-ui/core';

import Grid from '../../design_v2/ui/grid';

export const PassengersContainer = styled(Grid)(() => ({
    flexGrow: 1,
}));

export const PassengersBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 16, 8),
    borderRadius: theme.spacing(1),
}));

export const PassengersTitle = styled(Grid)(({ theme }) => ({
    ...theme.paddings(24, 0, 8),
    fontSize: 28,
    fontWeight: 600,
    color: theme.palette.mainText,
}));

export const AgreeCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
        color: theme.palette.main
    },

    '&.Mui-checked': {
        color: theme.palette.main
    }
}));

export const AgreementTheBook = styled(Grid)(({ theme }) => ({
    color: theme.palette.mainText,
    ...theme.paddings(16, 24, 0),
    ...theme.marginsY(0, 32),
    display: 'flex',
    alignItems: 'center',
}));

export const AgreementBookLink = styled('span')(({ theme }) => ({
    ...theme.paddings(0, 4),
    color: theme.palette.main,
    fontSize: 16,
    cursor: 'pointer',
}));

export const BonusCardContainer = styled(Grid)(() => ({
    width: 795,
}));

export const BonusCard = styled(Grid)(({ theme }) => ({
    ...theme.paddingsY(16, 32),
    color: theme.palette.bonusCard,
    fontSize: 16,
}));

export const BonusCardCheckbox = styled(Grid)(() => ({
    width: '4%',
}));

export const BonusCardTitle = styled(Grid)(() => ({
    width: '96%',
}));

export const BonusCardInput = styled(Grid)(({ theme }) => ({
    ...theme.paddingsY(16, 0),
}));

export const EmptyInputBlock = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const PayerTitle = styled(Grid)(({ theme }) => ({
    ...theme.paddingsY(0, 8),
    fontSize: 24,
    color: theme.palette.mainText,
    fontWeight: 500,
}));

export const PayerMess = styled(Grid)(({ theme }) => ({
    ...theme.paddingsY(0, 8),
    fontSize: 14,
    color: theme.palette.mainText,
}));

export const SubmitBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(0, 0, 64, 0),
    width: '100%',
    textAlign: 'center',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddings(12, 24, 64),
    },
}));