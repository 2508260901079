import styled from 'styled-components';

import Grid from '../../design_v2/ui/grid';

export const PriceContainer = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 20),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));

export const PriceBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16, 0, 16),
    backgroundColor: theme.palette.inputBackground,
    borderRadius: theme.spacing(2.25),
    boxShadow: '0 0 5px 1px rgba(0, 59, 110, .1)',
}));

export const PriceTotalBlock = styled(Grid)(({ theme, $isCashback }) => ({
    ...theme.paddingsY(0, 0),
    display: 'flex',
    alignItems: 'center',
    marginTop: $isCashback ? theme.spacing(2) : 0,
}));

export const FlightBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(0, 0, 16, 0),
}));

export const PriceTicketRow = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const TicketsBlock = styled(Grid)(({ theme }) => ({
    ...theme.paddings(16, 16, 0, 16),
    backgroundColor: theme.palette.inputBackground,
    borderRadius: theme.spacing(2.25),
    boxShadow: '0 0 5px 1px rgba(0, 59, 110, .1)',
}));

export const FlightTicketRow = styled(Grid)(({ theme, $isCashback }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: $isCashback ? 0 : theme.spacing(1),

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        alignItems: 'center',
    },
}));

export const FlightTicket = styled(Grid)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.mainText,
}));

export const FlightTicketAmount = styled(Grid)(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'right',
    color: theme.palette.mainText,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
}));

export const AmountPassengersBlock = styled(Grid)(() => ({
    width: '100%',
}));

export const TotalTitle = styled(Grid)(({ theme, $isCashback }) => ({
    fontSize: 18,
    fontWeight: 600,
    color: $isCashback ? theme.palette.cashback : theme.palette.mainText,
    lineHeight: 1,
}));

export const TotalPrice = styled(Grid)(({ theme, $isCashback }) => ({
    fontSize: 18,
    fontWeight: 600,
    color: $isCashback ? theme.palette.cashback : theme.palette.mainText,
    lineHeight: 1.
}));

export const TotalPriceText = styled('span')(() => ({
    fontSize: 20,
    fontWeight: 600,
}));
