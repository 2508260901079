import styled from 'styled-components';
import {Settings} from '@material-ui/icons';

export const Button = styled('button')(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    height: 42,
    width: 42,
    border: `1px solid ${theme.palette.main}`,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: theme.palette.transparent,
}));

export const SettingsIcon = styled(Settings)(({theme}) => ({
    width: 36,
    height: 36,
    color: theme.palette.main
}));

export const GroupTitle = styled('div')(({theme}) => ({
    marginBottom: 8,
    fontWeight: 600,
    color: theme.palette.main,
}));

export const Items = styled('div')(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: 32,
}));

export const Item = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    color: theme.palette.black,
}));

export const Code = styled('div')(({theme}) => ({
    color: theme.palette.languages,
    marginLeft: 8,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    transition: 'color 0.26s ease',
    userSelect: 'none',
    textTransform: 'uppercase',
}));

export const Label = styled('div')(({theme}) => ({
    color: theme.palette.mainText,
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 400,
    position: 'relative',
    width: '80%',
    textAlign: 'left',

    '&.active': {
        color: theme.palette.clientName
    }
}));

export const CheckboxBlock = styled('div')(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    paddingRight: 4,
}));

export const Checkbox = styled('div')(({theme}) => ({
    width: 15,
    height: 15,
    position: 'relative',
    borderRadius: '50%',
    transition: 'border-color 0.26s ease',
    backgroundColor: theme.palette.button.clearRouteBg,
    border: `1px solid ${theme.palette.button.checkboxClass}`,

    '&:after': {
        content: '',
        width: 8,
        height: 8,
        position: 'absolute',
        top: '50%',
        left: '50%',
        background: 'transparent',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'background-color 0.26s ease',
    },

    '&.active': {
        border: `3px solid ${theme.palette.clientName}`
    }
}));
