import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setAppConfigs } from "../../store/appConfigs/actions";
import useLocalization from "../../contexts/localization/hook";

import { ReactComponent as Sucsess } from "../../assets/icons/checked.svg";
import { setAllLoadngData } from "../../store/loading/actions";
import { Container } from "@material-ui/core";
import { Section, TextBlock } from "./components";

const SuccessfulPaymentPage = () => {
  const { t } = useLocalization();
  const containerRef = useRef();

  const { isAuth } = useSelector((state) => state.appConfigs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAllLoadngData({ isOrderLoading: false }));
    dispatch(
      setAppConfigs({
        isStickySearchBlock: false,
        isSmallSearchBlock: false,
        isFullScreenSearchBlock: false,
      })
    );

    window.scrollTo({
      behavior: "smooth",
      top: containerRef.current.offsetTop - 100,
    });
  }, [dispatch]);

  const goTo = (link, text) => <NavLink to={link}>{text}</NavLink>;

  return (
    <Container ref={containerRef} fixed>
      <Section>
        <TextBlock>
          <Sucsess />
          <h1>{t.your_order_has_been_successfully_paid_for}</h1>
          <h2>{t.the_ticket_will_be_sent_to_your_email_within_1_2_minutes}</h2>
          {isAuth
            ? goTo("/orders", t.go_to_orders_page)
            : goTo("/", t.go_to_the_main_page)}
        </TextBlock>
      </Section>
    </Container>
  );
};

export default SuccessfulPaymentPage;
