import React, { useEffect, useState } from "react";
import ClientsRewiev from "./parts/clientsRewiev";
import LastNews from "./parts/lastNews";
// import SubscriptionToNewsletter from "./parts/subscriptionToNewsletter";
import ToursAndDiscounts from "./parts/toursAndDiscounts";
import OurPartners from "./parts/ourPartners";
import API from "../../../lib/api";
import AdsAndFlights from "./parts/adsAndFlights";
import YandexIframe from "./parts/yandexIframe";
import TitleUI from "../ui/titleUI";
import destinationsIcon from "../../../assets/icons/iconsForTitle/directions.svg"

const Main = () => {
	const [data, setData] = useState(null);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const result = await API.getMainData();
		setData(result);
	};

	if (!data) return null;

	return (
		<>
			<AdsAndFlights data={data?.popularDestinations} />
			<YandexIframe />
			<ToursAndDiscounts tours={data?.tours} />
			<LastNews news={data?.news} />
			<ClientsRewiev reviews={data?.reviews} />
			{/* <SubscriptionToNewsletter /> */}
			<OurPartners partners={data?.partners} />
		</>
	);
};

export default Main;
