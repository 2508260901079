import styled from 'styled-components';

export const Title = styled('div')(({ theme }) => ({
    ...theme.margins(30, 0),
    fontSize: 30,
    color: theme.palette.mainText,
    fontWeight: 600,
    textAlign: 'center',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        fontSize: 36,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        fontSize: 24,
    },
}));

export const Items = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 50),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
}));

export const Item = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 16),
    ...theme.paddings(16, 16),
    display: 'flex',
    flexDirection: 'column',
    width: '32%',
    backgroundColor: theme.palette.inputBackground,
    borderRadius: theme.spacing(1.5),
    boxShadow: '0 0 5px 1px rgba(0, 59, 110, 0.1)',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: '48%',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const Label = styled('div')(({ theme }) => ({
    color: theme.palette.inputPlaceholderText,
}));

export const Value = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 16, 0),
    fontWeight: 600,
    color: theme.palette.mainText,
}));
