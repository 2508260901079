import styled from "styled-components";

export const Destinations = styled("div")(({ theme }) => ({
	marginBottom: "48px",

	h4: {
		margin: "0 0 14px 0",
		color: theme.palette.mainText,
	},
}));

export const ItemWrapper = styled("div")(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(3, 1fr)",
	gridAutoRows: "230px",
	gap: "16px",

	[theme.breakpoints.down(theme.breakpoints.tablet)]: {
		gridTemplateColumns: "repeat(2, 1fr)",
	},

	[theme.breakpoints.down(theme.breakpoints.miniTablet)]: {
		gridTemplateColumns: "1fr",
	},
}));

export const Figure = styled("figure")(({ theme }) => ({
	display: "block",
	padding: "0",
	margin: "0",
	position: "relative",
	overflow: "hidden",
	transition: "300ms",
	borderRadius: "16px",
	cursor: "pointer",
	background:
      `calc( .9*16px) calc( .9*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 20% 20%,#0000 75%,#fbb600 0),
      calc(-.1*16px) calc(-.1*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 20% 20%,#0000 75%,#fbb600 0),
      calc( .7*16px) calc( .7*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 40% 40%,#0000 75%,#0085ff 0),
      calc(-.3*16px) calc(-.3*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 40% 40%,#0000 75%,#0085ff 0),
      conic-gradient(from 90deg at 20% 20%,#fbb600 25%,#0085ff 0) 
       0 0/16px 16px;`,

	img: {
		width: "100%",
		objectFit: "cover",
		borderRadius: "16px",
		height: "100%",
		transition: "300ms 0ms",
	},

	figcaption: {
		height: "40%",
		transition: "300ms",

		div: {
			transition: "300ms 100ms",
		},
	},

	"&:hover": {
		img: {
			transform: "scale(1.3)",
			transition: "3s 150ms",
		},
		figcaption: {
			height: "100%",

			div: {
				marginBottom: "8px",
			},
		},
	},
}));

export const Figcaption = styled("figcaption")(({ theme }) => ({
	position: "absolute",
	display: "flex",
	alignItems: "flex-end",
	justifyContent: "space-between",
	bottom: 0,
	left: 0,
	width: "100%",
	padding: "10px",
	color: "#ffffff",
	borderRadius: "16px",
	background: "rgb(0,0,0)",
	// eslint-disable-next-line no-dupe-keys
	background: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",

	span: {
		fontSize: "18px",
		fontWeight: "500",
	},

	div: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		width: "100%",
		gap: "0 1rem",

		"span:nth-child(2)": {
			justifySelf: "end",
		},

		small: {
			opacity: 0.7,
			fontSize: "14px",
		},
	},
}));

export const SpinerLoader = styled("div")(({ theme }) => ({
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50px",
	height: "50px",
	color: "#ffffff",
}));

export const ShowMore = styled("button")(({ theme }) => ({
	backgroundColor: theme.palette.thereButton,
	color: "#ffffff",
	borderRadius: "100px",
	border: "none",
	padding: "8px 24px",
	display: "block",
	margin: "20px auto 0 auto",
	cursor: "pointer",
}))