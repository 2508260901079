import styled from 'styled-components';

export const RouteBlock = styled('div')(({ theme }) => ({
    ...theme.marginsY(8, 0),

    '&:nth-of-type(1)': {
        ...theme.marginsY(2, 0),
    },
}));

export const RouteCities = styled('div')(({ theme }) => ({
    fontSize: 15,
    marginBottom: 4,
    fontWeight: 600,
    color: theme.palette.mainText,
}));
