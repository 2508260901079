const breakpoints = {
  mobile: 600,
  miniTablet: 800,
  tablet: 1000,
  laptop: 1300,
  largeScreen: 1530,

  up: (value) => `@media (min-width: ${value}px)`,
  down: (value) => `@media (max-width: ${value - 1}px)`,
  upDown: (min, max) => `@media (min-width: ${min}px) and (max-width: ${max - 1}px)`,
};

export default breakpoints;
