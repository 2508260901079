import styled from 'styled-components';
import { Tune } from '@material-ui/icons';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/closeIcon.svg';

export const Wrapper = styled.div``;

export const ButtonBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
}));

export const FilterButton = styled('button')(({ theme }) => ({
    position: 'relative',
    ...theme.mixins.flexCenterCenter,
    ...theme.paddings(8, 16),
    borderRadius: theme.spacing(2.5),
    border: `1px solid ${theme.palette.main}`,
    backgroundColor: theme.palette.blocksBackground,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.mainText,
    boxShadow: `0px 0px 20px 6px rgba(238, 238, 238, 0.07)`,
}));

export const FilterIcon = styled(Tune)`
  margin-right: 8px;
`;

export const ActiveIndicator = styled('div')(({ theme }) => ({
    position: 'absolute',
    left: 30,
    top: 8,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.error,
    borderRadius: '50%',
}));

export const ModalBlock = styled.div`
  margin-top: -16px;
  padding: 0 8px;
`;

export const ButtonsBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.paddings(20, 0, 16),
    position: 'sticky',
    bottom: 0,
}));

export const Button = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.paddings(16, 16),
    fontWeight: 600,
    color: theme.palette.button.buttonWhite,
    border: 'none',
    backgroundColor: theme.palette.button.searchButtonBg,
    borderRadius: theme.spacing(1),
    fontSize: 16,
    lineHeight: 1,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.08)',
    width: '100%',
    letterSpacing: 1,
}));

export const Icon = styled(CloseIcon)`
  margin-right: 4px;
`;
