import styled from 'styled-components';

export const MainResultContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginTop: 32,
    backgroundColor: theme.palette.bodyBg,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        flexDirection: 'column',
    },
}));

export const MainResultFiltersContainer = styled('div')(({ theme }) => ({
    maxWidth: '25%',
    flexBasis: '25%',
    order: 1,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        maxWidth: '32%',
        flexBasis: ' 32%',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.paddings(32, 0, 40),
        order: 'unset',
        maxWidth: '100%',
        flexBasis: '100%',
        position: 'sticky',
        bottom: theme.spacing(1),
    },
}));

export const MainResultRecommendationsContainer = styled('div')(({ theme }) => ({
    maxWidth: '73%',
    flexBasis: '73%',
    order: 2,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        maxWidth: '66%',
        flexBasis: '66%',
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        maxWidth: '100%',
        flexBasis: '100%',
        order: 'unset',
    },
}));

export const MainResultNonPriceFlights = styled("div")(({ theme }) => ({
    ...theme.mixins.flexColumn,
    overflow: "hidden",
}));

export const RouteBlock = styled('div')(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.departureCountryAndAirCompany,
}));

export const AirlineLogo = styled('div')(({ imgUrl }) => ({
    height: 30,
    width: '100%',
    backgroundImage: `url(${imgUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
}));

