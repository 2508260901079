import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import LinkBackTo from '../ui/components/LinkBackTo/LinkBackTo';
import { useStyles } from '../../containers/order/styles';
import { Redirect } from 'react-router-dom';
import { BookStatus, OrderNumber } from './orderForm/orderForm';
import configs from '../../config';
import useLocalization from '../../contexts/localization/hook';

const authRoutes = configs.authRoutes;

export default function BrokenOrderForm(props) {
    const { orderData } = props;
    const { order_id, timestamp, client_email, client_phone, session_id, booking_status, order_status, status_at } =
        orderData;
    const [redirect, setRedirect] = useState(false);

    const RedirectToOrders = () => {
        setRedirect(true);
    };

    if (redirect) {
        return <Redirect push to={{ pathname: authRoutes.orders }} />;
    }

    return (
        <Container fixed>
            <Grid container>
                <Grid item xs={12}>
                    <LinkBackTo onClick={RedirectToOrders} />
                </Grid>
                <Grid item xs={12}>
                    <OrderNumber orderId={order_id} />
                </Grid>
                <Grid item xs={12}>
                    <BookStatus
                        timestamp={timestamp}
                        createdAt={status_at}
                        bookStatus={booking_status}
                        orderStatus={order_status}
                    />
                </Grid>
            </Grid>
            <RenderBrokenBookData clientEmail={client_email} clientPhone={client_phone} />
        </Container>
    );
}

const RenderBrokenBookData = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const { clientEmail, clientPhone } = props;

    return (
        <div className={classes.brokenOrderContainer}>
            <Grid container className={classes.bookDataBlock}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={3} className={classes.bookLabel}>
                            {t.book}
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9} sm={8}>
                                            <div className={classes.bookDataTitle}>{t.pnr}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9} sm={8}>
                                            <div className={classes.bookDataTitle}>{t.online_registration_locator}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.lineBlock}>
                    <Divider className={classes.line} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3} className={classes.bookLabel}>
                            {t.passengers}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.lineBlock}>
                    <Divider className={classes.line} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3} className={classes.bookLabel}>
                            {t.buyer}
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3} className={classes.bookDataTitle}>
                            {clientEmail}
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3} className={classes.bookDataTitle}>
                            {clientPhone}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
