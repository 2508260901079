import React from "react";
import MaskedInput from "react-text-mask";
import { ErrorsFieldBlock } from "../../../../styles/common/index";
import InputMessage from "../InputMessage/InputMessage";
import useLocalization from "../../../../contexts/localization/hook";
import config from "../../../../config/envConfigs";
import { FormControlUi, InputUi, LabelUi } from "./components";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return props && (
    <MaskedInput
      {...other}
      ref={(ref) => {        
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={config.phoneMask}
      placeholderChar={"\u2000"}
    />
  );
}

export default function PhoneField(props) {
  const {
    label,
    fieldError,
    meta: { touched, error },
  } = props;

  const { t } = useLocalization();

  let errorStyle = {};

  if (fieldError || (touched && error)) {
    errorStyle = {
      border: "solid 2px #c62828",
    };
  }

  return (
    <FormControlUi>
      <LabelUi>
        <span>{label}</span>
      </LabelUi>

      <InputUi
        {...props?.input}
        disableUnderline={true}
        inputComponent={TextMaskCustom}
        autoComplete="off"
        style={errorStyle}
      />

      <ErrorsFieldBlock>
        {touched && error && <InputMessage text={t[error]} />}
        {fieldError && <InputMessage text={fieldError} />}
      </ErrorsFieldBlock>
    </FormControlUi>
  );
}

PhoneField.defaultProps = {
  label: null,
};
