import styled, { css, keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const fadeIn = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    
    to {
        transform: translateY(100%); 
        opacity: 0;
    }
`;

export const Wrapper = styled('div')(({ isShow }) => ({
    display: isShow ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 250,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
}));

export const Backdrop = styled("div")(
    ({ theme }) => ({
        ...theme.mixins.flexCenterCenter,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 251,
        backgroundColor: theme.palette.transparent,
    }),
    css`
        animation: ${props => (props.isShow ? fadeIn : fadeOut)} 0.3s ease forwards;
    `
);

export const Modal = styled("div")(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
    backgroundColor: theme.palette.inputBackground,
    width: 700,
    position: 'relative',
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.margins(0, 16),
        width: '100%',
    }
}));

export const CloseButton = styled(IconButton)(({
    position: 'absolute !important',
    right: 12,
    top: 10,
}));

export const StyledCloseIcon = styled(Close)(({ theme }) => ({
    color: theme.palette.mainText,
}));

export const Text = styled("p")(({ theme }) => ({
    ...theme.paddings(44, 16, 16),
    color: theme.palette.mainText,
    margin: 0,
    fontSize: 18,
    textAlign: 'center',
}));

export const ButtonBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    ...theme.margins(16, 0),
}));

export const Button = styled(NavLink)(({ theme }) => ({
    ...theme.paddings(12, 24),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.main,
    border: 'none',
    color: theme.palette.button.buttonWhite,
    fontSize: 20,
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'none',
}));
