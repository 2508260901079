import styled from "styled-components";

import dushanbeHujand from "../../../../../images/ads/dushka-hujand1.jpg";
import dushanbeHujand4 from "../../../../../images/ads/dushka-hujand2.jpg";
import dushanbeHujand3 from "../../../../../images/ads/dushka-hujand3.jpg";

import Tbilisy240x608 from "../../../../../images/ads/240x608_tbilisy.jpg"
import Tbilisy528x160 from "../../../../../images/ads/528х160_tbilisy.jpg"
import Tbilisy720x192 from "../../../../../images/ads/720x192_tbilisy.jpg"
import Tbilisy912x192 from "../../../../../images/ads/912х192_tbilisy.jpg"


export const Header = styled('div')(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  textAlign: 'center',
  fontSize: '1.6rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.mainText,
  paddingTop: theme.spacing(3.75),
  paddingBottom: theme.spacing(3),

  div: {
    width: "26px",
    height: "26px",
    borderRadius: "6px",
    backgroundColor: "#0085ff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(45deg)",
    marginRight: "20px",

    

     img: {
      width: "16px",
      height: "16px",
      transform: "rotate(-45deg)"
     },

     [theme.breakpoints.down(theme.breakpoints.tablet)]: {
      display: "none",
     }
  }
}));

export const Blocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 192px 192px 192px 192px;
  gap: 16px;

  @media (max-width: 1299px) {
    display: flex;
    flex-direction: column;

    & > div {
      height: 192px;
    }
  }

  @media (max-width: 799px) {
    & > div {
      height: auto;
    }
  }

  .ad {
    border-radius: 16px;
    grid-row-start: 1;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: #3f8bfb;
    background-image: url(${dushanbeHujand});
    background-repeat: round;

    @media (max-width: 1299px) {
      background-image: url(${dushanbeHujand3});
    }

    @media (max-width: 999px) {
      background-image: url(${dushanbeHujand3});
    }

    @media (max-width: 799px) {
      background-image: url(${dushanbeHujand4});
      height: 192px;
    }

    @media (max-width: 499px) {
      height: 160px;
    }

    @media (max-width: 449px) {
      height: 150px;
    }

    @media (max-width: 399px) {
      height: 120px;
    }
  }

  .destination {
    grid-column-start: 2;
    grid-column-end: 3;

    &.wide {
      grid-column-end: 4;
    }
  }

  .bonus {
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 3;
    grid-column-end: 4;
    border-radius: 16px;
    background-color: #f7b306;
    background-image: url(${Tbilisy240x608});
    background-size: contain;
    background-repeat:round;

    @media (max-width: 1299px) {
      background-image: url(${Tbilisy912x192});
    }

    @media (max-width: 999px) {
      background-image: url(${Tbilisy720x192});
    }

    @media (max-width: 799px) {
      background-image: url(${Tbilisy528x160});
      height: 192px;
    }

    @media (max-width: 499px) {
      height: 160px;
    }

    @media (max-width: 449px) {
      height: 150px;
    }

    @media (max-width: 399px) {
      height: 120px;
    }
  }
`;
