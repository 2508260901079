import styled from 'styled-components';

export const FromDate = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor: theme.palette.searchForm,
    width: 200,
    position: 'relative',
    borderRadius: `${theme.spacing(1.5)} 0 0 ${theme.spacing(1.5)}`,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 226,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const ToDate = styled('div')(({ theme }) => ({
    height: 60,
    backgroundColor: theme.palette.searchForm,
    width: 200,
    borderRadius: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)} 0`,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        width: 226,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
    },
}));

export const Input = styled('input')(({ theme, isActive, direction }) => ({
    ...theme.paddings(8, 40, 8, 16),
    backgroundColor: theme.palette.transparent,
    width: '100%',
    height: '100%',
    margin: 0,
    border: 'none',
    borderRadius: 0,
    fontSize: '1rem',
    position: 'relative',
    color: theme.palette.searchTextForm,
    cursor: 'pointer',
    zIndex: isActive ? 10 : 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        borderRadius: direction === 'from' ? `0 0 0 ${theme.spacing(1.25)}` : 0,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        borderRadius: theme.spacing(1.5),
        width: '100%',
    },

    '::placeholder': {
        color: theme.palette.searchTextForm,
    },
}));

export const IconButton = styled('div')(({ theme, isActive }) => ({
    position: 'absolute',
    right: 8,
    top: 'calc(50% - 12px)',
    color: theme.palette.white,
    zIndex: isActive ? 10 : 5,

    '&:hover': {
        cursor: 'pointer',
    },
}));

export const Icon = styled('div')(({ theme, active }) => ({
    display: active ? 'block' : 'none',
    position: 'absolute',
    right: 8,
    top: 'calc(50% - 12px)',
    color: theme.palette.clientName,
}));
