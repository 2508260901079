import styled from "styled-components";

import Grid from "../../../ui/grid";
import { StyledButton } from "../../../../../styles/common/index";

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.paddings(8, 16),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2.5),
  backgroundColor: theme.palette.blocksBackground,
  position: "relative",
  boxShadow: theme.palette.ticketboxShadow,
  background: theme.palette.ticketBackground,

  [theme.breakpoints.down(theme.breakpoints.tablet)]: {
    display: "none",
  },
}));

export const SpecialText = styled("div")(({ theme }) => ({
  ...theme.paddings(0, 15),
  ...theme.mixins.flexAlignCenter,
  position: "absolute",
  right: "50%",
  transform: "translateX(50%)",
  top: 14,
  zIndex: 50,
  textAlign: "center",
  color: theme.palette.specialTextColor,
  height: theme.spacing(3.75),
  borderRadius: theme.spacing(3.75),
  backgroundColor: theme.palette.specialTextBgColor,
}));

export const FlightInfo = styled("div")({
  textAlign: "right",
  position: "relative",
});

export const ArrivalInfo = styled("div")({
  textAlign: "left",
  position: "relative",
});

export const DurationBlock = styled("div")(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  fontSize: 14,
  fontWeight: 500,
  color: "#9da8ae",
}));

export const RouteBlock = styled("div")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: "#9da8ae",
}));

export const TimeRouteBlock = styled("div")(({ theme }) => ({
  color: theme.palette.recommendation.flightTime,
  fontSize: 24,
  fontWeight: 500,
}));

export const RouteTitleContainer = styled("div")(({ theme, imgUrl }) => ({
  ...theme.mixins.flexAlignCenter,
  ...theme.paddings(7, 0),
  backgroundSize: theme.spacing(3),
  backgroundImage: `url(${imgUrl})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left center",
  justifyContent: "space-between"
}));

export const RouteTitle = styled("div")(({ theme, themeColor }) => ({
  color: themeColor,
  marginLeft: theme.spacing(4),
  fontSize: 18,
  fontWeight: 500,
}));

export const RouteMainInfoWrapper = styled("div")`
  cursor: pointer;
`

export const RouteMainInfo = styled("div")(({ theme }) => ({
  ...theme.paddings(10, 10, 10, 0),
  boxSizing: "border-box",
  height: 150,
  position: "relative",
  cursor: "pointer",

  [theme.breakpoints.down(theme.breakpoints.laptop)]: {
    height: 260,
  },

  "& > div": {
    height: "100%",
  },
}));

export const PentagonShape = styled("div")(({ theme, themeColor }) => ({
  width: 38,
  backgroundColor: themeColor,
  position: "absolute",
  left: -39,
  top: 0,
  borderRadius: "50%",
}));

export const AirlineLogoBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  flexDirection: "column",
  justifyContent: "center",
  gap: 10,
  width: "100%",
  height: "100%",
}));

export const AirlineLogo = styled("div")(({ theme, imgUrl }) => ({
  height: 30,
  width: "100%",
  backgroundImage: `url(${imgUrl})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: `center center`,
}));

export const FlightInfoGrid = styled(Grid)(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  order: 2,
  borderRadius: theme.spacing(1.75),
  border: `1px solid ${theme.palette.recommendation.flightInfoBorder}`,

  [theme.breakpoints.down(theme.breakpoints.laptop)]: {
    ...theme.paddings(12),
    border: "none",
    order: 3,
  },
}));

export const TransfersArrowImg = styled("div")(({ theme, imgUrl }) => ({
  height: 10,
  backgroundImage: `url(${imgUrl})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center center`,
  backgroundSize: "100%",
}));

export const StringSpan = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(0.375),
}));

export const NightTransfer = styled("div")(({ theme }) => ({
  color: theme.palette.main,
  fontWeight: 400,
  fontSize: 12,
}));

export const OrderInfoGrid = styled(Grid)(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  order: 3,

  [theme.breakpoints.down(theme.breakpoints.laptop)]: {
    order: 2,
  },
}));

export const SwitcherBlock = styled("div")(({ theme }) => ({
  justifySelf: "end",
  alignSelf: "end",
  marginTop: 12,
  display: "flex",
  alignItems: "center",
  gap: 8,

  span: {
    fontSize: 12,
    color: theme.palette.mainText
  },
}));

export const TariffChangeBlock = styled("div")(
  ({ theme, haseBaggage, tariffIsSelected }) => ({
    height: 20,
    width: 40,
    borderRadius: 20,
    position: "relative",
    backgroundColor:
      haseBaggage || tariffIsSelected ? theme.palette.main : "#e1e1e1",

    div: {
      width: 20,
      height: 20,
      border: "2px solid #e1e1e1",
      borderColor:
        haseBaggage || tariffIsSelected ? theme.palette.main : "#e1e1e1",
      backgroundColor: "#ffffff",
      borderRadius: "50%",
      position: "absolute",
      left: haseBaggage || tariffIsSelected ? "100%" : 0,
      transform: haseBaggage || tariffIsSelected ? "translateX(-100%)" : 0,
      transition: "600ms ease",
    },
  })
);

export const RightTariffChangeBlock = styled(TariffChangeBlock)(
  ({ theme }) => ({
    borderLeft: "1px solid #cfd8dc",
    paddingLeft: theme.spacing(1.25),
    marginLeft: theme.spacing(1.25),
  })
);

export const ShortTariffInfo = styled("div")({
  textAlign: "right",
  position: "relative",
});

export const ChangeTariffContainer = styled(ShortTariffInfo)(({ theme }) => ({
  ...theme.mixins.flexCenterCenter,
  flexDirection: "column",
  width: "100%",
  textAlign: "right",
  cursor: "pointer",
}));

export const ThirdBlock = styled("div")(({ theme }) => ({
  display: "grid",
}));

export const LowcostCharterContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const Charter = styled("div")(({ theme }) => ({
  color: "#fff",
  width: 100,
  borderRadius: theme.spacing(0.5),
  backgroundColor: "#67d457",
  textAlign: "center",
  marginRight: theme.spacing(0.625),
}));

export const Lowcost = styled("div")(({ theme }) => ({
  color: "#fff",
  width: 100,
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.specialTextBgColor,
  textAlign: "center",
}));

export const ClassBlock = styled("div")(({ theme }) => ({
  textAlign: "right",
  fontSize: 16,
  fontWeight: 500,
  color: theme.palette.recommendation.classInfo,
}));

export const AdditionalInformationShort = styled("div")(({ theme }) => ({
  textAlign: "right",
  fontSize: 14,
  fontWeight: 500,
  color: "#9da8ae",
  marginTop: theme.spacing(0.5),
}));

export const InlineBlock = styled("div")({ display: "inline-block" });

export const FreeSeatsLabelBlock = styled(InlineBlock)(({ theme }) => ({
  verticalAlign: 1,
  paddingLeft: theme.spacing(1.5),
}));

export const BaggageInfoBlock = styled("div")(({ theme }) => ({
  fontSize: 12,
  textAlign: "right",
  color: theme.palette.recommendation.classInfo,
}));

export const RouteAdditionallyInfo = styled("div")({ borderTop: "none" });

export const ButtonContainer = styled("div")(({ theme }) => ({
  ...theme.marginsY(16, 8),
}));

export const SupplierBlock = styled("div")(({ theme }) => ({
  color: "#9da8ae",
  fontSize: 14,
}));

export const AmountSubmitBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  justifyContent: "flex-end",
  gap: theme.spacing(2.5),

  [theme.breakpoints.down(theme.breakpoints.laptop)]: {
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },

  [theme.breakpoints.down(theme.breakpoints.mobile)]: {
    fontSize: 24,
  },
}));

export const AmountBlock = styled("div")(({ theme }) => ({
  ...theme.mixins.flexAlignCenter,
  color: theme.palette.mainText,
  fontSize: 24,
  fontWeight: 600,

  [theme.breakpoints.down(theme.breakpoints.mobile)]: {
    fontSize: 20,
  },

  "& > span": {
    color: theme.palette.mainText,
    marginRight: theme.spacing(1),
  },
}));

export const ModalSectionBlock = styled("div")(() => ({
  position: "relative",
  height: "100%",
}));

export const ChoosedButtonBlock = styled(InlineBlock)(({ theme }) => ({
  height: 40,
  position: "sticky",
  bottom: "0",
  background: theme.palette.inputBackground,
  borderRadius: 8,
  zIndex: "111",
  width: "100%",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));

export const ChoosedButton = styled(StyledButton)(({ theme }) => ({
  width: "auto",
  height: "100%",
  borderRadius: 8,
  border: "none",
  backgroundColor: theme.palette.main,
  textTransform: "uppercase",
  fontSize: 20,
  fontWeight: 600,
  padding: "0 20px",

  "&:hover": {
    backgroundColor: theme.palette.main,
  },
}));

export const ShareLinkBlock = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: 4,
  position: "absolute",
  right: 24,
  top: 16,

  svg: {
    width: 16,
    height: 16,

    path: {
      fill: theme.palette.main
    }
  },

  p: {
    color: theme.palette.main,
    fontSize: 14,
    margin: 0
  }
}))

export const CashbackBlock = styled("div")(({ theme }) => ({
  ...theme.paddings(4, 8),
  backgroundColor: theme.palette.cashback,
  fontSize: 14,
  fontWeight: "bold",
  borderRadius: 4,
  color: theme.palette.white,
}));

export const SpanChartText = styled("span")(({ theme }) => ({
  color: theme.palette.mainText,
  textAlign: "center",
  width: "100%",
  fontWeight: 600,
  fontSize: "17px",
}));

export const ChantText = styled("div")(({ theme }) => ({
  paddingTop: 16,
  textAlign: "center",
  fontSize: "18px",
  fontWeight: 600,
  width: "100%",
  color: theme.palette.mainText,
}));

export const DetailsWrap = styled("div")(({ theme }) => ({
  display: "grid",
  gap: 24,
  height: "100%"
}));
