import styled from 'styled-components';

export const Component = styled('div')(() => ({
    position: 'relative',
    width: '100%',
}));

export const SelectBlock = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterSpaceBetween,
    ...theme.paddings(0, 10),
    height: 60,
    width: '100%',
    border: `1px solid ${theme.palette.placeholderTextSearchOrder}`,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.inputBackground,
    cursor: 'pointer',
}));

export const Value = styled('span')(({ theme, isBold }) => ({
    fontWeight: isBold ? '600' : '400',
    color: theme.palette.mainText,
}));

export const Icon = styled('span')(() => ({
    transition: 'all 0.3s ease',

    '&.active': {
        transition: 'all 0.3s ease',
        transform: 'rotate(180deg)',
    },
}));

export const DropdownBlock = styled('div')(({ theme, isActive }) => ({
    ...theme.paddings(8, 0),
    display: isActive ? 'flex' : 'none',
    flexDirection: 'column',
    position: 'absolute',
    top: 'calc(100% + 8px)',
    width: '100%',
    backgroundColor: theme.palette.inputBackground,
    zIndex: 15,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: theme.spacing(1),
}));

export const Item = styled('button')(({ theme, isActive }) => ({
    ...theme.paddings(16, 16),
    color: theme.palette.mainText,
    width: '100%',
    backgroundColor: theme.palette.transparent,
    textAlign: 'left',
    border: 'none',

    '&:hover': {
        backgroundColor: theme.palette.buttonHover.exitButton,
        cursor: 'pointer',
    },
}));
