import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme, $hasMarginTop }) => ({
    ...theme.marginsY($hasMarginTop ? 32 : 0, 48, 0),
    color: theme.palette.mainText,
    fontSize: 28,
    fontWeight: 600,
    textAlign: 'center',
}));

