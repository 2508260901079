import React, { useState } from 'react';
import { Body, IconBlock, CaretIcon, Header, Title, Wrapper } from './components';

import caretImage from '../../../../../../images/arrow-s-left-cut.png';


const FilterBlock = props => {
    const { children, isOpen, title, borderBottom = false } = props;

    const [open, setOpen] = useState(isOpen);

    return (
        <Wrapper borderBottom={borderBottom}>
            <Header onClick={() => setOpen(!open)}>
                <Title>
                    {title.substr(0, 1).toUpperCase()}
                    {title.substr(1)}
                </Title>

                <IconBlock className={open ? 'active' : null}>
                    <CaretIcon/>
                </IconBlock>
            </Header>

            <Body className={open ? 'active' : null}>{children}</Body>
        </Wrapper>
    );
};

export default FilterBlock;
