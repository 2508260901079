import styled from 'styled-components';
import MaskedTextInput from 'react-text-mask';

export const Component = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumn,
}));

export const InputBlock = styled('div')(({ theme, error }) => ({
    ...theme.paddings(0, 10),
    display: 'flex',
    alignItems: 'center',
    height: 60,
    width: '100%',
    border: `1px solid ${error ? theme.palette.error : theme.palette.placeholderTextSearchOrder}`,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.inputBackground,
}));

export const Label = styled('div')(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.mainText,
    marginRight: 8,
    fontWeight: 600,

    '& > span': {
        color: theme.palette.error,
    },
}));

export const Input = styled(MaskedTextInput)(({ theme }) => ({
    backgroundColor: theme.palette.transparent,
    color: theme.palette.mainText,
    border: 'none',
    outline: 'none',
    fontSize: 16,
    flexGrow: 1,
    height: '100%',

    '&::placeholder': {
        color: theme.palette.inputPlaceholder,
    },
}));

export const ErrorText = styled('div')(({ theme }) => ({
    color: theme.palette.error,
    fontSize: 14,
    marginTop: 4,
}));
