import React from "react";
import DesktopRecommendation from "./desktop";
import MobileRecommendation from "./mobile";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Recommendation = ({
  recommendation,
  index,
  onBookingClick,
  getFareRules,
  getBrandFares,
  withoutPrice,
  specialText = null,
  modalShow,
  setModalShow,
  setSnakBarShow,
}) => {
  const { width } = useWindowDimensions();

  return (
    <>
      {width >= 1000 && (
        <DesktopRecommendation
          index={index}
          recommendation={recommendation}
          onBookingClick={onBookingClick}
          getFareRules={getFareRules}
          getBrandFares={getBrandFares}
          specialText={specialText}
          withoutPrice={withoutPrice}
          modalShow={modalShow}
          setModalShow={setModalShow}
          setSnakBarShow={setSnakBarShow}
        />
      )}

      {width < 1000 && (
        <MobileRecommendation
          index={index}
          recommendation={recommendation}
          onBookingClick={onBookingClick}
          getBrandFares={getBrandFares}
          getFareRules={getFareRules}
          specialText={specialText}
          withoutPrice={withoutPrice}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
    </>
  );
};

export default Recommendation;
