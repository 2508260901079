import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

export const FormControlUi = styled(FormControl)(() => ({
    '&.MuiFormControl-root': {
        position: 'relative',
        display: 'flex',
        height: '100%',
    },
}));

export const InputUi = styled(Input)(({ theme, width, height }) => ({
    '&.MuiInput-root': {
        ...theme.paddings(26, 0, 1, 16),
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: theme.spacing(1.5),
        border: `solid 1px ${theme.palette.placeholderTextSearchOrder}`,
        backgroundColor: theme.palette.inputBackground,
        fontSize: 18,
        color: theme.palette.mainText,
        boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
    },

    '&.Mui-focused': {
        outline: 'none',
        border: `solid 1px ${theme.palette.main}`,
    },

}));

export const LabelUi = styled('div')(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.departureCountryAndAirCompany,
    position: 'absolute',
    top: '8px',
    left: '16px',
    zIndex: 1,
}));
