const initialState = {
    bookingLoading: false,
    showLoading: true,
    searchLoading: true,
    searchData: null,

    isLocalizationLoading: true,
    isAuthCheckLoading: true,
    isSearchLoading: false,
    isPrebookLoading: false,
    isBookingLoading: false,
    isOrderLoading: false,
    isOrdersLoading: false,
};

export default initialState;
