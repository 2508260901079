import React from 'react';
import { Field } from 'redux-form';

import useLocalization from '../../../contexts/localization/hook';
import { required, email, phone } from '../../../lib/validate';

import Grid from '../../design_v2/ui/grid';
import ResponsiveFormField from '../../ui/responsive/FormField';
import PhoneField from '../../ui/components/PhoneField/PhoneField';

import { PayerMess, PayerTitle } from './components';

export const PayerBlock = ({ setPayerEmailToPassenger, setPayerPhoneToPassenger }) => {
    const { t } = useLocalization();

    return (
        <div>
            <PayerTitle item>
                {t.payer_contacts}
            </PayerTitle>

            <PayerMess item>
                {t.for_payer_mess}
            </PayerMess>

            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <Field
                            component={ResponsiveFormField}
                            name="payerEmail"
                            label={t.email}
                            widthSize="100%"
                            isPadding
                            onChange={e => setPayerEmailToPassenger(e.target.value)}
                            placeholder="example@mail.com"
                            validate={[required, email]}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Field
                            component={PhoneField}
                            height="auto"
                            width="100%"
                            name="payerPhone"
                            label={t.phone_number}
                            widthSize="100%"
                            isPadding
                            onChange={e => setPayerPhoneToPassenger(e.target.value)}
                            validate={[required, phone]}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Field
                            component={PhoneField}
                            height="auto"
                            width="100%"
                            name="payerWhatsApp"
                            label="WhatsApp"
                            widthSize="100%"
                            isPadding
                            validate={[phone]}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
