import styled from 'styled-components';

export const Title = styled('h1')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    color: theme.palette.mainText,
}));

export const FormGroup = styled('div')(({ theme }) => ({
    ...theme.margins(0, 'auto', 20),
    width: '100%',
    maxWidth: 570,
}));

export const PassengersTitle = styled('h2')(({ theme }) => ({
    ...theme.margins(32, 0),
    textAlign: 'center',
    color: theme.palette.mainText,
}));

export const PassengerItem = styled('div')(({ theme }) => ({
    ...theme.margins(0, 'auto', 48),
    width: '100%',
    maxWidth: 570,
}));

export const PassengerRemoveBlock = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const PassengerRemoveButton = styled('button')(({ theme }) => ({
    color: theme.palette.error,
    border: 'none',
    backgroundColor: theme.palette.transparent,
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
}));

export const PassengerFormInput = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 20),
}));

export const PassengerAddButtonBlock = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const PassengerAddButton = styled('button')(({ theme }) => ({
    ...theme.paddings(12, 24),
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.button.clearRouteBg,
    color: theme.palette.mainText,
    border: 'none',
    borderRadius: theme.spacing(1.5),
    cursor: 'pointer',
}));

export const SubmitButton = styled('button')(({ theme }) => ({
    ...theme.margins(20, 'auto', 48),
    height: 60,
    minWidth: 184,
    backgroundColor: theme.palette.main,
    border: 'none',
    borderRadius: theme.spacing(1.5),
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.searchTextForm,
    cursor: 'pointer',
    display: 'block',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.marginsY(30, 0),
        fontSize: 20,
    },
}));
