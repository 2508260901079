import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router';

import { setAppConfigs } from '../../store/appConfigs/actions';
import { setTransactionsItems, setTransactionsFilters, setIsLoading } from '../../store/transactions/actions';
import TransactionsPage from '../../pages/transactions';
import API from '../../lib/api';
import envConfigs from '../../config/envConfigs';
import useLocalization from '../../contexts/localization/hook';

const Transactions = () => {
    const { isAuth } = useSelector(state => state.appConfigs);
    const { transactionsFilters } = useSelector(state => state.transactions);
    const { t } = useLocalization();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: false,
            })
        );

        getTransactions();
    }, []);

    useEffect(() => {
        document.title = `${t.page_booking || ""} | ${envConfigs.appTitle}`;
    }, [t]);

    const getTransactions = useCallback(
        async (page = 1) => {
            dispatch(setIsLoading(true));

            await API.getTransactions({
                page,
                limit: 10,
            }).then(response => {
                dispatch(
                    setTransactionsFilters({
                        ...transactionsFilters,
                        lastPage: response.last_page,
                        currentPage: response.page,
                    })
                );
                dispatch(setTransactionsItems(response.data));
                dispatch(setIsLoading(false));
            });
        },
        [transactionsFilters]
    );

    if (!isAuth) return <Redirect to="/" />;

    return <TransactionsPage getTransactions={getTransactions} />;
};

export default withRouter(Transactions);
