import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const Container = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.bodyBg,
    zIndex: 500,
    ...theme.mixins.flexCenterCenter,
}));

export const Block = styled('div')(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    flexDirection: 'column',
}));

export const LoadingText = styled('div')(({ theme }) => ({
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.mainText,
    ...theme.marginsY(16, 0),
}));

export const BookLoadingText = styled('div')(({ theme }) => ({
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.mainText,
    ...theme.marginsY(16, 0),
}));

const Animation = styled.div`
  animation: ${rotate} 1.6s linear infinite;
`

export const LoadingCircularBlock = styled(Animation)(({ theme }) => ({
    ...theme.marginsY(8, 0),
}));
