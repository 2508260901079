import styled from 'styled-components';
import {Menu} from '@material-ui/icons';

import Link from '../../../../../../../ui/components/Link/Link';

export const Button = styled('button')(({theme}) => ({
    ...theme.mixins.flexCenterCenter,
    height: 42,
    width: 42,
    border: `1px solid ${theme.palette.main}`,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: theme.palette.transparent,
}));

export const MenuIcon = styled(Menu)(({theme}) => ({
    width: 36,
    height: 36,
    color: theme.palette.main,
}));

export const StyledLink = styled(Link)(({theme, marginTop}) => ({
    display: 'block',
    color: theme.palette.main,
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.6,
    margin: marginTop ? 16 : '0 0 16px',
}));

export const LinkForAgents = styled('a')(({theme}) => ({
    display: 'block',
    color: theme.palette.main,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.6,
    backgroundColor: theme.palette.transparent,
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    margin: '0 0 16px',
}));

export const ClientName = styled('div')(({theme}) => ({
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.clientName,
}));

export const ClientLogin = styled('div')(({theme}) => ({
    color: theme.palette.balance,
    fontSize: 14,
    marginBottom: 8,
}));

export const ClientBalanceBlock = styled('div')(({}) => ({
    display: 'flex',
    padding: '16px 0',
}));

export const ClientBalanceTitle = styled('div')(({theme}) => ({
    color: theme.palette.balance,
    marginRight: 8,
}));

export const ClientBalanceValue = styled('div')(({theme}) => ({
    color: theme.palette.mainText,
    fontWeight: 600,
    '& > span': {
        marginLeft: 4,
        color: theme.palette.main,
    }
}));

export const ExitButton = styled('div')(({theme}) => ({
    color: theme.palette.black,
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.6,
}));
