import styled from 'styled-components';
import { Grid, Container } from '@material-ui/core';

export const OrdersMainContainer = styled(Container)(({ theme }) => ({
    position: 'relative',
}));

export const OrdersTitle = styled(Grid)(({ theme }) => ({
    ...theme.paddingsY(16, 0),
    color: theme.palette.mainText,
    fontSize: 34,
    fontWeight: 500,
}));

export const OrdersItemsContainer = styled(Grid)(({ theme }) => ({
    minHeight: 360,
    order: 1,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        order: 2,
    },
}));

export const SearchFieldsContainer = styled(Grid)(({ theme }) => ({
    alignSelf: 'flex',
    paddingTop: '81px !important',
    order: 2,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: '100%',
        order: 1,
        paddingTop: '24px !important',
    },
}));

export const PaginationContainer = styled(Grid)(({ theme }) => ({
    order: 3,
}));