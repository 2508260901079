import React from "react";

import useLocalization from "../../../../../../contexts/localization/hook";

import { ReactComponent as ShareIcon } from "../../../../../../assets/icons/newDesign/share.svg";

import { ShareLinkBlock } from "./components";

const ShareIconWithText = ({ handleShareLink }) => {
  const { t } = useLocalization();

  return (
    <ShareLinkBlock onClick={handleShareLink}>
      <ShareIcon />
      <p>{t.share}</p>
    </ShareLinkBlock>
  );
};

export default ShareIconWithText;
