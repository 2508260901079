import React, { useState, useMemo } from 'react';
import { CircularProgress, Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import useLocalization from '../../contexts/localization/hook';
import { setRoutes } from '../../store/searchParams/actions';

import {
    Title,
    NoResultsBlock,
    CityBlock,
    CityName,
    CityTable,
    CityTableRow,
    CityTableRowLoading,
    CityTableColumnTo,
    CityTableColumnAirportCode,
    CityTableColumnWeekDays,
    CityTableColumnAirline,
    CityTableColumnPrice,
} from './components';
import Loading from '../../components/design_v2/ui/loading';
import API from '../../lib/api';

const PopulartDestinationsPage = ({ loading, destinations }) => {
    const { t, language } = useLocalization();
    const dispatch = useDispatch();
    const { routes } = useSelector(state => state.searchParams);
    const { currency } = useSelector(state => state.appConfigs);

    const [clickLoading, setClickLoading] = useState({ departureAirport: null, arrivalAirport: null, supplier: null });

    const getDayLabelsByIndexes = days => {
        return days.map(day =>
            moment()
                .day(day + 1)
                .format('dd')
        );
    };

    const handleClickFlight = async flight => {
        if (clickLoading.departureAirport) {
            return null;
        }

        setClickLoading({
            departureAirport: flight.departureAirport.code_iata,
            arrivalAirport: flight.arrivalAirport.code_iata,
            supplier: flight.supplier.code,
        });

        const departure = await API.getCities(flight.departureAirport.code_iata).then(result => result[0]);
        const arrival = await API.getCities(flight.arrivalAirport.code_iata).then(result => result[0]);

        const date = parseInt(
            flight.flightDays.find(day => day + 1 > moment().isoWeekday()) + 1 || flight.flightDays[0] + 1
        );
        const flightDate =
            date <= moment().isoWeekday() ? moment().add(1, 'weeks').isoWeekday(date) : moment().isoWeekday(date);

        const currentRoutes = [...routes];
        currentRoutes[0].from = departure;
        currentRoutes[0].to = arrival;
        currentRoutes[0].date_from = flightDate.format('YYYY-MM-DD');
        currentRoutes[1].from = arrival;
        currentRoutes[1].to = departure;

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        dispatch(setRoutes(currentRoutes));
        setClickLoading({ departureAirport: null, arrivalAirport: null, supplier: null });
    };

    const renderDestinations = useMemo(() => {
        return destinations.map(destination => {
            return (
                <CityBlock key={`destination-city-${destination.airportCode}`}>
                    <CityName>
                        {t.from_city} {destination.city[`name_${language}`]}
                    </CityName>

                    <CityTable>
                        <CityTableRow className="header">
                            <CityTableColumnTo>{t.to_city}</CityTableColumnTo>
                            <CityTableColumnAirportCode>{t.airport_code}</CityTableColumnAirportCode>
                            <CityTableColumnWeekDays>{t.weekdays}</CityTableColumnWeekDays>
                            <CityTableColumnAirline>{t.airline}</CityTableColumnAirline>
                            <CityTableColumnPrice>{t.price}</CityTableColumnPrice>
                        </CityTableRow>

                        {destination.flights.map(flight => {
                            return (
                                <CityTableRow
                                    key={`destination-${flight.departureAirport.code_iata}-${flight.arrivalAirport.code_iata}-${flight.supplier.code}`}
                                    onClick={() => handleClickFlight(flight)}
                                >
                                    {flight.departureAirport.code_iata === clickLoading.departureAirport &&
                                    flight.arrivalAirport.code_iata === clickLoading.arrivalAirport &&
                                    flight.supplier.code === clickLoading.supplier ? (
                                        <CityTableRowLoading>
                                            <CircularProgress size={14} style={{ color: '#fff' }} />
                                        </CityTableRowLoading>
                                    ) : null}

                                    <CityTableColumnTo>
                                        {flight.arrivalAirport.city[`name_${language}`]}
                                    </CityTableColumnTo>

                                    <CityTableColumnAirportCode>
                                        {flight.arrivalAirport.code_iata}
                                    </CityTableColumnAirportCode>

                                    <CityTableColumnWeekDays
                                        className={flight.flightDays.length !== 7 ? 'uppercase' : ''}
                                    >
                                        {flight.flightDays.length === 7
                                            ? t.daily
                                            : getDayLabelsByIndexes(flight.flightDays).join(', ')}
                                    </CityTableColumnWeekDays>

                                    <CityTableColumnAirline>{flight.supplier.name}</CityTableColumnAirline>

                                    <CityTableColumnPrice>
                                        {flight[`pop_price_${currency.toLowerCase()}`]
                                            ? `${t.from} ${flight[`pop_price_${currency.toLowerCase()}`]} ${currency}`
                                            : '-'}
                                    </CityTableColumnPrice>
                                </CityTableRow>
                            );
                        })}
                    </CityTable>
                </CityBlock>
            );
        });
    }, [destinations, language, clickLoading, currency]);

    return (
        <Container fixed>
            <Title>{t.popular_destinations}</Title>

            {loading ? (
                <NoResultsBlock>
                    <Loading />
                </NoResultsBlock>
            ) : (
                renderDestinations
            )}
        </Container>
    );
};

export default PopulartDestinationsPage;
