import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Container } from '@material-ui/core';

import useLocalization from '../../../../../contexts/localization/hook';

import {
    RewievBlock,
    Header,
    Icon,
    IconLeft,
    ArrowIcons,
    HeaderBlock,
    BodyBlock,
    Rewievs,
    Avatar,
    Client,
    Name,
    Message,
    Block,
    IconRight,
} from './components';
import RewievIcon from '../../../../../assets/icons/aboutCompany.svg';
import arrowRight from '../../../../../assets/icons/arrowRight.svg';
import envConfigs from '../../../../../config/envConfigs';
import LineDivider from '../lineDivider';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';

const ClientsRewiev = ({ reviews }) => {
    const slideToChange = useRef();
    const { t } = useLocalization();
    const { width } = useWindowDimensions();

    const renderButtons = () => {
        let check =
            (width >= 1300 && reviews.length > 3) ||
            (width < 1300 && width > 960 && reviews.length > 2) ||
            (width < 960 && reviews.length > 1);

        if (check) {
            return (
                <ArrowIcons>
                    <IconRight onClick={() => slideToChange.current.slickPrev()} src={arrowRight} />

                    <IconLeft onClick={() => slideToChange.current.slickNext()} src={arrowRight} />
                </ArrowIcons>
            );
        }

        return null;
    };

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        infinite: reviews.length > 3,
        arrows: false,
        dots: false,
        autoplay: false,
        speed: 300,
        draggable: true,
        useCSS: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1299,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: reviews.length > 2,
                },
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    };

    if (reviews.length === 0) return null;

    return (
        <>
            <Container fixed>
                <RewievBlock>
                    <HeaderBlock>
                        <Header>
                            <Icon src={RewievIcon} />
                            {t.customer_rew}
                        </Header>

                        {renderButtons()}
                    </HeaderBlock>

                    <BodyBlock>
                        <Slider {...settings} ref={slideToChange}>
                            {reviews.map(review => (
                                <Rewievs mLeft key={review.id}>
                                    <Block>
                                        <Avatar loading="lazy" src={`${envConfigs.uploadHost}${review.photo}`} />

                                        <Client>
                                            <Name>{review.name}</Name>

                                            <Message>{review.description}</Message>
                                        </Client>
                                    </Block>
                                </Rewievs>
                            ))}
                        </Slider>
                    </BodyBlock>
                </RewievBlock>
            </Container>

            <LineDivider />
        </>
    );
};

export default ClientsRewiev;
