import React, { useEffect, useRef } from 'react';
import { Close } from '@material-ui/icons';

import useLocalization from '../../../contexts/localization/hook';

import {
    Wrapper,
    Backdrop,
    Modal,
    CloseButton,
    TextBlock,
    LogoBlock,
    Logo,
    EventText,
    ButtonBlock,
    Button,
} from './components';

import dcLogoImage from '../../../images/otherLogos/dcPayment.png';

const DcPaymentPopup = ({ showPopup, handleClose, payUrl, payform }) => {
    const backdropRef = useRef(null);
    const { t } = useLocalization();

    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            if (showPopup) {
                document.body.classList.add('hidden');
            } else {
                document.body.classList.remove('hidden');
            }
        };
    }, [showPopup]);

    const handleBackdropClick = event => {
        if (event.target === backdropRef.current) {
            handleClose();
        }
    };

    if (!showPopup) return null;

    return (
        <Wrapper isShow={showPopup}>
            <Backdrop isShow={showPopup} onClick={handleBackdropClick} ref={backdropRef}>
                <Modal isShow={showPopup}>
                    <CloseButton onClick={handleClose} size="small">
                        <Close />
                    </CloseButton>

                    <TextBlock>
                        <LogoBlock>
                            <Logo src={dcLogoImage} alt="dc-logo" />
                        </LogoBlock>

                        <EventText>{t.confirm_actions}</EventText>

                        <form action={payUrl} method="POST">
                            <textarea name="Request" hidden>
                                {payform}
                            </textarea>
                            <ButtonBlock>
                                <Button type="submit">{t.confirm}</Button>
                            </ButtonBlock>
                        </form>
                    </TextBlock>
                </Modal>
            </Backdrop>
        </Wrapper>
    );
};

export default DcPaymentPopup;
