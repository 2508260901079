import logo1 from "../../../../../assets/images/partner-logos/air-astana.svg";
import logo2 from "../../../../../assets/images/partner-logos/belavia.svg";
import logo3 from "../../../../../assets/images/partner-logos/china-southern.svg";
import logo4 from "../../../../../assets/images/partner-logos/flydubai.svg";
import logo5 from "../../../../../assets/images/partner-logos/s7.svg";
import logo6 from "../../../../../assets/images/partner-logos/turkish.svg";
import logo7 from "../../../../../assets/images/partner-logos/ural-airline.svg";
import logo8 from "../../../../../assets/images/partner-logos/uzbekistan-airways.svg";

const partnerLogos = [
    { slug: 'partner-1', logo: logo1 },
    { slug: 'partner-2', logo: logo2 },
    { slug: 'partner-3', logo: logo3 },
    { slug: 'partner-4', logo: logo4 },
    { slug: 'partner-5', logo: logo5 },
    { slug: 'partner-6', logo: logo6 },
    { slug: 'partner-7', logo: logo7 },
    { slug: 'partner-8', logo: logo8 }
];

export default partnerLogos;