import styled from 'styled-components';

export const Block = styled('div')(({ theme }) => ({
    ...theme.mixins.flexDirectionColumnStart,
}));

export const Row = styled('div')(({ theme }) => ({
    ...theme.paddings(4, 0),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));

export const Checkbox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.rangeSlider,
    width: 15,
    height: 15,
    position: 'relative',
    border: `1px solid ${theme.palette.main}`,
    borderRadius: '50%',
    transition: 'border-color 0.26s ease',
    marginRight: 8,
    cursor: 'pointer',

    '&:after': {
        content: '',
        width: 8,
        height: 8,
        position: 'absolute',
        top: '50%',
        left: '50%',
        background: 'transparent',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'background-color 0.26s ease',
    },

    '&.active': {
        border: `3px solid ${theme.palette.main}`,
    },
}));

export const Text = styled('div')(() => ({
    color: 'inherit',
    lineHeight: 1.5,
    userSelect: 'none',
    cursor: 'pointer',
}));
