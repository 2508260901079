import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme }) => ({
    ...theme.marginsY(0, 16),

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        top: 'unset',
        bottom: 100,
    },
}));

export const DesktopBlock = styled('div')(({ theme }) => ({
    ...theme.paddings(8, 16),
    display: 'block',
    borderRadius: theme.spacing(1),
    boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
    backgroundColor: theme.palette.inputBackground,

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'none',
    },
}));

export const MobileBlock = styled('div')(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        display: 'block',
    },
}));
