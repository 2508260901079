import styled from "styled-components";

import bgPerson from "../../../../images/flyCash/bgPerson.png";

export const Wrapper = styled("section")(({ theme }) => ({
    background: `url(${bgPerson})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}));

export const InfoBlock = styled("div")(({ theme }) => ({
    ...theme.mixins.flexAlignCenter,
    ...theme.paddings(60, 25),
    gap: "5%",
    margin: "0 auto",
    maxWidth: 1280,
    height: "100%",
    position: "relative",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        flexDirection: "column",
        gap: 20,
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        ...theme.paddings(30, 15),
    }
}));

export const TextBlock = styled("div")(({ theme }) => ({
    width: "55%",

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: "100%",
        order: 1,
    },
}));

export const TitleBlock = styled("div")(({ theme }) => ({
    ...theme.margins(-60, 0),
    fontSize: 76,
    color: "#fff",
    fontWeight: 600,
    lineHeight: 1.3,

    [theme.breakpoints.down(theme.breakpoints.laptop)]: {
        fontSize: 64,
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        ...theme.margins(0, 0),
        textAlign: "center",
    },

    [theme.breakpoints.down(theme.breakpoints.mobile)]: {
        fontSize: 32,
    },
}));

export const ImageBlock = styled("div")(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    width: "40%",
    position: "relative",
    
    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: "100%",
        order: 2,
    },

    "& > img": {
        ...theme.margins(-120, 0, -60),
        width: "100%",

        [theme.breakpoints.down(theme.breakpoints.tablet)]: {
            ...theme.margins(0, 0, -60),
            width: "80%",
        },

        [theme.breakpoints.down(theme.breakpoints.mobile)]: {
            ...theme.margins(0, 0, -30),
            maxWidth: "60%",
        },
    }
}));