import { IconButton } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const Wrapper = styled('div')(({ isShow }) => ({
    display: isShow ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 250,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
}));

const Animation = styled.div`
  animation: ${props => (props.isShow ? fadeIn : fadeOut)};
`;

export const Backdrop = styled(Animation)(({ theme }) => ({
    ...theme.mixins.flexCenterCenter,
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 251,
    animationDuration: '0.3s',
    animationFillMode: 'forwards',
    backgroundColor: theme.palette.transparent,
}));

export const Modal = styled('div')(({ theme, fixedWidth, maxWidth }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    maxHeight: '90%',
    width: fixedWidth ? maxWidth : 'auto',
    maxWidth: maxWidth,
    position: 'relative',
    borderRadius: theme.spacing(1),
}));

export const Header = styled('div')(({ theme }) => ({
    ...theme.paddings(16, 16),
    ...theme.mixins.flexCenterSpaceBetween,
}));

export const Title = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: 18,
    color: '#404b5a',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
    ...theme.marginsX(0, 16),
    '&.MuiButtonBase-root': {
        color: theme.palette.mainText,
    }
}));

export const Body = styled('div')(({ theme }) => ({
    ...theme.paddings(0, 16, 16),
    overflowY: 'auto',
}));
