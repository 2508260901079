import { createContext } from 'react';
// import configs from '../../config';
import _ from 'lodash';

const language = null;
const langDataArray = JSON.parse(localStorage.getItem('available_languages'));

export default createContext({
    language,
    switchLang: () => {},
    t: langDataArray ? (_.isUndefined(langDataArray[language]) ? {} : langDataArray[language]) : {},
});
