import React, { useCallback, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { gapi } from 'gapi-script';

import {
    Navigation,
    NavigationLink,
    Form,
    FormTitle,
    FormSubtitle,
    FormGroup,
    FormSubmitButton,
    ForgotPasswordLink,
    LineWithText,
    SocialsButtons,
    SocialButton,
} from '../../components/design_v2/authPages/components';
import TextInput from '../../components/design_v2/ui/textInput';

import { nonAuthRoutes } from '../../config/routes';
import API from '../../lib/api';
import { setClientData } from '../../store/clientData/actions';
import { setAppConfigs } from '../../store/appConfigs/actions';
import useLocalization from '../../contexts/localization/hook';
import envConfigs from '../../config/envConfigs';
import { getPasswordWithPrefixAndPostfix } from '../../lib/password';
import { ReactComponent as GoogleIcon } from '../../assets/icons/googleIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebookIcon.svg';

const NewLoginPage = () => {
    const { isFullScreenSearchBlock } = useSelector(state => state.appConfigs);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useLocalization();
    const containerRef = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        login: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
        password: Yup.string().min(6, t.must_field_characters).required(t.required),
    });

    const responseFacebook = (response) => {
        const name = response.name.split(" ")

        API.authWiaSocials({
            firstName: name[0],
            lastName: name[1],
            email: response.id,
            password: `facebook-${response.id}`,
        })
            .then(res => {
                dispatch(
                    setClientData({
                        id: res.idp,
                        firstName: res.first_name,
                        lastName: res.last_name,
                        login: res.login,
                        balance: res.balance,
                        email: res.email,
                        phone: res.phone,
                        currency: res.currency,
                    })
                );

                dispatch(
                    setAppConfigs({
                        isAuth: true,
                    })
                );

                window.localStorage.setItem('token', res.token);

                history.push('/');
            })
            .catch(error => {
                console.log({ error });
            });
    }

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);

        const initClient = () => {
            gapi.client.init({
                clientId: envConfigs.googleAuthClientId,
                scope: '',
            });
        };
        gapi.load('client:auth2', initClient);

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, [isFullScreenSearchBlock]);

    const handleKeyPress = useCallback(e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleSubmit();
        }
    }, []);

    const handleFormSubmit = useCallback(
        values => {
            if (isLoading) {
                return;
            }

            setIsLoading(true);

            API.login(values)
                .then(response => {
                    dispatch(
                        setClientData({
                            id: response.data.idp,
                            firstName: response.data.first_name,
                            lastName: response.data.last_name,
                            login: response.data.login,
                            balance: response.data.balance,
                            email: response.data.email,
                            phone: response.data.phone,
                            currency: response.data.currency,
                        })
                    );

                    dispatch(
                        setAppConfigs({
                            isAuth: true,
                        })
                    );

                    window.localStorage.setItem('token', response.data.token);

                    history.push('/');
                })
                .catch(error => {
                    setFieldError('login', error.response.data.message);
                    setIsLoading(false);
                });
        },
        [isLoading]
    );

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldError } = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema,
        onSubmit: handleFormSubmit,
        validateOnBlur: true,
    });

    const onSuccess = res => {
        const { profileObj } = res;

        API.authWiaSocials({
            firstName: profileObj.givenName,
            lastName: profileObj.familyName,
            email: profileObj.email,
            password: getPasswordWithPrefixAndPostfix(profileObj.googleId),
        })
            .then(response => {
                dispatch(
                    setClientData({
                        id: response.idp,
                        firstName: response.first_name,
                        lastName: response.last_name,
                        login: response.login,
                        balance: response.balance,
                        email: response.email,
                        phone: response.phone,
                        currency: response.currency,
                    })
                );

                dispatch(
                    setAppConfigs({
                        isAuth: true,
                    })
                );

                window.localStorage.setItem('token', response.token);

                history.push('/');
            })
            .catch(error => {
                console.log({ error });
            });
    };

    const onFailure = err => {
        console.log('failed:', err);
    };

    return (
        <Container fixed ref={containerRef}>
            <Navigation>
                <NavigationLink active={true} to={nonAuthRoutes.login}>
                    {t.log_in}
                </NavigationLink>

                <NavigationLink to={nonAuthRoutes.registration}>{t.registration}</NavigationLink>
            </Navigation>

            <Form>
                <FormTitle>{t.log_in}</FormTitle>

                <FormSubtitle>{t.login_as_user}</FormSubtitle>

                <FormGroup error={errors.login}>
                    <TextInput
                        value={values.login}
                        onChangeText={handleChange('login')}
                        type="email"
                        required
                        placeholder={t.enter_email}
                        label={t.email}
                        error={errors.login}
                        onBlur={handleBlur('login')}
                        touched={touched.login}
                    />
                </FormGroup>

                <FormGroup error={errors.password}>
                    <TextInput
                        value={values.password}
                        onChangeText={handleChange('password')}
                        type="password"
                        required
                        placeholder={t.enter_password}
                        label={t.password_text}
                        error={errors.password}
                        onBlur={handleBlur('password')}
                        touched={touched.password}
                    />
                </FormGroup>
                <FormGroup>
                    <ForgotPasswordLink to={nonAuthRoutes.forgotPassword}>{t.forgot_password_text}</ForgotPasswordLink>
                </FormGroup>

                <FormSubmitButton onClick={handleSubmit} marginAdd>
                    {isLoading ? <CircularProgress color="inherit" size={26} /> : `${t.enter_text}`}
                </FormSubmitButton>

                <LineWithText>{t.or_use_socials || 'или используйте соц. сети'}</LineWithText>

                <SocialsButtons>
                    <GoogleLogin
                        clientId={envConfigs.googleAuthClientId}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        buttonText="Sign in with Google"
                        cookiePolicy={'single_host_origin'}
                        render={props => (
                            <SocialButton {...props}>
                                <GoogleIcon />
                            </SocialButton>
                        )}
                    />
                    <FacebookLogin
                        appId={envConfigs.facebookID}
                        fields="name,email,picture"
                        scope="public_profile"
                        callback={responseFacebook}
                        icon="fa-facebook"
                        render={props => (
                            <SocialButton {...props}>
                                <FacebookIcon />
                            </SocialButton>
                        )}
                    />
                </SocialsButtons>
            </Form>
        </Container>
    );
};

export default withRouter(NewLoginPage);
